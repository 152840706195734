<div class="menu-title" *ngIf="showTitle">{{menu.name}}</div>

<ng-container *ngFor="let item of menu.items">
    <ng-container *ngIf="shouldShow(item?.condition)">
        <ng-container [ngSwitch]="getItemType(item)">

            <a *ngSwitchCase="'link'" class="nav-item custom-menu-item link-nav-item {{itemClass}}" [href]="item.action" [ngClass]="toSnakeCase(item.label)" [target]="item.target">
                <mat-icon [svgIcon]="item.icon" *ngIf="item.icon"></mat-icon>
                <span class="text" trans>{{item.label}}</span>
            </a>

            <ng-container *ngIf="parseRoute(item.action) as route">
                <a *ngSwitchCase="'route'" class="nav-item custom-menu-item route-nav-item {{itemClass}}" [routerLink]="'/' + route.link" [queryParams]="route.queryParams" [ngClass]="toSnakeCase(item.label)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: item.action === '/' || item.activeExact !== false}" [target]="item.target">
                    <mat-icon [svgIcon]="item.icon" *ngIf="item.icon"></mat-icon>
                    <span class="text" trans>{{item.label}}</span>
                </a>
            </ng-container>

            <a *ngSwitchCase="'page'" class="nav-item custom-menu-item page-nave-item {{itemClass}}" [routerLink]="'/pages/' + item.action" [ngClass]="toSnakeCase(item.label)" routerLinkActive="active" [routerLinkActiveOptions]="{exact: item.activeExact !== false}" [target]="item.target">
                <mat-icon [svgIcon]="item.icon" *ngIf="item.icon"></mat-icon>
                <span class="text" trans>{{item.label}}</span>
            </a>
        </ng-container>
    </ng-container>
</ng-container>

<a download class="nav-item custom-menu-item page-nav-item flex items-center ml-[26px] mt-[14px] gap-[18px]"
   href="/assets/AyrDrive.zip">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
         stroke-width="2" stroke="currentColor" class="w-[20px] h-[20px] text-gray-500">
        <path stroke-linecap="round" stroke-linejoin="round"
              d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"/>
    </svg>
    <span class="text text-gray-500" trans>Desktop</span>
</a>

<ng-content></ng-content>
