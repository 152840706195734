<div class="fixed top-0 left-0 z-50">
    <a href="/pricing"
       class="group left-0 z-50 h-[54px] w-screen cursor-pointer items-center justify-center overflow-hidden bg-[#0066ff] text-white md:flex">
        <div
            class="mx-auto text-[16px] flex flex-row items-center justify-center space-x-3">
            <div class="flex flex-row space-x-1">
                <p class="flex flex-row font-semibold">
                    91% OFF on All Annual Plans - Limited Time Offer
                </p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
            </svg>
        </div>
    </a>
    <nav
        class="border-b-[1px] border-gray-300 flex items-center h-[64px] py-[32px] pr-[24px] opacity-95 w-full bg-white transition-all duration-100 lg:h-16 undefined backdrop-blur-lg backdrop-saturate-150 backdrop-filter">
        <div class="mx-[24px] w-full lg:mx-[56px] xl:mx-[96px]">
            <div
                class="mx-auto flex max-w-screen-xl w-screen-xl items-center justify-between">
                <div
                    class="text-[18px] text-[#4D5358] flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-start space-x-[16px] lg:space-x-[24px]">
                    <a style="cursor: pointer" class="flex flex-shrink-0"
                       href="/">
                        <img
                            class="select-none"
                            src="/assets/images/homepage/AyrDrive.svg"
                            alt="AyrDrive logo"/>
                    </a>
                    <span class="w-[16px]"></span>
                    <a class="sm:hidden md:block whitespace-nowrap py-1.5 px-[12px] transition duration-150 ease-in-out text-[#4D5358] hover:text-[#0066ff]"
                       href="/pricing">Pricing</a>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 items-center transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl">
                        <span>Products</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none absolute top-full left-1/2 z-50 w-[196px] -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div
                                class="relative grid gap-0 whitespace-nowrap lg:grid-cols-1">
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/drive-info">AyrDrive Storage</a>
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/photos">AyrDrive Photos</a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl items-center">
                        <span>Our Values</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none text-[#4D5358] absolute top-full left-1/2 z-50 w-52 -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div class="relative grid gap-0 lg:grid-cols-1"><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/privacy">Privacy</a><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/open-source">Open Source</a></div>
                        </div>
                    </div>
                    <a class="whitespace-nowrap text-[#4D5358] py-1.5 px-[12px] transition duration-150 ease-in-out hover:text-[#0066ff] font-medium"
                       href="/about">About Us</a>
                </div>
                <div
                    class="space-x-[16px] inline-flex text-[18px] font-medium text-[#4D5358]">
                    <div
                        class="flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-end gap-[16px]">
                        <a id="loginButton" href="/login"
                           class="whitespace-nowrap rounded-xl border px-[12px] py-[4px] transition duration-150 ease-in-out focus:border focus:outline-none md:flex border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff] hover:bg-opacity-10 active:border-[#0066ff]-dark active:text-[#0066ff]-dark text-[14px] font-medium">
                            Log in
                        </a>
                        <a href="/register"
                           class="flex justify-center rounded-xl border border-transparent py-[4px] px-[12px] text-[14px] font-medium focus:outline-none sm:inline-flex bg-[#0066ff] text-white hover:bg-[#0066ff]-dark active:bg-[#0066ff]-dark transition-all duration-75">
                            <p class="whitespace-nowrap">Get started</p></a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<section class="relative flex w-full flex-col overflow-hidden pt-[128px] pb-[64px]">
    <div
        class="relative flex flex-col items-center justify-center">
        <div class="flex flex-col items-center justify-center space-y-[64px] px-6">
            <div class="flex flex-col space-y-[36px] text-center"><h1
                class="max-w-[796px] text-[#18181b] text-[60px] font-semibold leading-tight sm:leading-tight">
                We specialize in creating applications that uphold and <br/>
                <span class="text-[#0066ff]">prioritize digital rights</span>
            </h1>
                <p class="text-[20px] font-normal text-[#3a3a3b]">
                    We create a reliable product and service, respecting users and their
                    needs
                </p></div>
            <div class="grid max-w-6xl grid-flow-row grid-cols-6 gap-[36px]">
                <div
                    class="col-span-6 h-60 select-none overflow-hidden rounded-[25px] bg-red-old-10 sm:h-72 md:col-span-4 lg:h-[192px] drop-shadow-[0_15px_15px_rgba(0,0,0,0.25)]">
                    <img loading="eager" class="h-full w-full object-cover object-center"
                         src="/assets/images/homepage/Internxt-headquarters.jpg"
                         draggable="false" alt="Internxt headquarters"></div>
                <div
                    class="col-span-6 h-60 select-none overflow-hidden rounded-[25px] bg-red-old-10 sm:h-72 md:col-span-2 lg:h-[192px] drop-shadow-[0_15px_15px_rgba(0,0,0,0.25)]">
                    <img loading="eager" class="h-full w-full object-cover object-center"
                         src="/assets/images/homepage/Internxt-office.png"
                         draggable="false"
                         alt="AyrDrive office"></div>
                <div
                    class="col-span-6 h-60 select-none overflow-hidden rounded-[25px] bg-red-old-10 sm:h-72 md:col-span-3 lg:h-[192px] drop-shadow-[0_15px_15px_rgba(0,0,0,0.25)]">
                    <img loading="eager" class="h-full w-full object-cover object-center"
                         src="/assets/images/homepage/Internxt-team.jpg" draggable="false"
                         alt="AyrDrive team"></div>
                <div
                    class="col-span-6 h-60 select-none overflow-hidden rounded-[25px] bg-red-old-10 sm:h-72 md:col-span-3 lg:h-[192px] drop-shadow-[0_15px_15px_rgba(0,0,0,0.25)]">
                    <img loading="eager" class="h-full w-full object-cover object-center"
                         src="/assets/images/homepage/team-at-Internxt-1.webp"
                         draggable="false" alt="Team at AyrDrive"></div>
            </div>
            <h4 class="text-center text-[36px] font-semibold text-[#18181b]">Proudly
                commended for remarkable work</h4>
            <div class="flex flex-col space-y-5 lg:flex-row lg:space-x-20 lg:space-y-0">
                <div class="flex flex-auto flex-shrink-0 pb-12 sm:p-0 sm:px-12">
                    <div class="flex flex-shrink-0 flex-col items-center space-y-3"><span
                        style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                        style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27125%27%20height=%2732%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                        alt="Forbes Logo" draggable="false"
                        src="/assets/images/homepage/forbes.webp"
                        decoding="async" data-nimg="intrinsic"
                        style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;"
                    ></span>
                    </div>
                </div>
                <div class="flex flex-auto flex-shrink-0 flex-col pb-12 sm:p-0 sm:px-12">
                    <div class="flex flex-shrink-0 flex-col items-center space-y-3"><span
                        style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                        style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2770%27%20height=%2732%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                        alt="South Summit Logo" draggable="false"
                        src="/assets/images/homepage/southsummit.webp"
                        decoding="async" data-nimg="intrinsic"
                        style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;"
                    ></span>
                    </div>
                </div>
                <div class="flex flex-auto flex-shrink-0 flex-col pb-12 sm:p-0 sm:px-12">
                    <div class="flex flex-shrink-0 flex-col items-center space-y-3"><span
                        style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                        style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27112%27%20height=%2732%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                        alt="TNW Logo" draggable="false"
                        src="/assets/images/homepage/tnw.webp"
                        decoding="async" data-nimg="intrinsic"
                        style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;"
                    ></span>
                    </div>
                </div>
                <div class="flex flex-auto flex-shrink-0 flex-col pb-12 sm:p-0 sm:px-12">
                    <div class="flex flex-shrink-0 flex-col items-center space-y-3"><span
                        style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                        style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%2790%27%20height=%2732%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                        alt="Startup Valencia Logo" draggable="false"
                        src="/assets/images/homepage/startupvalencia.webp"
                        decoding="async" data-nimg="intrinsic"
                        style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;"
                    ></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#f9f9fc] py-[64px]">
    <div class="flex flex-col items-center justify-center space-y-[64px] px-[20px]"><p
        class="text-center text-[60px] font-semibold text-[#18181b]">The AyrDrive
        story</p>
        <div
            class="flex flex-col-reverse items-center justify-center lg:flex-row lg:space-x-20">
            <div class="revealXRight flex pt-7 lg:pt-0 active"><span
                style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                alt="" aria-hidden="true"
                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27496%27%20height=%27520%27/%3e"
                style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                alt="Fran Villalba Segarra"
                class="rounded-[25px]"
                src="/assets/images/homepage/pick10.png"
                decoding="async" data-nimg="intrinsic"
                style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; min-width: 100%; max-width: 100%; max-height: 100%;"></span>
            </div>
            <div
                class="flex max-w-[400px] flex-col items-center justify-center space-y-5 text-center lg:text-left">
                <p class="text-[24px] font-medium text-[#18181b]">
                    Established in 2022 by visionary entrepreneur Eddy Parker, AyrDrive
                    is a cutting-edge cloud storage service based in Europe
                </p>
                <div class="flex flex-col space-y-5"><p
                    class="text-[20px] text-[#3a3a3b]">
                    Founded on a passion for user empowerment and technological ethics,
                    AyrDrive addresses rising concerns about digital privacy. The core
                    belief is that individuals deserve control over their digital lives,
                    guiding the company through all stages of development
                </p>
                    <p class="text-[20px] text-[#3a3a3b]">
                        Guided by a core belief, our company decisions have consistently
                        affirmed that privacy is a fundamental right since day one
                    </p>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-center justify-center lg:flex-row lg:space-x-20">
            <div
                class="flex max-w-[400px] flex-col items-center justify-center space-y-5 text-center lg:text-left">
                <p class="text-[24px] font-medium text-[#18181b]">
                    AyrDrive aims to revolutionize cloud services with a focus on
                    ethical data management offered by Big Tech
                </p>
                <div class="flex flex-col space-y-5">
                    <p class="text-[20px] text-[#3a3a3b]">
                        Looking ahead, AyrDrive is diligently expanding its repertoire
                        of services, pushing the boundaries of innovation to introduce
                        features that prioritize both security and seamless user
                        experience
                    </p>
                    <p class="text-[20px] text-[#3a3a3b]">
                        AyrDrive's goal is to create a secure cloud ecosystem,
                        empowering users with control over their digital privacy
                    </p>
                </div>
            </div>
            <div class="revealXLeft flex pt-7 lg:pt-0 active"><span
                style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                alt="" aria-hidden="true"
                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27496%27%20height=%27520%27/%3e"
                style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                alt="Work at AyrDrive"
                class="rounded-[25px]"
                src="/assets/images/homepage/pick12.png"
                decoding="async" data-nimg="intrinsic"
                style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; min-width: 100%; max-width: 100%; max-height: 100%;"></span>
            </div>
        </div>
    </div>
</section>

<div class="flex flex-col items-center justify-center py-[64px] px-[20px] bg-[#f9f9fc] bg-white">
    <section class="overflow-hidden max-w-screen-xl bg-[#f9f9fc] bg-white">
        <div class="revealY flex-row items-start justify-center lg:flex active">
            <div class="revealXRight flex max-w-[250px] flex-col active">
                <div *ngIf="tab !== 0"
                     class="flex border-gray-80 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=0"
                       class=" text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Innovative Storage Technologies
                    </p>
                </div>
                <div *ngIf="tab === 0"
                     class="flex border-gray-500 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=0"
                       class=" text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Innovative Storage Technologies
                    </p>
                </div>
                <div class="h-[16px] border-r-4 border-gray-10 pr-8 last:hidden"></div>
                <div *ngIf="tab !== 1"
                     class="flex border-gray-10 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=1"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Personalized File Management
                    </p>
                </div>
                <div *ngIf="tab === 1"
                     class="flex border-gray-500 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=1"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Personalized File Management
                    </p>
                </div>
                <div class="h-[16px] border-r-4 border-gray-10 pr-8 last:hidden"></div>
                <div *ngIf="tab !== 2"
                     class="flex border-gray-10 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=2"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Comprehensive File Versioning
                    </p>
                </div>
                <div *ngIf="tab === 2"
                     class="flex border-gray-500 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=2"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Comprehensive File Versioning
                    </p>
                </div>
                <div class="h-[16px] border-r-4 border-gray-10 pr-8 last:hidden"></div>
                <div *ngIf="tab !== 3"
                     class="flex border-gray-10 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=3"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Guarding against scams and viruses
                    </p>
                </div>
                <div *ngIf="tab === 3"
                     class="flex border-gray-500 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=3"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Guarding against scams and viruses
                    </p>
                </div>
                <div class="h-[16px] border-r-4 border-gray-10 pr-8 last:hidden"></div>
            </div>
            <div class="flex flex-col justify-start">
                <div class="flex w-auto justify-center px-[24px]">
                    <div class="flex flex-col rounded-3xl pl-6">
                        <div class="flex w-full max-w-[384px] flex-col space-y-6">
                            <div *ngIf="tab===0">
                                <svg fill="currentColor"
                                     class="text-[#0066ff]" viewBox="-0.5 -0.5 48 48"
                                     xmlns="http://www.w3.org/2000/svg" height="48"
                                     width="48">
                                    <path
                                        d="M37.504041666666666 28.342958333333335S46.02083333333333 27.24825 46.02083333333333 18.604166666666664a9.774041666666665 9.774041666666665 0 0 0 -10.144166666666665 -9.776A13.686791666666666 13.686791666666666 0 0 0 9.83475 13.865 7.34375 7.34375 0 0 0 0.9791666666666666 21.052083333333332c0 7.459291666666667 7.510208333333333 7.332 7.510208333333333 7.332"
                                        fill="none" stroke="#0066ff"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"></path>
                                    <path
                                        d="M42.104166666666664 41.125a4.895833333333333 4.895833333333333 0 0 1 -4.895833333333333 4.895833333333333H9.791666666666666A4.895833333333333 4.895833333333333 0 0 1 4.895833333333333 41.125v-4.895833333333333h37.20833333333333Z"
                                        fill="none" stroke="#0066ff"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"></path>
                                    <path
                                        d="m4.895833333333333 36.229166666666664 4.568791666666667 -9.971833333333333A3.3291666666666666 3.3291666666666666 0 0 1 12.239583333333332 24.479166666666664H33.291666666666664a3.789375 3.789375 0 0 1 2.9375 1.6900416666666667l5.875 10.059958333333332Z"
                                        fill="none" stroke="#0066ff"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"></path>
                                    <path d="m29.375 42.104166666666664 5.875 0"
                                          fill="none" stroke="#0066ff"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"></path>
                                </svg>
                            </div>
                            <svg width="48" height="48" *ngIf="tab===1"
                                 fill="currentColor"
                                 class="text-[#0066ff]"
                                 viewBox="-0.5 -0.5 48 48"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.8125 14.6875a3.9166666666666665 3.9166666666666665 0 1 0 7.833333333333333 0 3.9166666666666665 3.9166666666666665 0 1 0 -7.833333333333333 0Z"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m26.4375 24.87083333333333 -3.0824166666666666 -4.933041666666667a0.9791666666666666 0.9791666666666666 0 0 0 -1.6606666666666665 0L16.524416666666667 28.2l-2.1071666666666666 -3.3722499999999997a0.9791666666666666 0.9791666666666666 0 0 0 -1.6606666666666665 0L6.854166666666666 34.27083333333333h7.833333333333333"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M18.604166666666664 46.02083333333333h-15.666666666666666a1.9583333333333333 1.9583333333333333 0 0 1 -1.9583333333333333 -1.9583333333333333v-41.125a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 -1.9583333333333333h26.032124999999997a1.9583333333333333 1.9583333333333333 0 0 1 1.3845416666666666 0.5737916666666666L37.613708333333335 8.8125a1.9583333333333333 1.9583333333333333 0 0 1 0.5737916666666666 1.3845416666666666V20.5625"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M24.479166666666664 31.9365V44.0625a1.9583333333333333 1.9583333333333333 0 0 0 1.9583333333333333 1.9583333333333333h13.708333333333332a1.9583333333333333 1.9583333333333333 0 0 0 1.9583333333333333 -1.9583333333333333v-12.126"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m20.5625 35.25 11.464083333333333 -9.699625000000001a1.9583333333333333 1.9583333333333333 0 0 1 2.5301666666666667 0L46.02083333333333 35.25"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M36.229166666666664 46.02083333333333h-5.875v-5.875a2.9375 2.9375 0 0 1 5.875 0Z"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                 *ngIf="tab===2"
                                 class="text-[#0066ff]"
                                 viewBox="-0.5 -0.5 48 48" height="48" width="48">
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="m27.382591666666666 19.372773333333335 -1.914075 -7.751631666666666 -7.736180416666667 1.8636674999999998"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M43.88370416666666 28.353337500000002v15.530366666666666c0 0.5148458333333333 -0.2046458333333333 1.0087375 -0.5687 1.3727916666666666 -0.3640541666666667 0.3640541666666667 -0.85775 0.5685041666666667 -1.3725958333333332 0.5685041666666667H6.998848333333333c-0.5148654166666666 0 -1.0086395833333333 -0.20445 -1.3726937499999998 -0.5685041666666667 -0.36407375 -0.3640541666666667 -0.5686020833333333 -0.8579458333333333 -0.5686020833333333 -1.3727916666666666v-15.530366666666666"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M26.411845833333334 24.468787499999998H45.824999999999996"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M1.174953 24.468787499999998H14.764090416666667"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M25.441295833333335 11.621141666666666c-3.115708333333333 3.530248333333333 -4.842174999999999 8.072445833333333 -4.857841666666666 12.7810625 -0.015666666666666666 4.708420833333333 1.6802499999999998 9.262133333333333 4.7722625 12.813179166666666"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M5.057591666666666 2.1456675c-0.2680370833333333 0 -0.48531416666666666 -0.21729079166666665 -0.48531416666666666 -0.4853298333333333 0 -0.2680370833333333 0.21727708333333332 -0.48532591666666663 0.48531416666666666 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M5.057513333333333 2.1456675c0.2680370833333333 0 0.48531416666666666 -0.21729079166666665 0.48531416666666666 -0.4853298333333333 0 -0.2680370833333333 -0.21727708333333332 -0.48532591666666663 -0.48531416666666666 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M12.822677083333332 2.1456675c-0.2680370833333333 0 -0.48531416666666666 -0.21729079166666665 -0.48531416666666666 -0.4853298333333333 0 -0.2680370833333333 0.21727708333333332 -0.48532591666666663 0.48531416666666666 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M12.82283375 2.1456675c0.2680370833333333 0 0.48531416666666666 -0.21729079166666665 0.48531416666666666 -0.4853298333333333 0 -0.2680370833333333 -0.21727708333333332 -0.48532591666666663 -0.48531416666666666 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M20.587958333333333 2.1456675c-0.2680958333333333 0 -0.48547083333333335 -0.21729079166666665 -0.48547083333333335 -0.4853298333333333 0 -0.2680370833333333 0.21737499999999998 -0.48532591666666663 0.48547083333333335 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M20.587958333333333 2.1456675c0.2680958333333333 0 0.48547083333333335 -0.21729079166666665 0.48547083333333335 -0.4853298333333333 0 -0.2680370833333333 -0.21737499999999998 -0.48532591666666663 -0.48547083333333335 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M28.353337500000002 2.1456675c-0.2680958333333333 0 -0.48527499999999996 -0.21729079166666665 -0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 0.21717916666666665 -0.48532591666666663 0.48527499999999996 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M28.353141666666666 2.1456675c0.2680958333333333 0 0.48547083333333335 -0.21729079166666665 0.48547083333333335 -0.4853298333333333 0 -0.2680370833333333 -0.21737499999999998 -0.48532591666666663 -0.48547083333333335 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M36.118520833333335 2.1456675c-0.2680958333333333 0 -0.48527499999999996 -0.21729079166666665 -0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 0.21717916666666665 -0.48532591666666663 0.48527499999999996 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M36.118520833333335 2.1456675c0.2679 0 0.48527499999999996 -0.21729079166666665 0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 -0.21737499999999998 -0.48532591666666663 -0.48527499999999996 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 2.1456675c-0.2680958333333333 0 -0.48527499999999996 -0.21729079166666665 -0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 0.21717916666666665 -0.48532591666666663 0.48527499999999996 -0.48532591666666663"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 2.1456675c0.2679 0 0.48527499999999996 -0.21729079166666665 0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 -0.21737499999999998 -0.48532591666666663 -0.48527499999999996 -0.48532591666666663"
                                          stroke-width="2"></path>
                                </g>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M5.057591666666666 9.912848333333333c-0.2680370833333333 0 -0.48531416666666666 -0.21729666666666667 -0.48531416666666666 -0.48533374999999995s0.21727708333333332 -0.48533374999999995 0.48531416666666666 -0.48533374999999995"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M5.057513333333333 9.912848333333333c0.2680370833333333 0 0.48531416666666666 -0.21729666666666667 0.48531416666666666 -0.48533374999999995s-0.21727708333333332 -0.48533374999999995 -0.48531416666666666 -0.48533374999999995"
                                          stroke-width="2"></path>
                                </g>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M5.057591666666666 17.680009583333334c-0.2680370833333333 0 -0.48531416666666666 -0.21727708333333332 -0.48531416666666666 -0.48531416666666666 0 -0.2680370833333333 0.21727708333333332 -0.48533374999999995 0.48531416666666666 -0.48533374999999995"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M5.057513333333333 17.680009583333334c0.2680370833333333 0 0.48531416666666666 -0.21727708333333332 0.48531416666666666 -0.48531416666666666 0 -0.2680370833333333 -0.21727708333333332 -0.48533374999999995 -0.48531416666666666 -0.48533374999999995"
                                          stroke-width="2"></path>
                                </g>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 9.912848333333333c-0.2680958333333333 0 -0.48527499999999996 -0.21729666666666667 -0.48527499999999996 -0.48533374999999995s0.21717916666666665 -0.48533374999999995 0.48527499999999996 -0.48533374999999995"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 9.912848333333333c0.2679 0 0.48527499999999996 -0.21729666666666667 0.48527499999999996 -0.48533374999999995s-0.21737499999999998 -0.48533374999999995 -0.48527499999999996 -0.48533374999999995"
                                          stroke-width="2"></path>
                                </g>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 17.680009583333334c-0.2680958333333333 0 -0.48527499999999996 -0.21727708333333332 -0.48527499999999996 -0.48531416666666666 0 -0.2680370833333333 0.21717916666666665 -0.48533374999999995 0.48527499999999996 -0.48533374999999995"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 17.680009583333334c0.2679 0 0.48527499999999996 -0.21727708333333332 0.48527499999999996 -0.48531416666666666 0 -0.2680370833333333 -0.21737499999999998 -0.48533374999999995 -0.48527499999999996 -0.48533374999999995"
                                          stroke-width="2"></path>
                                </g>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" *ngIf="tab===3"
                                 class="text-[#0066ff]"
                                 viewBox="-0.5 -0.5 48 48" height="48" width="48">
                                <defs></defs>
                                <path
                                    d="M22.742124999999998 11.505208333333332a10.533874999999998 10.533874999999998 0 1 0 21.067749999999997 0 10.533874999999998 10.533874999999998 0 1 0 -21.067749999999997 0"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m40.72354166666667 18.95275 5.156291666666666 5.518583333333333"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m9.791666666666666 46.028666666666666 27.416666666666664 0"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path d="m22.520833333333332 42.112 0 3.9166666666666665"
                                      fill="none" stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M46.02083333333333 30.183791666666664V39.166666666666664a2.9375 2.9375 0 0 1 -2.9375 2.9375H3.9166666666666665A2.9375 2.9375 0 0 1 0.9791666666666666 39.166666666666664V9.791666666666666A2.9375 2.9375 0 0 1 3.9166666666666665 6.854166666666666h14.5465"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M21.958791666666663 26.65683333333333a5.875 5.875 0 1 1 -11.767625 0v-2.9375a5.875 5.875 0 1 1 11.767625 0Z"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m10.191166666666666 23.715416666666666 11.767625 0"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path d="m5.781 26.65683333333333 4.410166666666666 0"
                                      fill="none" stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m21.958791666666663 26.65683333333333 4.410166666666666 0"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m8.230875000000001 34.50191666666666 3.6836249999999997 -3.6836249999999997"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path d="m7.24975 16.359916666666663 3.995 3.995"
                                      fill="none" stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m23.919083333333333 34.50191666666666 -3.6836249999999997 -3.6836249999999997"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                            </svg>

                            <p class="text-[36px] font-semibold text-[#18181d]">{{ tabData[tab].header }}</p>
                            <p class="text-[24px] font-normal text-gray-600">{{ tabData[tab].text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-center justify-center space-y-10 px-5 lg:hidden">
            <div
                class="flex flex-col items-start justify-start rounded-3xl bg-white p-[40px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" viewBox="0 0 256 256"
                     class="mb-6 text-[48px] text-[#0066ff]">
                    <path
                        d="M207.06,80.67A111.24,111.24,0,0,0,128,48h-.4C66.07,48.21,16,99,16,161.13V184a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V160A111.25,111.25,0,0,0,207.06,80.67ZM224,184H119.71l54.76-75.3a8,8,0,0,0-12.94-9.42L99.92,184H32V161.13c0-3.08.15-6.12.43-9.13H56a8,8,0,0,0,0-16H35.27c10.32-38.86,44-68.24,84.73-71.66V88a8,8,0,0,0,16,0V64.33A96.14,96.14,0,0,1,221,136H200a8,8,0,0,0,0,16h23.67c.21,2.65.33,5.31.33,8Z"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col"><p
                    class="mb-6 text-[36px] font-medium text-[#18181d]">Speed without
                    limits</p>
                    <p class="text-[24px] text-[#636367]">Have the need for speed?
                        Our distributed service is optimized across lightning-fast servers
                        scattered throughout the EU, so you can get the best performance
                        out
                        of your connection.</p></div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-3xl bg-white p-[40px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" viewBox="0 0 256 256"
                     class="mb-6 text-[48px] text-[#0066ff]">
                    <path
                        d="M246,98.73l-56-64A8,8,0,0,0,184,32H72a8,8,0,0,0-6,2.73l-56,64a8,8,0,0,0,.17,10.73l112,120a8,8,0,0,0,11.7,0l112-120A8,8,0,0,0,246,98.73ZM222.37,96H180L144,48h36.37ZM74.58,112l30.13,75.33L34.41,112Zm89.6,0L128,202.46,91.82,112ZM96,96l32-42.67L160,96Zm85.42,16h40.17l-70.3,75.33ZM75.63,48H112L76,96H33.63Z"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col"><p
                    class="mb-6 text-[24px] font-medium text-[#18181d]">Frictionless
                    workflows</p>
                    <p class="text-[24px] text-[#636367]">AyrDrive products are
                        streamlined to be as usable as possible while providing a clean
                        look
                        to enhance and boost your productivity. Business security, made
                        easy.</p></div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-3xl bg-white p-[40px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" viewBox="0 0 256 256"
                     class="mb-6 text-[48px] text-[#0066ff]">
                    <path
                        d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm37.66-93.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L144,148.69l42.34-42.35A8,8,0,0,1,197.66,106.34Z"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col"><p
                    class="mb-6 text-[24px] font-medium text-[#18181d]">Backup storage on
                    demand</p>
                    <p class="text-[24px] text-[#636367]">Save your data from
                        disaster with built-in redundancy. Back up your essential work and
                        projects, across multiple fast servers throughout the EU, and
                        access
                        them from all your devices.</p></div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-3xl bg-white p-[40px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" viewBox="0 0 256 256"
                     class="mb-6 text-[48px] text-[#0066ff]">
                    <path
                        d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V158.75l-26.07-26.06a16,16,0,0,0-22.63,0l-20,20-44-44a16,16,0,0,0-22.62,0L40,149.37V56ZM40,172l52-52,80,80H40Zm176,28H194.63l-36-36,20-20L216,181.38V200ZM144,100a12,12,0,1,1,12,12A12,12,0,0,1,144,100Z"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col"><p
                    class="mb-6 text-[24px] font-medium text-[#18181d]">Protection for
                    your
                    best
                    moments</p>
                    <p class="text-[24px] text-[#636367]">Store all your photos in
                        one gallery and access them from everywhere. Save your memories
                        straight to your phone and view them from anywhere.</p></div>
            </div>
        </div>
    </section>
</div>

<section class="overflow-hidden  py-[64px] bg-[#f9f9fc]">
    <div
        class="flex flex-col items-center justify-center px-[20px] lg:flex-row lg:space-x-20">
        <div class="revealXRight undefined active rounded-lg">
            <span
                style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;">
                <span
                    style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;">
                    <img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27496%27%20height=%27520%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                draggable="false" alt="Protect your data"
                class="rounded-[25px]"
                src="/assets/images/homepage/secure-file-storage.jpeg"
                decoding="async" data-nimg="intrinsic"
                style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;"></span>
        </div>
        <div
            class="revealXLeft flex w-full max-w-[388px] flex-col items-center space-y-[24px] pt-8 text-center lg:items-start lg:pt-0 lg:text-start active">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                 fill="currentColor" viewBox="-0.5 -0.5 48 48" class="text-[#0066ff]">
                <g>
                    <path
                        d="m34.27083333333333 13.708333333333332 -13.297083333333333 12.415833333333332a1.9583333333333333 1.9583333333333333 0 0 1 -2.722083333333333 0L14.6875 22.520833333333332"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M4.895833333333333 2.9375a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 -1.9583333333333333h33.291666666666664a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 1.9583333333333333v12.259166666666665A34.81916666666667 34.81916666666667 0 0 1 23.5 46.02083333333333 34.81916666666667 34.81916666666667 0 0 1 4.895833333333333 15.196666666666665Z"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                </g>
            </svg>
            <p class="text-[48px] font-semibold text-[#18181b]">Protect your files with
                AyrDrive</p>
            <p class="text-[24px] text-[#3a3a3b]">
                Protect your data using AyrDrive
                With our service, you can be confident that only you have access to your
                files. Storage, sharing, and backup are securely and privately encrypted
            </p>
            <button
                class="flex w-max text-[16px] items-center rounded-lg bg-[#0066ff] px-[20px] py-[12px] font-medium text-white"
            >
                Try it out
            </button>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#0066ff] py-[64px]">
    <div
        class="flex flex-col items-center justify-center space-y-8 px-[20px] text-center">
        <div class="flex flex-col items-center space-y-[16px] text-center text-white">
            <p
                class="text-[36px] font-semibold">
                Try AyrDrive risk-free
            </p>
            <p class="w-full max-w-[570px] text-[20px] font-normal">
                Ensure the security and preservation of your files with our reliable cloud
                data storage service
            </p>
        </div>
        <a href="/register"
           class="flex rounded-lg bg-white px-[20px] py-[12px] text-[18px] font-medium
            text-[#0066ff] hover:bg-[#ffffffee]">
            Get started
        </a>
    </div>
</section>

<div class="flex w-full flex-col items-center justify-center px-[24px] py-[64px] sm:p-20 sm:py-12">
    <div
        class="flex w-full max-w-screen-md flex-col items-center justify-between space-y-8 pb-9 text-center lg:flex-row lg:items-start lg:space-y-0 lg:space-x-32 lg:text-left">
        <div
            class="flex w-full max-w-[384px] flex-col items-center justify-center space-y-3 lg:items-start">
            <div class="flex flex-col space-y-1">
                <h2 class="text-[24px] font-medium ">
                    Download AyrDrive
                </h2>
                <p class="text-gray-600 text-[16px]">
                    Smooth data management and secure cloud storage are available with the
                    AyrDrive desktop app
                </p>
            </div>
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                <div class="flex"><a
                    class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    download href="/assets/AyrDrive.zip">
                    <span>Download for
                        <!-- --> Windows</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                         fill="currentColor" viewBox="0 0 256 256">
                        <path
                            d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                    </svg>
                </a></div>
            </div>
        </div>
        <div
            class="flex mb-10 max-w-[384px] flex-col items-center justify-center space-y-3 text-center md:items-start md:text-left ">
            <div class="flex w-full flex-col space-y-1"><h2
                class="text-[24px] font-medium">Newsletter</h2>
                <p class="text-[16px] text-gray-600">
                    Want to stay informed about our latest products and updates? Enter
                    your email address, and we'll take care of the rest!
                </p>
            </div>
            <form data-code="r3s4c1" method="post" target="_blank" rel="noopener"
                  action=""
                  class="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
                <input type="hidden" name="ml-submit" value="1">
                <input type="email" name="fields[email]"
                       placeholder="Your email address"
                       class="flex h-auto w-full flex-row rounded-lg px-[12px] py-3 text-[18px] outline-none sm:py-2 sm:text-[16px] md:w-64 border-cool-gray-20 bg-white focus:border-blue-50 focus:ring-opacity-20 mb-2 appearance-none border text-left transition-all duration-150 focus:ring focus:ring-primary"
                       required="">
                <input type="submit" name="signup" value="Subscribe"
                       class="ml-2 flex w-full cursor-pointer items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[12px] py-3 text-[18px] font-medium text-white transition-all duration-75 hover:bg-[#0066ff]-dark focus:outline-none active:bg-[#0066ff]-dark sm:mb-2 sm:py-2 sm:text-[16px]">
            </form>
            <span class="text-[16px] text-gray-400">By subscribing you agree to our
                <a href="/legal" class="cursor-pointer underline visited:text-gray-400">
                    privacy policy
                </a>
            </span>
        </div>
    </div>
    <footer class="flex max-w-screen-md w-[100%] items-center justify-center">
        <div
            class="w-[100%] flex-col items-center justify-center md:space-y-[56px] lg:flex">
            <div
                class="flex w-[100%] flex-row justify-between md:justify-between lg:space-x-[80px]">
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Products</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/drive">AyrDrive
                                Storage</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/photos">AyrDrive
                                Photos</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/pricing">Pricing</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Company</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/about">About Us</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/privacy">Privacy</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="flex max-w-[200px] flex-row items-center hover:text-[#0066ff]"
                                href="/open-source">Open Source</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/legal">Legal</a></p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Join Us</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a href="/register"
                                                               target="_top"
                                                               class="hover:text-[#0066ff]">Create
                                Account</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/login?" target="_top">Log In</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/assets/images/homepage/AyrDrive-white-paper.pdf"
                                target="_blank"
                                rel="noreferrer" download="" class="hover:text-[#0066ff]">White
                                Paper</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex w-full max-w-[900px] flex-row justify-between  border-t-[1px] border-gray-300 pt-[20px]">
                <div class="flex flex-row items-center space-x-4"><a
                    style="cursor: pointer !important"
                    class="flex flex-shrink-0" href="/"><img loading="lazy"
                                                             style="height:24px"
                                                             src="/assets/images/homepage/AyrDrive.svg"
                                                             alt="AyrDrive logo"></a>
                    <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive
                        Universal Technologies SL</p></div>
                <div class="flex flex-row space-x-1">
                </div>
            </div>
        </div>
        <div class="bg-gray-5 bg-opacity-50 flex flex-col overflow-hidden lg:hidden">
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                    id="headlessui-disclosure-button-5" type="button"
                    aria-expanded="false"><span class="flex flex-row">Products</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[#18181b] text-[18px] font-medium"
                    id="headlessui-disclosure-button-7" type="button"
                    aria-expanded="false"><span class="flex flex-row">Company</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <a href="/register"
                   class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                   id="headlessui-disclosure-button-9" type="button"
                   aria-expanded="false"><span class="flex flex-row">Join Us</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </a>
            </div>
            <div class="flex w-screen">
                <div class="w-screen">
                    <button
                        class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                        id="headlessui-disclosure-button-15" type="button"
                        aria-expanded="false">
                        <div class="flex flex-row items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                 fill="currentColor" viewBox="0 0 256 256"
                                 class="text-gray-60">
                                <path
                                    d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z"></path>
                            </svg>
                            <span class="flex flex-row">English (EN)</span></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="flex text-gray-600">
                            <path
                                d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="hidden text-gray-600">
                            <path
                                d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div
                class="flex flex-col items-center space-y-4 py-10 border-t-[1px] border-gray-300">
                <div class="flex flex-row space-x-1">
                </div>
                <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive Universal
                    Technologies SL</p><a class="flex flex-shrink-0" routerLink="/"
                                          fragment="top-one"><img
                loading="lazy" width="110" height="12"
                src="/assets/images/homepage/AyrDrive.svg"
                alt="AyrDrive logo"></a></div>
        </div>
    </footer>
</div>
