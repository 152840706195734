export const AlertFileError = (): boolean => {
    if (sessionStorage.getItem('legacy_permissions') === 'disabled') {
        const alert = document.getElementById('errorMessageFile');
        const alert2 = document.getElementById('errorMessage');
        alert.removeAttribute('hidden');
        alert2.setAttribute('hidden', 'true');
        new Promise(resolve => setTimeout(resolve, 3500)).then(() => alert.setAttribute('hidden', 'true'));
        return true;
    }
    return false;
};
export const AlertError = (): boolean => {
    if (sessionStorage.getItem('legacy_permissions') === 'disabled') {
        const alert = document.getElementById('errorMessage');
        const alert2 = document.getElementById('errorMessageFile');
        alert.removeAttribute('hidden');
        alert2.setAttribute('hidden', 'true');
        new Promise(resolve => setTimeout(resolve, 3500)).then(() => alert.setAttribute('hidden', 'true'));
        return true;
    }
    return false;
};
