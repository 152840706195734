<div class="fixed top-0 left-0 z-50">
    <a href="/pricing"
       class="group left-0 z-50 h-[54px] w-screen cursor-pointer items-center justify-center overflow-hidden bg-[#0066ff] text-white md:flex">
        <div
            class="mx-auto text-[16px] flex flex-row items-center justify-center space-x-3">
            <div class="flex flex-row space-x-1">
                <p class="flex flex-row font-semibold">
                    91% OFF on All Annual Plans - Limited Time Offer
                </p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
            </svg>
        </div>
    </a>
    <nav
        class="border-b-[1px] border-gray-300 flex items-center h-[64px] py-[32px] pr-[24px] opacity-95 w-full bg-white transition-all duration-100 lg:h-16 undefined backdrop-blur-lg backdrop-saturate-150 backdrop-filter">
        <div class="mx-[24px] w-full lg:mx-[56px] xl:mx-[96px]">
            <div
                class="mx-auto flex max-w-screen-xl w-screen-xl items-center justify-between">
                <div
                    class="text-[18px] text-[#4D5358] flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-start space-x-[16px] lg:space-x-[24px]">
                    <a style="cursor: pointer" class="flex flex-shrink-0"
                       href="/">
                        <img
                            class="select-none"
                            src="/assets/images/homepage/AyrDrive.svg"
                            alt="AyrDrive logo"/>
                    </a>
                    <span class="w-[16px]"></span>
                    <a class="sm:hidden md:block whitespace-nowrap py-1.5 px-[12px] transition duration-150 ease-in-out text-[#4D5358] hover:text-[#0066ff]"
                       href="/pricing">Pricing</a>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 items-center transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl">
                        <span>Products</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none absolute top-full left-1/2 z-50 w-[196px] -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div
                                class="relative grid gap-0 whitespace-nowrap lg:grid-cols-1">
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/drive-info">AyrDrive Storage</a>
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/photos">AyrDrive Photos</a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl items-center">
                        <span>Our Values</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none text-[#4D5358] absolute top-full left-1/2 z-50 w-52 -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div class="relative grid gap-0 lg:grid-cols-1"><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/privacy">Privacy</a><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/open-source">Open Source</a></div>
                        </div>
                    </div>
                    <a class="whitespace-nowrap text-[#4D5358] py-1.5 px-[12px] transition duration-150 ease-in-out hover:text-[#0066ff] font-medium"
                       href="/about">About Us</a>
                </div>
                <div
                    class="space-x-[16px] inline-flex text-[18px] font-medium text-[#4D5358]">
                    <div
                        class="flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-end gap-[16px]">
                        <a id="loginButton" href="/login"
                           class="whitespace-nowrap rounded-xl border px-[12px] py-[4px] transition duration-150 ease-in-out focus:border focus:outline-none md:flex border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff] hover:bg-opacity-10 active:border-[#0066ff]-dark active:text-[#0066ff]-dark text-[14px] font-medium">
                            Log in
                        </a>
                        <a href="/register"
                           class="flex justify-center rounded-xl border border-transparent py-[4px] px-[12px] text-[14px] font-medium focus:outline-none sm:inline-flex bg-[#0066ff] text-white hover:bg-[#0066ff]-dark active:bg-[#0066ff]-dark transition-all duration-75">
                            <p class="whitespace-nowrap">Get started</p></a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<section class="relative flex w-full flex-col overflow-hidden pb-[64px] pt-[150px] bg-[#F8F8Fb]">
    <div class="flex flex-col items-center ">
        <div
            class="flex flex-col items-center justify-center space-y-[40px] px-6 text-center">
            <h1 class="max-w-[898px] undefined text-[60px] font-semibold leading-tight sm:text-[70px] sm:leading-tight">
                The right to privacy is <br/><span class="text-[#0066ff]">foundational to human rights</span>
            </h1>
            <h2 class="mb-8 w-full max-w-[898px] text-[20px] font-normal text-[#3a3a3b] sm:mb-[40px]">
                Here, at AyrDrive, we believe that a product should not only provide
                services but also respect fundamental human rights. We strive to create a
                product that will assist society in achieving better outcomes
            </h2>
        </div>
    </div>
</section>

<div class="flex flex-col items-center justify-center py-[64px] px-[20px]">
    <div
        class="revealY max-w-screen-xl  flex max-w-[768px] flex-col items-center justify-center space-y-6 text-center text-black active">
        <p class="mb-6 max-w-screen-xl  text-[48px] font-semibold sm:leading-tight">
            Secure your cloud data like never before
        </p>
        <p class="text-[18px] max-w-[768px]  text-gray-600">
            What makes AyrDrive so special? We always strive to provide the best services
            for our clients, tailored to meet their needs. Our team is comprised of
            professionals who are dedicated to protecting you and your files online
        </p>
        <div
            class="flex cursor-pointer flex-row items-center justify-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline">
            <a href="/about">Meet the AyrDrive Team</a>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
            </svg>
        </div>
    </div>
    <div
        class="revealY mt-[80px] grid grid-cols-1 flex-row flex-wrap justify-center gap-[40px] sm:grid-cols-2 active">
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 48 48"
                 height="32" width="32" class="mb-6 text-[48px] text-[#0066ff]">
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M13.639791666666666 19.220924166666666h14.6875l-3.9166666666666665 -4.40625"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M24.410625 25.585429166666664h-14.6875l3.9166666666666665 4.40625"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M44.0625 31.333333333333332h-13.708333333333332c-1.0815875 0 -1.9583333333333333 0.8767458333333332 -1.9583333333333333 1.9583333333333333v10.770833333333332c0 1.0815875 0.8767458333333332 1.9583333333333333 1.9583333333333333 1.9583333333333333h13.708333333333332c1.0815875 0 1.9583333333333333 -0.8767458333333332 1.9583333333333333 -1.9583333333333333V33.291666666666664c0 -1.0815875 -0.8767458333333332 -1.9583333333333333 -1.9583333333333333 -1.9583333333333333Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M37.20833333333333 22.520833333333332c-1.55805 0 -3.0524541666666667 0.6190291666666666 -4.1542125 1.7207875C31.952362499999996 25.343379166666665 31.333333333333332 26.83778333333333 31.333333333333332 28.395833333333332V31.333333333333332h11.75v-2.9375c0 -1.55805 -0.6190291666666666 -3.0524541666666667 -1.7207875 -4.1542125C40.2607875 23.1398625 38.76638333333333 22.520833333333332 37.20833333333333 22.520833333333332Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M37.20833333333333 39.46022083333333c-0.4055708333333333 0 -0.734375 -0.3288041666666666 -0.734375 -0.734375s0.3288041666666666 -0.734375 0.734375 -0.734375"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M37.20833333333333 39.46022083333333c0.4055708333333333 0 0.734375 -0.3288041666666666 0.734375 -0.734375s-0.3288041666666666 -0.734375 -0.734375 -0.734375"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M23.010416666666664 34.27083333333333H4.895833333333333c-1.03875875 0 -2.0349825 -0.4126208333333333 -2.769494583333333 -1.1471916666666666C1.3918149166666665 32.389070833333335 0.9791666666666666 31.392866666666666 0.9791666666666666 30.354166666666664v-25.458333333333332c0 -1.03875875 0.41264825 -2.0349825 1.1471720833333334 -2.769494583333333C2.860850833333333 1.3918149166666665 3.857074583333333 0.9791666666666666 4.895833333333333 0.9791666666666666h29.375c1.0387 0 2.0349041666666663 0.41264825 2.769475 1.1471720833333334 0.7345708333333333 0.7345120833333333 1.1471916666666666 1.7307358333333334 1.1471916666666666 2.769494583333333v13.708333333333332"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M0.9791666666666666 10.770833333333332h37.20833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M7.833333333333333 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M7.833333333333333 6.364583333333333c0.27038708333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21919624999999998 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M13.708333333333332 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M13.708333333333332 6.364583333333333c0.27038708333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21919624999999998 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M19.583333333333332 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M19.583333333333332 6.364583333333333c0.27044583333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21913749999999999 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col"><p
                class="mb-6 text-[24px] font-medium text-[#18181b]">Privacy First</p>
                <p class="text-[18px] text-gray-500">
                    With AyrDrive, you have full access and control over your data. We
                    do not store any files or passwords. Using encryption algorithms your
                    data is securely protected and accessible only to you
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 48 48"
                 class="mb-6 text-[48px] text-[#0066ff]"
                 height="32" width="32">
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M39.784325 41.21997916666667c-1.0580875 0 -1.9156416666666665 -0.85775 -1.9156416666666665 -1.9156416666666665 0 -1.0580875 0.8575541666666666 -1.9158374999999999 1.9156416666666665 -1.9158374999999999 1.0578916666666667 0 1.9156416666666665 0.85775 1.9156416666666665 1.9158374999999999 0 1.0578916666666667 -0.85775 1.9156416666666665 -1.9156416666666665 1.9156416666666665Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M7.214108333333334 41.21997916666667c-1.05802875 0 -1.9157004166666667 -0.85775 -1.9157004166666667 -1.9156416666666665 0 -1.0580875 0.8576716666666666 -1.9158374999999999 1.9157004166666667 -1.9158374999999999 1.0580091666666664 0 1.9157004166666667 0.85775 1.9157004166666667 1.9158374999999999 0 1.0578916666666667 -0.85769125 1.9156416666666665 -1.9157004166666667 1.9156416666666665Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M3.3844504166666662 10.568674583333333C2.32644125 10.568674583333333 1.46875 9.710983333333333 1.46875 8.652974166666667s0.85769125 -1.9157004166666667 1.9157004166666667 -1.9157004166666667 1.9157004166666667 0.85769125 1.9157004166666667 1.9157004166666667 -0.85769125 1.9157004166666667 -1.9157004166666667 1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M43.6154125 16.315775833333333c-1.0580875 0 -1.9156416666666665 -0.85769125 -1.9156416666666665 -1.9157004166666667s0.8575541666666666 -1.9157004166666667 1.9156416666666665 -1.9157004166666667c1.0578916666666667 0 1.9156416666666665 0.85769125 1.9156416666666665 1.9157004166666667s-0.85775 1.9157004166666667 -1.9156416666666665 1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m34.036029166666665 29.725737499999997 3.8332416666666664 0c1.0580875 0 1.9158374999999999 0.8575541666666666 1.9158374999999999 1.9156416666666665v1.9156416666666665"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M43.61462916666667 20.147333333333332v1.9156416666666665c0 1.0580875 -0.85775 1.9156416666666665 -1.9158374999999999 1.9156416666666665h-7.6627624999999995"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m12.964107916666666 27.8099 -3.83326125 0c-1.05802875 0 -1.9157004166666667 0.85775 -1.9157004166666667 1.9156416666666665l0 3.8314791666666665"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m3.3850183333333335 14.401113333333333 0 3.8314008333333334c0 1.0580091666666664 0.8576716666666666 1.9156025 1.9157004166666667 1.9156025H12.963970833333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M32.1203875 17.274301666666666H14.879142499999999c-1.0580091666666664 0 -1.9157004166666667 0.85769125 -1.9157004166666667 1.9157004166666667v13.40998125c0 1.0578916666666667 0.85769125 1.9156416666666665 1.9157004166666667 1.9156416666666665h17.241245c1.0580875 0 1.9158374999999999 -0.85775 1.9158374999999999 -1.9156416666666665V19.19000208333333c0 -1.0580091666666664 -0.85775 -1.9157004166666667 -1.9158374999999999 -1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m23.5 28.7687 0 -2.9149791666666665" stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M23.500195833333333 22.10488333333333c0.3707125 0 0.7330041666666667 0.10986249999999999 1.0412458333333332 0.31587916666666666 0.3082416666666667 0.20601666666666665 0.5485291666666666 0.49859166666666666 0.6903124999999999 0.8411041666666667 0.14178333333333334 0.3425125 0.17899166666666666 0.7192958333333334 0.10672916666666667 1.0829583333333335 -0.07245833333333333 0.3636625 -0.2508625 0.6975583333333334 -0.5130833333333333 0.9597791666666666 -0.262025 0.262025 -0.5961166666666666 0.440625 -0.9595833333333332 0.5128875 -0.3636625 0.07226250000000001 -0.7404458333333332 0.03525 -1.0829583333333335 -0.10672916666666667 -0.3425125 -0.14178333333333334 -0.6352833333333333 -0.3820708333333333 -0.8411041666666667 -0.6903124999999999 -0.20601666666666665 -0.3082416666666667 -0.31587916666666666 -0.6705333333333333 -0.31587916666666666 -1.0412458333333332 0 -0.49702500000000005 0.1974 -0.9738791666666666 0.5489208333333333 -1.3254 0.3515208333333333 -0.3515208333333333 0.8281791666666666 -0.5489208333333333 1.3254 -0.5489208333333333Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M30.204941666666667 12.485040833333333c0 -1.7782645833333333 -0.7063708333333334 -3.483679166666666 -1.9638166666666663 -4.741105416666667 -1.2574458333333334 -1.25742625 -2.962958333333333 -1.96383625 -4.741124999999999 -1.96383625 -1.7783625 0 -3.483679166666666 0.70641 -4.741164166666667 1.96383625 -1.25742625 1.25742625 -1.96383625 2.962840833333333 -1.96383625 4.741105416666667v4.7892608333333335h13.409942083333332V12.485040833333333Z"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col"><p
                class="mb-6 text-[24px] font-medium text-[#18181b]">
                Encryption is a key
            </p>
                <p class="text-[18px] text-gray-500">
                    Your data is encrypted before it leaves your device, during transfer,
                    and when it is stored on a server. We're using AES-256 encryption,
                    which guarantees the highest level of security for your information
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg" height="32"
                 width="32" fill="none"
                 class="mb-6 text-[48px] text-[#0066ff]">
                <path
                    d="M46.02083333333333 23.5s-10.081499999999998 12.729166666666666 -22.520833333333332 12.729166666666666S0.9791666666666666 23.5 0.9791666666666666 23.5 11.060666666666666 10.770833333333332 23.5 10.770833333333332 46.02083333333333 23.5 46.02083333333333 23.5Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M15.666666666666666 23.5a7.833333333333333 7.833333333333333 0 1 0 15.666666666666666 0 7.833333333333333 7.833333333333333 0 1 0 -15.666666666666666 0Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M23.5 19.583333333333332a3.9166666666666665 3.9166666666666665 0 1 1 -3.9166666666666665 3.9166666666666665"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col">
                <p class="mb-6 text-[24px] font-medium text-[#18181b]">
                    Open Source
                </p>
                <p class="text-[18px] text-gray-500">
                    We always strive to improve our product, which is why it is
                    open-source. This allows everyone to personally verify the reliability
                    of our service. The only thing we keep confidential is your data
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="-0.5 -0.5 48 48" class="mb-6 text-[48px] text-[#0066ff]"
                 height="32" width="32">
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="m31.823699999999995 32.04949583333333 -1.5666666666666667 11.663441666666666c-0.19583333333333333 1.3837583333333332 1.3708333333333331 2.372129166666667 2.5458333333333334 1.3837583333333332l3.9166666666666665 -3.9536791666666664 3.9166666666666665 3.9536791666666664c0.9791666666666666 0.9883708333333334 2.7416666666666663 0.19759583333333333 2.5458333333333334 -1.3837583333333332l-1.5666666666666667 -11.663441666666666"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M36.71875 33.546054166666664c4.867045833333333 0 8.8125 -3.9454541666666665 8.8125 -8.8125s-3.9454541666666665 -8.812539166666667 -8.8125 -8.812539166666667 -8.8125 3.9454933333333333 -8.8125 8.812539166666667 3.9454541666666665 8.8125 8.8125 8.8125Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M23.010416666666664 45.53125h-19.583333333333332c-1.1749999999999998 0 -1.9583333333333333 -0.7833333333333333 -1.9583333333333333 -1.9583333333333333V3.427083333333333c0 -1.1749999999999998 0.7833333333333333 -1.9583333333333333 1.9583333333333333 -1.9583333333333333h19.779166666666665c0.39166666666666666 0 0.9791666666666666 0.19586858333333332 1.3708333333333331 0.5875391666666666l9.595833333333333 8.616647083333334"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 14.197916666666666h12.924999999999999"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 23.989583333333332h12.924999999999999"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 33.78125h9.791666666666666"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M36.71875 27.51223333333333c1.5345499999999999 0 2.7786791666666666 -1.2441291666666665 2.7786791666666666 -2.7786791666666666 0 -1.5347458333333333 -1.2441291666666665 -2.7788749999999998 -2.7786791666666666 -2.7788749999999998 -1.5347458333333333 0 -2.7786791666666666 1.2441291666666665 -2.7786791666666666 2.7788749999999998 0 1.5345499999999999 1.2439333333333333 2.7786791666666666 2.7786791666666666 2.7786791666666666Z"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col">
                <p class="mb-6 text-[24px] font-medium text-[#18181b]">
                    Compliant and Secure
                </p>
                <p class="text-[18px] text-gray-500">
                    We have successfully passed several independent audits and can assure
                    you that your files are in safe hands. Our full compliance with GDPR
                    guarantees a 100% secure environment
                </p>
            </div>
        </div>
    </div>
</div>

<section class="relative flex w-full flex-col bg-[#F8F8Fb]">
    <div class="z-10 flex flex-col items-center py-[64px]">
        <div class="px-6 text-center">
            <h2 class="mb-[40px] text-[48px] font-semibold lg:text-[64px]">
                What makes privacy matter?
            </h2>
            <h3 class="mb-[40px] w-full max-w-[850px] text-[20px] font-normal text-[#3a3a3b]">
                In the modern internet landscape, the majority of services and products do
                not prioritize the respect of user privacy as their primary goal.
                Typically, such services are employed for data collection, which is
                subsequently utilized for monitoring and customizing targeted
                advertisements. These norms should not be widely accepted. We believe that
                in today's era, a product can meet the requirements and needs of users
                without compromising their privacy
            </h3>
        </div>
    </div>
</section>

<div class="z-10 flex flex-col items-center bg-gray-1 py-[64px] text-black">
    <div class="revealY space-y-32 px-[24px] text-left sm:text-center active">
        <div class="flex flex-col items-center justify-center space-y-4 text-center">
            <h2 class="mb-6 text-[48px] font-semibold  sm:leading-tight">
                Apps designed to protect
            </h2>
            <h3 class="max-w-[768px] mt-[16px] text-[18px] font-normal text-gray-600">
                A free product is not always truly free, as the payment for it comes in
                the form of your personal data. Our service never stores or retrieves your
                data. All control is in your hands and yours alone
            </h3>
        </div>
        <div
            class="flex flex-col space-y-32 text-left lg:grid lg:grid-cols-1 lg:grid-rows-2 lg:gap-20 lg:space-y-0">
            <div
                class="revealXRight flex overflow-hidden rounded-3xl  p-[32px] bg-white border-[1px] border-gray-300 lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-0 active">
                <div
                    class="flex h-full flex-col items-center justify-center p-7 text-center lg:items-start lg:pl-20 lg:text-start">
                    <h4 class="mb-[20px] text-[36px] font-semibold text-[#18181b]">
                        AyrDrive Storage
                    </h4>
                    <h5 class="mb-4 max-w-[340px] text-[18px] text-gray-500">
                        AyrDrive Storage is a service where AES-256 encryption is combined
                        with reliable data protection. Managing your files has never been
                        easier and more seamless. With Drive, you can be confident that
                        your files are protected from unauthorized access and stored in a
                        secure location
                    </h5>
                    <div class="flex justify-start mt-[12px]">
                        <a class="flex flex-row  border-[1px] transition-all duration-300 rounded-xl px-[20px] py-[12px] border-[#0066ff] items-center space-x-1 text-[18px] text-[#0066ff] hover:bg-[#0066ff11]"
                           href="/drive">
                            <span>
                                Start using Drive
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="currentColor"
                                 class="h-[16px] w-4">
                                <path
                                    d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"></path>
                            </svg>
                        </a></div>
                </div>
                <div class="lg:pl-15 relative mt-16 flex self-stretch lg:mt-0">
                    <div
                        class="lg:flex lg:max-w-[480px] bg-gradient-to-b from-[#0066ff] rounded-3xl to-slate-900">
                        <img
                            src="/assets/images/homepage/Internxt-Drive-H.png"
                            alt="AyrDrive Storage"
                            draggable="false"></div>
                </div>
            </div>
            <div
                class="revealXLeft flex overflow-hidden rounded-3xl  p-[32px] border-[1px] border-gray-300 lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-0 active">
                <div class="lg:pl-15 relative mt-16 flex self-stretch lg:mt-0">
                    <div
                        class="lg:flex lg:max-w-[480px] bg-gradient-to-b from-[#0066ff] rounded-3xl to-slate-900">
                        <img
                            src="/assets/images/homepage/photos-image-H.png"
                            alt="AyrDrive Photos">
                    </div>
                </div>
                <div
                    class="flex h-full flex-col items-center justify-center p-7 text-center lg:items-start lg:pl-20 lg:text-start">
                    <h4 class="mb-[20px] text-[36px] font-semibold text-[#18181b]">
                        AyrDrive Photos
                    </h4>
                    <h5 class="mb-4 max-w-[340px] text-[18px]  text-gray-500">
                        Preserve, organize, and share your precious moments with
                        AyrDrive Photos. Enjoy a user-friendly experience with secure,
                        anytime access to your entire photo collection across devices
                    </h5>
                    <div class="flex justify-start mt-[12px]"><a
                        class="flex border-[1px] rounded-xl transition-all duration-300 px-[20px] py-[12px] flex-row items-center space-x-1 text-[18px]  border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff11]"
                        href="/drive">
                        <span>
                            Sync your Photos
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor" class="h-[16px] w-4">
                            <path
                                d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"></path>
                        </svg>
                    </a></div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="overflow-hidden bg-[#F8F8Fb]">
    <div
        class="flex flex-col-reverse items-center justify-center px-[20px] py-[64px] text-center md:flex-row md:space-y-0 md:space-x-24 md:text-start">
        <div class="flex flex-col rounded-3xl pt-10 md:pt-0"><span
            style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
            style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
            alt="" aria-hidden="true"
            src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27496%27%20height=%27520%27/%3e"
            style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
            alt="encrypted file storage" draggable="false"
            src="/assets/images/homepage/encrypted-file-storage.webp"
            decoding="async" data-nimg="intrinsic" class="rounded-3xl"
            style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;"
        ><noscript></noscript></span>
        </div>
        <div
            class="revealXLeft flex max-w-[390px] flex-col items-center justify-center space-y-[24px] md:items-start active">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                 fill="currentColor" viewBox="-0.5 -0.5 48 48" class="text-[#0066ff]">
                <g>
                    <path
                        d="m34.27083333333333 13.708333333333332 -13.297083333333333 12.415833333333332a1.9583333333333333 1.9583333333333333 0 0 1 -2.722083333333333 0L14.6875 22.520833333333332"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M4.895833333333333 2.9375a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 -1.9583333333333333h33.291666666666664a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 1.9583333333333333v12.259166666666665A34.81916666666667 34.81916666666667 0 0 1 23.5 46.02083333333333 34.81916666666667 34.81916666666667 0 0 1 4.895833333333333 15.196666666666665Z"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                </g>
            </svg>
            <p class="mb-6 text-[48px] font-semibold sm:leading-tight">Advocate
                for a better tomorrow</p>
            <p class="text-[20px]">
                Never before has the security of personal data been so crucial. Only you
                can take privacy into your own hands. Learn more about your options for
                safeguarding personal information
            </p>
            <a href="/register"
               class="flex w-max text-[16px] items-center rounded-lg bg-[#0066ff] px-[20px] py-[12px] font-medium text-white"
            >
                Get started
            </a>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#0066ff] py-[64px]">
    <div
        class="flex flex-col items-center justify-center space-y-8 px-[20px] text-center">
        <div class="flex flex-col items-center space-y-[16px] text-center text-white">
            <p
                class="text-[36px] font-semibold">
                Get started now for free!
            </p>
            <p class="w-full max-w-[570px] text-[20px] font-normal">
                Create your account to enjoy up to 10 GB of free storage and gain full
                access to our cloud services
            </p>
        </div>
        <a href="/register"
           class="flex rounded-lg bg-white px-[20px] py-[12px] text-[18px] font-medium
            text-[#0066ff] hover:bg-[#ffffffee]">
            Get started
        </a>
    </div>
</section>

<div class="flex w-full flex-col items-center justify-center px-[24px] py-[64px] sm:p-20 sm:py-12">
    <div
        class="flex w-full max-w-screen-md flex-col items-center justify-between space-y-8 pb-9 text-center lg:flex-row lg:items-start lg:space-y-0 lg:space-x-32 lg:text-left">
        <div
            class="flex w-full max-w-[384px] flex-col items-center justify-center space-y-3 lg:items-start">
            <div class="flex flex-col space-y-1">
                <h2 class="text-[24px] font-medium ">
                    Download AyrDrive
                </h2>
                <p class="text-gray-600 text-[16px]">
                    Smooth data management and secure cloud storage are available with the
                    AyrDrive desktop app
                </p>
            </div>
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                <div class="flex"><a
                    class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    download href="/assets/AyrDrive.zip">
                    <span>Download for
                        <!-- --> Windows</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                         fill="currentColor" viewBox="0 0 256 256">
                        <path
                            d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                    </svg>
                </a></div>
            </div>
        </div>
        <div
            class="flex mb-10 max-w-[384px] flex-col items-center justify-center space-y-3 text-center md:items-start md:text-left ">
            <div class="flex w-full flex-col space-y-1"><h2
                class="text-[24px] font-medium">Newsletter</h2>
                <p class="text-[16px] text-gray-600">
                    Want to stay informed about our latest products and updates? Enter
                    your email address, and we'll take care of the rest!
                </p>
            </div>
            <form data-code="r3s4c1" method="post" target="_blank" rel="noopener"
                  action=""
                  class="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
                <input type="hidden" name="ml-submit" value="1">
                <input type="email" name="fields[email]"
                       placeholder="Your email address"
                       class="flex h-auto w-full flex-row rounded-lg px-[12px] py-3 text-[18px] outline-none sm:py-2 sm:text-[16px] md:w-64 border-cool-gray-20 bg-white focus:border-blue-50 focus:ring-opacity-20 mb-2 appearance-none border text-left transition-all duration-150 focus:ring focus:ring-primary"
                       required="">
                <input type="submit" name="signup" value="Subscribe"
                       class="ml-2 flex w-full cursor-pointer items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[12px] py-3 text-[18px] font-medium text-white transition-all duration-75 hover:bg-[#0066ff]-dark focus:outline-none active:bg-[#0066ff]-dark sm:mb-2 sm:py-2 sm:text-[16px]">
            </form>
            <span class="text-[16px] text-gray-400">By subscribing you agree to our
                <a href="/legal" class="cursor-pointer underline visited:text-gray-400">
                    privacy policy
                </a>
            </span>
        </div>
    </div>
    <footer class="flex max-w-screen-md w-[100%] items-center justify-center">
        <div
            class="w-[100%] flex-col items-center justify-center md:space-y-[56px] lg:flex">
            <div
                class="flex w-[100%] flex-row justify-between md:justify-between lg:space-x-[80px]">
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Products</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/drive">AyrDrive
                                Storage</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/photos">AyrDrive
                                Photos</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/pricing">Pricing</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Company</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/about">About Us</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/privacy">Privacy</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="flex max-w-[200px] flex-row items-center hover:text-[#0066ff]"
                                href="/open-source">Open Source</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/legal">Legal</a></p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Join Us</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a href="/register"
                                                               target="_top"
                                                               class="hover:text-[#0066ff]">Create
                                Account</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/login?" target="_top">Log In</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/assets/images/homepage/AyrDrive-white-paper.pdf"
                                target="_blank"
                                rel="noreferrer" download="" class="hover:text-[#0066ff]">White
                                Paper</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex w-full max-w-[900px] flex-row justify-between  border-t-[1px] border-gray-300 pt-[20px]">
                <div class="flex flex-row items-center space-x-4"><a
                    style="cursor: pointer !important"
                    class="flex flex-shrink-0" href="/"><img loading="lazy"
                                                             style="height:24px"
                                                             src="/assets/images/homepage/AyrDrive.svg"
                                                             alt="AyrDrive logo"></a>
                    <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive
                        Universal Technologies SL</p></div>
                <div class="flex flex-row space-x-1">
                </div>
            </div>
        </div>
        <div class="bg-gray-5 bg-opacity-50 flex flex-col overflow-hidden lg:hidden">
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                    id="headlessui-disclosure-button-5" type="button"
                    aria-expanded="false"><span class="flex flex-row">Products</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[#18181b] text-[18px] font-medium"
                    id="headlessui-disclosure-button-7" type="button"
                    aria-expanded="false"><span class="flex flex-row">Company</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <a href="/register"
                   class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                   id="headlessui-disclosure-button-9" type="button"
                   aria-expanded="false"><span class="flex flex-row">Join Us</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </a>
            </div>
            <div class="flex w-screen">
                <div class="w-screen">
                    <button
                        class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                        id="headlessui-disclosure-button-15" type="button"
                        aria-expanded="false">
                        <div class="flex flex-row items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                 fill="currentColor" viewBox="0 0 256 256"
                                 class="text-gray-60">
                                <path
                                    d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z"></path>
                            </svg>
                            <span class="flex flex-row">English (EN)</span></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="flex text-gray-600">
                            <path
                                d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="hidden text-gray-600">
                            <path
                                d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div
                class="flex flex-col items-center space-y-4 py-10 border-t-[1px] border-gray-300">
                <div class="flex flex-row space-x-1">
                </div>
                <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive Universal
                    Technologies SL</p><a class="flex flex-shrink-0" routerLink="/"
                                          fragment="top-one"><img
                loading="lazy" width="110" height="12"
                src="/assets/images/homepage/AyrDrive.svg"
                alt="AyrDrive logo"></a></div>
        </div>
    </footer>
</div>
