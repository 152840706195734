import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit
} from '@angular/core';
import {Settings} from '@common/core/config/settings.service';
import {Subscription} from 'rxjs';
import {HomepageContent} from './homepage-content';
import {DomSanitizer} from '@angular/platform-browser';
import {Translations} from '@common/core/translations/translations.service';

@Component({
    selector: 'homepage',
    templateUrl: './homepage.component.html',
    styleUrls: ['./homepage.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomepageComponent implements OnInit, OnDestroy {
    public content: HomepageContent;
    public overlayBackground;
    private sub: Subscription;
    public tab = 0;
    public tabData = [
        {
            header: 'Innovative Storage Technologies',
            text: 'Ensure uninterrupted access to your files with built-in backup. Our distributed network of servers across Europe guarantees reliable storage and swift access to your information',
            icon: '<path d="M207.06,80.67A111.24,111.24,0,0,0,128,48h-.4C66.07,48.21,16,99,16,161.13V184a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V160A111.25,111.25,0,0,0,207.06,80.67ZM224,184H119.71l54.76-75.3a8,8,0,0,0-12.94-9.42L99.92,184H32V161.13c0-3.08.15-6.12.43-9.13H56a8,8,0,0,0,0-16H35.27c10.32-38.86,44-68.24,84.73-71.66V88a8,8,0,0,0,16,0V64.33A96.14,96.14,0,0,1,221,136H200a8,8,0,0,0,0,16h23.67c.21,2.65.33,5.31.33,8Z"></path>'
        },
        {
            header: 'Personalized File Management',
            text: 'We assist in keeping your files organized. We enables storing your files in a convenient system and accessing them from any device, ensuring continuous control over your information',
            icon: ''
        },
        {
            header: 'Comprehensive File Versioning',
            text: 'Never lose track of changes! AyrDrive provides extensive file versioning capabilities, enabling you to revert to previous versions effortlessly and thus avoid data loss',
            icon: ''
        },
        {
            header: 'Guarding against scams and viruses',
            text: 'AyrDrive has the capability to identify ransomware and various malicious software, enabling effortless file restoration in the aftermath of attacks or unintended actions',
            icon: ''
        },
    ];

    public comments = [
        {
            title: 'Amelia P.',
            content: `I used other services like Google Drive and so on all the time. Then, friends suggested I try using AyrDrive. Honestly, it was the best decision. I haven't encountered such user-friendly simplicity anywhere else. The free version is sufficient for me, but in case I need it, I can still subscribe to a paid plan. Highly recommend!`,
            ava: '/assets/images/homepage/ava1.png'
        },
        {
            title: 'Evelyn T.',
            content: `AyrDrive proved incredibly user-friendly, requiring minimal effort for uploading and downloading - everything else was seamlessly handled by the program and drive`,
            ava: '/assets/images/homepage/ava2.png'
        },
        {
            title: 'Sarah H.',
            content: `A fast, reliable, and affordable way to preserve your memories without the fear of loss`,
            ava: '/assets/images/homepage/ava3.png'
        },
        {
            title: 'Rayan W.',
            content: `AyrDrive cloud storage has proven to be a reliable and efficient solution. Its seamless functionality ensures a smooth user experience, and I've experienced no disruptions or issues during my usage. This servie stands out as a trustworthy platform for secure and hassle-free file storage`,
            ava: '/assets/images/homepage/ava5.png'
        },
        {
            title: 'Luca C.',
            content: `I don't know what else to describe here: the service fully performs its functions. During my usage, I haven't encountered any issues working with AyrDrive`,
            ava: '/assets/images/homepage/ava6.png'
        },
        {
            title: 'Paul G.',
            content: `Compared to other services, this one is simply the best! No expensive plans, everything works smoothly, and most importantly, I'm confident that my data is secure. Thank you!`,
            ava: '/assets/images/homepage/ava7.png'
        },
        {
            title: 'Elijah O.',
            content: `AyrDrive completely met my expectations. Everything is easy and intuitive, files are saved without delays or lags. I especially appreciated the Sharing Photos feature`,
            ava: '/assets/images/homepage/ava9.png'
        },
    ];

    private shiftArrayRight(arr) {
        if (arr.length > 1) {
            const lastElement = arr.pop();
            arr.unshift(lastElement);
        }
    }

    private shiftArrayLeft(arr) {
        if (arr.length > 1) {
            const firstElement = arr.shift();
            arr.push(firstElement);
        }
    }

    public scrollLeft() {
        this.shiftArrayRight(this.comments);
    }

    public scrollRight() {
        this.shiftArrayLeft(this.comments);
    }

    constructor(
        public settings: Settings,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private i18n: Translations,
    ) {
    }

    ngOnInit() {
        this.sub = this.settings.all$().subscribe(() => {
            this.content = this.settings.getJson('homepage.appearance');
            this.overlayBackground = this.sanitizer.bypassSecurityTrustStyle(
                `linear-gradient(45deg, ${this.content.headerOverlayColor1} 0%, ${this.content.headerOverlayColor2} 100%)`
            );
            this.cd.markForCheck();
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }


    public scrollToTop() {
        document.querySelector('#top-one')
            .scrollIntoView({block: 'start', inline: 'center', behavior: 'smooth'});
    }

    public scrollToFeatures() {
        document.querySelector('.inline-features')
            .scrollIntoView({block: 'start', inline: 'center', behavior: 'smooth'});
    }

    public copyrightText() {
        const year = (new Date()).getFullYear();
        return this.i18n.t('Copyright © :year, All Rights Reserved', {year});
    }
}
