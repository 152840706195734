<router-outlet></router-outlet>
<router-outlet></router-outlet>
<div id="errorMessage" hidden class="alert" style="position: absolute; left: 50%; transform: translate(-50%, 0); top: 20vh; z-index: 999;">
    <div class="alert-box" style="display: flex; align-items: center;">
        <svg class="alert-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" fill="currentColor" viewBox="0 0 20 20" style="width: 16px; height: 16px; margin-right: 0.75rem;">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span class="sr-only" style="position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip-path: rect(0, 0, 0, 0); white-space: nowrap; border-width: 0;">Info</span>
        <div class="alert-content" style="font-weight: 600; font-size: 16px;">
            <span class="font-medium" style="color:#777;font-weight: 900;">Please wait for the vault decrypting...</span>
        </div>
    </div>
    <label for="alert-checkbox" class="overlayAla" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; cursor: pointer; z-index: 1;"></label>
</div>
<div id="errorMessageFile" class="alert" hidden style="position: absolute; left: 50%; transform: translate(-50%, 0); top: 20vh; z-index: 999;">
    <div class="alert-box" style="display: flex; align-items: center;">
        <svg class="alert-icon" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" height="16px" width="16px" fill="currentColor" viewBox="0 0 20 20" style="width: 16px; height: 16px; margin-right: 0.75rem;">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
        </svg>
        <span class="sr-only" style="position: absolute; width: 1px; height: 1px; padding: 0; margin: -1px; overflow: hidden; clip-path: rect(0, 0, 0, 0); white-space: nowrap; border-width: 0;">Info</span>
        <div class="alert-content" style="font-weight: 600; font-size: 16px;">
            <span class="font-medium" style="color:#777;font-weight: 900;">Please wait for the vault decrypting...</span>
        </div>
    </div>
    <label for="alert-checkbox" class="overlay" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; cursor: pointer; z-index: 1;"></label>
</div>
<style>
    .alert {
        align-items: center;
        padding: 1rem 2rem;
        font-size: 0.875rem;
        color: #ea580c;
        border-radius: 0.5rem;
        background-color: #fff;
        position: relative;
        transition: background-color 0.3s;
        box-shadow: 0px 1px 5px #ddd;
    }

    .alert:hover {
        background-color: #eee;
    }
</style>
