import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Settings } from '@common/core/config/settings.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Translations } from '@common/core/translations/translations.service';

@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutComponent implements OnInit, OnDestroy {
    public overlayBackground;
    private sub: Subscription;
    public tab = 0;
    public tabData = [
        {
            header: 'Innovative Storage Technologies',
            text: 'Ensure uninterrupted access to your files with built-in backup. Our distributed network of servers across Europe guarantees reliable storage and swift access to your information',
            icon: '<path d="M207.06,80.67A111.24,111.24,0,0,0,128,48h-.4C66.07,48.21,16,99,16,161.13V184a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V160A111.25,111.25,0,0,0,207.06,80.67ZM224,184H119.71l54.76-75.3a8,8,0,0,0-12.94-9.42L99.92,184H32V161.13c0-3.08.15-6.12.43-9.13H56a8,8,0,0,0,0-16H35.27c10.32-38.86,44-68.24,84.73-71.66V88a8,8,0,0,0,16,0V64.33A96.14,96.14,0,0,1,221,136H200a8,8,0,0,0,0,16h23.67c.21,2.65.33,5.31.33,8Z"></path>'
        },
        {
            header: 'Personalized File Management',
            text: 'We assist in keeping your files organized. We enables storing your files in a convenient system and accessing them from any device, ensuring continuous control over your information',
            icon: ''
        },
        {
            header: 'Comprehensive File Versioning',
            text: 'Never lose track of changes! AyrDrive provides extensive file versioning capabilities, enabling you to revert to previous versions effortlessly and thus avoid data loss',
            icon: ''
        },
        {
            header: 'Guarding against scams and viruses',
            text: 'AyrDrive has the capability to identify ransomware and various malicious software, enabling effortless file restoration in the aftermath of attacks or unintended actions',
            icon: ''
        },
    ];

    constructor(
        public settings: Settings,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private i18n: Translations,
    ) {}

    ngOnInit() {

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public scrollToFeatures() {
        document.querySelector('.inline-features')
            .scrollIntoView({block: 'start', inline: 'center', behavior: 'smooth'});
    }

    public copyrightText() {
        const year = (new Date()).getFullYear();
        return this.i18n.t('Copyright © :year, All Rights Reserved', {year});
    }
}
