import {NgModule} from '@angular/core';
import {Routes, RouterModule, ExtraOptions} from '@angular/router';
import { HomepageComponent } from './homepage/homepage.component';
import { GuestGuard } from '@common/guards/guest-guard.service';
import { ContactComponent } from '@common/contact/contact.component';
import { NOT_FOUND_ROUTES } from '@common/pages/not-found-routes';
import {PricingComponent} from './pricing/pricing.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {OpenSourceComponent} from './open-source/open-source.component';
import {LegalComponent} from './legal/legal.component';
import {DriveInfoComponent} from './drive-info/drive-info.component';
import {PhotosComponent} from './photos/photos.component';
import {AboutComponent} from './about/about.component';

const routes: Routes = [
    {path: '', pathMatch: 'full', component: HomepageComponent, canActivate: [GuestGuard]},
    {path: 'admin', loadChildren: () => import('src/app/admin/app-admin.module').then(m => m.AppAdminModule)},
    {path: 'pricing', component: PricingComponent},
    {path: 'privacy', component: PrivacyComponent},
    {path: 'open-source', component: OpenSourceComponent},
    {path: 'legal', component: LegalComponent},
    {path: 'about', component: AboutComponent},
    {path: 'photos', component: PhotosComponent},
    {path: 'drive-info', component: DriveInfoComponent},
    {path: 'drive', loadChildren: () => import('src/app/drive/drive.module').then(m => m.DriveModule)},
    {path: 'billing', loadChildren: () => import('@common/billing/billing.module').then(m => m.BillingModule)},
    {path: 'notifications', loadChildren: () => import('@common/notifications/notifications.module').then(m => m.NotificationsModule)},
    {path: 'contact', component: ContactComponent},
    ...NOT_FOUND_ROUTES,
];

const routerOptions: ExtraOptions = {
    useHash: false,
    anchorScrolling: 'enabled',
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
