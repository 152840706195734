import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Settings } from '@common/core/config/settings.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Translations } from '@common/core/translations/translations.service';

@Component({
    selector: 'photos',
    templateUrl: './photos.component.html',
    styleUrls: ['./photos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotosComponent implements OnInit, OnDestroy {
    public overlayBackground;
    private sub: Subscription;

    constructor(
        public settings: Settings,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer,
        private i18n: Translations,
    ) {}

    ngOnInit() {

    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    public scrollToFeatures() {
        document.querySelector('.inline-features')
            .scrollIntoView({block: 'start', inline: 'center', behavior: 'smooth'});
    }

    public copyrightText() {
        const year = (new Date()).getFullYear();
        return this.i18n.t('Copyright © :year, All Rights Reserved', {year});
    }
}
