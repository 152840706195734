<div class="fixed top-0 left-0 z-50">
    <a href="/pricing"
       class="group left-0 z-50 h-[54px] w-screen cursor-pointer items-center justify-center overflow-hidden bg-[#0066ff] text-white md:flex">
        <div
            class="mx-auto text-[16px] flex flex-row items-center justify-center space-x-3">
            <div class="flex flex-row space-x-1">
                <p class="flex flex-row font-semibold">
                    91% OFF on All Annual Plans - Limited Time Offer
                </p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
            </svg>
        </div>
    </a>
    <nav
        class="border-b-[1px] border-gray-300 flex items-center h-[64px] py-[32px] pr-[24px] opacity-95 w-full bg-white transition-all duration-100 lg:h-16 undefined backdrop-blur-lg backdrop-saturate-150 backdrop-filter">
        <div class="mx-[24px] w-full lg:mx-[56px] xl:mx-[96px]">
            <div
                class="mx-auto flex max-w-screen-xl w-screen-xl items-center justify-between">
                <div
                    class="text-[18px] text-[#4D5358] flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-start space-x-[16px] lg:space-x-[24px]">
                    <a style="cursor: pointer" class="flex flex-shrink-0"
                       href="/">
                        <img
                            class="select-none"
                            src="/assets/images/homepage/AyrDrive.svg"
                            alt="AyrDrive logo"/>
                    </a>
                    <span class="w-[16px]"></span>
                    <a class="sm:hidden md:block whitespace-nowrap py-1.5 px-[12px] transition duration-150 ease-in-out text-[#4D5358] hover:text-[#0066ff]"
                       href="/pricing">Pricing</a>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 items-center transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl">
                        <span>Products</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none absolute top-full left-1/2 z-50 w-[196px] -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div
                                class="relative grid gap-0 whitespace-nowrap lg:grid-cols-1">
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/drive-info">AyrDrive Storage</a>
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/photos">AyrDrive Photos</a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl items-center">
                        <span>Our Values</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none text-[#4D5358] absolute top-full left-1/2 z-50 w-52 -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div class="relative grid gap-0 lg:grid-cols-1"><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/privacy">Privacy</a><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/open-source">Open Source</a></div>
                        </div>
                    </div>
                    <a class="whitespace-nowrap text-[#4D5358] py-1.5 px-[12px] transition duration-150 ease-in-out hover:text-[#0066ff] font-medium"
                       href="/about">About Us</a>
                </div>
                <div
                    class="space-x-[16px] inline-flex text-[18px] font-medium text-[#4D5358]">
                    <div
                        class="flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-end gap-[16px]">
                        <a id="loginButton" href="/login"
                           class="whitespace-nowrap rounded-xl border px-[12px] py-[4px] transition duration-150 ease-in-out focus:border focus:outline-none md:flex border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff] hover:bg-opacity-10 active:border-[#0066ff]-dark active:text-[#0066ff]-dark text-[14px] font-medium">
                            Log in
                        </a>
                        <a href="/register"
                           class="flex justify-center rounded-xl border border-transparent py-[4px] px-[12px] text-[14px] font-medium focus:outline-none sm:inline-flex bg-[#0066ff] text-white hover:bg-[#0066ff]-dark active:bg-[#0066ff]-dark transition-all duration-75">
                            <p class="whitespace-nowrap">Get started</p></a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<section class="flex w-full flex-col">
    <div class="flex flex-col items-center pt-[128px] pb-[64px]">
        <div
            class="flex flex-col items-center justify-center space-y-[24px] px-[20px] text-center">
            <div
                class="flex w-max items-center justify-center rounded-lg bg-[#f3f3f8] py-[8px] px-[16px]">
                <h2 class="text-[20px] font-medium text-[#3a3a3b]">AyrDrive Storage</h2>
            </div>
            <h1 class="max-w-max text-[#18181b] text-[60px] font-semibold leading-tight sm:leading-tight">
                <span class="text-[#0066ff]">Safeguard your data with</span> <br
                class="hidden sm:flex">the most reliable cloud storage</h1>
            <h3 class="text-[20px] font-normal text-[#3a3a3b] lg:mb-20">
                Enjoy intuitively simple storage, copying, and sharing of your files.
                Storage provides you with reliable storage <br/>encrypted with top-tier
                encryption and complete autonomy over your data
            </h3>
        </div>
        <div class="flex h-full flex-col px-5 py-16"><img
            src="/assets/images/homepage/pick5.webp" width="757"
            height="419" alt="AyrDrive secure cloud storage" draggable="false"></div>
        <div class="flex w-full flex-col items-center justify-center space-y-9">
            <p class="text-center text-[36px] font-semibold text-[#18181b]">
                Download AyrDrive Storage
            </p>
            <div
                class="flex w-full flex-row items-center justify-center lg:items-start lg:space-x-32">
                <div class="hidden lg:flex flex-1 flex-grow flex-row justify-end">
                    <div class="flex flex-col items-center space-y-1"><a
                        class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                        download href="/assets/AyrDrive.zip"><span>Download for
                        <!-- --> Windows</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                             fill="currentColor" viewBox="0 0 256 256">
                            <path
                                d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                        </svg>
                    </a>
                    </div>
                </div>
                <div
                    class="hidden lg:flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    rel="noreferrer">
                </div>
                <div
                    class="flex flex-1 flex-grow flex-row justify-center text-[18px] lg:justify-start">
                    <div class="flex flex-col items-center space-y-1"><a
                        class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff]"
                        href="/register"
                        target="_blank" rel="noreferrer"><span>Open Storage Web</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                             fill="currentColor" viewBox="0 0 256 256">
                            <path
                                d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
                        </svg>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="flex w-full flex-col py-[64px] bg-[#f9f9fc]">
    <div class="flex w-full flex-col items-center">
        <div
            class="react-parallax flex w-full flex-col items-center justify-center text-center"
            style="position: relative; overflow: hidden;">
            <h2 class="relative text-[36px] font-semibold text-cool-gray-90 lg:mb-20 lg:text-[60px]">
                <div class="opacity-100 translate-y-0">All file types across</div>
                <div class="opacity-100 translate-y-0">various devices</div>
            </h2>
            <div class="react-parallax-content" style="position: relative;"></div>
        </div>

        <div
            class="max-w-[1024px] relative grid w-full grid-cols-1 justify-center gap-[80px] pt-10 lg:grid-cols-2 py-[64px]">
            <div class="col-span-1">
                <img class="rounded-xl" src="/assets/images/homepage/pick1.webp"/>
            </div>
            <div class="flex items-center justify-start">
                <div class="flex flex-col px-10 lg:px-0">
                    <h3 class="mb-6 text-[48px] font-semibold text-[#18181b]">
                        Discover your files on different devices
                    </h3>
                    <p class="w-full text-[24px] text-[#3a3a3b]">
                        Be confident that you can securely access your files through the
                        web or desktop version using the most secure cloud storage
                        application
                    </p>
                </div>
            </div>
        </div>

        <div
            class="max-w-[1024px] relative grid w-full grid-cols-1 justify-center gap-[80px] pt-10 lg:grid-cols-2 py-[64px]">
            <div class="flex items-center justify-end">
                <div
                    class="flex flex-col px-10 lg:px-0">
                    <h3 class="mb-6 text-[48px] font-semibold text-[#18181b]">
                        Access your files from anywhere in the world
                    </h3>
                    <p class="text-[24px] text-[#3a3a3b]">
                        AyrDrive Storage is designed to give users the ability to control
                        and organize their files regardless of their location. This
                        service allows you to group, organize into folders, and rename
                        files effortlessly. Additionally, you have the option to back up
                        your computer folders, ensuring that you always have the latest
                        updates for your most critical files
                    </p></div>
            </div>
            <div class="col-span-1">
                <img class="rounded-xl" src="/assets/images/homepage/pick2.webp"/>
            </div>
        </div>

        <div
            class="max-w-[1024px] relative grid w-full grid-cols-1 justify-center gap-[80px] pt-10 lg:grid-cols-2 py-[64px]">
            <div class="col-span-1">
                <img class="rounded-xl" src="/assets/images/homepage/pick3.webp"/>
            </div>
            <div class="flex items-center justify-start">
                <div
                    class="flex flex-col px-10 lg:px-0">
                    <h3 class="mb-6 text-[48px] font-semibold text-[#18181b]">
                        Safely share your files in just two clicks
                    </h3>
                    <p class="text-[24px] text-[#3a3a3b]">
                        AyrDrive Storage allows you to securely and confidentially
                        exchange important files with friends, family, or colleagues. You
                        have full control over file sharing, choosing who to share with
                        and determining how many times the files can be viewed or
                        downloaded
                    </p>
                </div>
            </div>
        </div>

        <div
            class="max-w-[1024px] relative grid w-full grid-cols-1 justify-center gap-[80px] pt-10 lg:grid-cols-2 py-[64px]">
            <div class="flex items-center justify-end">
                <div
                    class="flex flex-col lg:px-0 items-center">
                    <h3 class="mb-6 text-[48px] font-semibold text-[#18181b]">
                        Preserve what matters with a reliable backup
                    </h3>
                    <p class="text-[24px] text-[#3a3a3b]">
                        Say goodbye to the fear of losing important files, including work
                        drafts, as AyrDrive Storage, the cloud storage solution, protects
                        your data
                    </p>
                </div>
            </div>
            <div class="col-span-1">
                <img class="rounded-xl" src="/assets/images/homepage/pick4.webp"/>
            </div>
        </div>
    </div>
</section>

<!--<section class="flex w-full flex-col">
    <div class="flex w-full flex-col items-center">
        <div
            class="react-parallax flex w-full flex-col items-center justify-center text-center"
            style="position: relative; overflow: hidden;">
            <h2 class="relative text-[36px] font-semibold text-cool-gray-90 lg:mb-20 lg:text-[60px]">
                <div class="opacity-100 translate-y-0">All kinds of files,</div>
                <div class="opacity-100 translate-y-0">all kinds of devices</div>
            </h2>
            <div class="react-parallax-content" style="position: relative;"></div>
        </div>
        <div
            class="relative grid w-full grid-cols-1 justify-center gap-[80px] pt-10 lg:grid-cols-2 lg:pt-[80px] lg:pb-[80px]">
            <div
                class="revealXRight hidden w-full max-w-[713px] flex-col object-contain object-right lg:right-0 lg:flex active">
                <span
                    style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                    style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                    alt="" aria-hidden="true"
                    src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27713%27%20height=%27894%27/%3e"
                    style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                    draggable="false" alt="Secure file storage"
                    src="/assets/images/homepage/secure-file-storage.png" decoding="async"
                    data-nimg="intrinsic" class="w-full"
                    style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;"></span>
            </div>
            <div
                class="flex w-full flex-col items-center justify-center lg:-top-4 lg:items-start lg:pl-10">
                <img src="/assets/images/homepage/cloud-storage-app.webp"
                     alt="Cloud storage app"
                     width="250" height="505">
                <div class="mt-10 flex flex-col px-10 lg:px-0"><h3
                    class="mb-6 text-[36px] font-semibold text-[#18181b]">Find
                    your files <br class="hidden sm:flex">on all your <br
                        class="hidden sm:flex">devices</h3>
                    <p class="w-full max-w-[262px] text-[20px] text-[#3a3a3b]">Securely
                        access
                        all your files from your computer, smartphone, tablet, or any
                        device with a connection to the internet via the most secure cloud
                        storage app.</p></div>
            </div>
        </div>
        <div
            class="grid w-full grid-cols-1 justify-center pt-10 lg:grid-cols-2 lg:gap-[80px] lg:pb-[80px]">
            <div class="flex flex-col items-center lg:items-end lg:pr-[80px]">
                <div
                    class="flex w-full flex-col items-center pt-10 lg:w-auto lg:items-start lg:pt-0">
                    <div class="mb-20 flex flex-col px-10 pt-10 lg:mb-28 lg:px-0"><h3
                        class="mb-6 text-[36px] font-semibold text-[#18181b]">
                        Keep
                        your files <br class="hidden sm:flex">organized and <br
                        class="hidden sm:flex">accessible from <br
                        class="hidden sm:flex">anywhere</h3>
                        <p class="text-[20px] text-[#3a3a3b]">Internxt Storage lets you
                            organize
                            your <br class="hidden sm:flex">files in folders, rename them,
                            and filter <br class="hidden sm:flex">a list of files to find
                            what you want faster. <br class="hidden sm:flex">You can even
                            backup your computer <br class="hidden sm:flex">folders so you
                            always have the most <br class="hidden sm:flex">recent changes
                            of your most important files <br class="hidden sm:flex"> <br
                                class="hidden sm:flex"></p></div>
                    <div class="relative order-first lg:order-last"><img
                        src="/assets/images/homepage/Internxt-Drive.webp" width="250"
                        height="505"
                        alt="Internxt Storage"></div>
                </div>
            </div>
            <div
                class="relative hidden w-full max-w-[750px] flex-col items-start object-right pt-10 lg:flex 3xl:absolute 3xl:right-0">
                <div
                    class="revealXLeft right-0 hidden flex-col rounded-xl shadow-subtle-hard lg:flex active">
                    <span
                        style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                        style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27750%27%20height=%27900%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                        draggable="false" alt="Document folders"
                        src="/assets/images/homepage/document-folders.webp"
                        decoding="async"
                        data-nimg="intrinsic" class="h-full w-full bg-no-repeat "
                        style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; object-position: left center;"></span>
                </div>
            </div>
        </div>
        <div
            class="grid w-full grid-cols-1 items-center justify-center lg:grid-cols-2 lg:gap-[80px] lg:pt-[80px] lg:pb-[80px]">
            <div
                class="hidden flex-col items-start object-contain object-left pt-10 lg:left-0 lg:flex">
                <div
                    class="revealXRight hidden flex-col shadow-subtle-hard lg:flex active">
                    <span
                        style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                        style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27650%27%20height=%27545%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                        draggable="false" alt="File sharing"
                        src="/assets/images/homepage/File-sharing.webp" decoding="async"
                        data-nimg="intrinsic" class="h-full w-full bg-no-repeat "
                        style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover; object-position: right center;"></span>
                </div>
            </div>
            <div
                class="flex w-full flex-col items-center lg:w-auto lg:items-start lg:pl-10">
                <div
                    class="relative flex rounded-4xl bg-cover bg-left-top bg-no-repeat px-5 shadow-subtle lg:hidden">
                    <img src="/assets/images/homepage/File-sharing.webp" alt="Work list">
                </div>
                <div class="mb-20 flex flex-col px-10 pt-10 lg:mb-24 lg:px-0"><h3
                    class="mb-6 text-[36px] font-semibold text-[#18181b]">Share
                    your files <br class="hidden sm:flex">with ease, <br
                        class="hidden sm:flex">security is on us <br
                        class="hidden sm:flex"></h3>
                    <p class="text-[20px] text-[#3a3a3b]">Internxt Storage focuses on
                        privacy and
                        <br class="hidden sm:flex">security, so you only have to focus on
                        <br class="hidden sm:flex">what matters to you, like sharing files
                        <br class="hidden sm:flex">with your coworkers, or the plane
                        tickets <br class="hidden sm:flex">for your next holiday with
                        family. You <br class="hidden sm:flex">choose who you want to
                        share them with <br class="hidden sm:flex">and how many times you
                        want <br class="hidden sm:flex">them to be downloaded.</p></div>
            </div>
        </div>
        <div
            class="grid w-full grid-cols-1 justify-center gap-[80px] pt-[20px] lg:grid-cols-2 lg:pt-[80px] lg:pb-[80px]">
            <div
                class="flex flex-col items-center justify-center lg:items-end lg:pr-[80px]">
                <div class="flex w-full flex-col bg-transparent px-5 lg:hidden"><img
                    loading="lazy" class="w-full"
                    src="/assets/images/homepage/file-backup.webp"
                    draggable="false" alt="File backups"></div>
                <div class="mb-24 flex flex-col px-10 pt-10 lg:px-0 lg:pt-0"><h3
                    class="mb-6 text-[36px] font-semibold text-[#18181b]">Back up
                    what <br class="hidden sm:flex">matters to you <br
                        class="hidden sm:flex"></h3>
                    <p class="text-[20px] text-[#3a3a3b]">Now you can keep the latest
                        draft <br
                            class="hidden sm:flex">of that super important project <br
                            class="hidden sm:flex">or your university homework you are <br
                            class="hidden sm:flex">working on, safe and current in your
                        <br
                            class="hidden sm:flex">Internxt Storage. <br
                            class="hidden sm:flex">
                    </p></div>
            </div>
            <div
                class="hidden h-full flex-col items-start object-cover object-left lg:flex">
                <div
                    class="revealXLeft hidden flex-col rounded-xl shadow-subtle-hard lg:flex active">
                    <span
                        style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;"><span
                        style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;"><img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27600%27%20height=%27460%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                        draggable="false" alt="File backups"
                        src="/assets/images/homepage/file-backup.webp" decoding="async"
                        data-nimg="intrinsic"
                        style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: contain;"></span>
                </div>
            </div>
        </div>


        <div
            class="flex w-full flex-col items-center bg-gray-1 px-10 py-10 lg:px-0 lg:py-[80px]">
            <h3
                class="mb-[48px] text-center text-[48px] font-semibold text-[#18181b]">
                Sync your
                files in
                total privacy <br class="hidden sm:flex"></h3>
            <div class="flex w-full flex-col items-center justify-center space-y-9"><p
                class="text-center text-4xl font-semibold text-[#18181b]"></p>
                <div class="flex w-full flex-col items-center justify-center space-y-9">
                    <div
                        class="flex w-full flex-row items-center justify-center lg:items-start lg:space-x-32">
                        <div class="hidden lg:flex flex-1 flex-grow flex-row justify-end">
                            <div class="flex flex-col items-center space-y-1"><a
                                class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                                href="/exe"><span>Download for
                                Windows</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18"
                                     height="18"
                                     fill="currentColor" viewBox="0 0 256 256">
                                    <path
                                        d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                                </svg>
                            </a>
                            </div>
                        </div>
                        <div
                            class="hidden lg:flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                            rel="noreferrer">
                        </div>
                        <div
                            class="flex flex-1 flex-grow flex-row justify-center text-[18px] lg:justify-start">
                            <div class="flex flex-col items-center space-y-1"><a
                                class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff]"
                                href="/register"
                                target="_blank"
                                rel="noreferrer"><span>Open Storage Web</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                     height="16"
                                     fill="currentColor" viewBox="0 0 256 256">
                                    <path
                                        d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
                                </svg>
                            </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
-->

<section class="overflow-hidden ">
    <div
        class="flex flex-col items-center justify-center space-y-[80px] py-[64px] px-[20px]">
        <div
            class="revealY flex max-w-[768px] flex-col items-center justify-center space-y-[24px] text-center text-black active">
            <h3 class="text-center text-[48px] font-semibold text-[#18181b] lg:text-[64px]">
                Keep your files safe<br/> and confidential</h3>
            <p class="mb-[24px] text-[20px] text-[#3a3a3b]">
                Security and integrity are our primary values that we strive to provide to
                our users. We respect your rights and requirements, developing our product
                to align with your expectations
            <div
                class="flex cursor-pointer flex-row items-center justify-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline">
                <a href="/privacy">Our focus on privacy</a>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                     fill="currentColor" viewBox="0 0 256 256">
                    <path
                        d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
                </svg>
            </div>
        </div>
        <div
            class="revealY grid grid-cols-1 flex-row flex-wrap justify-center gap-[40px] sm:grid-cols-2 active">
            <div
                class="flex flex-col items-start justify-start rounded-2xl p-[40px] md:max-w-[488px] border-[1px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 48 48"
                     height="32" width="32" class="mb-[24px] text-4xl text-[#0066ff]">
                    <defs></defs>
                    <title>password-lock-1</title>
                    <path
                        d="M20.5625 26.4375h-17.625a1.9583333333333333 1.9583333333333333 0 0 1 -1.9583333333333333 -1.9583333333333333v-13.708333333333332a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 -1.9583333333333333h41.125a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 1.9583333333333333v9.832791666666665"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path d="m8.8125 13.708333333333332 0 7.833333333333333" fill="none"
                          stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="1"></path>
                    <path
                        d="m12.088791666666665 15.476708333333331 -6.550625 4.296583333333333"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M12.071166666666665 19.79875 5.5538333333333325 15.451249999999998"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path d="m20.5625 13.708333333333332 0 7.833333333333333" fill="none"
                          stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="1"></path>
                    <path
                        d="m23.838791666666665 15.476708333333331 -6.550625 4.296583333333333"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M23.821166666666667 19.79875 17.303833333333333 15.451249999999998"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M28.395833333333332 30.356125h13.708333333333332s1.9583333333333333 0 1.9583333333333333 1.9583333333333333V44.0625s0 1.9583333333333333 -1.9583333333333333 1.9583333333333333h-13.708333333333332s-1.9583333333333333 0 -1.9583333333333333 -1.9583333333333333v-11.748041666666666s0 -1.9583333333333333 1.9583333333333333 -1.9583333333333333"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M32.80208333333333 38.310874999999996a2.4479166666666665 2.4479166666666665 0 1 0 4.895833333333333 0 2.4479166666666665 2.4479166666666665 0 1 0 -4.895833333333333 0"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M40.14583333333333 27.416666666666664a4.895833333333333 4.895833333333333 0 0 0 -9.791666666666666 0v2.9375h9.791666666666666Z"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col">
                    <p class="mb-[24px] text-[24px] font-medium text-[#18181b]">
                        Advanced Encryption Standard
                    </p>
                    <p class="text-[18px] text-cool-[#3a3a3b] sm:text-[18px]">
                        Your privacy is in good hands. Our use of military-grade
                        encryption means that only you have the authority to access your
                        files
                    </p>
                </div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-2xl p-[40px] md:max-w-[488px] border-[1px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.5 -0.5 48 48"
                     height="32" width="32"
                     class="mb-[24px] text-4xl text-[#0066ff]">
                    <defs></defs>
                    <path
                        d="M22.03125 33.78125a11.75 11.75 0 1 0 23.5 0 11.75 11.75 0 1 0 -23.5 0"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="m39.01783333333333 30.363958333333333 -5.688958333333333 7.5865833333333335a1.4746249999999999 1.4746249999999999 0 0 1 -2.062125 0.2917916666666666 1.4315416666666665 1.4315416666666665 0 0 1 -0.15275 -0.13316666666666668l-2.9375 -2.9375"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M16.15625 39.65625h-11.75a2.9375 2.9375 0 0 1 -2.9375 -2.9375V4.40625a2.9375 2.9375 0 0 1 2.9375 -2.9375h20.815125a2.9375 2.9375 0 0 1 2.075833333333333 0.8597083333333333l5.6243333333333325 5.6243333333333325a2.9375 2.9375 0 0 1 0.8597083333333333 2.075833333333333V16.15625"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M12.979833333333334 13.655458333333332a4.645166666666666 4.645166666666666 0 1 0 9.290333333333333 0 4.645166666666666 4.645166666666666 0 1 0 -9.290333333333333 0"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M10.142208333333333 27.590958333333333a7.966499999999999 7.966499999999999 0 0 1 12.024166666666666 -6.854166666666666"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col">
                    <p class="mb-[24px] text-[24px] font-medium text-[#18181b]">
                        Zero-knowledge proof
                    </p>
                    <p class="text-[18px] text-cool-[#3a3a3b] sm:text-[18px]">
                        Using zero-knowledge technology, your files and encryption keys
                        are never kept or sent without encryption or hashing
                    </p>
                </div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-2xl p-[40px] md:max-w-[488px] border-[1px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px]">
                <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg"
                     height="32" width="32" class="mb-[24px] text-4xl text-[#0066ff]">
                    <path
                        d="M2.9375 7.392708333333333v14.955791666666665A24.109041666666666 24.109041666666666 0 0 0 19.033041666666666 44.806666666666665l2.1952916666666664 0.81075a6.589791666666667 6.589791666666667 0 0 0 4.543333333333333 0l2.1952916666666664 -0.81075A24.109041666666666 24.109041666666666 0 0 0 44.0625 22.3485V7.392708333333333a3.002125 3.002125 0 0 0 -1.8290833333333334 -2.7534166666666664A47.464124999999996 47.464124999999996 0 0 0 23.5 0.9791666666666666a47.464124999999996 47.464124999999996 0 0 0 -18.733416666666667 3.660125A3.002125 3.002125 0 0 0 2.9375 7.392708333333333Z"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M36.229166666666664 10.770833333333332h-3.9166666666666665l-8.526583333333333 8.526583333333333a7.833333333333333 7.833333333333333 0 1 0 3.9166666666666665 3.9166666666666665L36.229166666666664 14.6875Z"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M18.114583333333332 26.4375a2.4479166666666665 2.4479166666666665 0 1 0 4.895833333333333 0 2.4479166666666665 2.4479166666666665 0 1 0 -4.895833333333333 0Z"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col">
                    <p class="mb-[24px] text-[24px] font-medium text-[#18181b]">
                        Privacy by Design
                    </p>
                    <p class="text-[18px] text-cool-[#3a3a3b]">
                        We provide a service for people, which is why ensuring the
                        security of our users and their data is our foremost priority
                    </p>
                </div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-2xl p-[40px] md:max-w-[488px] border-[1px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px]">
                <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg"
                     width="32" height="32" class="mb-[24px] text-4xl text-[#0066ff]">
                    <path
                        d="M46.02083333333333 34.27083333333333a1.9583333333333333 1.9583333333333333 0 0 1 -1.9583333333333333 1.9583333333333333h-41.125a1.9583333333333333 1.9583333333333333 0 0 1 -1.9583333333333333 -1.9583333333333333v-11.75h13.708333333333332v1.9583333333333333a3.9284166666666662 3.9284166666666662 0 0 0 3.9166666666666665 3.9166666666666665h9.791666666666666a3.9284166666666662 3.9284166666666662 0 0 0 3.9166666666666665 -3.9166666666666665v-1.9583333333333333h13.708333333333332Z"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M0.9791666666666666 22.520833333333332 7.2673749999999995 11.75a1.9583333333333333 1.9583333333333333 0 0 1 1.6900416666666667 -0.9791666666666666h29.085166666666666a1.9583333333333333 1.9583333333333333 0 0 1 1.6900416666666667 0.9791666666666666l6.2882083333333325 10.770833333333332"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col">
                    <p class="mb-[24px] text-[24px] font-medium text-[#18181b]">
                        Open Source
                    </p>
                    <p class="text-[18px] text-cool-[#3a3a3b] sm:text-[18px]">
                        We always strive to improve our product, which is why it is
                        open-source. The only thing we keep confidential is your data
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overflow-hidden py-[64px]  bg-[#f9f9fc]">
    <div
        class="flex flex-col items-center justify-center px-[20px] lg:flex-row lg:space-x-20">
        <div class="revealXRight undefined active rounded-lg">
            <span
                style="box-sizing: border-box; display: inline-block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: relative; max-width: 100%;">
                <span
                    style="box-sizing: border-box; display: block; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; max-width: 100%;">
                    <img
                        alt="" aria-hidden="true"
                        src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%27496%27%20height=%27520%27/%3e"
                        style="display: block; max-width: 100%; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px;"></span><img
                draggable="false" alt="Protect your data"
                class="rounded-[25px]"
                src="/assets/images/homepage/secure-file-storage.webp"
                decoding="async" data-nimg="intrinsic"
                style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%;"></span>
        </div>
        <div
            class="revealXLeft flex w-full max-w-[388px] flex-col items-center space-y-[24px] pt-8 text-center lg:items-start lg:pt-0 lg:text-start active ">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                 fill="currentColor" viewBox="-0.5 -0.5 48 48" class="text-[#0066ff]">
                <g>
                    <path
                        d="m34.27083333333333 13.708333333333332 -13.297083333333333 12.415833333333332a1.9583333333333333 1.9583333333333333 0 0 1 -2.722083333333333 0L14.6875 22.520833333333332"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                    <path
                        d="M4.895833333333333 2.9375a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 -1.9583333333333333h33.291666666666664a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 1.9583333333333333v12.259166666666665A34.81916666666667 34.81916666666667 0 0 1 23.5 46.02083333333333 34.81916666666667 34.81916666666667 0 0 1 4.895833333333333 15.196666666666665Z"
                        fill="none" stroke="#0066ff" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2"></path>
                </g>
            </svg>
            <p class="text-[48px] font-semibold text-[#18181b]">
                Protect your data using AyrDrive
            </p>
            <p class="text-[24px] text-[#3a3a3b]">
                With our service, you can be confident that only you have access to your
                files. Storage, sharing, and backup are securely and privately encrypted
            </p>
            <button
                class="flex w-max items-center rounded-lg bg-[#0066ff] px-[20px] py-3 font-medium text-white">
                Try it out
            </button>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-white">
    <div
        class="flex flex-col items-center justify-center space-y-[40px] px-[40px] py-[64px]">
        <p class="text-center text-[36px] font-semibold">
            Need answers? We've got them
        </p>
        <div class="flex w-full max-w-[850px] flex-col space-y-2">
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===0 ? tabIndex=-1 : tabIndex=0"
                            type="button"
                            class="my-5 flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span
                            class="w-full text-[24px] font-medium">What is AyrDrive?</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===0 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===0"
                          class="markdown will-change-height flex h-auto flex-col space-y-3 overflow-hidden pb-[24px] duration-250 whitespace-wrap w-full pr-14 text-left text-[24px] text-gray-60 transition-all ease-in-out"><p>
AyrDrive Storage is a cloud storage service that employs AES-256 encryption and operates under the zero-knowledge protocol, ensuring complete confidentiality and robust protection. We do not collect any data or files. Access to the files is exclusively in your hands                    </p></span>
                </div>
            </div>
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===1?tabIndex=-1:tabIndex=1" type="button"
                            class="my-5 flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[24px] font-medium">
                            How to create an AyrDrive account?
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===1 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===1"
                          class="markdown will-change-height flex h-auto flex-col space-y-3 overflow-hidden pb-[24px] duration-250 whitespace-wrap w-full pr-14 text-left text-[24px] text-gray-60 transition-all ease-in-out"><p>
Visit our website and click on 'Get started' located in the upper right-hand corner. Register using your email address and create a password. Afterward, you'll be able to interact with our service                    </p></span>
                </div>
            </div>
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===2?tabIndex=-1:tabIndex=2" type="button"
                            class="my-5 flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[24px] font-medium">
                            How can i upload files to Storage?
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===2 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===2"
                          class="markdown will-change-height flex h-auto flex-col space-y-3 overflow-hidden pb-[24px] duration-250 whitespace-wrap w-full pr-14 text-left text-[24px] text-gray-60 transition-all ease-in-out"><p>
Go to Storage via web or through the app and log in. Select the files you want to upload and confirm your choice. Alternatively, you can drag and drop files into the browser window or the desktop application window                    </p>
                    </span>
                </div>
            </div>
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===3?tabIndex=-1:tabIndex=3" type="button"
                            class="my-5 flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[24px] font-medium">How to download AyrDrive windows app?</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===3 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===3"
                          class="markdown will-change-height flex h-auto flex-col space-y-3 overflow-hidden pb-[24px] duration-250 whitespace-wrap w-full pr-14 text-left text-[24px] text-gray-60 transition-all ease-in-out"><p>
Visit the AyrDrive website, at the bottom of the home page, you'll find the 'Download for Windows' button. After a successful installation, launch the application and log in to your account. That's it! You can now enjoy access to your files                    </p></span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#0066ff] py-[64px]">
    <div
        class="flex flex-col items-center justify-center space-y-8 px-[20px] text-center">
        <div class="flex flex-col items-center space-y-[16px] text-center text-white">
            <p
                class="text-[36px] font-semibold">
                Get started now for free!
            </p>
            <p class="w-full max-w-[570px] text-[20px] font-normal">
                Create your account to enjoy up to 10 GB of free storage and gain full
                access to our cloud services
            </p>
        </div>
        <a href="/register"
           class="flex rounded-lg bg-white px-[20px] py-[12px] text-[18px] font-medium
            text-[#0066ff] hover:bg-[#ffffffee]">
            Get started
        </a>
    </div>
</section>

<div
    class="flex w-full flex-col items-center justify-center px-[24px] py-[64px] sm:p-20 sm:py-12">
    <div
        class="flex w-full max-w-screen-md flex-col items-center justify-between space-y-8 pb-9 text-center lg:flex-row lg:items-start lg:space-y-0 lg:space-x-32 lg:text-left">
        <div
            class="flex w-full max-w-[384px] flex-col items-center justify-center space-y-3 lg:items-start">
            <div class="flex flex-col space-y-1">
                <h2 class="text-[24px] font-medium ">
                    Download AyrDrive
                </h2>
                <p class="text-gray-600 text-[16px]">
                    Smooth data management and secure cloud storage are available with the
                    AyrDrive desktop app
                </p>
            </div>
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                <div class="flex"><a
                    class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    download href="/assets/AyrDrive.zip">
                    <span>Download for
                        <!-- --> Windows</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                         fill="currentColor" viewBox="0 0 256 256">
                        <path
                            d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                    </svg>
                </a></div>
            </div>
        </div>
        <div
            class="flex mb-10 max-w-[384px] flex-col items-center justify-center space-y-3 text-center md:items-start md:text-left ">
            <div class="flex w-full flex-col space-y-1"><h2
                class="text-[24px] font-medium">Newsletter</h2>
                <p class="text-[16px] text-gray-600">
                    Want to stay informed about our latest products and updates? Enter
                    your email address, and we'll take care of the rest!
                </p>
            </div>
            <form data-code="r3s4c1" method="post" target="_blank" rel="noopener"
                  action=""
                  class="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
                <input type="hidden" name="ml-submit" value="1">
                <input type="email" name="fields[email]"
                       placeholder="Your email address"
                       class="flex h-auto w-full flex-row rounded-lg px-[12px] py-3 text-[18px] outline-none sm:py-2 sm:text-[16px] md:w-64 border-cool-gray-20 bg-white focus:border-blue-50 focus:ring-opacity-20 mb-2 appearance-none border text-left transition-all duration-150 focus:ring focus:ring-primary"
                       required="">
                <input type="submit" name="signup" value="Subscribe"
                       class="ml-2 flex w-full cursor-pointer items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[12px] py-3 text-[18px] font-medium text-white transition-all duration-75 hover:bg-[#0066ff]-dark focus:outline-none active:bg-[#0066ff]-dark sm:mb-2 sm:py-2 sm:text-[16px]">
            </form>
            <span class="text-[16px] text-gray-400">By subscribing you agree to our
                <a href="/legal" class="cursor-pointer underline visited:text-gray-400">
                    privacy policy
                </a>
            </span>
        </div>
    </div>
    <footer class="flex max-w-screen-md w-[100%] items-center justify-center">
        <div
            class="w-[100%] flex-col items-center justify-center md:space-y-[56px] lg:flex">
            <div
                class="flex w-[100%] flex-row justify-between md:justify-between lg:space-x-[80px]">
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Products</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/drive">AyrDrive
                                Storage</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/photos">AyrDrive
                                Photos</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/pricing">Pricing</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Company</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/about">About Us</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/privacy">Privacy</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="flex max-w-[200px] flex-row items-center hover:text-[#0066ff]"
                                href="/open-source">Open Source</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/legal">Legal</a></p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Join Us</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a href="/register"
                                                               target="_top"
                                                               class="hover:text-[#0066ff]">Create
                                Account</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/login?" target="_top">Log In</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/assets/images/homepage/AyrDrive-white-paper.pdf"
                                target="_blank"
                                rel="noreferrer" download="" class="hover:text-[#0066ff]">White
                                Paper</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex w-full max-w-[900px] flex-row justify-between  border-t-[1px] border-gray-300 pt-[20px]">
                <div class="flex flex-row items-center space-x-4"><a
                    style="cursor: pointer !important"
                    class="flex flex-shrink-0" href="/"><img loading="lazy"
                                                             style="height:24px"
                                                             src="/assets/images/homepage/AyrDrive.svg"
                                                             alt="AyrDrive logo"></a>
                    <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive
                        Universal Technologies SL</p></div>
                <div class="flex flex-row space-x-1">
                </div>
            </div>
        </div>
        <div class="bg-gray-5 bg-opacity-50 flex flex-col overflow-hidden lg:hidden">
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                    id="headlessui-disclosure-button-5" type="button"
                    aria-expanded="false"><span class="flex flex-row">Products</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[#18181b] text-[18px] font-medium"
                    id="headlessui-disclosure-button-7" type="button"
                    aria-expanded="false"><span class="flex flex-row">Company</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <a href="/register"
                   class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                   id="headlessui-disclosure-button-9" type="button"
                   aria-expanded="false"><span class="flex flex-row">Join Us</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </a>
            </div>
            <div class="flex w-screen">
                <div class="w-screen">
                    <button
                        class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                        id="headlessui-disclosure-button-15" type="button"
                        aria-expanded="false">
                        <div class="flex flex-row items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                 fill="currentColor" viewBox="0 0 256 256"
                                 class="text-gray-60">
                                <path
                                    d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z"></path>
                            </svg>
                            <span class="flex flex-row">English (EN)</span></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="flex text-gray-600">
                            <path
                                d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="hidden text-gray-600">
                            <path
                                d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div
                class="flex flex-col items-center space-y-4 py-10 border-t-[1px] border-gray-300">
                <div class="flex flex-row space-x-1">
                </div>
                <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive Universal
                    Technologies SL</p><a class="flex flex-shrink-0" routerLink="/"
                                          fragment="top-one"><img
                loading="lazy" width="110" height="12"
                src="/assets/images/homepage/AyrDrive.svg"
                alt="AyrDrive logo"></a></div>
        </div>
    </footer>
</div>

