<div class="fixed top-0 left-0 z-50">
    <a href="/pricing"
       class="group left-0 z-50 h-[54px] w-screen cursor-pointer items-center justify-center overflow-hidden bg-[#0066ff] text-white md:flex">
        <div
            class="mx-auto text-[16px] flex flex-row items-center justify-center space-x-3">
            <div class="flex flex-row space-x-1">
                <p class="flex flex-row font-semibold">
                    91% OFF on All Annual Plans - Limited Time Offer
                </p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
            </svg>
        </div>
    </a>
    <nav
        class="border-b-[1px] border-gray-300 flex items-center h-[64px] py-[32px] pr-[24px] opacity-95 w-full bg-white transition-all duration-100 lg:h-16 undefined backdrop-blur-lg backdrop-saturate-150 backdrop-filter">
        <div class="mx-[24px] w-full lg:mx-[56px] xl:mx-[96px]">
            <div
                class="mx-auto flex max-w-screen-xl w-screen-xl items-center justify-between">
                <div
                    class="text-[18px] text-[#4D5358] flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-start space-x-[16px] lg:space-x-[24px]">
                    <a style="cursor: pointer" class="flex flex-shrink-0"
                       href="/">
                        <img
                            class="select-none"
                            src="/assets/images/homepage/AyrDrive.svg"
                            alt="AyrDrive logo"/>
                    </a>
                    <span class="w-[16px]"></span>
                    <a class="sm:hidden md:block whitespace-nowrap py-1.5 px-[12px] transition duration-150 ease-in-out text-[#4D5358] hover:text-[#0066ff]"
                       href="/pricing">Pricing</a>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 items-center transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl">
                        <span>Products</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none absolute top-full left-1/2 z-50 w-[196px] -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div
                                class="relative grid gap-0 whitespace-nowrap lg:grid-cols-1">
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/drive-info">AyrDrive Storage</a>
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/photos">AyrDrive Photos</a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl items-center">
                        <span>Our Values</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none text-[#4D5358] absolute top-full left-1/2 z-50 w-52 -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div class="relative grid gap-0 lg:grid-cols-1"><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/privacy">Privacy</a><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/open-source">Open Source</a></div>
                        </div>
                    </div>
                    <a class="whitespace-nowrap text-[#4D5358] py-1.5 px-[12px] transition duration-150 ease-in-out hover:text-[#0066ff] font-medium"
                       href="/about">About Us</a>
                </div>
                <div
                    class="space-x-[16px] inline-flex text-[18px] font-medium text-[#4D5358]">
                    <div
                        class="flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-end gap-[16px]">
                        <a id="loginButton" href="/login"
                           class="whitespace-nowrap rounded-xl border px-[12px] py-[4px] transition duration-150 ease-in-out focus:border focus:outline-none md:flex border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff] hover:bg-opacity-10 active:border-[#0066ff]-dark active:text-[#0066ff]-dark text-[14px] font-medium">
                            Log in
                        </a>
                        <a href="/register"
                           class="flex justify-center rounded-xl border border-transparent py-[4px] px-[12px] text-[14px] font-medium focus:outline-none sm:inline-flex bg-[#0066ff] text-white hover:bg-[#0066ff]-dark active:bg-[#0066ff]-dark transition-all duration-75">
                            <p class="whitespace-nowrap">Get started</p></a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<section class="relative flex w-full flex-col overflow-hidden pb-[64px] pt-[150px]">
    <div class="flex flex-col items-center ">
        <div
            class="flex flex-col items-center justify-center space-y-[40px] px-6 text-center">
            <h1 class="max-w-[898px] undefined text-[60px] font-semibold leading-tight sm:text-[70px] sm:leading-tight">
                Building trust through the power of <span class="text-[#0066ff]">Open Source</span>
            </h1>
            <h2 class="mb-8 w-full max-w-[898px] text-[20px] font-normal text-[#3a3a3b] sm:mb-[40px]">
                Here at AyrDrive, we are committed to providing people with the highest
                level of privacy and security. This privacy-by-design approach is part of
                our philosophy to build technologies that protect your privacy and keep
                your data safe</h2>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#fafbfc]">
    <div
        class="flex flex-col items-center justify-center py-[64px] px-[20px] text-center">
        <div class="flex w-full max-w-[850px] flex-col items-center space-y-[64px]">
            <div class="flex flex-col space-y-[24px]"><p
                class="text-[48px] font-semibold text-[#18181b]">What is open source
                software?</p>
                <p class="text-[24px] text-[#3a3a3b]">Open source software, or OSS, is the
                    computer’s code used to create projects and is freely available to the
                    public. With OSS, you can view the code which built the website and,
                    with the proper knowledge, modify or improve its security, fix bugs,
                    or add new features</p></div>
            <p class="text-[24px] font-medium text-[#3a3a3b]">We use open source software
                in
                our Drive, Photos, and Send apps, ensuring that we are constantly updating
                and improving our source code. This commitment allows us to bring you the
                best experience possible</p><img
            src="/assets/images/homepage/Internxt-open-source.webp" width="796"
            alt="AyrDrive Open Source Software">
            <div class="flex flex-col space-y-[24px]"><p
                class="text-[20px] text-[#3a3a3b]">In our approach to application
                development, paramount attention is given to confidentiality and security,
                achieved through the capabilities of opensource software. With us, you no
                longer need to worry that your data is unprotected</p>
            </div>
        </div>
    </div>
</section>

<section style="background-image:url(/images/cyber-awareness/Background.svg)"
         class="overflow-hidden bg-[#0066ff] bg-cover px-[20px] py-[64px]">
    <div class="flex flex-col items-center justify-center space-y-[32px] text-center">
        <div
            class="flex  undefined flex-col items-center space-y-[16px] text-center text-white">
            <p class="text-[36px] font-semibold">Making privacy the core goal</p>
            <p class="w-full max-w-[573px] text-[20px] font-normal">
                Protect your files using AyrDrive cloud storage
            </p>
        </div>
        <a href="/register"
           class="flex rounded-lg bg-white px-[20px] py-[12px] text-[18px] font-medium text-[#0066ff] hover:bg-[#ffffffee]">
            Join AyrDrive today
        </a>
    </div>
</section>

<section class="overflow-hidden">
    <div class="flex flex-col items-center justify-center space-y-20 px-[20px] py-[64px]">
        <div class="flex flex-col items-center space-y-[24px] text-center"><p
            class="max-w-[756px] text-[48px] font-semibold text-[#18181b]">
            What sets open source software apart from closed source
        </p>
            <p class="max-w-[850px] text-[20px] text-[#3a3a3b]">
                Selecting the appropriate software involves considering the advantages and
                disadvantages of open source alternatives. The main differences between
                open source and closed source software can be summarized as follows:
            </p>
        </div>
        <div class="flex w-full">
            <div
                class="flex w-full flex-col space-y-10 md:flex-row md:space-y-0 md:space-x-20 lg:items-start lg:justify-center">
                <div class="flex h-full max-w-[387px] flex-1 flex-col rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div
                        class="flex w-full items-center space-x-4 rounded-l-lg bg-primary bg-opacity-3 py-4 pl-6">
                        <svg width="32" height="31" viewBox="0 0 32 31"
                             class="h-[32px] w-[32px]" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_5975_20611)">
                                <path
                                    d="M0 16.4947C0 16.39 0 16.2854 0 16.1815C0.0266667 16.0501 0.0641667 15.9203 0.0775 15.788C0.150833 15.0529 0.1625 14.3069 0.295833 13.5826C1.05833 9.44055 3.10667 6.06461 6.475 3.55107C10.0908 0.853333 14.1667 -0.132991 18.6142 0.602984C22.8083 1.29626 26.2017 3.38529 28.7575 6.78635C30.7658 9.45981 31.835 12.4883 31.925 15.8491C31.9292 16.0024 31.9742 16.1539 32 16.3071V16.4955C31.9733 16.6261 31.9367 16.7559 31.9225 16.8882C31.8317 17.7155 31.8117 18.5569 31.6467 19.3691C30.6833 24.0964 28.13 27.7102 23.9967 30.1894C23.4883 30.4941 23.2125 30.4138 22.9225 29.8997C21.3617 27.1274 19.8025 24.3543 18.24 21.5829C17.8792 20.9424 17.9267 20.7272 18.5408 20.3286C20.2075 19.2485 21.0467 17.2441 20.6192 15.366C20.0275 12.7704 17.6575 11.2122 15.05 11.7046C12.9525 12.0998 11.2883 14.0867 11.2867 16.2217C11.285 18.0219 12.0458 19.4152 13.56 20.3889C14.0175 20.6828 14.11 20.9675 13.8608 21.4096C12.26 24.2547 10.6558 27.0989 9.04833 29.9407C8.79 30.397 8.49 30.4724 8.03333 30.2044C5.40583 28.668 3.35833 26.5622 1.92833 23.8687C0.775 21.6976 0.14 19.3833 0.0758333 16.9192C0.0725 16.7769 0.0266667 16.6362 0 16.4947Z"
                                    fill="#2DAE4D"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_5975_20611">
                                    <rect width="32" height="30" fill="white"
                                          transform="translate(0 0.366699)"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <p class="text-[20px] font-semibold text-[#18181b]">Open
                            Source</p>
                    </div>
                    <div class="flex h-full flex-col">
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">Anyone can view, modify or
                                    change the code and improve it</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#00aa00]">
                                        <path
                                            d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">Open availability and
                                    transparency of the source code means anyone can
                                    review the code for potential issues.</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#00aa00]">
                                        <path
                                            d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">The software can be maintained
                                    and fixed quickly.</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#00aa00]">
                                        <path
                                            d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">More cost-effective as there
                                    are no licensing fees to pay for.</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#00aa00]">
                                        <path
                                            d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">More accessible due to its
                                    flexibility to add features and make changes</p>
                                </div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#00aa00]">
                                        <path
                                            d="M232.49,80.49l-128,128a12,12,0,0,1-17,0l-56-56a12,12,0,1,1,17-17L96,183,215.51,63.51a12,12,0,0,1,17,17Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex h-full w-full max-w-[387px] flex-col rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div
                        class="flex w-full items-center space-x-4 rounded-l-lg bg-primary bg-opacity-3 py-4 pl-6">
                        <svg width="32" class="h-[32px] w-[32px]" height="31"
                             viewBox="0 0 32 31" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M32 14.2392C32 14.3438 32 14.4485 32 14.5523C31.9733 14.6838 31.9358 14.8135 31.9225 14.9458C31.8492 15.681 31.8375 16.427 31.7042 17.1512C30.9417 21.2933 28.8933 24.6692 25.525 27.1828C21.9092 29.8805 17.8333 30.8668 13.3858 30.1309C9.19167 29.4376 5.79833 27.3486 3.2425 23.9475C1.23417 21.274 0.165 18.2456 0.0750002 14.8847C0.0708327 14.7315 0.0258345 14.5799 1.39353e-06 14.4267L1.41e-06 14.2383C0.0266681 14.1077 0.0633349 13.9779 0.0775008 13.8456C0.168335 13.0184 0.188333 12.1769 0.353334 11.3648C1.31667 6.63743 3.87 3.0237 8.00334 0.544493C8.51167 0.239719 8.7875 0.320099 9.0775 0.834192C10.6383 3.60645 12.1975 6.37955 13.76 9.15097C14.1208 9.79149 14.0733 10.0067 13.4592 10.4052C11.7925 11.4853 10.9533 13.4898 11.3808 15.3678C11.9725 17.9634 14.3425 19.5216 16.95 19.0293C19.0475 18.6341 20.7117 16.6472 20.7133 14.5121C20.715 12.7119 19.9542 11.3187 18.44 10.3449C17.9825 10.051 17.89 9.76637 18.1392 9.32428C19.74 6.47918 21.3442 3.63492 22.9517 0.793168C23.21 0.336847 23.51 0.261491 23.9667 0.529422C26.5942 2.06585 28.6417 4.17162 30.0717 6.86517C31.225 9.03626 31.86 11.3505 31.9242 13.8147C31.9275 13.957 31.9733 14.0977 32 14.2392Z"
                                fill="#E60B00"/>
                        </svg>
                        <p class="text-[20px] font-semibold text-[#18181b]">Closed
                            Source</p>
                    </div>
                    <div class="flex h-full flex-col">
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">The source code is only
                                    accessible to those who created it.</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#aa0000]">
                                        <path
                                            d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">The code is inaccessible to the
                                    public, meaning a few people can check for security
                                    flaws.</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#aa0000]">
                                        <path
                                            d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">Updating the code takes time,
                                    leaving it vulnerable to attacks.</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#aa0000]">
                                        <path
                                            d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">The software can be high-priced
                                    and requires a license to use it.</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#aa0000]">
                                        <path
                                            d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex h-full w-full flex-row items-center bg-primary bg-opacity-3">
                            <div
                                class="flex h-full w-full flex-row items-center justify-between border-b border-primary border-opacity-3 bg-gray-1 pl-6">
                                <div class="flex w-[275px] py-4 lg:max-w-[275px]"><p
                                    class="text-[#3a3a3b]">Change relies on the company
                                    and may take some time.</p></div>
                            </div>
                            <div class="flex h-full w-full flex-col md:w-max md:px-4">
                                <div
                                    class="flex flex-grow flex-col items-center justify-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32"
                                         height="32" fill="currentColor"
                                         viewBox="0 0 256 256" class="text-[#aa0000]">
                                        <path
                                            d="M208.49,191.51a12,12,0,0,1-17,17L128,145,64.49,208.49a12,12,0,0,1-17-17L111,128,47.51,64.49a12,12,0,0,1,17-17L128,111l63.51-63.52a12,12,0,0,1,17,17L145,128Z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#fafbfc]">
    <div
        class="flex flex-col items-center justify-center space-y-[64px] py-[64px] px-[20px]">
        <div class="flex max-w-[914px] flex-col space-y-6 text-center"><p
            class="text-[48px] font-semibold text-[#18181b]">Our service is entirely
            opensource</p>
            <div class="text-[20px] text-[#3a3a3b]">Feel free to examine how AyrDrive
                functions or inspect the source code to understand the development of the
                web version and Windows app
            </div>
        </div>
        <div class="flex w-full flex-col items-center justify-center pt-6">
            <picture>
                <img src="/assets/images/homepage/pick5.webp"
                     alt="AyrDrive secure cloud storage" width="757" draggable="false">
            </picture>
        </div>
        <div class="flex w-full flex-col items-center justify-center space-y-9"><p
            class="text-center text-[36px] font-semibold text-[#18181b]">Download AyrDrive</p>
            <div
                class="flex w-full flex-row items-center justify-center lg:items-start lg:space-x-32">
                <div class="hidden lg:flex flex-1 flex-grow flex-row justify-end">
                    <div class="flex flex-col items-center space-y-1"><a
                        class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                        download href="/assets/AyrDrive.zip"><span>Download for
                        <!-- --> Windows</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                             fill="currentColor" viewBox="0 0 256 256">
                            <path
                                d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                        </svg>
                    </a>
                    </div>
                </div>
                <div
                    class="hidden lg:flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    rel="noreferrer">
                </div>
                <div
                    class="flex flex-1 flex-grow flex-row justify-center text-[18px] lg:justify-start">
                    <div class="flex flex-col items-center space-y-1"><a
                        class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff]"
                        href="/register"
                        target="_blank" rel="noreferrer"><span>Open Drive Web</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                             fill="currentColor" viewBox="0 0 256 256">
                            <path
                                d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
                        </svg>
                    </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#0066ff] py-[64px]">
    <div
        class="flex flex-col items-center justify-center space-y-8 px-[20px] text-center">
        <div class="flex flex-col items-center space-y-[16px] text-center text-white">
            <p
                class="text-[36px] font-semibold">
                Get started now for free!
            </p>
            <p class="w-full max-w-[570px] text-[20px] font-normal">
                Create your account to enjoy up to 10 GB of free storage and gain full
                access to our cloud services
            </p>
        </div>
        <a href="/register"
           class="flex rounded-lg bg-white px-[20px] py-[12px] text-[18px] font-medium
            text-[#0066ff] hover:bg-[#ffffffee]">
            Get started
        </a>
    </div>
</section>

<div
    class="flex w-full flex-col items-center justify-center px-[24px] py-[64px]">
    <div
        class="flex w-full max-w-screen-md flex-col items-center justify-between space-y-8 pb-9 text-center lg:flex-row lg:items-start lg:space-y-0 lg:space-x-32 lg:text-left">
        <div
            class="flex w-full max-w-[384px] flex-col items-center justify-center space-y-3 lg:items-start">
            <div class="flex flex-col space-y-1">
                <h2 class="text-[24px] font-medium ">
                    Download AyrDrive
                </h2>
                <p class="text-gray-600 text-[16px]">
                    Smooth data management and secure cloud storage are available with the
                    AyrDrive desktop app
                </p>
            </div>
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                <div class="flex"><a
                    class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    download href="/assets/AyrDrive.zip">
                    <span>Download for
                        <!-- --> Windows</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                         fill="currentColor" viewBox="0 0 256 256">
                        <path
                            d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                    </svg>
                </a></div>
            </div>
        </div>
        <div
            class="flex mb-10 max-w-[384px] flex-col items-center justify-center space-y-3 text-center md:items-start md:text-left ">
            <div class="flex w-full flex-col space-y-1"><h2
                class="text-[24px] font-medium">Newsletter</h2>
                <p class="text-[16px] text-gray-600">
                    Want to stay informed about our latest products and updates? Enter
                    your email address, and we'll take care of the rest!
                </p>
            </div>
            <form data-code="r3s4c1" method="post" target="_blank" rel="noopener"
                  action=""
                  class="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
                <input type="hidden" name="ml-submit" value="1">
                <input type="email" name="fields[email]"
                       placeholder="Your email address"
                       class="flex h-auto w-full flex-row rounded-lg px-[12px] py-3 text-[18px] outline-none sm:py-2 sm:text-[16px] md:w-64 border-cool-gray-20 bg-white focus:border-blue-50 focus:ring-opacity-20 mb-2 appearance-none border text-left transition-all duration-150 focus:ring focus:ring-primary"
                       required="">
                <input type="submit" name="signup" value="Subscribe"
                       class="ml-2 flex w-full cursor-pointer items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[12px] py-3 text-[18px] font-medium text-white transition-all duration-75 hover:bg-[#0066ff]-dark focus:outline-none active:bg-[#0066ff]-dark sm:mb-2 sm:py-2 sm:text-[16px]">
            </form>
            <span class="text-[16px] text-gray-400">By subscribing you agree to our
                <a href="/legal" class="cursor-pointer underline visited:text-gray-400">
                    privacy policy
                </a>
            </span>
        </div>
    </div>
    <footer class="flex max-w-screen-md w-[100%] items-center justify-center">
        <div
            class="w-[100%] flex-col items-center justify-center md:space-y-[56px] lg:flex">
            <div
                class="flex w-[100%] flex-row justify-between md:justify-between lg:space-x-[80px]">
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Products</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/drive">AyrDrive
                                Storage</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/photos">AyrDrive
                                Photos</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/pricing">Pricing</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Company</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/about">About Us</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/privacy">Privacy</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="flex max-w-[200px] flex-row items-center hover:text-[#0066ff]"
                                href="/open-source">Open Source</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/legal">Legal</a></p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Join Us</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a href="/register"
                                                               target="_top"
                                                               class="hover:text-[#0066ff]">Create
                                Account</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/login?" target="_top">Log In</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/assets/images/homepage/AyrDrive-white-paper.pdf"
                                target="_blank"
                                rel="noreferrer" download="" class="hover:text-[#0066ff]">White
                                Paper</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex w-full max-w-[900px] flex-row justify-between  border-t-[1px] border-gray-300 pt-[20px]">
                <div class="flex flex-row items-center space-x-4"><a
                    style="cursor: pointer !important"
                    class="flex flex-shrink-0" href="/"><img loading="lazy"
                                                             style="height:24px"
                                                             src="/assets/images/homepage/AyrDrive.svg"
                                                             alt="AyrDrive logo"></a>
                    <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive
                        Universal Technologies SL</p></div>
                <div class="flex flex-row space-x-1">
                </div>
            </div>
        </div>
        <div class="bg-gray-5 bg-opacity-50 flex flex-col overflow-hidden lg:hidden">
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                    id="headlessui-disclosure-button-5" type="button"
                    aria-expanded="false"><span class="flex flex-row">Products</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[#18181b] text-[18px] font-medium"
                    id="headlessui-disclosure-button-7" type="button"
                    aria-expanded="false"><span class="flex flex-row">Company</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <a href="/register"
                   class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                   id="headlessui-disclosure-button-9" type="button"
                   aria-expanded="false"><span class="flex flex-row">Join Us</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </a>
            </div>
            <div class="flex w-screen">
                <div class="w-screen">
                    <button
                        class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                        id="headlessui-disclosure-button-15" type="button"
                        aria-expanded="false">
                        <div class="flex flex-row items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                 fill="currentColor" viewBox="0 0 256 256"
                                 class="text-gray-60">
                                <path
                                    d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z"></path>
                            </svg>
                            <span class="flex flex-row">English (EN)</span></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="flex text-gray-600">
                            <path
                                d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="hidden text-gray-600">
                            <path
                                d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div
                class="flex flex-col items-center space-y-4 py-10 border-t-[1px] border-gray-300">
                <div class="flex flex-row space-x-1">
                </div>
                <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive Universal
                    Technologies SL</p><a class="flex flex-shrink-0" routerLink="/"
                                          fragment="top-one"><img
                loading="lazy"
                src="/assets/images/homepage/AyrDrive.svg"
                alt="AyrDrive logo"></a></div>
        </div>
    </footer>
</div>
