<div class="fixed top-0 left-0 z-50">
    <a href="/pricing"
       class="group left-0 z-50 h-[54px] w-screen cursor-pointer items-center justify-center overflow-hidden bg-[#0066ff] text-white md:flex">
        <div
            class="mx-auto text-[16px] flex flex-row items-center justify-center space-x-3">
            <div class="flex flex-row space-x-1">
                <p class="flex flex-row font-semibold">
                    91% OFF on All Annual Plans - Limited Time Offer
                </p>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
            </svg>
        </div>
    </a>
    <nav
        class="border-b-[1px] border-gray-300 flex items-center h-[64px] py-[32px] pr-[24px] opacity-95 w-full bg-white transition-all duration-100 lg:h-16 undefined backdrop-blur-lg backdrop-saturate-150 backdrop-filter">
        <div class="mx-[24px] w-full lg:mx-[56px] xl:mx-[96px]">
            <div
                class="mx-auto flex max-w-screen-xl w-screen-xl items-center justify-between">
                <div
                    class="text-[18px] text-[#4D5358] flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-start space-x-[16px] lg:space-x-[24px]">
                    <a style="cursor: pointer" class="flex flex-shrink-0"
                       (click)="scrollToTop()">
                        <img
                            class="select-none"
                            src="/assets/images/homepage/AyrDrive.svg"
                            alt="AyrDrive logo"/>
                    </a>
                    <span class="w-[16px]"></span>
                    <a class="sm:hidden md:block whitespace-nowrap py-1.5 px-[12px] transition duration-150 ease-in-out text-[#4D5358] hover:text-[#0066ff]"
                       href="/pricing">Pricing</a>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 items-center transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl">
                        <span>Products</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none absolute top-full left-1/2 z-50 w-[196px] -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div
                                class="relative grid gap-0 whitespace-nowrap lg:grid-cols-1">
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/drive-info">AyrDrive Storage</a>
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/photos">AyrDrive Photos</a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl items-center">
                        <span>Our Values</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none text-[#4D5358] absolute top-full left-1/2 z-50 w-52 -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div class="relative grid gap-0 lg:grid-cols-1"><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/privacy">Privacy</a><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/open-source">Open Source</a></div>
                        </div>
                    </div>
                    <a class="whitespace-nowrap text-[#4D5358] py-1.5 px-[12px] transition duration-150 ease-in-out hover:text-[#0066ff] font-medium"
                       href="/about">About Us</a>
                </div>
                <div
                    class="space-x-[16px] inline-flex text-[18px] font-medium text-[#4D5358]">
                    <div
                        class="flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-end gap-[16px]">
                        <a id="loginButton" href="/login"
                           class="whitespace-nowrap rounded-xl border px-[12px] py-[4px] transition duration-150 ease-in-out focus:border focus:outline-none md:flex border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff] hover:bg-opacity-10 active:border-[#0066ff]-dark active:text-[#0066ff]-dark text-[14px] font-medium">
                            Log in
                        </a>
                        <a href="/register"
                           class="flex justify-center rounded-xl border border-transparent py-[4px] px-[12px] text-[14px] font-medium focus:outline-none sm:inline-flex bg-[#0066ff] text-white hover:bg-[#0066ff]-dark active:bg-[#0066ff]-dark transition-all duration-75">
                            <p class="whitespace-nowrap">Get started</p></a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<section class="overflow-hidden" id="top-one">
    <div
        class="flex items-center justify-center w-[100%] mt-[100px] border-b border-gray-5 py-[64px]">
        <div
            class="flex max-w-screen-xl w-screen-xl mx-[24px] w-full lg:mx-[56px] xl:mx-[96px] items-center justify-between flex-row">
            <div
                class="my-6 flex flex-col items-start text-left w-full min-w-[550px]">
                <h1 class="undefined text-[60px] font-semibold leading-tight">
                    Secure today, ready for tomorrow with <span
                    class=" whitespace-nowrap text-[#0066ff]">AyrDrive</span></h1>
                <h2 class="my-[16px] w-[100%] text-[18px] font-normal text-[#4D5358] md:mb-[32px] max-w-[400px]">
                    Make sure your files and data are protected with our open source and
                    encrypted services. AyrDrive Storage, Photos, and more
                </h2>
                <a href="/register"
                   class="mt-3 flex w-[100%] px-[36px] flex-row items-center justify-center space-x-4 rounded-xl bg-[#0066ff] px-5 py-[10px] text-[18px] text-white transition duration-100 focus:outline-none focus-visible:bg-[#0066ff]-dark active:bg-[#0066ff]-dark sm:mt-0 sm:w-auto sm:text-[18px]">
                    <div class="flex flex-row items-center space-x-2"><span
                        class="font-medium">Try it out for free!</span><span
                        class="opacity-60"></span><span
                        class="opacity-60"></span></div>
                </a>
            </div>
            <div>
                <img
                    class="rounded-xl translate-x-[0%] scale-100 overflow-hidden h-full lg:aspect-[4/3] xl:aspect-[5/3] object-cover object-left"
                    src="/assets/images/homepage/browser.webp"/>
            </div>
        </div>
    </div>
</section>

<div
    class="flex w-full flex-shrink-0 flex-col items-center justify-center text-center py-[64px] bg-[#f9f9fc]">
    <h3 class="mb-[32px] text-[36px] text-[#18181b] font-medium">We were chosen by</h3>
    <div class="flex flex-row flex-wrap justify-around">
        <div
            class="flex h-20 flex-shrink-0 items-center justify-center w-auto px-[24px]">
            <img loading="lazy" src="/assets/images/homepage/ovhcloud.svg"
                 draggable="false"
                 alt="ovh cloud logo"></div>
        <div
            class="flex h-20 flex-shrink-0 items-center justify-center w-auto px-[24px]">
            <img loading="lazy" src="/assets/images/homepage/telefonica.svg"
                 draggable="false" alt="telefonica logo"></div>
        <div
            class="flex h-20 flex-shrink-0 items-center justify-center w-auto px-[24px]">
            <img loading="lazy" src="/assets/images/homepage/esade.webp"
                 class="mx-auto h-[28px]"
                 draggable="false" alt="esade logo"></div>
        <div
            class="flex h-20 flex-shrink-0 items-center justify-center w-auto px-[24px]">
            <img loading="lazy" src="/assets/images/homepage/notion_vc.webp"
                 class="mx-auto h-[16px]" draggable="false" alt="notion vc logo"></div>
        <div
            class="flex h-20 flex-shrink-0 items-center justify-center w-auto px-[24px]">
            <img loading="lazy" src="/assets/images/homepage/angelscapital.svg"
                 draggable="false" alt="angels capital logo"></div>
    </div>
</div>

<div class="w-[100%] flex items-center justify-center py-[64px]">
    <div
        class="w-[100%] max-w-screen-lg rounded-3xl bg-[#0066ff] p-[36px] flex flex-row items-center justify-between">
        <div class="flex flex-col">
            <h2 class="max-w-[600px] mb-6 text-[48px] text-white font-semibold sm:leading-tight">
                Your files and photos under the aegis of the most robust cloud protection
            </h2>
            <h3 class="max-w-[768px] mb-6 w-full  text-[18px] font-normal  text-white">
                Rediscover data ownership with AyrDrive's private and secure cloud
                services, dedicated to ensuring the security of your files and privacy
                rights. Built on a foundation of open-source software, AyrDrive services
                are built to ensure unmatched safety and flexibility, guaranteeing maximum
                utility
            </h3>
            <div class="flex flex-row">
                <a href="/register"
                   class="flex rounded-xl bg-white text-[18px] px-[20px] py-[12px] text-[#0066ff] hover:bg-[#ffffffee]">
                    Create free account
                </a>
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="342" height="378"
             viewBox="0 0 171 189" fill="none">
            <g clip-path="url(#clip0_0_318)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M133.435 175.368L65.5601 175.345C62.0121 175.343 59.1097 172.446 59.1112 168.905L59.1261 124.97C59.1276 121.43 62.0315 118.535 65.58 118.536L133.455 118.56C137.004 118.56 139.906 121.458 139.905 124.999L139.889 168.934C139.888 172.474 136.984 175.369 133.435 175.368Z"
                      fill="#6796B1"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M65.5798 118.536C65.5798 118.536 64.0132 121.688 67.8361 121.689C71.6585 121.69 131.075 121.711 131.075 121.711C131.075 121.711 134.238 122.131 134.239 118.609L65.5798 118.536Z"
                      fill="#AED7E8"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M132.242 140.566L130.158 144.953C130.158 144.953 82.0185 133.621 70.2144 135.002C60.3005 136.163 59.2172 131.952 59.124 130.573L59.1112 168.905C59.1097 172.445 62.0121 175.343 65.5601 175.344L133.435 175.367C136.984 175.369 139.888 172.474 139.889 168.933L139.899 140.458C137.111 142.919 132.242 140.566 132.242 140.566Z"
                      fill="#6E9EBA"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M133.435 175.368V174.644L65.5605 174.62C63.9867 174.62 62.5588 173.979 61.5184 172.941C60.4786 171.902 59.8366 170.478 59.8366 168.908H59.2171L59.8366 168.921V168.908H59.2171L59.8366 168.921V168.906L59.8519 124.971C59.8525 123.4 60.495 121.976 61.5353 120.938C62.5762 119.9 64.0041 119.26 65.5779 119.26V118.823L65.5763 119.26H65.5779V118.823L65.5763 119.26L65.5789 118.563L65.5528 119.259L65.5763 119.26L65.5789 118.563L65.5528 119.259L65.5794 119.26L133.455 119.284C135.029 119.284 136.456 119.925 137.497 120.963C138.537 122.002 139.179 123.427 139.179 124.997H139.81L139.179 124.994V124.997H139.81L139.179 124.994L139.181 125.052L139.905 124.999H139.179L139.163 168.933C139.163 170.503 138.52 171.927 137.48 172.966C136.439 174.003 135.012 174.644 133.438 174.644V175.147L133.439 174.644H133.438V175.147L133.439 174.644L133.397 174.645L133.435 175.368V174.644V175.368L133.473 176.091L133.437 175.396L133.44 176.092L133.473 176.091L133.437 175.396L133.44 176.092L133.437 175.459L133.435 176.092H133.44L133.437 175.459L133.435 176.092H133.438C137.389 176.089 140.611 172.876 140.615 168.933L140.63 124.999L140.628 124.945L139.918 124.998L140.63 124.993L140.628 124.945L139.918 124.998L140.63 124.993L139.946 124.998L140.63 125.001V124.993L139.946 124.998L140.63 125.001V124.997C140.627 121.055 137.407 117.84 133.455 117.836L65.5799 117.812V118.536L65.6071 117.812H65.581H65.5769L65.5779 118.345V117.812H65.5769L65.5779 118.345V117.812C61.6265 117.815 58.4046 121.028 58.4005 124.97L58.3851 168.905H59.1111L58.3851 168.89V168.908C58.3882 172.85 61.6085 176.064 65.56 176.068L133.435 176.092L133.473 176.091L133.435 175.368Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M128.297 170.004L70.7023 169.985C67.7635 169.984 65.3613 167.584 65.3613 164.653L65.3741 129.227C65.3752 126.295 67.7799 123.898 70.7181 123.899L128.313 123.919C131.251 123.92 133.655 126.319 133.654 129.251L133.641 164.676C133.64 167.608 131.235 170.005 128.297 170.004Z"
                      fill="#FFFFFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.7023 169.985L128.297 170.004C131.235 170.005 133.64 167.608 133.641 164.676L133.648 146.488L130.158 142.108C128.642 140.204 125.743 140.203 124.226 142.106L111.332 158.269L107.003 152.836C105.4 150.824 102.339 150.822 100.735 152.833L96.1127 158.626L82.2284 141.197C80.6372 139.199 77.5924 139.208 76.0125 141.216L65.3649 154.751L65.3613 164.653C65.3613 167.584 67.7635 169.984 70.7023 169.985Z"
                      fill="#FFFFFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M96.1124 158.626L82.2281 141.197C80.6369 139.199 77.5921 139.208 76.0122 141.216L75.0737 142.409L95.0925 164.713C95.0925 164.713 97.3258 163.228 96.1124 158.626Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M110.621 166.19C110.621 166.19 114.297 163.587 111.332 158.269L107.003 152.836C105.4 150.824 102.339 150.823 100.735 152.833L99.957 153.808L110.621 166.19Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M130.158 142.108C128.642 140.204 125.743 140.203 124.226 142.106L123.827 142.605L133.644 155.484L133.648 146.488L130.158 142.108Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M70.7023 169.984L70.7018 170.709L128.296 170.729H128.307L128.299 170.074V170.729H128.307L128.299 170.074V170.729C131.64 170.726 134.363 168.01 134.367 164.676L134.373 146.488C134.373 146.324 134.318 146.166 134.215 146.037L130.726 141.657C129.826 140.525 128.502 139.953 127.191 139.955C125.882 139.953 124.559 140.524 123.658 141.655L111.332 157.105L107.571 152.385C106.628 151.199 105.242 150.6 103.868 150.602C102.496 150.6 101.111 151.198 100.167 152.382L96.1132 157.463L82.7967 140.747C81.8618 139.572 80.4887 138.978 79.1284 138.98C77.7583 138.978 76.376 139.58 75.4416 140.769L64.7941 154.304C64.6947 154.43 64.6394 154.589 64.6394 154.75L64.6359 164.652V164.648V164.654C64.6389 167.988 67.3601 170.705 70.7018 170.709L70.7023 169.984V169.261C69.4336 169.26 68.2832 168.744 67.4435 167.906C66.6049 167.068 66.0873 165.92 66.0873 164.654H65.6424L66.0873 164.657V164.654H65.6424L66.0873 164.657V164.653L66.0909 155.001L76.5833 141.663C77.2341 140.837 78.1746 140.43 79.1284 140.428C80.075 140.43 81.0094 140.831 81.6606 141.648L95.5449 159.077C95.6821 159.249 95.8925 159.35 96.1127 159.35C96.3328 159.35 96.5432 159.249 96.6804 159.077L101.303 153.284C101.962 152.458 102.909 152.051 103.868 152.05C104.828 152.051 105.775 152.459 106.436 153.286L110.764 158.719C110.901 158.891 111.111 158.992 111.332 158.992C111.552 158.992 111.762 158.891 111.899 158.719L124.794 142.556C125.41 141.785 126.294 141.405 127.191 141.403C128.089 141.405 128.973 141.785 129.59 142.558L132.922 146.74L132.916 164.676C132.915 165.942 132.397 167.09 131.558 167.927C130.718 168.764 129.567 169.281 128.299 169.281H128.288L128.297 169.935V169.281H128.288L128.297 169.935V169.281L70.7023 169.261V169.984Z"
                      fill="#3F234F"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M128.297 170.005V169.281L70.7023 169.261C69.4336 169.26 68.2832 168.744 67.4435 167.907C66.6049 167.069 66.0873 165.921 66.0873 164.654H65.9491L66.0873 164.655V164.654H65.9491L66.0873 164.655L65.4095 164.654L66.0873 164.668V164.655L65.4095 164.654L66.0873 164.668V164.653L66.0996 129.228C66.1001 127.961 66.6182 126.814 67.4568 125.976C68.297 125.14 69.4474 124.623 70.7161 124.623V124.185L70.7151 124.623H70.7161V124.185L70.7151 124.623L70.7171 123.926L70.691 124.623L70.7151 124.623L70.7171 123.926L70.691 124.623L70.7176 124.623L128.312 124.643C129.582 124.644 130.732 125.16 131.572 125.997C132.41 126.836 132.928 127.983 132.928 129.25H133.56L132.928 129.247V129.25H133.56L132.928 129.247L132.93 129.305L133.654 129.251H132.928L132.916 164.676C132.915 165.942 132.397 167.09 131.558 167.928C130.718 168.764 129.567 169.281 128.299 169.281V169.908L128.302 169.281H128.299V169.908L128.302 169.281L128.24 169.283L128.297 170.005V169.281V170.005L128.354 170.726L128.298 170.017L128.303 170.729L128.354 170.726L128.298 170.017L128.303 170.729L128.298 170.046L128.295 170.729H128.303L128.298 170.046L128.295 170.729H128.299C131.64 170.726 134.363 168.01 134.367 164.676L134.379 129.252L134.377 129.198L133.668 129.25L134.379 129.246L134.377 129.198L133.668 129.25L134.379 129.246L133.695 129.25L134.379 129.253V129.246L133.695 129.25L134.379 129.253V129.25C134.377 125.916 131.655 123.199 128.313 123.195L70.7181 123.175V123.899L70.7453 123.176L70.7197 123.175H70.7151L70.7161 123.711V123.175H70.7151L70.7161 123.711V123.175C67.3755 123.178 64.6522 125.893 64.6487 129.227L64.6359 164.652L65.3613 164.653L64.6359 164.638V164.654C64.6389 167.988 67.3601 170.705 70.7018 170.709L128.296 170.729L128.354 170.726L128.297 170.005Z"
                      fill="#3F234F"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M119.629 137.503C119.628 140.659 117.062 143.217 113.899 143.216C110.735 143.215 108.171 140.655 108.172 137.499C108.173 134.342 110.739 131.784 113.903 131.785C117.066 131.786 119.63 134.346 119.629 137.503Z"
                      fill="#99D3D1"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M113.899 141.47C111.034 141.469 108.667 139.367 108.246 136.625C108.202 136.911 108.172 137.201 108.172 137.498C108.171 140.655 110.735 143.214 113.899 143.215C117.062 143.217 119.628 140.659 119.629 137.503C119.629 137.205 119.6 136.915 119.556 136.63C119.132 139.371 116.764 141.471 113.899 141.47Z"
                      fill="#46A09A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M119.629 137.503L118.903 137.502C118.903 140.258 116.663 142.492 113.901 142.492H113.9L113.874 142.492L113.899 143.216V142.492C111.137 142.491 108.897 140.256 108.898 137.5H108.467L108.898 137.502V137.5H108.467L108.898 137.502L108.197 137.5L108.897 137.527L108.898 137.502L108.197 137.5L108.897 137.527L108.898 137.499C108.898 134.743 111.139 132.508 113.901 132.508H113.902V131.805L113.867 132.508L113.902 132.508V131.805L113.867 132.508L113.902 132.509C116.664 132.509 118.904 134.744 118.903 137.5V137.52L119.629 137.503L118.903 137.502L119.629 137.503L120.354 137.485L119.692 137.501L120.354 137.5V137.485L119.692 137.501L120.354 137.5H120.291H120.354H120.291H120.354C120.354 133.944 117.467 131.062 113.903 131.061V131.785L113.938 131.062L113.902 131.061H113.901C110.337 131.061 107.448 133.942 107.446 137.498L108.172 137.499L107.447 137.47L107.446 137.497V137.502L107.971 137.5H107.446V137.502L107.971 137.5H107.446C107.447 141.056 110.334 143.938 113.898 143.939H113.924L113.9 143.255V143.939H113.924L113.9 143.255V143.939H113.901C117.465 143.939 120.353 141.058 120.354 137.503V137.485L119.629 137.503Z"
                      fill="#3F234F"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M129.407 175.368L61.5314 175.345C57.9829 175.343 55.0805 172.446 55.082 168.905L55.0974 124.97C55.0984 121.43 58.0023 118.535 61.5508 118.536L129.426 118.56C132.975 118.56 135.877 121.458 135.875 124.999L135.86 168.934C135.859 172.474 132.955 175.369 129.407 175.368Z"
                      fill="#6796B1"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M61.5507 118.536C61.5507 118.536 59.9846 121.688 63.807 121.689C67.6294 121.69 127.046 121.711 127.046 121.711C127.046 121.711 130.209 122.131 130.21 118.609L61.5507 118.536Z"
                      fill="#AED7E8"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M128.214 140.566L126.128 144.953C126.128 144.953 77.9899 133.621 66.1853 135.002C56.2714 136.163 55.188 131.952 55.0953 130.573L55.082 168.905C55.0805 172.445 57.9829 175.343 61.5314 175.344L129.407 175.367C132.955 175.369 135.859 172.474 135.86 168.933L135.87 140.458C133.082 142.919 128.214 140.566 128.214 140.566Z"
                      fill="#6E9EBA"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M129.407 175.368V174.644L61.5314 174.62C59.9576 174.62 58.5302 173.979 57.4894 172.941C56.4495 171.902 55.808 170.478 55.8075 168.908V168.907L55.1604 168.907L55.8075 168.917V168.907L55.1604 168.907L55.8075 168.917V168.906L55.8229 124.971C55.8234 123.4 56.4664 121.976 57.5062 120.938C58.5476 119.9 59.9755 119.26 61.5493 119.26V118.82L61.5478 119.26H61.5493V118.82L61.5478 119.26L61.5498 118.563L61.5237 119.259L61.5478 119.26L61.5498 118.563L61.5237 119.259L61.5509 119.26L129.426 119.284C130.999 119.284 132.427 119.925 133.468 120.963C134.507 122.002 135.149 123.426 135.149 124.996H135.665L135.149 124.994V124.996H135.665L135.149 124.994L135.15 125.035L135.875 124.999H135.149L135.135 168.933C135.134 170.503 134.491 171.927 133.451 172.966C132.41 174.003 130.983 174.644 129.409 174.644V175.151L129.411 174.644H129.409V175.151L129.411 174.644L129.369 174.645L129.407 175.368V174.644V175.368L129.445 176.091L129.408 175.396L129.411 176.092L129.445 176.091L129.408 175.396L129.411 176.092L129.408 175.46L129.406 176.092H129.411L129.408 175.46L129.406 176.092H129.409C133.36 176.089 136.581 172.876 136.586 168.933L136.601 124.999L136.6 124.962L135.906 124.997L136.601 124.994L136.6 124.962L135.906 124.997L136.601 124.994L135.967 124.997L136.601 124.999V124.994L135.967 124.997L136.601 124.999V124.996C136.598 121.054 133.378 117.84 129.426 117.836L61.5514 117.812L61.5509 118.536L61.578 117.812H61.5524H61.5478L61.5493 118.348V117.812H61.5478L61.5493 118.348V117.812C57.5979 117.815 54.3755 121.028 54.3714 124.97L54.3566 168.905H55.082L54.3566 168.894V168.908C54.3591 172.85 57.5795 176.064 61.5309 176.068L129.406 176.092L129.445 176.091L129.407 175.368Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M124.268 170.004L66.6732 169.985C63.735 169.984 61.3317 167.584 61.3328 164.653L61.3451 129.227C61.3461 126.295 63.7513 123.898 66.6891 123.899L124.284 123.919C127.223 123.92 129.625 126.319 129.625 129.251L129.612 164.676C129.611 167.608 127.206 170.005 124.268 170.004Z"
                      fill="#FFFFFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M66.6732 169.985L124.268 170.004C127.206 170.005 129.611 167.608 129.612 164.676L129.619 146.488L126.129 142.108C124.612 140.204 121.715 140.203 120.197 142.106L107.303 158.269L102.974 152.836C101.372 150.824 98.3102 150.822 96.7062 152.833L92.0841 158.626L78.1998 141.197C76.6081 139.199 73.5634 139.208 71.9839 141.216L61.3363 154.751L61.3328 164.653C61.3317 167.584 63.735 169.984 66.6732 169.985Z"
                      fill="#FFFFFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M92.0837 158.626L78.1994 141.197C76.6077 139.199 73.5629 139.208 71.9835 141.216L71.045 142.409L91.0638 164.713C91.0638 164.713 93.2966 163.228 92.0837 158.626Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M106.592 166.19C106.592 166.19 110.269 163.587 107.302 158.269L102.974 152.836C101.372 150.824 98.3101 150.823 96.706 152.833L95.9283 153.808L106.592 166.19Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M126.129 142.108C124.613 140.204 121.715 140.203 120.197 142.106L119.799 142.605L129.615 155.484L129.618 146.488L126.129 142.108Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M66.6731 169.984V170.709L124.268 170.729H124.279L124.27 170.074V170.729H124.279L124.27 170.074V170.729C127.611 170.726 130.334 168.01 130.337 164.676L130.344 146.488C130.344 146.324 130.289 146.166 130.186 146.038L126.697 141.657C125.797 140.525 124.473 139.953 123.162 139.955C121.852 139.953 120.53 140.524 119.629 141.655L107.303 157.105L103.542 152.385C102.599 151.199 101.213 150.6 99.8394 150.602C98.4673 150.6 97.0819 151.198 96.1383 152.382L92.0845 157.463L78.768 140.747C77.8331 139.572 76.4595 138.978 75.0992 138.98C73.7296 138.978 72.3473 139.58 71.4129 140.769L60.7654 154.304C60.6655 154.43 60.6108 154.589 60.6103 154.75L60.6072 164.652V164.648V164.654C60.6097 167.988 63.3314 170.705 66.6731 170.709V169.984L66.6736 169.261C65.4044 169.26 64.2545 168.744 63.4143 167.906C62.5757 167.068 62.0586 165.92 62.0581 164.654H61.6137L62.0581 164.657V164.654H61.6137L62.0581 164.657V164.653L62.0617 155.001L72.5547 141.663C73.2054 140.837 74.1459 140.43 75.0992 140.428C76.0463 140.43 76.9802 140.831 77.6319 141.648L91.5162 159.077C91.6529 159.249 91.8633 159.35 92.084 159.35C92.3041 159.35 92.5145 159.249 92.6518 159.077L97.2739 153.284C97.9333 152.458 98.8799 152.051 99.8394 152.05C100.799 152.051 101.747 152.459 102.406 153.286L106.734 158.719C106.871 158.891 107.082 158.992 107.302 158.992C107.523 158.992 107.733 158.891 107.87 158.719L120.764 142.556C121.381 141.785 122.265 141.405 123.162 141.403C124.06 141.405 124.945 141.785 125.561 142.558L128.893 146.74L128.886 164.676C128.886 165.942 128.368 167.09 127.529 167.927C126.689 168.764 125.539 169.281 124.27 169.281H124.26L124.268 169.934V169.281H124.26L124.268 169.934V169.281L66.6736 169.261L66.6731 169.984Z"
                      fill="#3F234F"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M124.268 170.005V169.281L66.6736 169.261C65.4044 169.26 64.2545 168.744 63.4143 167.907C62.5757 167.069 62.0586 165.921 62.0581 164.654H61.6199L62.0581 164.656V164.654H61.6199L62.0581 164.656L61.3716 164.654L62.0581 164.672V164.656L61.3716 164.654L62.0581 164.672V164.653L62.0709 129.228C62.0714 127.961 62.589 126.814 63.4282 125.976C64.2683 125.14 65.4187 124.623 66.6874 124.623V124.187L66.6859 124.623H66.6874V124.187L66.6859 124.623L66.6879 123.927L66.6623 124.623L66.6859 124.623L66.6879 123.927L66.6623 124.623L66.6889 124.623L124.284 124.643C125.553 124.644 126.703 125.16 127.543 125.997C128.382 126.836 128.899 127.983 128.899 129.25H129.341L128.899 129.249V129.25H129.341L128.899 129.249L128.9 129.287L129.625 129.251H128.899L128.886 164.676C128.886 165.942 128.368 167.09 127.529 167.928C126.689 168.764 125.539 169.281 124.27 169.281V169.906L124.273 169.281H124.27V169.906L124.273 169.281L124.211 169.283L124.268 170.005V169.281V170.005L124.325 170.726L124.269 170.017L124.274 170.729L124.325 170.726L124.269 170.017L124.274 170.729L124.269 170.046L124.266 170.729H124.274L124.269 170.046L124.266 170.729H124.27C127.611 170.726 130.334 168.01 130.337 164.676L130.35 129.252L130.349 129.215L129.646 129.25L130.35 129.246L130.349 129.215L129.646 129.25L130.35 129.246L129.682 129.25L130.35 129.251V129.246L129.682 129.25L130.35 129.251V129.25C130.347 125.916 127.626 123.199 124.284 123.195L66.6895 123.175L66.6889 123.899L66.7161 123.176L66.6905 123.175H66.6859L66.6874 123.711V123.175H66.6859L66.6874 123.711V123.175C63.3463 123.178 60.6236 125.893 60.6195 129.227L60.6072 164.652L61.3326 164.653L60.6072 164.634V164.654C60.6097 167.988 63.3314 170.705 66.6731 170.709L124.268 170.729L124.325 170.726L124.268 170.005Z"
                      fill="#3F234F"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M115.6 137.503C115.599 140.659 113.033 143.217 109.87 143.216C106.706 143.215 104.142 140.655 104.143 137.499C104.144 134.342 106.71 131.784 109.873 131.785C113.037 131.786 115.601 134.346 115.6 137.503Z"
                      fill="#99D3D1"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M109.871 141.47C107.005 141.469 104.638 139.367 104.217 136.625C104.173 136.911 104.143 137.201 104.143 137.498C104.142 140.655 106.706 143.214 109.87 143.215C113.033 143.217 115.599 140.659 115.6 137.503C115.6 137.205 115.57 136.915 115.526 136.63C115.103 139.371 112.736 141.471 109.871 141.47Z"
                      fill="#46A09A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M115.6 137.503L114.875 137.502C114.874 140.258 112.634 142.492 109.872 142.492H109.871L109.844 142.492L109.87 143.216V142.492C107.107 142.491 104.869 140.256 104.869 137.5V137.5H104.178L104.869 137.517V137.5H104.178L104.869 137.517V137.499C104.869 134.743 107.109 132.508 109.872 132.508H109.873V131.805L109.838 132.508L109.873 132.508V131.805L109.838 132.508L109.873 132.509C112.635 132.509 114.875 134.745 114.875 137.501V137.519L115.6 137.503L114.875 137.502L115.6 137.503L116.326 137.486L115.642 137.502H116.326V137.486L115.642 137.502H116.326V137.501C116.325 133.945 113.438 131.062 109.874 131.061L109.873 131.785L109.908 131.062L109.873 131.061H109.872C106.308 131.061 103.419 133.942 103.418 137.498L104.143 137.499L103.418 137.479V137.5C103.418 141.056 106.305 143.938 109.869 143.939H109.894L109.871 143.255V143.939H109.894L109.871 143.255V143.939H109.872C113.435 143.939 116.324 141.058 116.326 137.503V137.486L115.6 137.503Z"
                      fill="#3F234F"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M162.792 149.062L147.438 133.743C146.706 133.013 145.52 133.013 144.789 133.743L129.434 149.062C128.255 150.239 129.091 152.253 130.759 152.253H132.555C133.59 152.253 134.429 153.089 134.429 154.122V172.875C134.429 173.907 135.267 174.743 136.302 174.743H155.925C156.959 174.743 157.798 173.907 157.798 172.875V154.122C157.798 153.089 158.636 152.253 159.671 152.253H161.467C163.136 152.253 163.971 150.239 162.792 149.062Z"
                      fill="#F35656"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M157.798 172.875V154.121C157.798 153.089 158.637 152.253 159.671 152.253H161.467C163.136 152.253 163.971 150.239 162.792 149.062L160.677 146.952C160.771 148.619 160.438 150.128 158.918 150.128C155.518 150.128 155.906 154.29 155.906 158.936C155.906 162.46 155.898 170.881 155.894 174.743H155.925C156.959 174.743 157.798 173.906 157.798 172.875Z"
                      fill="#AB4040"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M136.928 147.16C139.296 144.988 145.689 138.914 149.235 135.537L147.438 133.743C146.706 133.013 145.52 133.013 144.789 133.743L129.434 149.062C128.255 150.239 129.091 152.253 130.759 152.253H132.555C133.59 152.253 134.429 153.089 134.429 154.122V172.875C134.429 173.907 135.267 174.743 136.302 174.743H139.011V152.22C139.011 152.22 133.576 150.232 136.928 147.16Z"
                      fill="#F26A6A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M162.792 149.062L163.305 148.55L147.951 133.231C147.445 132.727 146.776 132.471 146.114 132.472C145.451 132.471 144.781 132.727 144.276 133.231L128.921 148.55C128.403 149.064 128.154 149.733 128.157 150.37C128.158 151.038 128.413 151.678 128.867 152.165C129.319 152.653 129.994 152.979 130.759 152.977H132.555C133.189 152.978 133.702 153.49 133.703 154.121V172.875C133.704 174.307 134.867 175.467 136.302 175.467H155.925C157.36 175.467 158.523 174.307 158.523 172.875V154.121C158.524 153.489 159.037 152.978 159.671 152.977H161.467C162.233 152.979 162.907 152.653 163.36 152.166C163.814 151.679 164.069 151.038 164.07 150.37C164.073 149.733 163.823 149.064 163.305 148.55L162.792 149.062L162.279 149.574C162.523 149.821 162.616 150.089 162.618 150.37C162.62 150.663 162.5 150.964 162.299 151.178C162.094 151.391 161.831 151.526 161.467 151.529H159.671C158.235 151.529 157.072 152.689 157.072 154.121V172.875C157.071 173.506 156.558 174.018 155.925 174.02H136.302C135.668 174.018 135.156 173.507 135.155 172.875V154.121C135.154 152.689 133.991 151.529 132.555 151.529H130.759C130.395 151.526 130.132 151.391 129.928 151.178C129.726 150.964 129.607 150.663 129.608 150.37C129.611 150.089 129.703 149.821 129.948 149.574L145.302 134.255C145.528 134.03 145.817 133.921 146.114 133.92C146.41 133.921 146.699 134.03 146.925 134.255L162.279 149.574L162.792 149.062Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M124.771 175.368L56.8958 175.345C53.3473 175.343 50.4454 172.446 50.4464 168.905L50.4618 124.97C50.4628 121.43 53.3672 118.535 56.9152 118.536L124.79 118.56C128.339 118.56 131.241 121.458 131.24 124.999L131.225 168.934C131.223 172.474 128.32 175.369 124.771 175.368Z"
                      fill="#8CB7D7"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M56.9151 118.536C56.9151 118.536 55.349 121.688 59.1714 121.689C62.9943 121.69 122.411 121.711 122.411 121.711C122.411 121.711 125.574 122.131 125.575 118.609L56.9151 118.536Z"
                      fill="#AED7E8"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M123.578 140.566L121.493 144.953C121.493 144.953 73.3543 133.621 61.5496 135.002C51.6357 136.163 50.5529 131.952 50.4597 130.573L50.4464 168.905C50.4454 172.445 53.3473 175.343 56.8958 175.344L124.771 175.367C128.32 175.369 131.223 172.474 131.225 168.933L131.234 140.458C128.446 142.919 123.578 140.566 123.578 140.566Z"
                      fill="#6E9EBA"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M124.771 175.368L124.772 174.644L56.8958 174.62C55.3225 174.62 53.8946 173.979 52.8537 172.941C51.8144 171.902 51.1724 170.478 51.1724 168.908V168.907L50.5253 168.907L51.1719 168.917L51.1724 168.907L50.5253 168.907L51.1719 168.917L51.1724 168.906L51.1872 124.971C51.1883 123.4 51.8308 121.976 52.8706 120.938C53.912 119.9 55.3399 119.26 56.9137 119.26V118.82L56.9122 119.26H56.9137V118.82L56.9122 119.26L56.9142 118.562L56.8881 119.259L56.9122 119.26L56.9142 118.562L56.8881 119.259L56.9152 119.26L124.79 119.284C126.364 119.284 127.792 119.925 128.832 120.963C129.872 122.002 130.514 123.426 130.514 124.996V125L130.514 125.023L131.24 124.999H130.514L130.499 168.933C130.498 170.503 129.856 171.927 128.816 172.966C127.775 174.003 126.347 174.644 124.774 174.644V175.151L124.776 174.644H124.774V175.151L124.776 174.644L124.733 174.645L124.771 175.368L124.772 174.644L124.771 175.368L124.809 176.091L124.773 175.396L124.776 176.092L124.809 176.091L124.773 175.396L124.776 176.092L124.773 175.461L124.771 176.092H124.776L124.773 175.461L124.771 176.092H124.774C128.725 176.089 131.946 172.876 131.95 168.933L131.965 124.999V124.975L131.288 124.997L131.965 124.994V124.975L131.288 124.997L131.965 124.994L131.369 124.997L131.965 124.997V124.994L131.369 124.997L131.965 124.997V124.996C131.963 121.054 128.742 117.84 124.791 117.836L56.9157 117.812L56.9152 118.536L56.9429 117.812H56.9168H56.9122L56.9137 118.348V117.812H56.9122L56.9137 118.348V117.812C52.9623 117.815 49.7404 121.028 49.7363 124.97L49.7209 168.905H50.4464L49.7209 168.894V168.908C49.7235 172.85 52.9438 176.064 56.8953 176.068L124.771 176.092L124.809 176.091L124.771 175.368Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M119.633 170.004L62.0376 169.985C59.0993 169.984 56.6961 167.584 56.6971 164.653L56.7099 129.227C56.711 126.295 59.1157 123.898 62.054 123.899L119.649 123.919C122.586 123.92 124.99 126.319 124.989 129.251L124.976 164.676C124.976 167.608 122.571 170.005 119.633 170.004Z"
                      fill="#FFFFFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M62.0376 169.985L119.633 170.004C122.571 170.005 124.976 167.608 124.976 164.676L124.983 146.488L121.494 142.108C119.977 140.204 117.08 140.203 115.562 142.106L102.667 158.269L98.3387 152.836C96.7362 150.824 93.6746 150.822 92.0701 152.833L87.449 158.626L73.5642 141.197C71.9725 139.199 68.9277 139.208 67.3478 141.216L56.7007 154.751L56.6971 164.653C56.6961 167.584 59.0993 169.984 62.0376 169.985Z"
                      fill="#FFFFFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M87.4491 158.626L73.5643 141.197C71.9725 139.199 68.9278 139.208 67.3479 141.216L66.4094 142.409L86.4287 164.713C86.4287 164.713 88.6619 163.228 87.4491 158.626Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M101.957 166.19C101.957 166.19 105.633 163.587 102.667 158.269L98.3386 152.836C96.7361 150.824 93.6745 150.823 92.0699 152.833L91.2922 153.808L101.957 166.19Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M121.494 142.108C119.977 140.204 117.079 140.203 115.561 142.106L115.163 142.605L124.98 155.484L124.983 146.488L121.494 142.108Z"
                      fill="#CBE5E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M62.0376 169.984V170.709L119.632 170.729H119.643L119.634 170.074V170.729H119.643L119.634 170.074V170.729C122.976 170.726 125.698 168.01 125.702 164.676L125.708 146.488C125.708 146.324 125.654 146.166 125.551 146.038L122.062 141.657C121.161 140.525 119.838 139.953 118.527 139.955C117.217 139.953 115.894 140.524 114.994 141.655L102.667 157.105L98.907 152.385C97.9634 151.199 96.5775 150.6 95.2039 150.602C93.8318 150.6 92.4464 151.198 91.5023 152.382L87.449 157.463L74.1325 140.747C73.1976 139.572 71.824 138.978 70.4637 138.98C69.0941 138.978 67.7118 139.58 66.7769 140.769L56.1299 154.304C56.03 154.43 55.9753 154.589 55.9753 154.75L55.9717 164.652V164.648V164.654C55.9742 167.988 58.6959 170.705 62.0376 170.709V169.984L62.0381 169.261C60.7689 169.26 59.619 168.744 58.7788 167.906C57.9402 167.068 57.4231 165.92 57.4226 164.654H56.9782L57.4226 164.657V164.654H56.9782L57.4226 164.657L57.4231 164.653L57.4262 155.001L67.9186 141.663C68.5694 140.837 69.5104 140.43 70.4637 140.428C71.4108 140.43 72.3452 140.831 72.9964 141.648L86.8807 159.077C87.0179 159.249 87.2283 159.35 87.4485 159.35C87.6686 159.35 87.8796 159.249 88.0168 159.077L92.6379 153.284C93.2978 152.458 94.2444 152.051 95.2039 152.05C96.1638 152.051 97.111 152.459 97.7709 153.286L102.099 158.719C102.236 158.891 102.446 158.992 102.667 158.992C102.887 158.992 103.098 158.891 103.235 158.719L116.129 142.556C116.746 141.785 117.63 141.405 118.527 141.403C119.424 141.405 120.309 141.785 120.926 142.558L124.258 146.74L124.251 164.676C124.25 165.942 123.733 167.09 122.894 167.927C122.053 168.764 120.903 169.281 119.634 169.281H119.624L119.633 169.935V169.281H119.624L119.633 169.935V169.281L62.0381 169.261L62.0376 169.984Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M119.633 170.005V169.281L62.0381 169.261C60.7689 169.26 59.619 168.744 58.7788 167.907C57.9402 167.069 57.4231 165.921 57.4226 164.654V164.654H56.7601L57.4226 164.663V164.654H56.7601L57.4226 164.663L57.4231 164.653L57.4354 129.228C57.4359 127.961 57.9535 126.814 58.7927 125.976C59.6328 125.14 60.7832 124.623 62.0519 124.623V124.185L62.0504 124.623H62.0519V124.185L62.0504 124.623L62.0529 123.926L62.0268 124.623L62.0504 124.623L62.0529 123.926L62.0268 124.623L62.0534 124.623L119.649 124.643C120.917 124.644 122.067 125.16 122.907 125.997C123.746 126.836 124.263 127.983 124.264 129.25V129.269L124.989 129.251H124.264L124.251 164.676C124.25 165.942 123.733 167.09 122.894 167.928C122.053 168.764 120.903 169.281 119.634 169.281V169.908L119.637 169.281H119.634V169.908L119.637 169.281L119.575 169.283L119.633 170.005V169.281V170.005L119.69 170.726L119.634 170.017L119.638 170.729L119.69 170.726L119.634 170.017L119.638 170.729L119.634 170.046L119.631 170.729H119.638L119.634 170.046L119.631 170.729H119.634C122.976 170.726 125.698 168.01 125.702 164.676L125.715 129.252V129.233L125.027 129.25H125.715V129.233L125.027 129.25H125.715V129.25C125.712 125.916 122.99 123.199 119.649 123.195L62.054 123.175V123.899L62.0811 123.176L62.055 123.175H62.0504L62.0519 123.709V123.175H62.0504L62.0519 123.709V123.175C58.7108 123.178 55.9881 125.893 55.984 129.227L55.9717 164.652L56.6972 164.653L55.9717 164.643V164.654C55.9742 167.988 58.6959 170.705 62.0376 170.709L119.632 170.729L119.69 170.726L119.633 170.005Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M110.964 137.503C110.963 140.659 108.398 143.217 105.234 143.216C102.07 143.215 99.5059 140.655 99.5074 137.499C99.5085 134.342 102.074 131.784 105.238 131.785C108.401 131.786 110.965 134.346 110.964 137.503Z"
                      fill="#99D3D1"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M105.234 141.47C102.369 141.469 100.003 139.367 99.5817 136.625C99.5371 136.911 99.5074 137.201 99.5074 137.498C99.5059 140.655 102.07 143.214 105.234 143.215C108.398 143.217 110.963 140.659 110.964 137.503C110.964 137.205 110.935 136.915 110.891 136.63C110.468 139.371 108.1 141.471 105.234 141.47Z"
                      fill="#46A09A"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M110.964 137.503L110.239 137.502C110.238 140.258 107.998 142.492 105.236 142.492H105.235L105.209 142.492L105.234 143.216V142.492C102.472 142.491 100.233 140.256 100.233 137.501V137.5H99.5822L100.233 137.511L100.233 137.5H99.5822L100.233 137.511L100.233 137.499C100.233 134.743 102.474 132.508 105.236 132.508H105.237V131.805L105.203 132.508L105.237 132.508V131.805L105.203 132.508L105.237 132.509C108 132.509 110.239 134.745 110.239 137.501V137.519L110.964 137.503L110.239 137.502L110.964 137.503L111.69 137.486L111.006 137.502H111.69L111.69 137.486L111.006 137.502H111.69V137.501C111.69 133.945 108.802 131.062 105.238 131.061V131.785L105.273 131.062L105.237 131.061H105.236C101.672 131.061 98.7835 133.942 98.782 137.498L99.5075 137.499L98.782 137.485V137.501C98.7825 141.057 101.67 143.938 105.233 143.939H105.259L105.235 143.255V143.939H105.259L105.235 143.255V143.939H105.236C108.8 143.939 111.688 141.058 111.69 137.503L111.69 137.486L110.964 137.503Z"
                      fill="#3F234F"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M113.583 26.4018C111.408 26.4018 109.309 26.7267 107.331 27.3254C103.721 12.1522 90.0535 0.865356 73.7414 0.865356C57.5312 0.865356 43.9326 12.0138 40.2218 27.0444C38.5456 26.6266 36.7931 26.4018 34.9874 26.4018C23.0988 26.4018 13.4608 36.0178 13.4608 47.8789C13.4608 59.7409 23.0988 69.3564 34.9874 69.3564H68.4767C70.1934 69.6185 71.9515 69.7548 73.7414 69.7548C75.5318 69.7548 77.2899 69.6185 79.0065 69.3564H113.583C125.471 69.3564 135.108 59.7409 135.108 47.8789C135.108 36.0178 125.471 26.4018 113.583 26.4018Z"
                      fill="#567DF4"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M68.4766 69.3563C70.1932 69.6184 71.9513 69.7548 73.7412 69.7548C75.5316 69.7548 77.2897 69.6184 79.0064 69.3563H113.583C125.471 69.3563 135.108 59.7408 135.108 47.8788C135.108 36.0177 125.471 26.4017 113.583 26.4017C111.408 26.4017 109.309 26.7266 107.33 27.3253C107.33 27.3253 125.575 29.7353 125.575 47.6755C125.575 65.6162 109.556 64.3621 109.556 64.3621H30.8653C30.8653 64.3621 26.1664 64.3315 20.3406 63.6128C24.183 67.1747 29.3288 69.3563 34.9872 69.3563H68.4766Z"
                      fill="#415EB6"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M43.758 32.4697C43.758 32.4697 55.4526 -3.43209 91.9488 6.04345C86.6626 2.76302 80.4252 0.865356 73.7414 0.865356C57.5312 0.865356 43.9326 12.0138 40.2218 27.0444C38.5456 26.6266 36.7931 26.4018 34.9874 26.4018C23.0988 26.4018 13.4608 36.0178 13.4608 47.8788C13.4608 51.1955 14.2155 54.336 15.5609 57.1399C15.8436 46.3893 19.7233 27.9031 43.758 32.4697Z"
                      fill="#DDEEFB"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M114.717 153.191L39.9109 160.397C36.0004 160.773 32.4918 157.89 32.1145 153.988L27.4289 105.566C27.0515 101.665 29.9422 98.1646 33.8526 97.7876L108.658 90.5821C112.569 90.2057 116.078 93.0897 116.455 96.9913L121.14 145.413C121.518 149.315 118.627 152.815 114.717 153.191Z"
                      fill="#8CB7D7"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M114.717 153.191L114.647 152.471L39.8415 159.676C39.6342 159.696 39.4283 159.706 39.2241 159.706C35.9715 159.708 33.1525 157.212 32.8366 153.918L28.1516 105.497C28.1316 105.29 28.1219 105.085 28.1219 104.881C28.1198 101.636 30.6208 98.8231 33.9226 98.5085L108.728 91.303C108.936 91.283 109.142 91.2733 109.346 91.2733C112.598 91.2713 115.417 93.7666 115.733 97.0608L120.418 145.482C120.438 145.689 120.449 145.894 120.449 146.098C120.451 149.343 117.949 152.156 114.647 152.471L114.717 153.191L114.787 153.912C118.845 153.518 121.897 150.089 121.899 146.098C121.899 145.848 121.888 145.596 121.863 145.343L117.178 96.9219C116.782 92.8727 113.346 89.8272 109.346 89.8252C109.095 89.8252 108.843 89.8369 108.589 89.8615L33.7833 97.067C29.7249 97.4613 26.6725 100.89 26.6704 104.881C26.6704 105.131 26.6827 105.383 26.7068 105.636L31.3924 154.057C31.7876 158.107 35.224 161.152 39.2241 161.154C39.4744 161.154 39.7268 161.142 39.9808 161.118L114.787 153.912L114.717 153.191Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M108.48 147.828L45.0042 153.942C41.766 154.254 38.861 151.866 38.5482 148.635L34.7703 109.592C34.458 106.362 36.8515 103.463 40.0898 103.151L103.566 97.0367C106.803 96.7246 109.709 99.1131 110.022 102.344L113.799 141.386C114.112 144.617 111.718 147.516 108.48 147.828Z"
                      fill="#FFFFFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M45.0039 153.942L108.48 147.827C111.718 147.516 114.112 144.617 113.799 141.386L111.859 121.341L107.545 116.885C105.67 114.949 102.476 115.257 101.007 117.515L88.5265 136.702L83.1753 131.175C81.1935 129.129 77.819 129.454 76.2667 131.841L71.7936 138.717L54.6265 120.988C52.6585 118.956 49.3035 119.29 47.7773 121.671L37.4917 137.722L38.5479 148.635C38.8607 151.866 41.7657 154.254 45.0039 153.942Z"
                      fill="#FFFFFE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M71.7939 138.717L54.6269 120.988C52.6588 118.956 49.3039 119.29 47.7777 121.671L46.8715 123.086L71.3209 145.534C71.3209 145.534 73.6227 143.659 71.7939 138.717Z"
                      fill="#D8FFFF"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M88.5918 145.506C88.5918 145.506 92.3651 142.246 88.5268 136.702L83.1756 131.175C81.1938 129.129 77.8193 129.454 76.267 131.841L75.5144 132.997L88.5918 145.506Z"
                      fill="#D8FFFF"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M107.546 116.885C105.67 114.949 102.477 115.257 101.007 117.515L100.622 118.107L112.819 131.256L111.86 121.341L107.546 116.885Z"
                      fill="#D8FFFF"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M45.0042 153.942L45.0743 154.663L108.55 148.548C111.976 148.215 114.55 145.323 114.553 141.953C114.553 141.743 114.542 141.53 114.522 141.317L112.582 121.272C112.566 121.108 112.496 120.956 112.382 120.838L108.067 116.382C107.094 115.376 105.803 114.882 104.532 114.883C102.943 114.881 101.354 115.652 100.398 117.121L88.4146 135.544L83.6973 130.672C82.6764 129.617 81.3237 129.099 79.9906 129.101C78.3246 129.099 76.6591 129.906 75.6582 131.446L71.6817 137.56L55.1484 120.485C54.1373 119.44 52.7975 118.927 51.4771 118.929C49.8157 118.927 48.1554 119.738 47.1662 121.282L36.8806 137.332C36.7936 137.467 36.7542 137.631 36.77 137.791L37.8257 148.705C38.1601 152.122 41.0589 154.691 44.4359 154.693C44.6468 154.693 44.8598 154.683 45.0738 154.663H45.0743L45.0042 153.942L44.935 153.221C44.7671 153.238 44.6007 153.245 44.4359 153.245C41.8063 153.247 39.525 151.229 39.2705 148.565L38.2384 137.901L48.3888 122.062C49.1015 120.951 50.2785 120.379 51.4771 120.377C52.4283 120.378 53.3739 120.738 54.1045 121.491L71.2716 139.22C71.4247 139.379 71.6438 139.459 71.8634 139.438C72.0826 139.417 72.2822 139.296 72.402 139.112L76.8757 132.235C77.5996 131.122 78.7843 130.551 79.9906 130.549C80.9546 130.55 81.913 130.915 82.6538 131.678L88.005 137.205C88.1581 137.363 88.3772 137.443 88.5964 137.422C88.816 137.401 89.0157 137.28 89.1355 137.096L101.616 117.909C102.294 116.868 103.402 116.333 104.532 116.331C105.434 116.333 106.33 116.674 107.023 117.388L111.162 121.662L113.078 141.456C113.093 141.623 113.102 141.789 113.102 141.953C113.104 144.577 111.079 146.853 108.41 147.107L44.9345 153.221H44.935L45.0042 153.942Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M108.48 147.827L108.41 147.107L44.9345 153.221C44.767 153.237 44.6007 153.245 44.4358 153.245C41.8063 153.247 39.5249 151.228 39.2705 148.565L35.4926 109.523C35.4762 109.355 35.4685 109.189 35.4685 109.025C35.4665 106.402 37.4903 104.125 40.1593 103.871L103.635 97.7573C103.803 97.741 103.969 97.7333 104.134 97.7333C106.763 97.7313 109.045 99.751 109.3 102.414L113.077 141.456C113.093 141.623 113.102 141.789 113.102 141.953C113.104 144.577 111.079 146.853 108.41 147.107L108.55 148.548C111.976 148.215 114.55 145.323 114.552 141.953C114.552 141.743 114.543 141.53 114.522 141.317L110.744 102.274C110.41 98.8566 107.511 96.2872 104.134 96.2852C103.923 96.2852 103.71 96.2954 103.496 96.3163L40.02 102.43C36.5939 102.763 34.0191 105.655 34.0171 109.025C34.0171 109.236 34.0273 109.448 34.0478 109.662L37.8257 148.705C38.16 152.122 41.0588 154.691 44.4358 154.693C44.6467 154.693 44.8597 154.683 45.0737 154.663L108.55 148.548L108.48 147.827Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M95.449 112.931C95.7854 116.411 93.2317 119.502 89.7446 119.839C86.2575 120.175 83.1585 117.626 82.8222 114.148C82.4848 110.669 85.039 107.577 88.5256 107.24C92.0127 106.904 95.1116 109.452 95.449 112.931Z"
                      fill="#34DEDE"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M89.559 117.915C86.4006 118.22 83.5678 116.155 82.8101 113.178C82.7922 113.497 82.7901 113.82 82.8224 114.148C83.1588 117.627 86.2578 120.175 89.7448 119.839C93.2319 119.503 95.7856 116.411 95.4493 112.932C95.417 112.604 95.3535 112.288 95.2747 111.978C95.1016 115.044 92.7168 117.611 89.559 117.915Z"
                      fill="#11A4A4"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M95.4492 112.932L94.7268 113.001C94.7447 113.185 94.7529 113.367 94.7529 113.547C94.7534 116.397 92.5796 118.84 89.6751 119.119C89.4913 119.136 89.3086 119.145 89.1278 119.145C86.2715 119.145 83.8232 116.976 83.5442 114.078C83.5268 113.895 83.5181 113.712 83.5181 113.532C83.5176 110.682 85.6914 108.24 88.5954 107.962C88.7797 107.944 88.9625 107.935 89.1432 107.935C91.9995 107.935 94.4473 110.104 94.7268 113.001L95.4492 112.932L96.1711 112.862C95.8168 109.216 92.7439 106.487 89.1432 106.487C88.9159 106.487 88.6865 106.498 88.4561 106.521C84.8011 106.873 82.0667 109.939 82.0667 113.532C82.0667 113.759 82.0774 113.988 82.0999 114.217C82.4537 117.864 85.5276 120.592 89.1278 120.593C89.3551 120.593 89.584 120.582 89.8144 120.56C93.4694 120.206 96.2038 117.139 96.2044 113.547C96.2044 113.32 96.1936 113.092 96.1711 112.862L95.4492 112.932Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M102.26 69.3564V101.054C102.26 101.454 102.585 101.778 102.986 101.778C103.387 101.778 103.712 101.454 103.712 101.054V69.3564C103.712 68.9564 103.387 68.6326 102.986 68.6326C102.585 68.6326 102.26 68.9564 102.26 69.3564Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M41.692 69.3564V106.41C41.692 106.81 42.0166 107.134 42.4175 107.134C42.8184 107.134 43.143 106.81 43.143 106.41V69.3564C43.143 68.9564 42.8184 68.6326 42.4175 68.6326C42.0166 68.6326 41.692 68.9564 41.692 69.3564Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M45.0978 106.409C45.0978 107.886 43.8983 109.084 42.4176 109.084C40.937 109.084 39.7374 107.886 39.7374 106.409C39.7374 104.933 40.937 103.735 42.4176 103.735C43.8983 103.735 45.0978 104.933 45.0978 106.409Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M105.667 101.054C105.667 102.531 104.467 103.728 102.986 103.728C101.506 103.728 100.306 102.531 100.306 101.054C100.306 99.5765 101.506 98.3796 102.986 98.3796C104.467 98.3796 105.667 99.5765 105.667 101.054Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M51.2415 169.794C51.1959 169.505 51.1724 169.209 51.1724 168.908V168.906L51.1754 160.039L55.9732 159.577L55.9716 164.652V164.648V164.654C55.9727 165.99 56.4104 167.228 57.1502 168.231L51.2415 169.794Z"
                      fill="#52748B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M50.4464 170.004L50.7039 160.085L51.1755 160.039L51.1724 168.906L51.1719 168.907H51.1724V168.908C51.1724 169.209 51.1959 169.505 51.2415 169.795L50.4464 170.004Z"
                      fill="#281634"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M87.4489 157.463L86.77 156.611L88.2425 156.469L87.4489 157.463ZM102.062 156.346L101.168 155.224L104.418 154.911L103.598 155.939L102.062 156.346Z"
                      fill="#B6B8B5"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M58.6964 167.822C57.9069 166.992 57.4236 165.88 57.4226 164.657L57.4231 164.653L57.4247 159.437L79.7617 157.285L83.3603 161.295L58.6964 167.822ZM87.7444 160.135C87.7132 159.88 87.6702 159.612 87.6133 159.331C87.771 159.294 87.9144 159.206 88.0168 159.077L90.2515 156.275L93.1749 155.994L95.0718 158.196L87.7444 160.135ZM105.949 155.317L106.428 154.717L109.241 154.446L105.949 155.317Z"
                      fill="#B6B8B5"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M83.3604 161.294L79.7617 157.285L85.0484 156.776L86.8807 159.077C87.018 159.249 87.2284 159.35 87.4485 159.35C87.4485 159.35 87.4485 159.35 87.449 159.35C87.5048 159.35 87.5596 159.344 87.6134 159.331C87.6702 159.612 87.7132 159.88 87.7444 160.134L83.3604 161.294Z"
                      fill="#90A5A9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M95.0718 158.196L93.1749 155.994L99.4466 155.39L100.531 156.751L95.0718 158.196Z"
                      fill="#90A5A9"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M87.449 159.35C87.4485 159.35 87.4485 159.35 87.4485 159.35C87.2283 159.35 87.0179 159.249 86.8807 159.077L85.0483 156.777L86.7701 156.611L87.449 157.463L88.2426 156.469L90.2516 156.276L88.0168 159.077C87.9144 159.206 87.771 159.294 87.6133 159.331C87.5596 159.344 87.5048 159.35 87.449 159.35ZM100.531 156.751L99.4466 155.39L101.168 155.224L102.062 156.346L100.531 156.751ZM103.598 155.939L104.418 154.911L106.428 154.717L105.949 155.317L103.598 155.939Z"
                      fill="#281634"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M57.1503 168.231C56.4104 167.228 55.9727 165.99 55.9717 164.654V164.652L55.9732 159.577L57.4247 159.437L57.4231 164.653L57.4226 164.657C57.4236 165.88 57.907 166.992 58.6964 167.822L57.1503 168.231Z"
                      fill="#281634"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M50.704 160.084L50.7127 159.745L117.697 152.209L109.241 154.446L106.428 154.717L104.418 154.91L101.168 155.224L99.4467 155.39L93.1749 155.993L90.2516 156.275L88.2426 156.468L86.7701 156.61L85.0484 156.776L79.7617 157.285L57.4247 159.437L55.9732 159.577L51.1755 160.039L50.704 160.084Z"
                      fill="#281634"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M155.892 126.793C155.892 128.028 154.888 129.03 153.65 129.03C152.412 129.03 151.408 128.028 151.408 126.793C151.408 125.558 152.412 124.556 153.65 124.556C154.888 124.556 155.892 125.558 155.892 126.793Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M170.611 128.844C170.611 129.462 170.109 129.963 169.49 129.963C168.871 129.963 168.369 129.462 168.369 128.844C168.369 128.227 168.871 127.726 169.49 127.726C170.109 127.726 170.611 128.227 170.611 128.844Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M9.05188 57.1398C9.05188 57.7578 8.55014 58.2584 7.93116 58.2584C7.31219 58.2584 6.80994 57.7578 6.80994 57.1398C6.80994 56.5222 7.31219 56.0216 7.93116 56.0216C8.55014 56.0216 9.05188 56.5222 9.05188 57.1398Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M154.771 63.8914C154.771 64.5094 154.269 65.01 153.65 65.01C153.031 65.01 152.53 64.5094 152.53 63.8914C152.53 63.2738 153.031 62.7732 153.65 62.7732C154.269 62.7732 154.771 63.2738 154.771 63.8914Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M33.1074 82.0484C33.1074 82.6659 32.6057 83.1665 31.9862 83.1665C31.3672 83.1665 30.8655 82.6659 30.8655 82.0484C30.8655 81.4308 31.3672 80.9297 31.9862 80.9297C32.6057 80.9297 33.1074 81.4308 33.1074 82.0484Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M153.65 115.923C153.65 116.54 153.148 117.042 152.529 117.042C151.91 117.042 151.408 116.54 151.408 115.923C151.408 115.305 151.91 114.805 152.529 114.805C153.148 114.805 153.65 115.305 153.65 115.923Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M112.086 74.9483C112.086 75.5658 111.584 76.0664 110.965 76.0664C110.346 76.0664 109.844 75.5658 109.844 74.9483C109.844 74.3307 110.346 73.8296 110.965 73.8296C111.584 73.8296 112.086 74.3307 112.086 74.9483Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.01 123.432C17.01 124.049 16.5083 124.55 15.8893 124.55C15.2698 124.55 14.7681 124.049 14.7681 123.432C14.7681 122.814 15.2698 122.313 15.8893 122.313C16.5083 122.313 17.01 122.814 17.01 123.432Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M7.13342 62.7731C7.13342 64.0082 6.12944 65.0099 4.89148 65.0099C3.65352 65.0099 2.64954 64.0082 2.64954 62.7731C2.64954 61.538 3.65352 60.5363 4.89148 60.5363C6.12944 60.5363 7.13342 61.538 7.13342 62.7731Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M150.772 71.5933C150.772 72.8284 149.768 73.8296 148.53 73.8296C147.292 73.8296 146.288 72.8284 146.288 71.5933C146.288 70.3576 147.292 69.3564 148.53 69.3564C149.768 69.3564 150.772 70.3576 150.772 71.5933Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M10.1729 134.789C10.1729 136.024 9.16887 137.025 7.93091 137.025C6.69244 137.025 5.68896 136.024 5.68896 134.789C5.68896 133.553 6.69244 132.552 7.93091 132.552C9.16887 132.552 10.1729 133.553 10.1729 134.789Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M22.5825 88.317C22.5825 89.5522 21.579 90.5534 20.3406 90.5534C19.1026 90.5534 18.0991 89.5522 18.0991 88.317C18.0991 87.0819 19.1026 86.0802 20.3406 86.0802C21.579 86.0802 22.5825 87.0819 22.5825 88.317Z"
                      fill="#22215B"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M50.8161 150.143L28.2206 127.598C27.1439 126.524 25.3986 126.524 24.3219 127.598L1.72632 150.143C-0.0103035 151.875 1.21946 154.838 3.67541 154.838H6.31874C7.84136 154.838 9.07574 156.069 9.07574 157.588V185.187C9.07574 186.706 10.3096 187.937 11.8322 187.937H40.7102C42.2328 187.937 43.4667 186.706 43.4667 185.187V157.588C43.4667 156.069 44.7011 154.838 46.2237 154.838H48.867C51.323 154.838 52.5527 151.875 50.8161 150.143Z"
                      fill="#FFDE6C"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M43.4666 185.187V157.588C43.4666 156.069 44.701 154.838 46.2231 154.838H48.8669C51.3229 154.838 52.5526 151.875 50.816 150.143L47.7032 147.037C47.843 149.492 47.3525 151.711 45.1157 151.711C40.1106 151.711 40.6814 157.836 40.6814 164.673C40.6814 169.859 40.6702 182.253 40.6651 187.937H40.7101C42.2327 187.937 43.4666 186.706 43.4666 185.187Z"
                      fill="#CC8900"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.7533 147.342C16.2388 144.146 25.6458 135.207 30.8654 130.237L28.2206 127.598C27.1439 126.524 25.3986 126.524 24.3219 127.598L1.72632 150.143C-0.0103034 151.875 1.21946 154.838 3.67541 154.838H6.31874C7.84136 154.838 9.07573 156.069 9.07573 157.588V185.187C9.07573 186.706 10.3096 187.937 11.8322 187.937H15.8185V154.79C15.8185 154.79 7.82088 151.864 12.7533 147.342Z"
                      fill="#FFB110"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M50.8161 150.143L51.3296 149.631L28.7335 127.087C28.0552 126.409 27.1597 126.068 26.2709 126.069C25.3826 126.068 24.4872 126.409 23.8088 127.087L1.21276 149.631C0.514427 150.324 0.184715 151.214 0.187765 152.068C0.18881 152.962 0.531322 153.825 1.14057 154.477C1.74727 155.13 2.64374 155.565 3.67537 155.562H6.3187C6.88187 155.562 7.38515 155.788 7.75479 156.155C8.12341 156.524 8.34971 157.026 8.34971 157.588V185.187C8.35022 187.106 9.90867 188.661 11.8322 188.661H40.7102C42.6337 188.661 44.1921 187.106 44.1926 185.187V157.588C44.1926 157.026 44.4184 156.524 44.7876 156.155C45.1572 155.788 45.6605 155.562 46.2236 155.562H48.867C49.8986 155.565 50.7951 155.13 51.4018 154.477C52.0105 153.825 52.3535 152.962 52.3546 152.068C52.3576 151.214 52.0279 150.324 51.3296 149.631L50.3031 150.655C50.7275 151.081 50.9005 151.57 50.9036 152.068C50.9046 152.587 50.6973 153.11 50.341 153.489C49.9815 153.868 49.4972 154.111 48.867 154.114H46.2236C44.3002 154.115 42.7417 155.669 42.7412 157.588V185.187C42.7407 185.749 42.5149 186.251 42.1463 186.62C41.7766 186.987 41.2733 187.213 40.7102 187.213H11.8322C11.269 187.213 10.7657 186.987 10.3961 186.62C10.0275 186.251 9.80167 185.749 9.80116 185.187V157.588C9.80065 155.669 8.24219 154.115 6.3187 154.114H3.67537C3.04512 154.111 2.56028 153.868 2.20139 153.489C1.84505 153.11 1.6377 152.587 1.63873 152.068C1.6418 151.57 1.81485 151.081 2.23927 150.655L24.8353 128.11C25.2337 127.713 25.7492 127.518 26.2709 127.517C26.7931 127.518 27.3087 127.713 27.707 128.11L50.3031 150.655L50.8161 150.143Z"
                      fill="#22215B"/>
            </g>
            <defs>
                <clipPath id="clip0_0_318">
                    <rect width="171" height="189" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    </div>
</div>

<div class="flex flex-col items-center justify-center py-[64px] px-[20px]">
    <div
        class="revealY max-w-screen-xl  flex max-w-[768px] flex-col items-center justify-center space-y-6 text-center text-black active">
        <p class="mb-6 max-w-screen-xl  text-[48px] font-semibold sm:leading-tight">
            Secure your cloud data like never before
        </p>
        <p class="text-[18px] max-w-[768px]  text-gray-600">
            What makes AyrDrive so special? We always strive to provide the best
            services for our clients, tailored to meet their needs. Our team is comprised
            of professionals who are dedicated to protecting you and your files online
        </p>
        <div
            class="flex cursor-pointer flex-row items-center justify-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline">
            <a href="/about">Meet the AyrDrive Team</a>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
            </svg>
        </div>
    </div>
    <div
        class="revealY mt-[80px] grid grid-cols-1 flex-row flex-wrap justify-center gap-[40px] sm:grid-cols-2 active">
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 48 48"
                 height="32" width="32" class="mb-6 text-[48px] text-[#0066ff]">
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M13.639791666666666 19.220924166666666h14.6875l-3.9166666666666665 -4.40625"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M24.410625 25.585429166666664h-14.6875l3.9166666666666665 4.40625"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M44.0625 31.333333333333332h-13.708333333333332c-1.0815875 0 -1.9583333333333333 0.8767458333333332 -1.9583333333333333 1.9583333333333333v10.770833333333332c0 1.0815875 0.8767458333333332 1.9583333333333333 1.9583333333333333 1.9583333333333333h13.708333333333332c1.0815875 0 1.9583333333333333 -0.8767458333333332 1.9583333333333333 -1.9583333333333333V33.291666666666664c0 -1.0815875 -0.8767458333333332 -1.9583333333333333 -1.9583333333333333 -1.9583333333333333Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M37.20833333333333 22.520833333333332c-1.55805 0 -3.0524541666666667 0.6190291666666666 -4.1542125 1.7207875C31.952362499999996 25.343379166666665 31.333333333333332 26.83778333333333 31.333333333333332 28.395833333333332V31.333333333333332h11.75v-2.9375c0 -1.55805 -0.6190291666666666 -3.0524541666666667 -1.7207875 -4.1542125C40.2607875 23.1398625 38.76638333333333 22.520833333333332 37.20833333333333 22.520833333333332Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M37.20833333333333 39.46022083333333c-0.4055708333333333 0 -0.734375 -0.3288041666666666 -0.734375 -0.734375s0.3288041666666666 -0.734375 0.734375 -0.734375"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M37.20833333333333 39.46022083333333c0.4055708333333333 0 0.734375 -0.3288041666666666 0.734375 -0.734375s-0.3288041666666666 -0.734375 -0.734375 -0.734375"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M23.010416666666664 34.27083333333333H4.895833333333333c-1.03875875 0 -2.0349825 -0.4126208333333333 -2.769494583333333 -1.1471916666666666C1.3918149166666665 32.389070833333335 0.9791666666666666 31.392866666666666 0.9791666666666666 30.354166666666664v-25.458333333333332c0 -1.03875875 0.41264825 -2.0349825 1.1471720833333334 -2.769494583333333C2.860850833333333 1.3918149166666665 3.857074583333333 0.9791666666666666 4.895833333333333 0.9791666666666666h29.375c1.0387 0 2.0349041666666663 0.41264825 2.769475 1.1471720833333334 0.7345708333333333 0.7345120833333333 1.1471916666666666 1.7307358333333334 1.1471916666666666 2.769494583333333v13.708333333333332"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M0.9791666666666666 10.770833333333332h37.20833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M7.833333333333333 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M7.833333333333333 6.364583333333333c0.27038708333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21919624999999998 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M13.708333333333332 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M13.708333333333332 6.364583333333333c0.27038708333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21919624999999998 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M19.583333333333332 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M19.583333333333332 6.364583333333333c0.27044583333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21913749999999999 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col"><p
                class="mb-6 text-[24px] font-medium text-[#18181b]">Privacy First</p>
                <p class="text-[18px] text-gray-500">
                    With AyrDrive, you have full access and control over your data. We
                    do not store any files or passwords. Using encryption algorithms your
                    data is securely protected and accessible only to you
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 48 48"
                 class="mb-6 text-[48px] text-[#0066ff]"
                 height="32" width="32">
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M39.784325 41.21997916666667c-1.0580875 0 -1.9156416666666665 -0.85775 -1.9156416666666665 -1.9156416666666665 0 -1.0580875 0.8575541666666666 -1.9158374999999999 1.9156416666666665 -1.9158374999999999 1.0578916666666667 0 1.9156416666666665 0.85775 1.9156416666666665 1.9158374999999999 0 1.0578916666666667 -0.85775 1.9156416666666665 -1.9156416666666665 1.9156416666666665Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M7.214108333333334 41.21997916666667c-1.05802875 0 -1.9157004166666667 -0.85775 -1.9157004166666667 -1.9156416666666665 0 -1.0580875 0.8576716666666666 -1.9158374999999999 1.9157004166666667 -1.9158374999999999 1.0580091666666664 0 1.9157004166666667 0.85775 1.9157004166666667 1.9158374999999999 0 1.0578916666666667 -0.85769125 1.9156416666666665 -1.9157004166666667 1.9156416666666665Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M3.3844504166666662 10.568674583333333C2.32644125 10.568674583333333 1.46875 9.710983333333333 1.46875 8.652974166666667s0.85769125 -1.9157004166666667 1.9157004166666667 -1.9157004166666667 1.9157004166666667 0.85769125 1.9157004166666667 1.9157004166666667 -0.85769125 1.9157004166666667 -1.9157004166666667 1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M43.6154125 16.315775833333333c-1.0580875 0 -1.9156416666666665 -0.85769125 -1.9156416666666665 -1.9157004166666667s0.8575541666666666 -1.9157004166666667 1.9156416666666665 -1.9157004166666667c1.0578916666666667 0 1.9156416666666665 0.85769125 1.9156416666666665 1.9157004166666667s-0.85775 1.9157004166666667 -1.9156416666666665 1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m34.036029166666665 29.725737499999997 3.8332416666666664 0c1.0580875 0 1.9158374999999999 0.8575541666666666 1.9158374999999999 1.9156416666666665v1.9156416666666665"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M43.61462916666667 20.147333333333332v1.9156416666666665c0 1.0580875 -0.85775 1.9156416666666665 -1.9158374999999999 1.9156416666666665h-7.6627624999999995"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m12.964107916666666 27.8099 -3.83326125 0c-1.05802875 0 -1.9157004166666667 0.85775 -1.9157004166666667 1.9156416666666665l0 3.8314791666666665"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m3.3850183333333335 14.401113333333333 0 3.8314008333333334c0 1.0580091666666664 0.8576716666666666 1.9156025 1.9157004166666667 1.9156025H12.963970833333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M32.1203875 17.274301666666666H14.879142499999999c-1.0580091666666664 0 -1.9157004166666667 0.85769125 -1.9157004166666667 1.9157004166666667v13.40998125c0 1.0578916666666667 0.85769125 1.9156416666666665 1.9157004166666667 1.9156416666666665h17.241245c1.0580875 0 1.9158374999999999 -0.85775 1.9158374999999999 -1.9156416666666665V19.19000208333333c0 -1.0580091666666664 -0.85775 -1.9157004166666667 -1.9158374999999999 -1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m23.5 28.7687 0 -2.9149791666666665" stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M23.500195833333333 22.10488333333333c0.3707125 0 0.7330041666666667 0.10986249999999999 1.0412458333333332 0.31587916666666666 0.3082416666666667 0.20601666666666665 0.5485291666666666 0.49859166666666666 0.6903124999999999 0.8411041666666667 0.14178333333333334 0.3425125 0.17899166666666666 0.7192958333333334 0.10672916666666667 1.0829583333333335 -0.07245833333333333 0.3636625 -0.2508625 0.6975583333333334 -0.5130833333333333 0.9597791666666666 -0.262025 0.262025 -0.5961166666666666 0.440625 -0.9595833333333332 0.5128875 -0.3636625 0.07226250000000001 -0.7404458333333332 0.03525 -1.0829583333333335 -0.10672916666666667 -0.3425125 -0.14178333333333334 -0.6352833333333333 -0.3820708333333333 -0.8411041666666667 -0.6903124999999999 -0.20601666666666665 -0.3082416666666667 -0.31587916666666666 -0.6705333333333333 -0.31587916666666666 -1.0412458333333332 0 -0.49702500000000005 0.1974 -0.9738791666666666 0.5489208333333333 -1.3254 0.3515208333333333 -0.3515208333333333 0.8281791666666666 -0.5489208333333333 1.3254 -0.5489208333333333Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M30.204941666666667 12.485040833333333c0 -1.7782645833333333 -0.7063708333333334 -3.483679166666666 -1.9638166666666663 -4.741105416666667 -1.2574458333333334 -1.25742625 -2.962958333333333 -1.96383625 -4.741124999999999 -1.96383625 -1.7783625 0 -3.483679166666666 0.70641 -4.741164166666667 1.96383625 -1.25742625 1.25742625 -1.96383625 2.962840833333333 -1.96383625 4.741105416666667v4.7892608333333335h13.409942083333332V12.485040833333333Z"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col"><p
                class="mb-6 text-[24px] font-medium text-[#18181b]">
                Encryption is a key
            </p>
                <p class="text-[18px] text-gray-500">
                    Your data is encrypted before it leaves your device, during transfer,
                    and when it is stored on a server. We're using AES-256 encryption,
                    which guarantees the highest level of security for your information
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg" height="32"
                 width="32" fill="none"
                 class="mb-6 text-[48px] text-[#0066ff]">
                <path
                    d="M46.02083333333333 23.5s-10.081499999999998 12.729166666666666 -22.520833333333332 12.729166666666666S0.9791666666666666 23.5 0.9791666666666666 23.5 11.060666666666666 10.770833333333332 23.5 10.770833333333332 46.02083333333333 23.5 46.02083333333333 23.5Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M15.666666666666666 23.5a7.833333333333333 7.833333333333333 0 1 0 15.666666666666666 0 7.833333333333333 7.833333333333333 0 1 0 -15.666666666666666 0Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M23.5 19.583333333333332a3.9166666666666665 3.9166666666666665 0 1 1 -3.9166666666666665 3.9166666666666665"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col">
                <p class="mb-6 text-[24px] font-medium text-[#18181b]">
                    Open Source
                </p>
                <p class="text-[18px] text-gray-500">
                    We always strive to improve our product, which is why it is
                    open-source. This allows everyone to personally verify the reliability
                    of our service. The only thing we keep confidential is your data
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="-0.5 -0.5 48 48" class="mb-6 text-[48px] text-[#0066ff]"
                 height="32" width="32">
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="m31.823699999999995 32.04949583333333 -1.5666666666666667 11.663441666666666c-0.19583333333333333 1.3837583333333332 1.3708333333333331 2.372129166666667 2.5458333333333334 1.3837583333333332l3.9166666666666665 -3.9536791666666664 3.9166666666666665 3.9536791666666664c0.9791666666666666 0.9883708333333334 2.7416666666666663 0.19759583333333333 2.5458333333333334 -1.3837583333333332l-1.5666666666666667 -11.663441666666666"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M36.71875 33.546054166666664c4.867045833333333 0 8.8125 -3.9454541666666665 8.8125 -8.8125s-3.9454541666666665 -8.812539166666667 -8.8125 -8.812539166666667 -8.8125 3.9454933333333333 -8.8125 8.812539166666667 3.9454541666666665 8.8125 8.8125 8.8125Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M23.010416666666664 45.53125h-19.583333333333332c-1.1749999999999998 0 -1.9583333333333333 -0.7833333333333333 -1.9583333333333333 -1.9583333333333333V3.427083333333333c0 -1.1749999999999998 0.7833333333333333 -1.9583333333333333 1.9583333333333333 -1.9583333333333333h19.779166666666665c0.39166666666666666 0 0.9791666666666666 0.19586858333333332 1.3708333333333331 0.5875391666666666l9.595833333333333 8.616647083333334"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 14.197916666666666h12.924999999999999"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 23.989583333333332h12.924999999999999"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 33.78125h9.791666666666666"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M36.71875 27.51223333333333c1.5345499999999999 0 2.7786791666666666 -1.2441291666666665 2.7786791666666666 -2.7786791666666666 0 -1.5347458333333333 -1.2441291666666665 -2.7788749999999998 -2.7786791666666666 -2.7788749999999998 -1.5347458333333333 0 -2.7786791666666666 1.2441291666666665 -2.7786791666666666 2.7788749999999998 0 1.5345499999999999 1.2439333333333333 2.7786791666666666 2.7786791666666666 2.7786791666666666Z"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col">
                <p class="mb-6 text-[24px] font-medium text-[#18181b]">
                    Compliant and Secure
                </p>
                <p class="text-[18px] text-gray-500">
                    We have successfully passed several independent audits and can assure
                    you that your files are in safe hands. Our full compliance with GDPR
                    guarantees a 100% secure environment
                </p>
            </div>
        </div>
    </div>
</div>

<div class="flex flex-col items-center justify-center py-[64px] px-[20px] bg-[#f9f9fc]">
    <section class="overflow-hidden max-w-screen-xl bg-[#f9f9fc] py-24">
        <div class="revealY flex-row items-start justify-center lg:flex active">
            <div class="revealXRight flex max-w-[250px] flex-col active">
                <div *ngIf="tab !== 0"
                     class="flex border-gray-80 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=0"
                       class=" text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Innovative Storage Technologies
                    </p>
                </div>
                <div *ngIf="tab === 0"
                     class="flex border-gray-500 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=0"
                       class=" text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Innovative Storage Technologies
                    </p>
                </div>
                <div class="h-[16px] border-r-4 border-gray-10 pr-8 last:hidden"></div>
                <div *ngIf="tab !== 1"
                     class="flex border-gray-10 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=1"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Personalized File Management
                    </p>
                </div>
                <div *ngIf="tab === 1"
                     class="flex border-gray-500 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=1"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Personalized File Management
                    </p>
                </div>
                <div class="h-[16px] border-r-4 border-gray-10 pr-8 last:hidden"></div>
                <div *ngIf="tab !== 2"
                     class="flex border-gray-10 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=2"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Comprehensive File Versioning
                    </p>
                </div>
                <div *ngIf="tab === 2"
                     class="flex border-gray-500 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=2"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Comprehensive File Versioning
                    </p>
                </div>
                <div class="h-[16px] border-r-4 border-gray-10 pr-8 last:hidden"></div>
                <div *ngIf="tab !== 3"
                     class="flex border-gray-10 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=3"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Guarding against scams and viruses
                    </p>
                </div>
                <div *ngIf="tab === 3"
                     class="flex border-gray-500 cursor-pointer flex-row items-center border-r-4 p-2 pr-8">
                    <p (click)="tab=3"
                       class="text-[24px] font-medium text-[#18181d] hover:text-[#0066ff]">
                        Guarding against scams and viruses
                    </p>
                </div>
                <div class="h-[16px] border-r-4 border-gray-10 pr-8 last:hidden"></div>
            </div>
            <div class="flex flex-col justify-start">
                <div class="flex w-auto justify-center px-[24px]">
                    <div class="flex flex-col rounded-3xl pl-6">
                        <div class="flex w-full max-w-[384px] flex-col space-y-6">
                            <div *ngIf="tab===0">
                                <svg fill="currentColor"
                                     class="text-[#0066ff]" viewBox="-0.5 -0.5 48 48"
                                     xmlns="http://www.w3.org/2000/svg" height="48"
                                     width="48">
                                    <path
                                        d="M37.504041666666666 28.342958333333335S46.02083333333333 27.24825 46.02083333333333 18.604166666666664a9.774041666666665 9.774041666666665 0 0 0 -10.144166666666665 -9.776A13.686791666666666 13.686791666666666 0 0 0 9.83475 13.865 7.34375 7.34375 0 0 0 0.9791666666666666 21.052083333333332c0 7.459291666666667 7.510208333333333 7.332 7.510208333333333 7.332"
                                        fill="none" stroke="#0066ff"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"></path>
                                    <path
                                        d="M42.104166666666664 41.125a4.895833333333333 4.895833333333333 0 0 1 -4.895833333333333 4.895833333333333H9.791666666666666A4.895833333333333 4.895833333333333 0 0 1 4.895833333333333 41.125v-4.895833333333333h37.20833333333333Z"
                                        fill="none" stroke="#0066ff"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"></path>
                                    <path
                                        d="m4.895833333333333 36.229166666666664 4.568791666666667 -9.971833333333333A3.3291666666666666 3.3291666666666666 0 0 1 12.239583333333332 24.479166666666664H33.291666666666664a3.789375 3.789375 0 0 1 2.9375 1.6900416666666667l5.875 10.059958333333332Z"
                                        fill="none" stroke="#0066ff"
                                        stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"></path>
                                    <path d="m29.375 42.104166666666664 5.875 0"
                                          fill="none" stroke="#0066ff"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"></path>
                                </svg>
                            </div>
                            <svg width="48" height="48" *ngIf="tab===1"
                                 fill="currentColor"
                                 class="text-[#0066ff]"
                                 viewBox="-0.5 -0.5 48 48"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.8125 14.6875a3.9166666666666665 3.9166666666666665 0 1 0 7.833333333333333 0 3.9166666666666665 3.9166666666666665 0 1 0 -7.833333333333333 0Z"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m26.4375 24.87083333333333 -3.0824166666666666 -4.933041666666667a0.9791666666666666 0.9791666666666666 0 0 0 -1.6606666666666665 0L16.524416666666667 28.2l-2.1071666666666666 -3.3722499999999997a0.9791666666666666 0.9791666666666666 0 0 0 -1.6606666666666665 0L6.854166666666666 34.27083333333333h7.833333333333333"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M18.604166666666664 46.02083333333333h-15.666666666666666a1.9583333333333333 1.9583333333333333 0 0 1 -1.9583333333333333 -1.9583333333333333v-41.125a1.9583333333333333 1.9583333333333333 0 0 1 1.9583333333333333 -1.9583333333333333h26.032124999999997a1.9583333333333333 1.9583333333333333 0 0 1 1.3845416666666666 0.5737916666666666L37.613708333333335 8.8125a1.9583333333333333 1.9583333333333333 0 0 1 0.5737916666666666 1.3845416666666666V20.5625"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M24.479166666666664 31.9365V44.0625a1.9583333333333333 1.9583333333333333 0 0 0 1.9583333333333333 1.9583333333333333h13.708333333333332a1.9583333333333333 1.9583333333333333 0 0 0 1.9583333333333333 -1.9583333333333333v-12.126"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m20.5625 35.25 11.464083333333333 -9.699625000000001a1.9583333333333333 1.9583333333333333 0 0 1 2.5301666666666667 0L46.02083333333333 35.25"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M36.229166666666664 46.02083333333333h-5.875v-5.875a2.9375 2.9375 0 0 1 5.875 0Z"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                 *ngIf="tab===2"
                                 class="text-[#0066ff]"
                                 viewBox="-0.5 -0.5 48 48" height="48" width="48">
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="m27.382591666666666 19.372773333333335 -1.914075 -7.751631666666666 -7.736180416666667 1.8636674999999998"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M43.88370416666666 28.353337500000002v15.530366666666666c0 0.5148458333333333 -0.2046458333333333 1.0087375 -0.5687 1.3727916666666666 -0.3640541666666667 0.3640541666666667 -0.85775 0.5685041666666667 -1.3725958333333332 0.5685041666666667H6.998848333333333c-0.5148654166666666 0 -1.0086395833333333 -0.20445 -1.3726937499999998 -0.5685041666666667 -0.36407375 -0.3640541666666667 -0.5686020833333333 -0.8579458333333333 -0.5686020833333333 -1.3727916666666666v-15.530366666666666"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M26.411845833333334 24.468787499999998H45.824999999999996"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M1.174953 24.468787499999998H14.764090416666667"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M25.441295833333335 11.621141666666666c-3.115708333333333 3.530248333333333 -4.842174999999999 8.072445833333333 -4.857841666666666 12.7810625 -0.015666666666666666 4.708420833333333 1.6802499999999998 9.262133333333333 4.7722625 12.813179166666666"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M5.057591666666666 2.1456675c-0.2680370833333333 0 -0.48531416666666666 -0.21729079166666665 -0.48531416666666666 -0.4853298333333333 0 -0.2680370833333333 0.21727708333333332 -0.48532591666666663 0.48531416666666666 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M5.057513333333333 2.1456675c0.2680370833333333 0 0.48531416666666666 -0.21729079166666665 0.48531416666666666 -0.4853298333333333 0 -0.2680370833333333 -0.21727708333333332 -0.48532591666666663 -0.48531416666666666 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M12.822677083333332 2.1456675c-0.2680370833333333 0 -0.48531416666666666 -0.21729079166666665 -0.48531416666666666 -0.4853298333333333 0 -0.2680370833333333 0.21727708333333332 -0.48532591666666663 0.48531416666666666 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M12.82283375 2.1456675c0.2680370833333333 0 0.48531416666666666 -0.21729079166666665 0.48531416666666666 -0.4853298333333333 0 -0.2680370833333333 -0.21727708333333332 -0.48532591666666663 -0.48531416666666666 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M20.587958333333333 2.1456675c-0.2680958333333333 0 -0.48547083333333335 -0.21729079166666665 -0.48547083333333335 -0.4853298333333333 0 -0.2680370833333333 0.21737499999999998 -0.48532591666666663 0.48547083333333335 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M20.587958333333333 2.1456675c0.2680958333333333 0 0.48547083333333335 -0.21729079166666665 0.48547083333333335 -0.4853298333333333 0 -0.2680370833333333 -0.21737499999999998 -0.48532591666666663 -0.48547083333333335 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M28.353337500000002 2.1456675c-0.2680958333333333 0 -0.48527499999999996 -0.21729079166666665 -0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 0.21717916666666665 -0.48532591666666663 0.48527499999999996 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M28.353141666666666 2.1456675c0.2680958333333333 0 0.48547083333333335 -0.21729079166666665 0.48547083333333335 -0.4853298333333333 0 -0.2680370833333333 -0.21737499999999998 -0.48532591666666663 -0.48547083333333335 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M36.118520833333335 2.1456675c-0.2680958333333333 0 -0.48527499999999996 -0.21729079166666665 -0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 0.21717916666666665 -0.48532591666666663 0.48527499999999996 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <path stroke="#0066ff"
                                      d="M36.118520833333335 2.1456675c0.2679 0 0.48527499999999996 -0.21729079166666665 0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 -0.21737499999999998 -0.48532591666666663 -0.48527499999999996 -0.48532591666666663"
                                      stroke-width="2"></path>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 2.1456675c-0.2680958333333333 0 -0.48527499999999996 -0.21729079166666665 -0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 0.21717916666666665 -0.48532591666666663 0.48527499999999996 -0.48532591666666663"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 2.1456675c0.2679 0 0.48527499999999996 -0.21729079166666665 0.48527499999999996 -0.4853298333333333 0 -0.2680370833333333 -0.21737499999999998 -0.48532591666666663 -0.48527499999999996 -0.48532591666666663"
                                          stroke-width="2"></path>
                                </g>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M5.057591666666666 9.912848333333333c-0.2680370833333333 0 -0.48531416666666666 -0.21729666666666667 -0.48531416666666666 -0.48533374999999995s0.21727708333333332 -0.48533374999999995 0.48531416666666666 -0.48533374999999995"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M5.057513333333333 9.912848333333333c0.2680370833333333 0 0.48531416666666666 -0.21729666666666667 0.48531416666666666 -0.48533374999999995s-0.21727708333333332 -0.48533374999999995 -0.48531416666666666 -0.48533374999999995"
                                          stroke-width="2"></path>
                                </g>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M5.057591666666666 17.680009583333334c-0.2680370833333333 0 -0.48531416666666666 -0.21727708333333332 -0.48531416666666666 -0.48531416666666666 0 -0.2680370833333333 0.21727708333333332 -0.48533374999999995 0.48531416666666666 -0.48533374999999995"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M5.057513333333333 17.680009583333334c0.2680370833333333 0 0.48531416666666666 -0.21727708333333332 0.48531416666666666 -0.48531416666666666 0 -0.2680370833333333 -0.21727708333333332 -0.48533374999999995 -0.48531416666666666 -0.48533374999999995"
                                          stroke-width="2"></path>
                                </g>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 9.912848333333333c-0.2680958333333333 0 -0.48527499999999996 -0.21729666666666667 -0.48527499999999996 -0.48533374999999995s0.21717916666666665 -0.48533374999999995 0.48527499999999996 -0.48533374999999995"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 9.912848333333333c0.2679 0 0.48527499999999996 -0.21729666666666667 0.48527499999999996 -0.48533374999999995s-0.21737499999999998 -0.48533374999999995 -0.48527499999999996 -0.48533374999999995"
                                          stroke-width="2"></path>
                                </g>
                                <g>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 17.680009583333334c-0.2680958333333333 0 -0.48527499999999996 -0.21727708333333332 -0.48527499999999996 -0.48531416666666666 0 -0.2680370833333333 0.21717916666666665 -0.48533374999999995 0.48527499999999996 -0.48533374999999995"
                                          stroke-width="2"></path>
                                    <path stroke="#0066ff"
                                          d="M43.88370416666666 17.680009583333334c0.2679 0 0.48527499999999996 -0.21727708333333332 0.48527499999999996 -0.48531416666666666 0 -0.2680370833333333 -0.21737499999999998 -0.48533374999999995 -0.48527499999999996 -0.48533374999999995"
                                          stroke-width="2"></path>
                                </g>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" *ngIf="tab===3"
                                 class="text-[#0066ff]"
                                 viewBox="-0.5 -0.5 48 48" height="48" width="48">
                                <defs></defs>
                                <path
                                    d="M22.742124999999998 11.505208333333332a10.533874999999998 10.533874999999998 0 1 0 21.067749999999997 0 10.533874999999998 10.533874999999998 0 1 0 -21.067749999999997 0"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m40.72354166666667 18.95275 5.156291666666666 5.518583333333333"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m9.791666666666666 46.028666666666666 27.416666666666664 0"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path d="m22.520833333333332 42.112 0 3.9166666666666665"
                                      fill="none" stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M46.02083333333333 30.183791666666664V39.166666666666664a2.9375 2.9375 0 0 1 -2.9375 2.9375H3.9166666666666665A2.9375 2.9375 0 0 1 0.9791666666666666 39.166666666666664V9.791666666666666A2.9375 2.9375 0 0 1 3.9166666666666665 6.854166666666666h14.5465"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="M21.958791666666663 26.65683333333333a5.875 5.875 0 1 1 -11.767625 0v-2.9375a5.875 5.875 0 1 1 11.767625 0Z"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m10.191166666666666 23.715416666666666 11.767625 0"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path d="m5.781 26.65683333333333 4.410166666666666 0"
                                      fill="none" stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m21.958791666666663 26.65683333333333 4.410166666666666 0"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m8.230875000000001 34.50191666666666 3.6836249999999997 -3.6836249999999997"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                                <path d="m7.24975 16.359916666666663 3.995 3.995"
                                      fill="none" stroke="#0066ff" stroke-linecap="round"
                                      stroke-linejoin="round" stroke-width="2"></path>
                                <path
                                    d="m23.919083333333333 34.50191666666666 -3.6836249999999997 -3.6836249999999997"
                                    fill="none" stroke="#0066ff" stroke-linecap="round"
                                    stroke-linejoin="round" stroke-width="2"></path>
                            </svg>

                            <p class="text-[36px] font-semibold text-[#18181d]">{{ tabData[tab].header }}</p>
                            <p class="text-[24px] font-normal text-gray-600">{{ tabData[tab].text }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col items-center justify-center space-y-10 px-5 lg:hidden">
            <div
                class="flex flex-col items-start justify-start rounded-3xl bg-white p-[40px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" viewBox="0 0 256 256"
                     class="mb-6 text-[48px] text-[#0066ff]">
                    <path
                        d="M207.06,80.67A111.24,111.24,0,0,0,128,48h-.4C66.07,48.21,16,99,16,161.13V184a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V160A111.25,111.25,0,0,0,207.06,80.67ZM224,184H119.71l54.76-75.3a8,8,0,0,0-12.94-9.42L99.92,184H32V161.13c0-3.08.15-6.12.43-9.13H56a8,8,0,0,0,0-16H35.27c10.32-38.86,44-68.24,84.73-71.66V88a8,8,0,0,0,16,0V64.33A96.14,96.14,0,0,1,221,136H200a8,8,0,0,0,0,16h23.67c.21,2.65.33,5.31.33,8Z"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col"><p
                    class="mb-6 text-[36px] font-medium text-[#18181d]">Speed without
                    limits</p>
                    <p class="text-[24px] text-[#636367]">Have the need for speed?
                        Our distributed service is optimized across lightning-fast servers
                        scattered throughout the EU, so you can get the best performance
                        out
                        of your connection.</p></div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-3xl bg-white p-[40px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" viewBox="0 0 256 256"
                     class="mb-6 text-[48px] text-[#0066ff]">
                    <path
                        d="M246,98.73l-56-64A8,8,0,0,0,184,32H72a8,8,0,0,0-6,2.73l-56,64a8,8,0,0,0,.17,10.73l112,120a8,8,0,0,0,11.7,0l112-120A8,8,0,0,0,246,98.73ZM222.37,96H180L144,48h36.37ZM74.58,112l30.13,75.33L34.41,112Zm89.6,0L128,202.46,91.82,112ZM96,96l32-42.67L160,96Zm85.42,16h40.17l-70.3,75.33ZM75.63,48H112L76,96H33.63Z"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col"><p
                    class="mb-6 text-[24px] font-medium text-[#18181d]">Frictionless
                    workflows</p>
                    <p class="text-[24px] text-[#636367]">AyrDrive products are
                        streamlined to be as usable as possible while providing a clean
                        look
                        to enhance and boost your productivity. Business security, made
                        easy.</p></div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-3xl bg-white p-[40px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" viewBox="0 0 256 256"
                     class="mb-6 text-[48px] text-[#0066ff]">
                    <path
                        d="M160,40A88.09,88.09,0,0,0,81.29,88.67,64,64,0,1,0,72,216h88a88,88,0,0,0,0-176Zm0,160H72a48,48,0,0,1,0-96c1.1,0,2.2,0,3.29.11A88,88,0,0,0,72,128a8,8,0,0,0,16,0,72,72,0,1,1,72,72Zm37.66-93.66a8,8,0,0,1,0,11.32l-48,48a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L144,148.69l42.34-42.35A8,8,0,0,1,197.66,106.34Z"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col"><p
                    class="mb-6 text-[24px] font-medium text-[#18181d]">Backup storage on
                    demand</p>
                    <p class="text-[24px] text-[#636367]">Save your data from
                        disaster with built-in redundancy. Back up your essential work and
                        projects, across multiple fast servers throughout the EU, and
                        access
                        them from all your devices.</p></div>
            </div>
            <div
                class="flex flex-col items-start justify-start rounded-3xl bg-white p-[40px] sm:p-[40px] md:max-w-[488px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                     fill="currentColor" viewBox="0 0 256 256"
                     class="mb-6 text-[48px] text-[#0066ff]">
                    <path
                        d="M216,40H40A16,16,0,0,0,24,56V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V56A16,16,0,0,0,216,40Zm0,16V158.75l-26.07-26.06a16,16,0,0,0-22.63,0l-20,20-44-44a16,16,0,0,0-22.62,0L40,149.37V56ZM40,172l52-52,80,80H40Zm176,28H194.63l-36-36,20-20L216,181.38V200ZM144,100a12,12,0,1,1,12,12A12,12,0,0,1,144,100Z"></path>
                </svg>
                <div class="flex w-full max-w-[400px] flex-col"><p
                    class="mb-6 text-[24px] font-medium text-[#18181d]">Protection for
                    your
                    best
                    moments</p>
                    <p class="text-[24px] text-[#636367]">Store all your photos in
                        one gallery and access them from everywhere. Save your memories
                        straight to your phone and view them from anywhere.</p></div>
            </div>
        </div>
    </section>
</div>

<div class="flex flex-col items-center justify-center space-y-24 py-[64px] px-5">
    <div class="max-w-screen-xl w-[100%] flex flex-row justify-between gap-[36px]">
        <div class="grid grid-cols-3 gap-[24px] w-full">
            <div
                class="flex justify-between snap-center flex-col overflow-hidden rounded-3xl bg-white p-[32px] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                <div class="flex flex-col gap-[15px]">
                    <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg"
                         class="w-[48px] h-[48px] text-gray-800 dark:text-white"
                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                         fill="#0066ff"
                         height="48" width="48">
                        <path
                            d="M0.9791666666666666 23.470625a22.520833333333332 22.520833333333332 0 1 0 45.041666666666664 0 22.520833333333332 22.520833333333332 0 1 0 -45.041666666666664 0Z"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                        <path
                            d="M1.3179583333333333 19.55395833333333h11.914499999999999a5.875 5.875 0 0 1 4.1555833333333325 1.7194166666666666L19.583333333333332 23.468666666666667a5.875 5.875 0 0 1 0.23695833333333333 8.058541666666667l-2.669208333333333 3.002125A5.875 5.875 0 0 0 15.666666666666666 38.432291666666664v6.1589583333333335"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                        <path
                            d="M39.677791666666664 7.803958333333333h-11.358333333333333a4.40625 4.40625 0 0 0 0 8.8125h0.846a5.875 5.875 0 0 1 4.895833333333333 2.614375l4.343583333333333 6.519291666666667a5.875 5.875 0 0 0 4.895833333333333 2.6163333333333334h2.1952916666666664"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <div>
                        <p class="mb-6 text-[24px] text-[#18181b] font-semibold sm:leading-tight">
                            Your Network Ally<br/><br/>
                        </p>
                        <p class="text-[18px] text-gray-500 font-normal">
                            Our cloud solution is designed to be your trusted partner,
                            providing
                            easy access and protection for your valuable data anytime,
                            anywhere.
                            Experience the convenience of secure file management at your
                            fingertips
                        </p>
                    </div>
                </div>
                <div
                    class="flex cursor-pointer flex-row items-center justify-center space-x-2 text-[#0066ff] lg:justify-start">
                    <div
                        class="flex flex-row items-center rounded-xl py-[12px] hover:underline decoration-2">
                        <a href="/privacy"
                           class="text-[20px] text-[#0066ff] visited:text-[#0066ff]">
                            The
                            fight for
                            privacy
                        </a>
                        <svg
                            class="w-[18px] h-[18px] text-gray-800 dark:text-white ml-[8px]"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="#0066ff" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div
                class="flex justify-between gap-[24px] snap-center flex-col overflow-hidden rounded-3xl bg-white p-[32px]  rounded-3xl border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                <div class="flex flex-col gap-[15px]">
                    <svg class="w-[48px] h-[48px] text-gray-800 dark:text-white"
                         xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="-0.5 -0.5 48 48" height="48" width="48">
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M29.046 10.366887916666666c3.9758083333333327 -0.7992545833333333 7.1769 -4.039199583333334 7.9774666666666665 -8.117859583333333 0.8003708333333334 4.07866 4.000679166666666 7.318605 7.9764875 8.117859583333333m0 0.004660833333333334c-3.9758083333333327 0.7992545833333333 -7.1769 4.039219166666666 -7.977270833333333 8.117879166666667 -0.8005666666666666 -4.07866 -4.000875 -7.3186245833333325 -7.976683333333333 -8.117879166666667"
                              stroke-width="2"></path>
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M22.685920833333334 10.373056666666665H5.82018625c-2.1096145833333333 0 -3.819787916666667 1.71015375 -3.819787916666667 3.819768333333333V33.291666666666664c0 2.1095166666666665 1.710173333333333 3.8197291666666664 3.819787916666667 3.8197291666666664H36.378391666666666c2.1095166666666665 0 3.8197291666666664 -1.7102125 3.8197291666666664 -3.8197291666666664v-9.518087499999998"
                              stroke-width="2"></path>
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M21.099866666666667 37.108850000000004v7.639654166666666"
                              stroke-width="2"></path>
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.368334166666667 44.75105h11.459265833333333"
                              stroke-width="2"></path>
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.000398333333333 29.79565H40.198120833333334"
                              stroke-width="2"></path>
                    </svg>
                    <div>
                        <p class="mb-6 text-[24px]  text-[#18181b] font-semibold sm:leading-tight">
                            Various devices, Unified experience
                        </p>
                        <p class="text-[18px]  text-gray-500">
                            Discover our cloud storage service, available both as a web
                            version
                            and a desktop application. Enjoy seamless synchronization of
                            your
                            data
                            across all devices, combined with robust encryption measures
                            to
                            safeguard your information
                        </p>
                    </div>
                </div>
                <div
                    class="flex cursor-pointer text-[#0066ff]  flex-row items-center justify-center space-x-2 text-[#0066ff] lg:justify-start">
                    <div
                        class="flex flex-row items-center rounded-xl py-[12px] hover:underline decoration-2">
                        <a href="/drive-info"
                           class="text-[18px] text-[#0066ff] visited:text-[#0066ff]">Download
                            apps</a>
                        <svg
                            class="w-[18px] h-[18px] text-gray-800 dark:text-white ml-[8px]"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="#0066ff" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div
                class="justify-between flex snap-center flex-col overflow-hidden rounded-3xl bg-white p-[32px] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                <div class="flex flex-col gap-[15px]">
                    <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg"
                         class="w-[48px] h-[48px] text-gray-800 dark:text-white"
                         height="48" width="48">
                        <path
                            d="M40.14583333333333 27.234541666666665A8.446291666666665 8.446291666666665 0 0 0 45.041666666666664 19.30525a9.225708333333333 9.225708333333333 0 0 0 -9.701583333333332 -9.076875A13.134541666666665 13.134541666666665 0 0 0 23.5 2.9375a12.934791666666667 12.934791666666667 0 0 0 -13.071874999999999 11.963458333333334A6.973624999999999 6.973624999999999 0 0 0 1.9583333333333333 21.576916666666666 6.239249999999999 6.239249999999999 0 0 0 6.854166666666666 28.004166666666666"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                        <path
                            d="M11.75 32.314458333333334a11.75 11.75 0 1 0 23.5 0 11.75 11.75 0 1 0 -23.5 0Z"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                        <path
                            d="m28.736583333333332 28.899124999999998 -5.679166666666666 7.5865833333333335a1.46875 1.46875 0 0 1 -2.212916666666666 0.15666666666666668l-2.9375 -2.9375"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <div>
                        <p class="mb-6 text-[24px] font-semibold sm:leading-tight">
                            AyrDrive is free<br/><br/>
                        </p>
                        <p class=" text-[18px] text-gray-500">
                            We offer a generous free tier for our cloud service, providing
                            users
                            with essential features at no cost. This complimentary access
                            allows
                            individuals to experience the full functionality of our
                            service
                            before
                            considering any premium options
                        </p>
                    </div>
                </div>
                <div
                    class="flex cursor-pointer  text-[#0066ff] flex-row items-center justify-center space-x-2 text-[#0066ff] lg:justify-start">
                    <div
                        class="flex flex-row items-center rounded-xl py-[12px] hover:underline decoration-2">
                        <a href="/register"
                           class="text-[18px] text-[#0066ff] visited:text-[#0066ff]">Create
                            free account</a>
                        <svg
                            class="w-[18px] h-[18px] text-gray-800 dark:text-white ml-[8px]"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="#0066ff" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="z-10 flex flex-col items-center bg-gray-1 py-[64px] text-black">
    <div class="revealY space-y-32 px-[24px] text-left sm:text-center active">
        <div class="flex flex-col items-center justify-center space-y-4 text-center">
            <h2 class="mb-6 text-[48px] font-semibold  sm:leading-tight">
                It's all about experience
            </h2>
            <h3 class="max-w-[768px] mt-[16px] text-[18px] font-normal text-gray-600">
                AyrDrive is not just storage; Drive and Photos are meticulously crafted
                tools designed to enhance your digital experience
            </h3>
        </div>
        <div
            class="flex flex-col space-y-32 text-left lg:grid lg:grid-cols-1 lg:grid-rows-2 lg:gap-20 lg:space-y-0">
            <div
                class="revealXRight flex overflow-hidden rounded-3xl  p-[32px] bg-white border-[1px] border-gray-300 lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-0 active">
                <div
                    class="flex h-full flex-col items-center justify-center p-7 text-center lg:items-start lg:pl-20 lg:text-start">
                    <h4 class="mb-[20px] text-[36px] font-semibold text-[#18181b]">
                        AyrDrive Storage
                    </h4>
                    <h5 class="mb-4 max-w-[340px] text-[18px] text-gray-500">
                        AyrDrive Storage is a service where AES-256 encryption is combined
                        with reliable data protection. Managing your files has never been
                        easier and more seamless. With Drive, you can be confident that
                        your files are protected from unauthorized access and stored in a
                        secure location
                    </h5>
                    <div class="flex justify-start mt-[12px]">
                        <a class="flex flex-row  border-[1px] transition-all duration-300 rounded-xl px-[20px] py-[12px] border-[#0066ff] items-center space-x-1 text-[18px] text-[#0066ff] hover:bg-[#0066ff11]"
                           href="/drive">
                            <span>
                                Start using Drive
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                 viewBox="0 0 24 24" fill="currentColor"
                                 class="h-[16px] w-4">
                                <path
                                    d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"></path>
                            </svg>
                        </a></div>
                </div>
                <div class="lg:pl-15 relative mt-16 flex self-stretch lg:mt-0">
                    <div
                        class="lg:flex lg:max-w-[480px] bg-gradient-to-b from-[#0066ff] rounded-3xl to-slate-900">
                        <img
                            src="/assets/images/homepage/Internxt-Drive-H.webp"
                            alt="AyrDrive Storage"
                            draggable="false"></div>
                </div>
            </div>
            <div
                class="revealXLeft flex overflow-hidden rounded-3xl  p-[32px] border-[1px] border-gray-300 lg:grid lg:grid-cols-2 lg:grid-rows-1 lg:gap-0 active">
                <div class="lg:pl-15 relative mt-16 flex self-stretch lg:mt-0">
                    <div
                        class="lg:flex lg:max-w-[480px] bg-gradient-to-b from-[#0066ff] rounded-3xl to-slate-900">
                        <img
                            src="/assets/images/homepage/photos-image-H.webp"
                            alt="AyrDrive Photos">
                    </div>
                </div>
                <div
                    class="flex h-full flex-col items-center justify-center p-7 text-center lg:items-start lg:pl-20 lg:text-start">
                    <h4 class="mb-[20px] text-[36px] font-semibold text-[#18181b]">
                        AyrDrive Photos
                    </h4>
                    <h5 class="mb-4 max-w-[340px] text-[18px]  text-gray-500">
                        Preserve, organize, and share your precious moments with
                        AyrDrive Photos. Enjoy a user-friendly experience with secure,
                        anytime access to your entire photo collection across devices
                    </h5>
                    <div class="flex justify-start mt-[12px]"><a
                        class="flex border-[1px] rounded-xl transition-all duration-300 px-[20px] py-[12px] flex-row items-center space-x-1 text-[18px]  border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff11]"
                        href="/drive">
                        <span>
                            Sync your Photos
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor" class="h-[16px] w-4">
                            <path
                                d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z"></path>
                        </svg>
                    </a></div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="overflow-hidden py-[64px] bg-[#f9f9fc]">
    <div class="flex items-center justify-center p-[24px] pb-[20px] active">
        <p class="mb-6 text-center text-[48px] font-medium">
            Honest reviews from our users
        </p>
    </div>
    <div class="flex flex-row items-center justify-center overflow-hidden">
        <div
            class="flex flex-row items-center items-stretch justify-center space-x-[16px] py-[12px]">
            <div
                class="flex opacity-60 w-screen justify-center shadow-subtle-hard md:w-auto">
                <div
                    class="flex snap-center flex-col overflow-hidden rounded-3xl bg-white p-[24px] h-[100%] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="flex w-auto max-w-[300px] flex-col">
                        <div class="flex w-[331px] flex-row">
                            <div class="flex items-center w-[331px]">
                                <img [src]="comments[0].ava" class="w-24"/>
                                <div class=" pl-[8px] text-[18px] font-semibold">
                                    <span>{{comments[0].title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="pt-9 text-[18px] font-normal text-gray-500">
                            <p class="line-clamp-6">{{comments[0].content}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex opacity-60 w-screen justify-center shadow-subtle-hard md:w-auto">
                <div
                    class="flex snap-center flex-col overflow-hidden rounded-3xl bg-white p-[24px] h-[100%] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="flex w-auto max-w-[300px] flex-col">
                        <div class="flex w-[331px] flex-row">
                            <div class="flex items-center w-[331px]">
                                <img [src]="comments[1].ava" class="w-24"/>
                                <div class=" pl-[8px] text-[18px] font-semibold">
                                    <span>{{comments[1].title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="pt-9 text-[18px] font-normal text-gray-500">
                            <p class="line-clamp-6">{{comments[1].content}}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div class="flex flex-col items-center min-h-[100%]">
                <button (click)="scrollLeft()"
                        class="rounded-full my-auto bg-[#0066ff] h-[36px] w-[36px] flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                         fill="#fff"
                         viewBox="0 0 256 256">
                        <path
                            d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path>
                    </svg>
                </button>
            </div>
            <div
                class="flex w-screen justify-center shadow-subtle-hard md:w-auto">
                <div
                    class="flex snap-center flex-col overflow-hidden rounded-3xl bg-white p-[24px] h-[100%] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="flex w-auto max-w-[300px] flex-col">
                        <div class="flex w-[331px] flex-row">
                            <div class="flex items-center w-[331px]">
                                <img [src]="comments[2].ava" class="w-24"/>
                                <div class=" pl-[8px] text-[18px] font-semibold">
                                    <span>{{comments[2].title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="pt-9 text-[18px] font-normal text-gray-500">
                            <p class="line-clamp-6">{{comments[2].content}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex w-screen justify-center shadow-subtle-hard md:w-auto">
                <div
                    class="flex snap-center flex-col overflow-hidden rounded-3xl bg-white p-[24px] h-[100%] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="flex w-auto max-w-[300px] flex-col">
                        <div class="flex items-center w-[331px]">
                            <img [src]="comments[3].ava" class="w-24"/>
                            <div class=" pl-[8px] text-[18px] font-semibold">
                                <span>{{comments[3].title}}</span>
                            </div>
                        </div>
                        <div class="pt-9 text-[18px] font-normal text-gray-500">
                            <p class="line-clamp-6">{{comments[3].content}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex w-screen justify-center shadow-subtle-hard md:w-auto grow">
                <div
                    class="flex flex-col rounded-3xl bg-white p-[24px] grow border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="flex w-auto max-w-[300px] flex-col grow">
                        <div class="flex w-[331px] flex-row">
                            <div class="flex items-center w-[331px]">
                                <img [src]="comments[4].ava" class="w-24"/>
                                <div class=" pl-[8px] text-[18px] font-semibold">
                                    <span>{{comments[4].title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="pt-9 text-[18px] font-normal text-gray-500 grow">
                            <p class="line-clamp-6">{{comments[4].content}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col items-center min-h-[100%]">
                <button (click)="scrollRight()"
                        class="rounded-full my-auto bg-[#0066ff] h-[36px] w-[36px] flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                         fill="#fff"
                         viewBox="0 0 256 256">
                        <path
                            d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
                    </svg>
                </button>
            </div>


            <div
                class="flex opacity-60 w-screen justify-center shadow-subtle-hard md:w-auto">
                <div
                    class="flex snap-center flex-col overflow-hidden rounded-3xl bg-white p-[24px] h-[100%] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="flex w-auto max-w-[300px] flex-col">
                        <div class="flex w-[331px] flex-row">
                            <div class="flex items-center w-[331px]">
                                <img [src]="comments[5].ava" class="w-24"/>
                                <div class=" pl-[8px] text-[18px] font-semibold">
                                    <span>{{comments[5].title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="pt-9 text-[18px] font-normal text-gray-500">
                            <p class="line-clamp-6">{{comments[5].content}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex opacity-60 w-screen justify-center shadow-subtle-hard md:w-auto">
                <div
                    class="flex snap-center flex-col overflow-hidden rounded-3xl bg-white p-[24px] h-[100%] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="flex w-auto max-w-[300px] flex-col">
                        <div class="flex w-[331px] flex-row">
                            <div class="flex items-center w-[331px]">
                                <img [src]="comments[6].ava" class="w-24"/>
                                <div class=" pl-[8px] text-[18px] font-semibold">
                                    <span>{{comments[6].title}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="pt-9 text-[18px] font-normal text-gray-500">
                            <p class="line-clamp-6">{{comments[6].content}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--
<div class="w-[100%] flex items-center justify-center py-[64px]">
    <div class="w-[100%] flex justify-center items-center max-w-screen-md">
        <div
            class="flex mb-10 max-w-[584px] flex-col items-center justify-center space-y-3 text-center md:items-start md:text-left ">
            <div class="flex w-full flex-col space-y-1 text-center"><h2
                class="text-[48px] text-[#18181b] font-medium">Newsletter</h2>
                <p class="text-[18px] text-gray-600">Want to hear from us
                    when
                    we
                    launch new products or release a new update? Type your mail address
                    and we’ll do the rest!</p>
            </div>
            <form data-code="r3s4c1" method="post" target="_blank" rel="noopener"
                  action="https://app.mailerlite.com/webforms/submit/r3s4c1"
                  class="flex w-full flex-col items-center justify-center md:flex-row">
                <input type="hidden" name="ml-submit" value="1">
                <input type="email"
                       name="fields[email]"
                       placeholder="Your email address"
                       class="flex  max-w-[250px] h-auto w-full flex-row rounded-xl px-[20px] py-[12px] text-[18px] outline-none sm:py-2 sm:text-[18px] border-cool-gray-20 bg-white focus:border-blue-50 focus:ring-opacity-20 mb-2 appearance-none border text-left transition-all duration-150 focus:ring focus:ring-[#0066ff]"
                       required="">
                <input
                    type="submit" name="signup" value="Subscribe"
                    class="ml-2 flex cursor-pointer items-center justify-center rounded-xl border border-transparent bg-[#0066ff] px-[20px] py-[12px] text-[18px] font-medium text-white transition-all duration-75 hover:bg-[#0066ff]-dark focus:outline-none active:bg-[#0066ff]-dark sm:mb-2 sm:py-2 sm:text-[18px]">
            </form>
            <span class="text-[16px] w-full text-gray-400 text-center">By subscribing you agree to our
                <a href="/legal"
                   class="cursor-pointer w-full  text-center underline visited:text-gray-400">
                    privacy policy.
                </a>
            </span>
        </div>
    </div>
</div>
-->
<section class="overflow-hidden bg-[#0066ff] py-[64px]">
    <div
        class="flex flex-col items-center justify-center space-y-8 px-[20px] text-center">
        <div class="flex flex-col items-center space-y-[16px] text-center text-white">
            <p
                class="text-[36px] font-semibold">
                Get started now for free!
            </p>
            <p class="w-full max-w-[570px] text-[20px] font-normal">
                Create your account to enjoy up to 10 GB of free storage and gain full
                access to our cloud services
            </p>
        </div>
        <a href="/register"
           class="flex rounded-lg bg-white px-[20px] py-[12px] text-[18px] font-medium
            text-[#0066ff] hover:bg-[#ffffffee]">
            Get started
        </a>
    </div>
</section>

<div class="flex w-full flex-col items-center justify-center px-[24px] py-[64px] sm:p-20 sm:py-12">
    <div
        class="flex w-full max-w-screen-md flex-col items-center justify-between space-y-8 pb-9 text-center lg:flex-row lg:items-start lg:space-y-0 lg:space-x-32 lg:text-left">
        <div
            class="flex w-full max-w-[384px] flex-col items-center justify-center space-y-3 lg:items-start">
            <div class="flex flex-col space-y-1">
                <h2 class="text-[24px] font-medium ">
                    Download AyrDrive
                </h2>
                <p class="text-gray-600 text-[16px]">
                    Smooth data management and secure cloud storage are available with the
                    AyrDrive desktop app
                </p>
            </div>
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                <div class="flex"><a
                    class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    download href="/assets/AyrDrive.zip">
                    <span>Download for
                        <!-- --> Windows</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                         fill="currentColor" viewBox="0 0 256 256">
                        <path
                            d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                    </svg>
                </a></div>
            </div>
        </div>
        <div
            class="flex mb-10 max-w-[384px] flex-col items-center justify-center space-y-3 text-center md:items-start md:text-left ">
            <div class="flex w-full flex-col space-y-1"><h2
                class="text-[24px] font-medium">Newsletter</h2>
                <p class="text-[16px] text-gray-600">
                    Want to stay informed about our latest products and updates? Enter
                    your email address, and we'll take care of the rest!
                </p>
            </div>
            <form data-code="r3s4c1" method="post" target="_blank" rel="noopener"
                  action=""
                  class="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
                <input type="hidden" name="ml-submit" value="1">
                <input type="email" name="fields[email]"
                       placeholder="Your email address"
                       class="flex h-auto w-full flex-row rounded-lg px-[12px] py-3 text-[18px] outline-none sm:py-2 sm:text-[16px] md:w-64 border-cool-gray-20 bg-white focus:border-blue-50 focus:ring-opacity-20 mb-2 appearance-none border text-left transition-all duration-150 focus:ring focus:ring-primary"
                       required="">
                <input type="submit" name="signup" value="Subscribe"
                       class="ml-2 flex w-full cursor-pointer items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[12px] py-3 text-[18px] font-medium text-white transition-all duration-75 hover:bg-[#0066ff]-dark focus:outline-none active:bg-[#0066ff]-dark sm:mb-2 sm:py-2 sm:text-[16px]">
            </form>
            <span class="text-[16px] text-gray-400">By subscribing you agree to our
                <a href="/legal" class="cursor-pointer underline visited:text-gray-400">
                    privacy policy
                </a>
            </span>
        </div>
    </div>
    <footer class="flex max-w-screen-md w-[100%] items-center justify-center">
        <div
            class="w-[100%] flex-col items-center justify-center md:space-y-[56px] lg:flex">
            <div
                class="flex w-[100%] flex-row justify-between md:justify-between lg:space-x-[80px]">
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Products</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]">
                                <a class="hover:text-[#0066ff]" href="/drive">
                                    AyrDrive Storage
                                </a>
                            </p>
                            <p class="hover:text-[#0066ff]">
                                <a class="hover:text-[#0066ff]" href="/photos">
                                    AyrDrive Photos
                                </a>
                            </p>
                            <p class="hover:text-[#0066ff]">
                                <a class="hover:text-[#0066ff]" href="/pricing">
                                    Pricing
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Company</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/about">About Us</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/privacy">Privacy</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="flex max-w-[200px] flex-row items-center hover:text-[#0066ff]"
                                href="/open-source">Open Source</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/legal">Legal</a></p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Join Us</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a href="/register"
                                                               target="_top"
                                                               class="hover:text-[#0066ff]">Create
                                Account</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/login?" target="_top">Log In</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/assets/images/homepage/AyrDrive-white-paper.pdf"
                                target="_blank"
                                rel="noreferrer" download="" class="hover:text-[#0066ff]">White
                                Paper</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex w-full max-w-[900px] flex-row justify-between  border-t-[1px] border-gray-300 pt-[20px]">
                <div class="flex flex-row items-center space-x-4"><a
                    style="cursor: pointer !important"
                    class="flex flex-shrink-0" (click)="scrollToTop()"><img loading="lazy"
                                                                            style="height:24px"
                                                                            src="/assets/images/homepage/AyrDrive.svg"
                                                                            alt="AyrDrive logo"></a>
                    <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive
                        Universal Technologies SL</p></div>
                <div class="flex flex-row space-x-1">
                </div>
            </div>
        </div>
        <div class="bg-gray-5 bg-opacity-50 flex flex-col overflow-hidden lg:hidden">
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                    id="headlessui-disclosure-button-5" type="button"
                    aria-expanded="false"><span class="flex flex-row">Products</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[#18181b] text-[18px] font-medium"
                    id="headlessui-disclosure-button-7" type="button"
                    aria-expanded="false"><span class="flex flex-row">Company</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <a href="/register"
                   class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                   id="headlessui-disclosure-button-9" type="button"
                   aria-expanded="false"><span class="flex flex-row">Join Us</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </a>
            </div>
            <div class="flex w-screen">
                <div class="w-screen">
                    <button
                        class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                        id="headlessui-disclosure-button-15" type="button"
                        aria-expanded="false">
                        <div class="flex flex-row items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                 fill="currentColor" viewBox="0 0 256 256"
                                 class="text-gray-60">
                                <path
                                    d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z"></path>
                            </svg>
                            <span class="flex flex-row">English (EN)</span></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="flex text-gray-600">
                            <path
                                d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="hidden text-gray-600">
                            <path
                                d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div
                class="flex flex-col items-center space-y-4 py-10 border-t-[1px] border-gray-300">
                <div class="flex flex-row space-x-1">
                </div>
                <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive Universal
                    Technologies SL</p><a class="flex flex-shrink-0" routerLink="/"
                                          fragment="top-one"><img
                loading="lazy" width="110" height="12"
                src="/assets/images/homepage/AyrDrive.svg"
                alt="AyrDrive logo"></a></div>
        </div>
    </footer>
</div>
