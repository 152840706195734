<material-navbar [menuPosition]="settings.get('vebto.navbar.defaultPosition')" *ngIf="!settings.get('vebto.accountSettings.hideNavbar')"></material-navbar>

<div class="content container" *ngIf="userErrors$ | async as userErrors">
    <h1 class="header">
        <span class="header-text" trans>Account Settings</span>
        <ng-container *ngIf="breakpoints.isMobile$ | async">
            <button mat-icon-button [matMenuTriggerFor]="navSidebarMenu" class="mobile-toggle-button">
                <mat-icon svgIcon="settings"></mat-icon>
            </button>

            <mat-menu #navSidebarMenu="matMenu">
                <logged-in-user-menu></logged-in-user-menu>
            </mat-menu>
        </ng-container>
    </h1>

    <div class="panels">
        <ng-template #extraPanelRef></ng-template>

        <form style="border-radius:16px" class="panel account-settings-panel" (ngSubmit)="updateAccountSettings()" [formGroup]="userForm" ngNativeValidate>
            <div class="panel-header font-semibold" style="font-size: 18px" trans>Update Name or Profile Image</div>
            <div class="panel-body">
                <div class="input-container input-inline">
                    <label for="first_name" trans>First Name</label>
                    <input type="text" style="font-size: 16px; border-radius: 8px" placeholder="First name"
                           formControlName="first_name" id="first_name" name="first_name" required
                           class="w-full placeholder:text-[16px] text-[16px] placeholder:text-gray-300 placeholder:pt-4 h-[48px] ml-0 mr-0 active:bg-gray-50 border border-[1px] border-gray-500 text-gray-900 text-sm rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <div class="error first-name-error" *ngIf="userErrors.first_name">{{userErrors.first_name}}</div>
                </div>

                <div class="input-container input-inline">
                    <label for="last_name" trans>Last Name</label>
                    <input type="text" style="font-size: 16px; border-radius: 8px" placeholder="Last name"
                           formControlName="last_name" id="last_name" name="last_name" required
                           class="w-full placeholder:text-[16px] text-[16px] placeholder:text-gray-300 placeholder:pt-4 h-[48px] ml-0 mr-0 active:bg-gray-50 border border-[1px] border-gray-500 text-gray-900 text-sm rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <div class="error last-name-error" *ngIf="userErrors.last_name">{{userErrors.last_name}}</div>
                </div>

                <div class="input-container input-inline avatar-input-container">
                    <label for="last_name" trans>Profile image</label>
                    <div class="flex items-center gap-4">
                        <img [src]="avatar$ | async" class="max-w-[100px] max-h-[100px] rounded-xl" alt="User avatar">
                        <div class="flex h-full flex-col items-center justify-around">
                            <button type="button" style="font-size:16px"
                                    [disabled]="loading$ | async" (click)="openAvatarUploadDialog()" trans
                                    class="flex items-center justify-center gap-4 text-[16px] my-2 text-green-500 p-[6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                                </svg>
                            </button>
                            <button type="button" style="font-size:16px"
                                    [disabled]="loading$ | async" (click)="deleteAvatar()" trans
                                    class="flex items-center justify-center gap-4 text-[16px] my-2 text-rose-500 p-[6px]">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="panel-footer">
                <button type="submit" style="font-size:16px"
                        [disabled]="loading$ | async" trans
                        class="flex items-center justify-center gap-4 text-[16px] h-[48px] my-2 text-white bg-[#0066ff] hover:bg-blue-800 transition-color duration-200 focus:ring-4 focus:ring-blue-300 font-medium rounded-[8px] px-[20px] py-[10px] me-2 mb-2 focus:outline-none">
                    Save Changes
                </button>
            </div>
        </form>

        <form style="border-radius:16px" class="panel account-settings-panel change-password-panel" (ngSubmit)="changeUserPassword()" [formGroup]="passwordForm" *ngIf="passwordErrors$ | async as passwordErrors" ngNativeValidate>
            <div class="panel-header font-semibold" style="font-size: 18px" trans>Update Password</div>
            <div class="panel-body">
                <div class="input-container input-inline" *ngIf="currentUser.get('has_password')">
                    <label for="current_password" trans>Current Password</label>
                    <input type="password" style="font-size: 16px; border-radius: 8px" placeholder="Current password"
                           formControlName="current_password" id="current_password" name="current_password" required
                           class="w-full placeholder:text-[16px] text-[16px] placeholder:text-gray-300 placeholder:pt-4 h-[48px] ml-0 mr-0 active:bg-gray-50 border border-[1px] border-gray-500 text-gray-900 text-sm rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <div class="error current-password-error" *ngIf="passwordErrors.current_password">{{passwordErrors.current_password}}</div>
                </div>

                <div class="input-container input-inline">
                    <label for="new_password" trans>New Password</label>
                    <input type="password" style="font-size: 16px; border-radius: 8px" placeholder="New password"
                           formControlName="new_password" id="new_password" name="new_password" required
                           class="w-full placeholder:text-[16px] text-[16px] placeholder:text-gray-300 placeholder:pt-4 h-[48px] ml-0 mr-0 active:bg-gray-50 border border-[1px] border-gray-500 text-gray-900 text-sm rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <div class="error new-password-error" *ngIf="passwordErrors.new_password">{{passwordErrors.new_password}}</div>
                </div>

                <div class="input-container input-inline">
                    <label for="new_password_confirmation" trans>Confirm Password</label>
                    <input type="password" style="font-size: 16px; border-radius: 8px" placeholder="Confirm password"
                           formControlName="new_password_confirmation" id="new_password_confirmation" name="new_password_confirmation" required
                           class="w-full placeholder:text-[16px] text-[16px] placeholder:text-gray-300 placeholder:pt-4 h-[48px] ml-0 mr-0 active:bg-gray-50 border border-[1px] border-gray-500 text-gray-900 text-sm rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                    <div class="error new-password-confirmation-error" *ngIf="passwordErrors.new_password_confirmation">{{passwordErrors.new_password_confirmation}}</div>
                </div>

                <div class="input-container input-inline api-input-container" *ngIf="apiEnabled()">
                    <label for="api_token" trans>Api Token</label>
                    <input type="text" id="api_token" name="api_token" [value]="(initialUser$ | async).api_token" readonly>
                </div>
            </div>
            <div class="panel-footer">
                <button type="submit" style="font-size:16px"
                        [disabled]="loading$ | async" trans
                        class="flex items-center justify-center gap-4 text-[16px] h-[48px] my-2 text-white bg-[#0066ff] hover:bg-blue-800 transition-color duration-200 focus:ring-4 focus:ring-blue-300 font-medium rounded-[8px] px-[20px] py-[10px] me-2 mb-2 focus:outline-none">
                    Update
                </button>
            </div>
        </form>

        <form style="border-radius:16px" class="panel account-settings-panel preferences-panel" (ngSubmit)="updateAccountSettings()" [formGroup]="userForm" ngNativeValidate>
            <div class="panel-header font-semibold" style="font-size: 18px" trans>Update Account Preferences</div>
            <div class="panel-body">
                <div class="input-container input-inline">
                    <label for="language" trans>Language</label>
                    <select style="border-radius: 8px" name="language" id="language" formControlName="language" (ngModelChange)="changeLanguage($event)">
                        <option [value]="localization.language" *ngFor="let localization of selects.localizations">{{localization.name}}</option>
                    </select>
                    <div class="error language-error" *ngIf="userErrors.language">{{userErrors.language}}</div>
                </div>

                <div class="input-container input-inline">
                    <label for="country" trans>Country</label>
                    <select style="border-radius: 8px" name="country" id="country" formControlName="country">
                        <option value="{{country.code}}" *ngFor="let country of selects.countries">{{country.name}}</option>
                    </select>
                    <div class="error country-error" *ngIf="userErrors.country">{{userErrors.country}}</div>
                </div>

                <div class="input-container input-inline">
                    <label for="timezone" trans>Timezone</label>
                    <select style="border-radius: 8px" name="timezone" id="timezone" formControlName="timezone">
                        <optgroup [label]="pair.key" *ngFor="let pair of selects.timezones | keyvalue">
                            <option [value]="timezone.value" *ngFor="let timezone of pair.value">{{timezone.text}}</option>
                        </optgroup>
                    </select>
                    <div class="error timezone-error" *ngIf="userErrors.timezone">{{userErrors.timezone}}</div>
                </div>
            </div>
            <div class="panel-footer">
                <button type="submit" style="font-size:16px"
                        [disabled]="loading$ | async" trans
                        class="text-[16px] h-[48px] my-2 text-white bg-[#0066ff] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[8px] px-[20px] py-[10px] me-2 mb-2 focus:outline-none">
                    Save Changes
                </button>
            </div>
        </form>
    </div>

    <div class="logout-button-container">
        <button type="button" style="font-size:16px"
                [disabled]="loading$ | async" (click)="auth.logOut()"
                class="flex items-center justify-center gap-4 text-[16px] h-[48px] my-2 text-white bg-[#ff6600] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[8px] px-[20px] py-[10px] me-2 mb-2 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m15 15 6-6m0 0-6-6m6 6H9a6 6 0 0 0 0 12h3" />
            </svg>
            Logout
        </button>
    </div>
</div>
