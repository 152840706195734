<div class="navbar-container shadow-lg transparent" [class.container]="container">
    <a routerLink="/" class="logo-container img-logo-container"
       *ngIf="config.get('branding.logo_light')">
        <img class="logo" [alt]="'Site logo' | trans"
             src="assets/images/homepage/AyrDrive.svg">
    </a>
    <a routerLink="/" class="logo-container" *ngIf="!config.get('branding.logo_light')">
        <div class="text-logo">{{config.get('branding.site_name')}}</div>
    </a>

    <button class="toggle-sidebar-button" mat-icon-button *ngIf="shouldShowToggleBtn()"
            (click)="toggleButtonClick.emit()" aria-label="Toggle sidebar visibility">
        <mat-icon svgIcon="menu"></mat-icon>
    </button>

    <ng-content></ng-content>

    <div class="block md:hidden">
        <ng-container
            *ngIf="(mobileOrTablet$ | async) && menuPosition && !shouldShowToggleBtn()">
            <button class="toggle-sidebar-button" mat-icon-button
                    [matMenuTriggerFor]="menu">
                <mat-icon  style="color:#0066ff!important" svgIcon="menu"></mat-icon>
            </button>

            <mat-menu #menu="matMenu" [overlapTrigger]="false">
                <custom-menu [position]="menuPosition"
                             itemClass="mat-menu-item"></custom-menu>
            </mat-menu>
        </ng-container>
    </div>

    <custom-menu [position]="menuPosition" [horizontal]="true"
                 [class.color-accent]="color === 'accent'"
                 *ngIf="!(mobileOrTablet$ | async)"></custom-menu>

    <div class="right-side-actions" *ngIf="!hideRightSideActions">
        <ng-content select="[rightContent]"></ng-content>
        <notification-button  style="color:#0066ff!important"
            *ngIf="!isMobile() && config.get('notifications.integrated') && currentUser.isLoggedIn$ | async"></notification-button>

        <ng-container *ngIf="searchInput && (mobileOrTablet$ | async)">
            <button mat-icon-button class="mobile-search-toggle"
                    (click)="openMobileSearch()"
                    *ngIf="!(mobileSearchActive$ | async); else closeToggle">
                <mat-icon svgIcon="search"  style="color:#0066ff!important"></mat-icon>
            </button>
            <ng-template #closeToggle>
                <button mat-icon-button class="mobile-search-toggle"
                        (click)="closeMobileSearch()">
                    <mat-icon style="color:#0066ff!important" svgIcon="close"></mat-icon>
                </button>
            </ng-template>
        </ng-container>
        <logged-in-user-widget
            [hideRegisterButton]="hideRegisterButton"></logged-in-user-widget>
    </div>
</div>
