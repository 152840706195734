<auth-page infoRowTarget="signin">
    <form (ngSubmit)="register()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
        <div class="font-semibold mb-[6px] text-[24px]">
            {{ route.snapshot.data.message || 'Create a new account' }}
        </div>

        <div class="flex w-full flex-col items-center gap-[12px]">
            <div class="flex flex-col w-full">
                <label for="email" class="text-[16px] mb-1">Email</label>
                <input type="email" style="font-size: 16px" placeholder="Email"
                       formControlName="email" id="email" required
                       class="placeholder:text-[16px] text-[16px] placeholder:text-gray-300 placeholder:pt-4 h-[48px] ml-0 mr-0 active:bg-gray-50 border border-[1px] border-gray-300 text-gray-900 text-sm rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                <p class="error" *ngIf="errors.email">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.email}}</span>
                </p>
            </div>

            <div class="flex flex-col w-full">
                <label for="register_password" class="text-[16px] mb-1">Password</label>
                <input type="password" style="font-size: 16px" placeholder="Password"
                       formControlName="password" id="register_password" required
                       class="placeholder:text-[16px] text-[16px] placeholder:text-gray-300 placeholder:pt-4 h-[48px] ml-0 mr-0 active:bg-gray-50 border border-[1px] border-gray-300 text-gray-900 text-sm rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">

                <p class="error" *ngIf="errors.password">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.password}}</span>
                </p>
            </div>

            <div class="flex flex-col w-full">
                <label for="register_password_confirmation" class="text-[16px] mb-1">Confirm Password</label>
                <input type="password" style="font-size: 16px" placeholder="Password"
                       formControlName="password_confirmation" id="register_password_confirmation" required
                       class="placeholder:text-[16px] text-[16px] placeholder:text-gray-300 placeholder:pt-4 h-[48px] ml-0 mr-0 active:bg-gray-50 border border-[1px] border-gray-300 text-gray-900 text-sm rounded-[8px] focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
            </div>

            <div class="flex flex-col w-full" *ngIf="settings.get('envato.enable') && settings.get('envato.require_purchase_code')">
                <label for="purchase_code" trans>Envato Purchase Code</label>
                <input type="text" formControlName="purchase_code" id="purchase_code" required>
                <p class="error" *ngIf="errors.purchase_code">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span>{{errors.purchase_code}}</span>
                </p>
            </div>
        </div>

        <div class="policies" *ngIf="registerPolicies.length">
            <mat-checkbox name="remember-me" class="policy-checkbox" *ngFor="let policy of registerPolicies" [formControlName]="policy.id" color="accent" required trans>
                <span trans>I accept the</span>&ngsp;
                <a [href]="policy.type === 'link' ? policy.action : (settings.getBaseUrl() + policy.action)" *ngIf="policy.action" target="_blank">
                    <strong>{{policy.label}}</strong>
                </a>
                <strong *ngIf=!policy.action>{{policy.label}}</strong>
            </mat-checkbox>
        </div>

        <div class="my-[12px]">
            <mat-checkbox name="remember-me" class="my-[24px]" color="accent"
                          formControlName="remember" style="font-size:16px;" trans>Stay signed in for a month
            </mat-checkbox>
        </div>

        <button type="submit" style="font-size:16px"
                [disabled]="loading$ | async" trans
                class="w-full text-[16px] h-[48px] my-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-[8px] text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
            Create Account
        </button>


        <ng-container *ngIf="!settings.get('registration.disable') && false">
            <button type="button" class="no-style alternative-signin-method" (click)="socialAuth.loginWith('envato')" *ngIf="settings.get('social.envato.enable'); else defaultSocialAuth">
                <mat-icon svgIcon="envato-custom"></mat-icon>
                <span trans>Sign in with Envato instead</span>
            </button>
            <ng-template #defaultSocialAuth>
                <div class="social-icons center">
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('facebook')" *ngIf="settings.get('social.facebook.enable')" class="social-icon no-style facebook" [matTooltip]="'Login with facebook' | trans">
                        <mat-icon svgIcon="facebook"></mat-icon>
                    </button>
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('google')" *ngIf="settings.get('social.google.enable')" class="social-icon no-style google" [matTooltip]="'Login with google' | trans">
                        <mat-icon svgIcon="google"></mat-icon>
                    </button>
                    <button type="button" mat-icon-button (click)="socialAuth.loginWith('twitter')" *ngIf="settings.get('social.twitter.enable')" class="social-icon no-style twitter" [matTooltip]="'Login with twitter' | trans">
                        <mat-icon svgIcon="twitter"></mat-icon>
                    </button>
                </div>
            </ng-template>
        </ng-container>
    </form>
</auth-page>
