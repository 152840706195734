<div class="fixed top-0 left-0 z-50">
    <a href="/pricing"
       class="group left-0 z-50 h-[54px] w-screen cursor-pointer items-center justify-center overflow-hidden bg-[#0066ff] text-white md:flex">
        <div
            class="mx-auto text-[16px] flex flex-row items-center justify-center space-x-3">
            <div class="flex flex-row space-x-1"><p class="flex flex-row font-semibold">
                Black Friday is here! Save a huge 91% OFF all annual plans!</p></div>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
            </svg>
        </div>
    </a>
    <nav
        class=" flex items-center h-[64px] py-[32px] pr-[24px] opacity-95 w-full bg-white transition-all duration-100 lg:h-16 undefined backdrop-blur-lg backdrop-saturate-150 backdrop-filter border-opacity-0 border-b border-black">
        <div class="mx-4 w-full lg:mx-10 xl:mx-32">
            <div class="mx-auto flex w-screen-xl items-center justify-between">
                <div
                    class="flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-start space-x-4 lg:space-x-0">
                    <a class="flex flex-shrink-0 pl-4" href="/">
                        <img
                            class="select-none"
                            src="/assets/images/homepage/AyrDrive.svg"
                            alt="AyrDrive logo"/>
                    </a>
                </div>
                <div
                    class="space-x-[16px] inline-flex text-[16px] font-medium text-[#4D5358]">
                    <a class="sm:hidden md:block whitespace-nowrap py-1.5 px-[12px] transition duration-150 ease-in-out text-[#4D5358] hover:text-[#0066ff]"
                       href="/pricing">Pricing</a>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 items-center transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-lg">
                        <span>Products</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none absolute top-full left-1/2 z-50 w-[196px] -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div
                                class="relative grid gap-0 whitespace-nowrap lg:grid-cols-1">
                                <a class="flex flex-row justify-start rounded-lg py-2 px-[12px] text-[18px] font-medium text-[#343a3f] hover:bg-cool-gray-5"
                                   href="/drive-info">AyrDrive Drive</a>
                                <a class="flex flex-row justify-start rounded-lg py-2 px-[12px] text-[18px] font-medium text-[#343a3f] hover:bg-cool-gray-5"
                                   href="/photos">AyrDrive Photos</a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-lg items-center">
                        <span>Our Values</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none text-[#4D5358] absolute top-full left-1/2 z-50 w-52 -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div class="relative grid gap-0 lg:grid-cols-1"><a
                                class="flex flex-row justify-start rounded-lg py-2 px-[12px] text-[18px] font-medium text-[#343a3f] hover:bg-cool-gray-5"
                                href="/privacy">Privacy</a><a
                                class="flex flex-row justify-start rounded-lg py-2 px-[12px] text-[18px] font-medium text-[#343a3f] hover:bg-cool-gray-5"
                                href="/open-source">Open Source</a></div>
                        </div>
                    </div>
                    <a class="whitespace-nowrap text-[#4D5358] py-1.5 px-[12px] transition duration-150 ease-in-out hover:text-[#0066ff] font-medium"
                       href="/about">About Us</a>
                    <div
                        class="flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-end">
                        <a id="loginButton" href="/login"
                           class="mr-2 whitespace-nowrap rounded-lg border py-[4px] px-3 transition duration-150 ease-in-out focus:border focus:outline-none md:flex border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff] hover:bg-opacity-10 active:border-[#0066ff]-dark active:text-[#0066ff]-dark text-[14px] font-medium">
                            Log in
                        </a>
                        <a href="/register"
                           class="flex justify-center rounded-lg border border-transparent py-[4px] px-3 text-[14px] font-medium focus:outline-none sm:inline-flex bg-[#0066ff] text-white hover:bg-[#0066ff]-dark active:bg-[#0066ff]-dark transition-all duration-75">
                            <p class="whitespace-nowrap">Get started</p></a>

                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>


<div class="hidden flex-col pt-[128px] text-[16px] lg:flex">
    <div
        class="flex flex-col items-center justify-center bg-gradient-to-b from-[#0066ff] to-[#0055ee] py-[80px]">
        <h1 class="mb-8 select-none text-[60px] font-semibold text-white">Terms of
            Service</h1></div>
    <div class="flex flex-row items-start space-x-16 py-[80px] px-24">
        <div
            class="top-[200px] hidden max-h-[450px] w-max flex-col space-y-6 overflow-y-scroll rounded-lg border border-gray-10 px-6 py-9 pr-20 lg:flex">
            <div class="flex flex-col space-y-6">
                <a routerLink="/legal" fragment="Terms and Conditions Overview"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Terms and Conditions Overview</a>
                <a routerLink="/legal" fragment="Eligibility and Authority"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Eligibility and Authority</a>
                <a routerLink="/legal" fragment="Open Source Software"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Open Source Software</a>
                <a routerLink="/legal" fragment="Use of the Services"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Use of the Services</a>
                <a routerLink="/legal" fragment="Modifications to the Services"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Modifications to the Services</a>
                <a routerLink="/legal"
                   fragment="Repeat Infringer Policy and Copyright Complaints"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Repeat Infringer Policy and Copyright Complaints</a>
                <a routerLink="/legal" fragment="Trademarks"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Trademarks</a>
                <a routerLink="/legal" fragment="Third-Party Content"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Third-Party Content</a>
                <a routerLink="/legal" fragment="User Conduct"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    User Conduct</a>
                <a routerLink="/legal" fragment="User Content"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    User Content</a>
                <a routerLink="/legal" fragment="Feedback"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Feedback</a>
                <a routerLink="/legal" fragment="Indemnification"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Indemnification</a>
                <a routerLink="/legal" fragment="Disclaimers"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Disclaimers</a>
                <a routerLink="/legal" fragment="Limitation of Liability"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Limitation of Liability</a>
                <a routerLink="/legal" fragment="Release"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Release</a>
                <a routerLink="/legal" fragment="Governing Law and Venue"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Governing Law and Venue</a>
                <a routerLink="/legal" fragment="Severability"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Severability</a>
                <a routerLink="/legal" fragment="Termination or Suspension"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Termination or Suspension</a>
                <a routerLink="/legal" fragment="Amendment"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Amendment</a>
                <a routerLink="/legal" fragment="Service Level"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Service Level</a>
                <a routerLink="/legal" fragment="Storage Platform"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Storage Platform</a>
                <a routerLink="/legal" fragment="Storage Materials"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Storage Materials</a>
                <a routerLink="/legal" fragment="Payment"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Payment</a>
                <a routerLink="/legal" fragment="Effect of Suspension or Termination"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Effect of Suspension or Termination</a>
                <a routerLink="/legal" fragment="Miscellaneous"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Miscellaneous</a>
                <a routerLink="/legal" fragment="Privacy Policy Overview"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Privacy Policy Overview</a>
                <a routerLink="/legal" fragment="Information you provide to us"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Information you provide to us</a>
                <a routerLink="/legal"
                   fragment="Information collected by cookies and other tracking technologies via our services."
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Information collected by cookies and other tracking technologies via
                    our services.</a>
                <a routerLink="/legal" fragment="Security"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Security</a>
                <a routerLink="/legal"
                   fragment="Transfer of information to governing authorities"
                   class="cursor-pointer text-[16px] font-medium text-gray-60 hover:text-[#0066ff]">
                    Transfer of information to governing authorities</a></div>
        </div>
        <div
            class="mb-10 flex flex-col rounded-lg border border-gray-10 drop-shadow lg:hidden">
            <div class="flex flex-col">
                <div class="mt-1 flex flex-col">
                    <button
                        class="focus-visible:border-indigo-500 focus-visible:ring-offset-orange-300 flex w-full cursor-default flex-row items-center justify-between rounded-lg py-2 px-5 text-[16px] focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm"
                        id="headlessui-menu-button-61" type="button" aria-haspopup="true"
                        aria-expanded="false"><p>Terms and Conditions Overview</p><span><svg
                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        fill="currentColor" viewBox="0 0 256 256"
                        class="pointer-events-none flex items-center"><path
                        d="M213.66,101.66l-80,80a8,8,0,0,1-11.32,0l-80-80A8,8,0,0,1,53.66,90.34L128,164.69l74.34-74.35a8,8,0,0,1,11.32,11.32Z"></path></svg></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-col space-y-8">
            <div class="flex flex-row items-center space-x-2 text-[16px] text-gray-80">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                     fill="currentColor" viewBox="0 0 256 256">
                    <path
                        d="M208,32H184V24a8,8,0,0,0-16,0v8H88V24a8,8,0,0,0-16,0v8H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32ZM72,48v8a8,8,0,0,0,16,0V48h80v8a8,8,0,0,0,16,0V48h24V80H48V48ZM208,208H48V96H208V208Z"></path>
                </svg>
                <p>Last updated: March 2023</p></div>
            <div class="border border-gray-10"></div>
            <div class="flex scroll-smooth">
                <div class="flex flex-col">
                    <div class="mb-[64px] flex flex-col"><p
                        class="mb-3 text-[24px] font-medium"
                        id="Terms and Conditions Overview">
                        Terms and Conditions Overview</p>
                        <p class="mb-[24px]">These Terms and conditions (“conditions”) are
                            the
                            Terms and conditions that regulate access to and use of the
                            website and services that AyrDrive Universal Technologies SL
                            (“AyrDrive”), a company registered in La Marina de Valencia,
                            Muelle de la Aduana s/n, Edificio Lanzadera, 46024 Valencia,
                            Spain, puts at the disposal of its users and clients. These
                            Terms and conditions shall be governed and construed under
                            Spanish Law and shall be subject to that exclusive
                            jurisdiction should any dispute, claim or disagreement arise
                            from them. AyrDrive may update and amend these Terms and
                            Conditions at any time and without prior notice, to adapt them
                            to current regulations and to technological or business
                            changes. The User should frequently verify the Terms and
                            Conditions to ensure they always have the most up to date
                            version. By accessing or using the websites, mobile
                            applications and online services of AyrDrive, including
                            AyrDrive.com, (collectively, the “Services”) you will be bound
                            by these Terms of Use (“Terms”) and all terms incorporated by
                            reference. If you do not agree to these Terms, you may not
                            access or use the Services. If you have any questions
                            regarding these Terms, please contact us at
                            hello@AyrDrive.com. Please refer to our Privacy Policy for
                            information about how we collect, use and disclose information
                            about you.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Eligibility and Authority">
                        Eligibility and Authority</h2>
                        <p class="mb-[24px]">You must be 18 years of age or older to
                            access or
                            use our Services. If you are using the Services on behalf of
                            any entity: (a) you represent and warrant that you are
                            authorized to accept these Terms on such entity’s behalf and
                            that such entity will be responsible for breach of these Terms
                            by you or any other employee or agent of such entity; and (b)
                            references to “you” in these Terms refer to you and such
                            entity, jointly.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Open Source Software">Open
                        Source Software</h2>
                        <p class="mb-[24px]">We may make the source code for much of the
                            software that supports our Services, including the Storage
                            Platform, available for download as open source software. You
                            agree to be bound by, and comply with, any license agreement
                            that applies to this open source software. You will not
                            indicate that you are associated with AyrDrive in connection
                            with any of your modifications or distributions of this open
                            source software. The source code we release in connection with
                            open source software is not part of the Services, and your use
                            of that source code without interacting with our Services is
                            not subject to these Terms. For clarity, though, when we host
                            any software and enable you to access and use such software as
                            a service through our websites and apps, then these Terms will
                            apply to such access and use.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Use of the Services">Use of
                        the Services</h2>
                        <p class="mb-[24px]">The Services, including all text, videos,
                            images,
                            data, software, or other files, content, and materials
                            contained on the Services, are the proprietary property of
                            AyrDrive and our licensors. Subject to your compliance with
                            these Terms, you may use the Services on a non-exclusive basis
                            for your own personal use. Nothing in these Terms will be
                            construed as conferring any right or license to any patent,
                            trademark, copyright or other proprietary rights of AyrDrive
                            or any third party, whether by estoppel, implication or
                            otherwise. To be clear, we do not claim any ownership over the
                            content you submit via the Services.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Modifications to the Services">
                        Modifications to the Services</h2>
                        <p class="mb-[24px]">We reserve the right, in our sole discretion,
                            to
                            modify the Services (or any features or functionality of the
                            Services) at any time without notice and without obligation or
                            liability to you.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Repeat Infringer Policy and Copyright Complaints">
                        Repeat Infringer Policy and Copyright Complaints</h2>
                        <p class="mb-[24px]">We have a policy of limiting access to the
                            Service
                            and terminating the accounts of users who infringe the
                            intellectual property rights of others. If you believe that
                            anything on the Services infringes any copyright that you own
                            or control, you may notify us using the email address
                            hello@AyrDrive.com.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Trademarks">Trademarks</h2>
                        <p class="mb-[24px]">“AyrDrive,” the Company logo, any other
                            Company
                            service names, logos or slogans that may appear on the
                            Services, and the look and feel of the Services, including all
                            page headers, custom graphics, button icons and scripts, are
                            trademarks, service marks or trade dress of AyrDrive and our
                            suppliers and our licensors, and may not be copied, imitated
                            or used, in whole or in part, without our or the applicable
                            trademark holder’s prior written permission. You may not use
                            any meta tags or other “hidden text” utilizing “AyrDrive” or
                            any other name, trademark or product or service name of
                            AyrDrive without our prior written permission. Further, you
                            may not use, frame or utilize framing techniques to enclose
                            any AyrDrive trademark, logo or other proprietary information,
                            including the images found on the Services, the content of any
                            text or the layout or design of any page, or form contained on
                            a page, on the Services without our express written
                            consent.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Third-Party Content">
                        Third-Party Content</h2>
                        <p class="mb-[24px]">We may display third-party content,
                            advertisements, links, promotions, logos, and other materials
                            through the Services (collectively, “Third-Party Content”). We
                            do not control, endorse, sponsor or adopt any third parties
                            referenced on the Services or Third-Party Content, and we make
                            no representations or warranties of any kind regarding such
                            Third-Party Content, including, without limitation, regarding
                            its accuracy or completeness. Your interactions with
                            Third-Party Content, and any third party that provides
                            Third-Party Content, are solely between you and such third
                            parties, and AyrDrive is not responsible or liable in any
                            manner for such interactions or Third-Party Content. When you
                            leave the Services and enter a third-party site or service,
                            our terms and policies no longer apply.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="User Conduct">User
                        Conduct</h2>
                        <p class="mb-[24px]">You will comply with all applicable laws in
                            connection with your access and use of the Services, and you
                            are solely responsible for your conduct while accessing or
                            using the Services. Further, you will not: engage in conduct
                            that is harassing, threatening, intimidating, or stalking, or
                            that we otherwise deem objectionable; use the Services in any
                            manner that could interfere with, disrupt, negatively affect
                            or inhibit other users from fully enjoying the Services or
                            that could damage, disable, overburden or impair the
                            functioning of the Services in any manner; reverse engineer
                            any aspect of the Services or do anything that might discover
                            the source code of the Services (other than in connection with
                            open source software we release); violate, infringe or
                            misappropriate any intellectual property or other third-party
                            right or commit a tort; modify, copy (other than standard page
                            caching), publicly perform, publicly display, sell, rent,
                            lease, timeshare or otherwise distribute the Services, in
                            whole or in part. This restriction does not apply to open
                            source software we release, which you can use subject to the
                            applicable open source software license terms; attempt to
                            bypass or circumvent measures employed to prevent or limit
                            access to any content, area or functionality on the Services,
                            without providing prior notice to AyrDrive of the method used
                            to bypass or circumvent; use any of the Services other than
                            for its intended purposes; or use the Services to engage in or
                            promote any activity that violates these Terms.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="User Content">User
                        Content</h2>
                        <p class="mb-[24px]">(a) The Services include areas that allow
                            users to
                            create, post, upload, share or store content, including, but
                            not limited to, photos, videos, sound, text, graphics, code,
                            items or other information and materials, including Storage
                            Materials (collectively, “User Content”). “Storage Materials”
                            refers to any User Content provided or otherwise processed via
                            our Services for storage on the Storage Platform.</p>
                        <p class="mb-[24px]">(b) We do not own, control or endorse any
                            User
                            Content that is transmitted, stored, or processed via the
                            Services. You are solely responsible for any of your User
                            Content and for your use of any interactive features and areas
                            of the Services, and you represent and warrant that (i) you
                            own all intellectual property rights (or have obtained all
                            necessary permissions) to provide your User Content and to
                            grant the licenses in this Agreement; (ii) your User Content
                            will not violate any agreements or confidentiality
                            obligations; and (iii) your User Content will not violate,
                            infringe or misappropriate any intellectual property right or
                            other proprietary right, including the right of publicity or
                            privacy, of any person or entity.</p>
                        <p class="mb-[24px]">(c) You will not create, post, share or store
                            User
                            Content that: is unlawful, libelous, defamatory, harassing,
                            threatening, invasive of privacy or publicity rights, abusive,
                            inflammatory, fraudulent or otherwise objectionable; would
                            constitute, encourage or provide instructions for a criminal
                            offense, violate the rights of any party, otherwise create
                            liability or violate any local, state, national or
                            international law; contains or depicts any statements, remarks
                            or claims that do not reflect your honest views and
                            experiences; impersonates, or misrepresents your affiliation
                            with, any person or entity (including AyrDrive); references or
                            depicts AyrDrive or our Services but fails to disclose any
                            material connection to us that may exist; contains any
                            unsolicited promotions, political campaigning, advertising or
                            solicitations; contains any viruses, corrupted data or other
                            harmful, disruptive or destructive files or content; or in our
                            sole judgment, is objectionable or that restricts or inhibits
                            any other person from using or enjoying the Services, or that
                            may expose AyrDrive or others to any harm or liability of any
                            type.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Feedback">Feedback</h2>
                        <p class="mb-[24px]">You may submit questions, comments,
                            suggestions,
                            ideas, original or creative materials or other information
                            about AyrDrive or the Services to us (collectively,
                            “Feedback”). Feedback does not include User Content. We
                            reserve the right to use Feedback for any purpose without
                            compensation to you. Do not send us Feedback if you expect to
                            be paid or want to continue to own or claim rights in them;
                            your idea might be great, but we may have already had the same
                            or a similar idea and we do not want disputes.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Indemnification">
                        Indemnification</h2>
                        <p class="mb-[24px]">(a) To the fullest extent permitted by
                            applicable
                            law, you will indemnify, defend and hold harmless AyrDrive and
                            our respective past, present and future employees, officers,
                            directors, contractors, consultants, equity holders,
                            suppliers, vendors, service providers, parent companies,
                            subsidiaries, affiliates, agents, representatives,
                            predecessors, successors and assigns (the “Company Parties”)
                            from and against all claims, damages, costs and expenses
                            (including attorneys’ fees) that arise from or relate to: (i)
                            your access to or use of our Services; (ii) your User Content
                            (including any Storage Materials); (iii) any Feedback you
                            provide; or (iv) your violation of these Terms.</p>
                        <p class="mb-[24px]">(b) AyrDrive reserves the right to exercise
                            sole
                            control over the defense, at your expense, of any claim
                            subject to indemnification. This indemnity is in addition to,
                            and not in lieu of, any other indemnities set forth in a
                            written agreement between you and AyrDrive.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Disclaimers">Disclaimers</h2>
                        <p class="mb-[24px]">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE
                            LAW
                            AND EXCEPT AS OTHERWISE SPECIFIED IN THE AGREEMENT, (A) THE
                            SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS
                            WITHOUT WARRANTIES OF ANY KIND, AND WE EXPRESSLY DISCLAIM ALL
                            IMPLIED WARRANTIES AS TO THE SERVICES, INCLUDING, WITHOUT
                            LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR
                            A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT; (B) WE DO
                            NOT REPRESENT OR WARRANT THAT THE SERVICES ARE ACCURATE,
                            COMPLETE, RELIABLE, CURRENT OR ERROR-FREE, MEET YOUR
                            REQUIREMENTS, OR THAT DEFECTS IN THE SERVICES WILL BE
                            CORRECTED; AND (C) WE CANNOT AND DO NOT REPRESENT OR WARRANT
                            THAT THE SERVICES OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER
                            HARMFUL COMPONENTS.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Limitation of Liability">
                        Limitation of Liability</h2>
                        <p class="mb-[24px]">(A) TO THE FULLEST EXTENT PERMITTED BY
                            APPLICABLE
                            LAW: (I) IN NO EVENT WILL AyrDrive OR ANY OF THE COMPANY
                            PARTIES BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
                            CONSEQUENTIAL, OR EXEMPLARY DAMAGES OF ANY KIND (INCLUDING,
                            BUT NOT LIMITED TO, WHERE RELATED TO LOSS OF REVENUE, INCOME
                            OR PROFITS, LOSS OF USE OR DATA, OR DAMAGES FOR BUSINESS
                            INTERRUPTION) ARISING OUT OF OR IN ANY WAY RELATED TO THE
                            ACCESS OR USE OF THE SERVICES OR OTHERWISE RELATED TO THE
                            AGREEMENT, REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN
                            CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO, SIMPLE
                            NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR ANY OTHER
                            LEGAL OR EQUITABLE THEORY (EVEN IF THE PARTY HAS BEEN ADVISED
                            OF THE POSSIBILITY OF SUCH DAMAGES AND REGARDLESS OF WHETHER
                            SUCH DAMAGES WERE FORESEEABLE); AND (II) IN NO EVENT WILL THE
                            AGGREGATE LIABILITY OF AyrDrive AND THE COMPANY PARTIES
                            (JOINTLY), WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING
                            NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR OTHER
                            THEORY, ARISING OUT OF OR RELATING TO THE AGREEMENT OR THE USE
                            OF OR INABILITY TO USE THE SERVICES, EXCEED THE GREATER OF ANY
                            COMPENSATION YOU PAY TO US FOR USE OF THE SERVICES OR
                            €100.</p>
                        <p class="mb-[24px]">(B) THE LIMITATIONS SET FORTH IN SECTION
                            15(A)
                            WILL NOT LIMIT OR EXCLUDE LIABILITY FOR THE GROSS NEGLIGENCE,
                            FRAUD OR INTENTIONAL, WILLFUL OR RECKLESS MISCONDUCT OF
                            AyrDrive.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Release">Release</h2>
                        <p class="mb-[24px]">To the fullest extent permitted by applicable
                            law,
                            you release AyrDrive and the other Company Parties from
                            responsibility, liability, claims, demands and/or damages
                            (actual and consequential) of every kind and nature, known and
                            unknown (including, but not limited to, claims of negligence),
                            arising out of or related to disputes between users and the
                            acts or omissions of third parties.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Dispute Resolution and Arbitration">
                        Dispute Resolution and Arbitration</h2>
                        <p class="mb-[24px]">PLEASE READ THE FOLLOWING SECTION CAREFULLY
                            BECAUSE IT REQUIRES YOU TO ARBITRATE CERTAIN DISPUTES AND
                            CLAIMS WITH AyrDrive AND LIMITS THE MANNER IN WHICH YOU CAN
                            SEEK RELIEF FROM US.</p>
                        <p class="mb-[24px]">Except for any disputes, claims, suits,
                            actions,
                            causes of action, demands or proceedings (collectively,
                            “Disputes”) in which either party seeks to bring an individual
                            action in small claims court or seeks injunctive or other
                            equitable relief for the alleged unlawful use of intellectual
                            property, including, without limitation, copyrights,
                            trademarks, trade names, logos, trade secrets or patents, you
                            and AyrDrive (a) waive your and AyrDrive respective rights to
                            have any and all Disputes arising from or related to this
                            Agreement or the Services resolved in a court, and (b) waive
                            your and AyrDrive's respective rights to a jury trial.
                            Instead, you and AyrDrive will arbitrate Disputes through
                            binding arbitration (which is the referral of a Dispute to one
                            or more persons charged with reviewing the Dispute and making
                            a final and binding determination to resolve it instead of
                            having the Dispute decided by a judge or jury in court).</p>
                        <p class="mb-[24px]">Any Dispute arising out of or related to this
                            Agreement or the Services is personal to you and AyrDrive, and
                            will be resolved solely through individual arbitration and
                            will not be brought as a class arbitration, class action or
                            any other type of representative proceeding. There will be no
                            class arbitration or arbitration in which an individual
                            attempts to resolve a Dispute as a representative of another
                            individual or group of individuals. Further, a Dispute cannot
                            be brought as a class or other type of representative action,
                            whether within or outside of arbitration, or on behalf of any
                            other individual or group of individuals.</p>
                        <p class="mb-[24px]">Each party will notify the other party in
                            writing
                            of any arbitrable or small claims Dispute within thirty (30)
                            days of the date it arises so that the parties can attempt in
                            good faith to resolve the Dispute informally. Notice to
                            AyrDrive will be sent by e-mail to AyrDrive at
                            hello@AyrDrive.com. Notice to you will be by email to the
                            then-current email address in your Account. Your notice must
                            include (a) your name, postal address, email address and
                            telephone number, (b) a description in reasonable detail of
                            the nature or basis of the Dispute, and (c) the specific
                            relief that you are seeking. If you and AyrDrive cannot agree
                            on how to resolve the Dispute within thirty (30) days after
                            the date notice is received by the applicable party, then
                            either you or AyrDrive may, as appropriate and in accordance
                            with this Section 17, commence an arbitration proceeding or,
                            to the extent specifically provided for in Section 17.1, file
                            a claim in court.</p>
                        <p class="mb-[24px]">Any arbitration will occur in Spain.
                            Arbitration
                            will be conducted confidentially by a single arbitrator in
                            accordance with the rules of the Judicial Arbitration and
                            Mediation Services (“JAMS”), which are hereby incorporated by
                            reference. The state and federal courts located in Spain will
                            have exclusive jurisdiction over any appeals and the
                            enforcement of an arbitration award. You may also litigate a
                            Dispute in the small claims court located in the county where
                            you reside if the Dispute meets the requirements to be heard
                            in small claims court.</p>
                        <p class="mb-[24px]">This Agreement and the applicable JAMS rules,
                            the
                            arbitrator will have (a) the exclusive authority and
                            jurisdiction to make all procedural and substantive decisions
                            regarding a Dispute, including the determination of whether a
                            Dispute is arbitrable, and (b) the authority to grant any
                            remedy that would otherwise be available in court; provided,
                            however, that the arbitrator does not have the authority to
                            conduct a class arbitration or a representative action, which
                            is prohibited by these Terms. The arbitrator may only conduct
                            an individual arbitration and may not consolidate more than
                            one individual’s claims, preside over any type of class or
                            representative proceeding or preside over any proceeding
                            involving more than one individual.</p>
                        <p class="mb-[24px]">The rules of JAMS and additional information
                            about
                            JAMS are available on the JAMS website. By agreeing to be
                            bound by these Terms, you either (a) acknowledge and agree
                            that you have read and understood the rules of JAMS, or (b)
                            waive your opportunity to read the rules of JAMS and any claim
                            that the rules of JAMS are unfair or should not apply for any
                            reason.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Governing Law and Venue">
                        Governing Law and Venue</h2>
                        <p class="mb-[24px]">This Agreement and your access to and use of
                            the
                            Services will be governed by and construed and enforced in
                            accordance with the laws of Spain, without regard to conflict
                            of law rules or principles (whether of Spain any other
                            jurisdiction) that would cause the application of the laws of
                            any other jurisdiction. Any Dispute between the parties
                            arising out or relating to this Agreement that is not subject
                            to arbitration or cannot be heard in small claims court will
                            be resolved in the courts of the Spain.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Severability">
                        Severability</h2>
                        <p class="mb-[24px]">If any term, clause or provision of this
                            Agreement
                            is held invalid or unenforceable, then that term, clause or
                            provision will be severable from this Agreement and will not
                            affect the validity or enforceability of any remaining part of
                            that term, clause or provision, or any other term, clause or
                            provision of this Agreement.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Termination or Suspension">
                        Termination or Suspension</h2>
                        <p class="mb-[24px]">Notwithstanding anything contained in this
                            Agreement, we reserve the right, without notice and in our
                            sole discretion, to terminate this Agreement or suspend your
                            right to access the Services. You may terminate this Agreement
                            without notice by discontinuing use of the Services. All
                            rights and licenses granted to you under this Agreement will
                            immediately be revoked upon our termination of the Agreement
                            or our suspension of your access to the Services, and you must
                            immediately submit payment for any fees payable to AyrDrive
                            under the Agreement (as specified in the Storage Terms).
                            AyrDrive users can pay for a lifetime plan. A lifetime plan is
                            in effect for the duration of the account owner or 99 years,
                            whichever is shorter. Lifetime accounts do not come with a
                            standard 30 day money-back guarantee, which covers the rest of
                            our plans. If a user loses access to his or her lifetime
                            account, that lifetime account will be deemed as lost.
                            AyrDrive might sometimes offer “infinite” or “unlimited”
                            lifetime memberships. These memberships are regulated under a
                            fair-use policy, under which no user might use above 99999 GB
                            of storage. AyrDrive reserves the right to prevent or stop any
                            customer’s use of AyrDrive services that appears to be abusive
                            or is unduly burdensome. For more information on how this
                            policy applies to your service plan, please contact us.</p>
                    </div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Amendment">Amendment</h2>
                        <p class="mb-[24px]">We reserve the right to change the terms of
                            this
                            Agreement from time to time in our sole discretion. If we make
                            changes to the Agreement, we will provide notice of such
                            changes, such as by posting the revised Agreement terms to the
                            Services and updating the “Last Updated” date at the top of
                            these Terms and the Storage Terms. Your continued use of the
                            Services will confirm your acceptance of the revised
                            Agreement. If you do not agree to the modified Agreement, you
                            must stop using the Services.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Service Level">Service
                        Level</h2>
                        <p class="mb-[24px]">(a) AyrDrive will use commercially reasonable
                            efforts to meet the following service level commitment: except
                            for scheduled maintenance, our Storage Services will be
                            available 99.99% of the time. We calculate availability based
                            upon the service records we maintain. We will use reasonable
                            efforts to notify you in advance of any scheduled
                            maintenance.</p>
                        <p class="mb-[24px]">(b) Our SLA obligations do not extend to any
                            unavailability of the Storage Services that is caused by: (i)
                            any hardware or software that you use in connection with the
                            Storage Services; (ii) misuse of our Storage Services or the
                            Storage Platform, including use in breach of the Agreement or
                            use other than in accordance with any documentation or ther
                            instructions provided by AyrDrive; (iii) circumstances or
                            events beyond the reasonable control of AyrDrive; or (iv) our
                            suspension or termination of your access to the Storage
                            Services pursuant to the rights we have reserved under the
                            Agreement.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Storage Platform">Storage
                        Platform</h2>
                        <p class="mb-[24px]">The Storage Services enable you to store data
                            and
                            other materials (“Storage Materials”) via a distributed
                            network of third-party devices (“Storage Nodes”) that comprise
                            the Storage Platform. The Storage Platform is an
                            interconnected set of Storage Nodes that independently operate
                            open-source software to facilitate the storage of your Storage
                            Materials. Users acting as Storage Nodes acknowledge that they
                            must comply with EU GDPR Privacy Law. The Storage Services are
                            designed to encrypt Storage Materials locally on your device
                            and then facilitate the distribution of such encrypted data
                            fragments to the Storage Nodes. The Storage Platform and
                            Storage Nodes are not operated or maintained by AyrDrive, and
                            AyrDrive has no responsibility or liability for the Storage
                            Platform or Storage Nodes. The Storage Services enable you to
                            protect against loss of your Storage Materials due to the
                            failure of any individual Storage Node by allowing you to
                            configure the redundancy and distribution of your Storage
                            Materials. You are solely responsible for your use of the
                            Storage Platform, including for configurations that you deem
                            appropriate to maintain access to your Storage Materials in a
                            manner that meets your expectations. In order to use the
                            Storage Services, you will need to register for an account
                            (“Account”). In connection with your creation of any Account,
                            you will (i) provide accurate, current and complete Account
                            information, (ii) maintain and promptly update your Account
                            information as necessary, (iii) maintain the security of your
                            Account, including by not using the same password for the
                            Storage Services that you use for third-party services, (iv)
                            not disclose your password or private keys to any third party,
                            and (v) immediately notify us if you discover or otherwise
                            suspect any unauthorized activities in connection with your
                            Account or the Storage Services. You acknowledge and agree
                            that if you lose your password, you may not be able to recover
                            any Storage Materials. One same person cannot hold various
                            free AyrDrive accounts. AyrDrive offers a referral program
                            where users can earn €5 worth of credit that can be used
                            inside AyrDrive's platform. By referring a friend to join
                            AyrDrive, both parties can earn €5. The person you refer must
                            be an active AyrDrive user, and can sign up in any of
                            AyrDrive's plans. AyrDrive reserves the right to review
                            referrals and disqualify any referrals that haven't been
                            active in the platform. A single user can earn a maximum of
                            €100 worth of referral credit.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Storage Materials">Storage
                        Materials</h2>
                        <p class="mb-[24px]">(a) AyrDrive will not be responsible for any
                            loss,
                            misuse, or deletion of Storage Materials or any failure of any
                            Storage Materials to be stored or encrypted. You are solely
                            responsible for backing up any Storage Materials.</p>
                        <p class="mb-[24px]">(b) You are responsible for properly
                            configuring
                            and using the Storage Services to store your Storage Materials
                            via the Storage Platform, and for maintaining appropriate
                            security of your Storage Materials, which may include the use
                            of encryption. We reserve the right at any time, without
                            notice, to remove, reject or delete any Storage Materials that
                            contain plain text data or otherwise violate the terms of this
                            Agreement (including AyrDrive Terms of Use relating to User
                            Content).</p>
                        <p class="mb-[24px]">(c) You are solely responsible for ensuring
                            that
                            any processing of Storage Materials via the Storage Services
                            is in compliance with all applicable laws. We make no
                            representations or warranties regarding the suitability of the
                            Storage Services for the processing of any particular types of
                            data. You will provide all notices to, and obtain any consents
                            from, third parties as required by applicable law in
                            connection with the processing of Storage Materials via the
                            Storage Services. You must not process any Storage Materials
                            that include sensitive data that require heightened security
                            protections, including any (i) “protected health information,”
                            as defined under HIPAA, (ii) “cardholder data,” as defined by
                            the PCI DSS, or (iii) “Sensitive Personal Data” as defined
                            under the EU Directive 95/46/EC as enacted in the member
                            states of the European Union.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Payment">Payment</h2>
                        <p class="mb-[24px]">(a) We will provide notice via the Storage
                            Services of the fees applicable to use of the Storage Services
                            ('Fees'). All Fees are exclusive of any applicable taxes
                            unless stated otherwise, which are your sole responsibility.
                            We will provide notice at least 30 days in advance of any
                            increase to the Fees.</p>
                        <p class="mb-[24px]">(b) Except where otherwise indicated via the
                            Storage Services, we will charge all Fees to a payment method
                            designated by you via the Storage Services that is acceptable
                            to us. You represent and warrant that you are authorized to
                            use the payment method you designate via the Storage Services
                            and authorize us to charge all Fees to that method. If your
                            payment method cannot be verified, is invalid, or is not
                            otherwise acceptable, your right to use the Storage Services
                            may be suspended automatically. Fees will be charged on a
                            monthly basis based on the prior month’s usage. You can avoid
                            incurring Fees at any time by discontinuing your use of the
                            Storage Services to store your Storage Materials.</p>
                        <p class="mb-[24px]">(c) Users with a free account who do not log
                            in to
                            their account for a period of one year will be considered
                            inactive. Users will receive an email notice 7 days prior to
                            reaching inactive status. Once a free account reaches that
                            status, its files will be removed from the network in order to
                            free up space for those free users who are actively using
                            their account.</p>
                        <p class="mb-[24px]">(d) Accounts on any of our premium plans who
                            fail
                            to renew their plan within a period of 7 days will be
                            terminated.</p>
                        <p class="mb-[24px]">(e) All users who buy any of AyrDrive's
                            premium
                            plans will be eligible for a 30 day refund, unless they
                            already receive a 30 day trial period of any of AyrDrive's
                            premium plan. After those first 30 days using any of our
                            premium plans have passed, no refund will be applicable. To
                            request a refund, you should reach out to us via email, at
                            hello@AyrDrive.com.</p>
                        <p class="mb-[24px]">(f) Storage Nodes agree to let AyrDrive
                            generate
                            invoices on their behalf, so that both parties can legally
                            justify the payment AyrDrive sends to Storage Nodes every
                            month. The invoice will be issued by the Storage Nodes (with
                            the personal details he specifies at sign up), to AyrDrive.
                            Storage Nodes can contact us and request those invoices at any
                            time.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Effect of Suspension or Termination">
                        Effect of Suspension or Termination</h2>
                        <p class="mb-[24px]">If your access to the Storage Services is
                            suspended or terminated, you will no longer have access to the
                            Storage Materials processed via the Storage Services for
                            storage in the Storage Platform, and your Storage Materials
                            may not be recoverable. If we terminate or suspend your access
                            to the Storage Services for reasons other than your breach of
                            the Agreement, we will make commercially reasonable efforts to
                            provide you an opportunity to retrieve your Storage Materials
                            via the Storage Services. We will terminate any free accounts
                            that have no activity for one year (this includes any account
                            that has not uploaded, downloaded, shared or modified a file
                            in a period of one year). We will also terminate accounts
                            under any of our paid plans, that fail to renew their plan
                            after 7 days from the payment renewal deadline. Appropriate
                            notice will be given to the user to try to avoid account
                            termination.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Miscellaneous">
                        Miscellaneous</h2>
                        <p class="mb-[24px]">The Agreement constitutes the entire
                            agreement
                            between you and AyrDrive relating to your access to and use of
                            our Services. We may assign our rights and obligations under
                            the Agreement. The failure of AyrDrive to exercise or enforce
                            any right or provision of the Agreement will not operate as a
                            waiver of such right or provision. We will not be liable for
                            any delay or failure to perform any obligation under the
                            Agreement where the delay or failure results from any cause
                            beyond our reasonable control. Except as otherwise provided in
                            herein, the Agreement is intended solely for the benefit of
                            the parties and are not intended to confer third-party
                            beneficiary rights upon any other person or entity.</p></div>
                    <div class="mb-[64px] flex w-full flex-col"><h2
                        class="mb-3 text-[24px] font-medium" id="Privacy Policy Overview">
                        Privacy Policy Overview</h2>
                        <p class="mb-[24px]">The Privacy Policy is part of the Legal
                            Notice
                            that governs the Web Page: www.AyrDrive.com together with the
                            Cookies Usage Policy.</p>
                        <p class="mb-[24px]">The Web page www.AyrDrive.com is owned by
                            AyrDrive
                            Universal Technologies, S.L. and complies with the
                            requirements derived from Law 34/2002, of 11 July, on
                            Information Society Services and Electronic Commerce, and
                            current regulations relating to the protection of personal
                            data and, in particular, Regulation (EU) 2016/679 of the
                            European Parliament and of the Council of 27 April 2016 on the
                            protection of natural persons with regard to the processing of
                            personal data and the free movement of such data and Organic
                            Law 3/2018, of 5 December, on the Protection of Personal Data
                            and guarantee of digital rights.</p>
                        <p class="mb-[24px]">AyrDrive Universal Technologies, S.L.
                            reserves the
                            right to modify or adapt this Privacy Policy at any time.
                            Therefore, we recommend that you review it every time you
                            access the Website. In the event that you have registered on
                            the website and access your account or profile, upon accessing
                            it, you will be informed in the event that there have been
                            substantial changes in relation to the processing of your
                            personal </p><h5 class="mb-3 text-[24px] font-medium">Who is
                            the
                            Person Responsible for the Treatment?</h5>
                        <p class="mb-[24px]">The data collected or provided voluntarily
                            through
                            the Web Page, either by browsing it, as well as all those who
                            can provide us in the contact forms, via email or telephone,
                            will be collected and processed by the File Manager, whose
                            data are indicated below:</p>
                        <div
                            class="-mx-6 flex flex-col overflow-hidden overflow-x-auto px-6">
                            <table class="table-auto bg-white">
                                <tr>
                                    <th class="border border-cool-gray-30 bg-cool-gray-5 py-2 px-8">
                                        Identity
                                    </th>
                                    <th class="border border-cool-gray-30 bg-cool-gray-5 py-2 px-8">
                                        AyrDrive Universal Technologies, S.L.
                                    </th>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">CIF
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        B98936354
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">Postal
                                        address
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">La
                                        Marina de València, Muelle de la Aduana s/n,
                                        Edificio Lanzadera, - 46024 – València
                                        (Valencia/València)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Phone
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        677753624
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        E-mail
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        hello@AyrDrive.com
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">DPD
                                        Contact:
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        dpd@prodatvalencia.es
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">URL:
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        www.AyrDrive.com
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Mercantile Registry
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Valencia/València, Tomo ..., Libro ..., Folio ...,
                                        Sección ..., Hoja V-....
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <p class="mb-[24px] mt-4">If, for any reason, you wish to contact
                            us on
                            any matter related to the processing of your personal data or
                            privacy (with our Data Protection Officer), you can do so
                            through any of the means indicated above.</p></div>
                    <div class="flex w-full flex-col" id="Cookies"><h2
                        class="mb-3 text-[24px] font-medium">Cookies</h2><h5
                        class="mb-[24px] text-[24px]">Regulations on the Use of
                        Cookies</h5>
                        <p class="mb-[24px]">This Cookies Policy is an integral part of
                            the
                            Legal Notice and Privacy Policy of AyrDrive Universal
                            Technologies, S.L.. Accessing and browsing the site, or the
                            use of its services, implies acceptance of the General
                            Conditions set out in our Legal Notice (and therefore of the
                            Privacy Policy and Cookies Policy). Please read these
                            carefully.</p>
                        <p class="mb-[24px]">Thank you for deciding to visit us. We want
                            your
                            experience on the site to be the best possible, and for that
                            reason we have drafted, in compliance with the second
                            paragraph of Article 22 of Law 34/2002, of July 11, 2002, of
                            services of the information society and electronic commerce
                            (hereinafter, LSSI), this Cookies Policy in the clearest, most
                            transparent, complete and understandable way possible.</p><h5
                            class="mb-[24px] text-[24px]">What is a Cookie?</h5>
                        <p class="mb-[24px]">This website uses cookies and/or similar
                            technologies that store and retrieve information when you
                            browse. In general, these technologies can serve a variety of
                            purposes, such as, for example, recognizing you as a user,
                            obtaining information about your browsing habits, or
                            customizing the way content is displayed.</p>
                        <p class="mb-[24px]">Cookies are files containing small amounts of
                            information that are downloaded to the user's browser or
                            device being used (smartphone, tablet or connected TV) when
                            visiting a website. Their main purpose is to store information
                            about your visit and recognize the user each time you access
                            the website. It also allows us to improve the quality and
                            usability of our website.</p>
                        <p class="mb-[24px]">Cookies are essential to the functioning of
                            the
                            Internet; they cannot harm the user's computer/device and, if
                            enabled in your browser settings, help us to identify and
                            resolve possible malfunctions of www.AyrDrive.com.</p>
                        <p class="mb-[24px]">Like most sites on the internet, this website
                            uses
                            Cookies to ensure that the website functions correctly, to
                            store preferences, such as, for example, the language you have
                            selected or the font size, to learn about the user's browsing
                            experience, to collect anonymous statistical information, such
                            as, for example, which pages the user has visited or how long
                            the user has stayed on the website.</p><h5
                            class="mb-[24px] text-[24px]">Types of Cookies</h5>
                        <p class="mb-[24px]">There are different types of cookies. They
                            all
                            work in the same way, but have small differences:</p>
                        <p class="mb-[24px]">Session Cookies: are those designed to
                            collect and
                            store data while the user accesses a web page. They are
                            usually used to store information that is only interesting to
                            keep for the provision of the service requested by the user on
                            a single occasion (for example, a list of products, a list of
                            products acquired) and disappear at the end of the
                            session.</p>
                        <p class="mb-[24px]">Session cookies last only for the duration of
                            your
                            visit and are deleted when you close your browser. Their main
                            purpose is to identify the type of device, support the
                            security of the website or its basic functionality. They do
                            not contain personal information that would allow us to
                            identify an individual.</p>
                        <p class="mb-[24px]">Persistent or Permanent Cookies: are those in
                            which the data remain stored in the terminal and can be
                            accessed and processed for a period defined by the person
                            responsible for the cookie, which can range from a few minutes
                            to several years.</p>
                        <p class="mb-[24px]">They are stored on the hard drive of the
                            device
                            and our website reads them every time you make a new visit to
                            the website; they have a specific expiration date, after which
                            the cookie stops working. They allow us to identify your
                            actions and preferences; analyze visits and help us understand
                            how users reach our site and improve our services.</p>
                        <p class="mb-[24px]">Technical Cookies, strictly necessary:
                            (exempted
                            from compliance with the obligations established in Article
                            22.2 of the LSSI when they allow to provide the service
                            requested by the user). They are those that allow the user to
                            navigate through a website, platform or application and the
                            use of the different options or services that exist in it,
                            including those that the publisher uses to enable the
                            management and operation of the website and enable its
                            functions and services, such as, for example, control traffic
                            and data communication, identify the session, access
                            restricted parts, remember the elements that make up an order,
                            make the buying process of an order, manage the payment,
                            control fraud linked to the security of the service, make the
                            request for registration or participation in an event, count
                            visits for the purpose of billing licenses of the software
                            with which the service works (website, platform or
                            application), use security features during navigation, store
                            content for broadcasting videos or sound, enable dynamic
                            content (for example, animation of loading a text or image) or
                            share content through social networks.</p>
                        <p class="mb-[24px]">Preference or personalization cookies: these
                            are
                            cookies that allow information to be remembered so that the
                            user can access the service with certain characteristics that
                            may differentiate their experience from that of other users,
                            such as, for example, the language, the number of results to
                            be displayed when the user performs a search, the appearance
                            or content of the service depending on the type of browser
                            through which the user accesses the service or the region from
                            which the user accesses the service, etc.</p>
                        <p class="mb-[24px]">Geo-location cookies: these are used to find
                            out
                            in which country or region the user is located when accessing
                            a website service in order to offer content or services
                            appropriate to the user's location.</p>
                        <p class="mb-[24px]">Analysis or measurement cookies: are those
                            that
                            allow the party responsible for them to monitor and analyze
                            the behavior of users of the websites to which they are
                            linked, including the quantification of the impacts of
                            advertisements. The information collected through this type of
                            cookies is used in the measurement of the activity of the
                            websites, application or platform, in order to make
                            improvements based on the analysis of the usage data made by
                            users of the service.</p>
                        <p class="mb-[24px]">Behavioral advertising cookies: are those
                            that
                            store information on user behavior obtained through the
                            continuous observation of their browsing habits, allowing the
                            development of a specific profile to display advertising based
                            on the same.</p>
                        <p class="mb-[24px]">Anonymous: they only collect information
                            about the
                            advertising spaces displayed on the website, regardless of the
                            user accessing the website, i.e. without expressly identifying
                            the user..</p>
                        <p class="mb-[24px]">Personalized: they collect personal
                            information of
                            the user of the website by a third party, for the
                            personalization of such advertising spaces.</p>
                        <p class="mb-[24px]">Functionality Cookies: allow to remember
                            decisions
                            made by the user, such as login or identifier.</p>
                        <p class="mb-[24px]">The information these cookies collect is
                            anonymized (i.e., it does not contain your name, address, or
                            other data).</p>
                        <p class="mb-[24px]">Own Cookies: those that are sent to the
                            user's
                            terminal equipment from a computer or domain managed by the
                            editor itself and from which the service requested by the user
                            is provided.</p>
                        <p class="mb-[24px]">Third-party cookies: third-party cookies are
                            cookies installed by a website that is not the one you are
                            visiting; for example, those used by social networks (such as
                            Facebook) or by external content plugins (such as Google
                            Maps). In addition, some advertising companies use this type
                            of file to track your visits on each site on which they
                            advertise.</p><h5 class="mb-[24px] text-[24px]">Use of Cookies
                            by
                            www.AyrDrive.com</h5>
                        <p class="mb-[24px]">By accessing www.AyrDrive.com, you expressly
                            agree
                            that we can use this type of cookies on your devices. If you
                            disable cookies, your browsing may not be optimal and some of
                            the utilities available may not work properly.</p>
                        <p class="mb-[24px]">Own cookies. In order to recognize you and
                            provide
                            you with a better service, our site uses its own cookies
                            (small text files that your browser stores. The advantages of
                            accepting our cookies translate into time savings.</p>
                        <p class="mb-[24px]">They can also be used to recognize you
                            between
                            successive visits and thus adapt the content shown to you, to
                            obtain information about the date and time of your last visit,
                            to measure some traffic parameters within the site itself, and
                            to estimate the number of visits made, so that we can focus
                            and adjust services and promotions more effectively.</p>
                        <p class="mb-[24px]">No cookie allows us to contact you with your
                            phone
                            number, email address or any other means of contact. No cookie
                            can extract information from your hard drive or steal personal
                            information.</p>
                        <p class="mb-[24px]">Third Party Cookies. These cookies are those
                            that
                            are sent to the user's terminal equipment from a computer or
                            domain that is not managed by the editor, but by another
                            entity that processes the data obtained through the
                            cookies.</p><h5 class=" text-2.5xl mb-[24px] mt-4">User
                            configuration to avoid Cookies.</h5>
                        <p class="mb-[24px]">From www.AyrDrive.com and in compliance with
                            current legislation, we provide you with information that
                            allows you to configure your browser / Internet browsers to
                            maintain your privacy and security in relation to cookies.
                            Therefore, we provide you with information and links to the
                            official support sites of the main browsers so that you can
                            decide whether or not to accept the use of cookies.</p>
                        <p class="mb-[24px]">Thus, you can block cookies through your
                            browser's
                            configuration tools (all modern browsers allow you to change
                            the Cookie settings. These settings are usually found in the
                            "Options" or "Preferences" of the browser menu) or you can set
                            your browser to warn you when a server wants to save a
                            cookie: </p>
                        <ol>
                            <li class="mb-[24px]">If you use Microsoft Internet Explorer,
                                under
                                the menu option Tools &gt; Internet Options &gt; Privacy
                                &gt; Settings. To learn more visit
                                https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies
                            </li>
                            <li class="mb-[24px]">If you use Mozilla Firefox, in the menu
                                option Tools &gt; Options &gt; Privacy &gt; Cookies. To
                                learn more visit
                                https://support.mozilla.org/es/kb/Deshabilitar%20cookies%20de%20terceros
                            </li>
                            <li class="mb-[24px]">If you use Google Chrome, under Options
                                &gt;
                                Advanced &gt; Privacy. To learn more visit
                                http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647
                            </li>
                            <li class="mb-[24px]">If you use Opera, in the Security and
                                Privacy
                                option, you can configure the browser. To learn more visit
                                http://www.opera.com/help/tutorials/security/cookies/
                            </li>
                            <li class="mb-[24px]">If you use Apple Safari, in the
                                Preferences
                                &gt; Security or Privacy option, you can configure Browser
                                settings. To learn more visit
                                https://support.apple.com/kb/PH21411?viewlocale=es_ES&amp;locale=es_ES
                            </li>
                            <li class="mb-[24px]">If you want to disable Google Universal
                                Analytics, you can install the following extension
                                provided by Google:
                                https://tools.google.com/dlpage/gaoptout
                            </li>
                        </ol>
                        <p class="mb-[24px]">Some features and services may be disabled or
                            have
                            a different behavior than expected such as, for example,
                            remain identified, keep purchases in the "shopping cart" in an
                            e-commerce service or receive information targeted to your
                            location, among others.</p>
                        <p class="mb-[24px]">If you disable the use of Cookies on this
                            website,
                            it is likely that you will no longer be able to access certain
                            areas of the site or that the browsing experience on the site
                            will be significantly degraded.</p></div>
                    <div class="mb-[24px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"></h2>
                        <p class="mb-[24px]"></p></div>
                    <div class="mb-[64px] mt-1 flex flex-col"><h5
                        class=" mb-[24px] text-4xl"
                        id="When, why, by whom, how, for what purpose and for how long do we process your personal data?">
                        When, why, by whom, how, for what purpose and for how long do we
                        process your personal data?</h5><h5 class=" text-3.5xl mb-[24px]">
                        When
                        and why?</h5>
                        <p class="mb-[24px]">You can browse most of our web pages without
                            providing any personal information, but in some cases this
                            information is necessary to provide you with the electronic
                            services you request from us. </p>
                        <p class="mb-[24px]">If we need to collect personal data to
                            provide you
                            with the service, we will process the information in
                            accordance with the policy set out in this document and in the
                            specific terms and conditions of the particular service in
                            question (if any), which contain specific privacy statements
                            about the use of the data and inform you why, for what
                            purpose, how, for how long we process your personal data and
                            what security measures we implement.</p><h5
                            class=" text-3.5xl mb-[24px]">Who collects your data?</h5>
                        <p class="mb-[24px]">The collection and processing of personal
                            data
                            that you may provide to us is carried out by our company or,
                            where appropriate, its data processors. In relation to the
                            latter case, these processors are third parties who are
                            contractually required to ensure that their activity respects
                            the law and implement appropriate security measures to protect
                            such data.</p><h5 class=" text-3.5xl mb-[24px]">What for?</h5>
                        <p class="mb-[24px]">The personal data that we request from you,
                            or
                            that you provide to us as a result of your browsing, is used
                            by us to manage, provide and improve the services you have
                            requested from us.</p>
                        <p class="mb-[24px]">For example, we will process your personal
                            data in
                            order to manage the queries you send us, to manage your
                            participation in personnel selection processes, to send you
                            electronic communications if you so request, and/or for the
                            preparation of statistics.</p>
                        <p class="mb-[24px]">In this sense, we ask you for an e-mail
                            address
                            when you use our contact forms on the web. We only collect the
                            sender's personal data necessary to respond to you.</p>
                        <p class="mb-[24px]">When you subscribe to our newsletters we also
                            ask
                            you for an email to provide the service, in any case you can
                            manage your unsubscribe from the service whenever you want and
                            we put means at your disposal to do so.</p><h5
                            class=" text-3.5xl mb-[24px]">How do we treat your data?</h5>
                        <p class="mb-[24px]">We collect personal information only to the
                            extent
                            necessary to achieve a specific purpose. The information will
                            not be used for a purpose incompatible with the one
                            described.</p>
                        <p class="mb-[24px]">We only disclose the information to third
                            parties
                            if it is necessary for the fulfillment of the purpose of the
                            service and only to persons who need to know them. All this in
                            order to be able to provide the service by treating your
                            personal data with confidentiality and reserve, in accordance
                            with current legislation.</p>
                        <p class="mb-[24px]">In any case, our company adopts security
                            measures
                            to protect the data against possible abuse or unauthorized
                            access, alteration or loss.</p><h5
                            class=" text-3.5xl mb-[24px]">
                            How do we treat your data?</h5>
                        <p class="mb-[24px]">We store data only for the time necessary to
                            fulfill the purpose of its collection or further processing.
                            The period of data retention will depend on the service and
                            each service will indicate the duration of the processing of
                            personal data.</p>
                        <p class="mb-[24px]">A table with specific retention periods is
                            provided at the end of this document.</p><h5
                            class=" text-3.5xl mb-[24px]">For what purposes will we
                            process
                            your personal data?</h5><h5 class=" text-2.5xl mb-[24px]">
                            Customers:</h5>
                        <p class="mb-[24px]">We process your personal data in order to (i)
                            manage your purchase or service provided; (ii) maintain the
                            contractual and pre-contractual relationship for billing,
                            preparation of budgets and follow up on the same as well as
                            send you information by electronic means that deal with your
                            request; (iii) sending communications on commercial
                            information by electronic means that may be of interest to
                            you, provided there is express authorization; (iv) we may
                            develop a commercial profile based on the information you
                            provide us in order to offer products and services according
                            to your interests. No automated decisions will be made on the
                            basis of such a profile.</p><h5 class=" text-2.5xl mb-[24px]">
                            Suppliers:</h5>
                        <p class="mb-[24px]">We process your personal data for the purpose
                            of
                            (i) invoicing and (ii) maintaining business contact, (iii) as
                            well as sending you information by electronic means about our
                            products or services.</p><h5 class=" text-2.5xl mb-[24px]">Web
                            or
                            e-mail contacts:</h5>
                        <p class="mb-[24px]">We process your personal data for the purpose
                            of
                            (i) answering your queries and requests; (ii) managing the
                            requested service or processing your order; (iii) sending you
                            commercial information by electronic means that may be of
                            interest to you, we may create a commercial profile based on
                            the information you provide us in order to offer you products
                            and services according to your interests. No automated
                            decisions will be made on the basis of such a profile.</p><h5
                            class=" text-2.5xl mb-[24px]">Social media contacts:</h5>
                        <p class="mb-[24px]">We process your personal data in order to (i)
                            answer your queries and requests, (ii) manage the requested
                            service, answer your request or process your order and (iii)
                            interact with you and create a community of followers.</p><h5
                            class=" text-2.5xl mb-[24px]">Job seekers:</h5>
                        <p class="mb-[24px]">We process your personal data in order to (i)
                            count on you in recruitment selection processes, (ii) summon
                            you for job interviews and evaluate your candidacy, (iii)
                            communicate your resume to group companies, collaborators or
                            related companies with the sole purpose of involving you in
                            their selection processes, provided that you have given us
                            your consent.</p><h5 class=" text-2.5xl mb-[24px]">
                            Participants in
                            our contests:</h5>
                        <p class="mb-[24px]">We process your personal data in order to
                            manage
                            your participation in the contests we organize as well as to
                            publicize the winners of the contest and the awards
                            ceremony.</p>
                        <p class="mb-[24px]">Winning participants may be photographed or
                            videotaped and disseminated in any of the media, our website
                            or other media. Consequently it is possible that the image of
                            the participants may be captured, recorded and/or reproduced
                            in an ancillary way to the main activity.</p>
                        <p class="mb-[24px]">We process your personal data in order to (i)
                            count on you in recruitment selection processes, (ii) summon
                            you for job interviews and evaluate your candidacy, (iii)
                            communicate your resume to group companies, collaborators or
                            related companies with the sole purpose of involving you in
                            their selection processes, provided that you have given us
                            your consent.</p><h5 class=" text-2.5xl mb-[24px]">Chat
                            online:</h5>
                        <p class="mb-[24px]">We process your personal data in order to
                            answer
                            your queries and requests.</p>
                        <p class="mb-[24px]">The data will be deleted from our database
                            once
                            the query or information requested through the chat is
                            resolved.</p><h5 class=" text-2.5xl mb-[24px]">Web users:</h5>
                        <p class="mb-[24px]">By browsing our website we collect
                            information
                            about your browser, your device and data from your use of our
                            website as well as any information you provide to us when
                            using our website. In an anonymized or aggregated form, we may
                            record the IP address (Internet access identification number
                            of the device, which allows devices, systems and servers to
                            recognize and communicate with each other).</p>
                        <p class="mb-[24px]">The purpose of the processing is (i) to
                            obtain
                            practical knowledge about how users use our website to enable
                            us to improve it; (ii) to perform statistical analysis to help
                            us improve our business strategy; (iii) to perform web
                            performance analysis and (iv) for technical security and
                            system diagnostics. </p>
                        <p class="mb-[24px]">The data we collect is not related to a
                            specific
                            user and will be stored in our databases.</p>
                        <p class="mb-[24px]">The aforementioned data, as well as any
                            personal
                            data you may provide us with, are stored by means of cookies
                            that are collected in a format pseudonymized and are subject
                            to the presentation of objections to the processing of this
                            personal data, as detailed in the Cookie Policy. </p>
                        <p class="mb-[24px]">You can consult the Cookies Policy in the
                            corresponding section.</p>
                        <p class="mb-[24px]">The information of your navigation can be
                            stored
                            by Google Analytics, so we refer to the Privacy Policy of
                            Google, as it collects and treats such information.
                            http://www.google.com/intl/en/policies/privacy/ </p>
                        <p class="mb-[24px]">Similarly, from our website you can provide
                            the
                            utility of Google Maps, which may have access to your
                            location, in the event that it is allowed, in order to provide
                            you with greater specificity about the distance and / or roads
                            to our headquarters. In this regard, we refer to the privacy
                            policy used by Google Maps, in order to know the use and
                            processing of such data
                            http://www.google.com/intl/en/policies/privacy/ </p>
                        <p class="mb-[24px]">In order to offer information or services of
                            interest based on the User's location, we may access data
                            relating to the geolocation of the User's device in those
                            cases in which the user's configuration for this purpose so
                            permits.</p>
                        <p class="mb-[24px]">The Portal may offer functionalities to share
                            content through third party applications, such as Facebook or
                            Twitter. These applications may collect and process
                            information related to the user's browsing on the different
                            websites. Any personal information collected through these
                            applications may be used by third party users of these
                            applications. Your interactions are subject to the privacy
                            policies of the companies providing the applications.</p>
                        <p class="mb-[24px]">The Portal may host blogs, forums, and other
                            social networking applications or services in order to
                            facilitate the exchange of knowledge and content. Any personal
                            information provided by the user may be shared with other
                            users of that service, over which we have no control.</p><h5
                            class=" text-2.5xl mb-[24px]">What is the legitimacy for the
                            processing of your data?</h5><h5
                            class=" text-2.5xl mb-[24px]">
                            Customers:</h5>
                        <p class="mb-[24px]">The legal basis for the processing of your
                            data is
                            (i) the execution of a contract and maintenance of the
                            contractual relationship and (ii) your consent that is
                            requested for the remission of offers of products and services
                            through electronic means, without in any case the withdrawal
                            of this consent conditions the execution of the contract.</p>
                        <h5 class=" text-2.5xl mb-[24px]">Suppliers:</h5>
                        <p class="mb-[24px]">The legal basis for processing your data is
                            (i)
                            the performance of a contract to which the data subject is a
                            party or for the implementation of pre-contractual
                            measures.</p><h5 class=" text-2.5xl mb-[24px]">Web or e-mail
                            contacts:</h5>
                        <p class="mb-[24px]">The legal basis for the processing of your
                            data is
                            (i) the consent of the data subject.</p>
                        <p class="mb-[24px]">In those cases where to make a request it is
                            necessary to fill out a form and "click" on the submit button,
                            the completion of the same will necessarily imply that you
                            have been informed and have expressly given your consent to
                            the content of the clause attached to the form or acceptance
                            of the privacy policy.</p>
                        <p class="mb-[24px]">All our forms have a check box that must be
                            checked to access the services offered.</p>
                        <p class="mb-[24px]">The purposes of the treatment will be the
                            following:</p>
                        <p class="mb-[24px]">a) Manage queries or requests for information
                            that
                            you send us through the Web Page, email or telephone.</p>
                        <p class="mb-[24px]">b) Sending communications, special
                            promotions,
                            news or actions that are of interest to you or that you
                            request from us, including by electronic means. As this is an
                            accessory purpose to the main one, you must check the box
                            provided for this purpose.</p>
                        <p class="mb-[24px]">The personal data you provide us by this
                            means
                            will not be communicated to third parties, being AyrDrive
                            Universal Technologies, S.L. who give, directly answer to such
                            queries.</p><h5 class=" text-2.5xl mb-[24px]">Social network
                            contacts:</h5>
                        <p class="mb-[24px]">The legal basis for the processing of your
                            data is
                            the acceptance of the contractual relationship with the
                            relevant social network provider manifested when registering
                            in their application and in accordance with their privacy
                            policies, which is external to us.</p><h5
                            class=" text-2.5xl mb-[24px]">Work with us:</h5>
                        <p class="mb-[24px]">In the event that you provide us with your
                            curriculum vitae, either by means of the Web Page, e-mail or
                            physically at the address or any headquarters of AyrDrive
                            Universal Technologies, S.L. will incorporate them into its
                            database. The curriculum will be stored for a period of 1
                            year, after which, if we have not contacted you, it will be
                            deleted.</p>
                        <p class="mb-[24px]">The legal basis for the treatment will be
                            based on
                            the express consent given by the interested party for the
                            processing of the data contained in the curriculum by sending
                            it and checking the box provided for this purpose.In those
                            cases where to make a request it is necessary to fill out a
                            form and "click" on the submit button, the completion of the
                            same will necessarily imply that you have been informed and
                            have expressly given your consent to the content of the clause
                            attached to the form or acceptance of the privacy policy.</p>
                        <p class="mb-[24px]">The purpose of the treatment is to
                            incorporate you
                            to present and future selection processes of AyrDrive
                            Universal Technologies, S.L. or any entity belonging to the
                            business group.</p>
                        <p class="mb-[24px]">In the event that the interested party is
                            finally
                            incorporated as an employee of AyrDrive Universal
                            Technologies, S.L. or any of the entities belonging to the
                            business group, your data will be incorporated into a database
                            owned by the same, in order to internally manage the
                            employee-employer employment relationship.</p><h5
                            class=" text-2.5xl mb-[24px]">Sending Newsletter:</h5>
                        <p class="mb-[24px]">The Web Page allows the option of subscribing
                            to
                            the Newsletter of AyrDrive Universal Technologies, S.L. To do
                            so, it is necessary to provide an e-mail address to which it
                            will be sent.</p>
                        <p class="mb-[24px]">Such information will be stored in a database
                            of
                            AyrDrive Universal Technologies, S.L. in which it will be
                            registered until the interested party requests to unsubscribe
                            from it or, where appropriate, AyrDrive Universal
                            Technologies, S.L. ceases to send it.</p>
                        <p class="mb-[24px]">The legal basis for the processing of this
                            personal data is the express consent given by all interested
                            parties who subscribe to this service by checking the box
                            provided for this purpose.</p>
                        <p class="mb-[24px]">The e-mail data will only be processed and
                            stored
                            for the purpose of managing the sending of the Newsletter by
                            users who request it.</p><h5 class=" text-2.5xl mb-[24px]">
                            Chat
                            online:</h5>
                        <p class="mb-[24px]">La base legal para procesar sus datos es su
                            consentimiento al usar el chat.</p><h5
                            class=" text-2.5xl mb-[24px]">Participants in our
                            contests:</h5>
                        <p class="mb-[24px]">The legal basis for the processing of your
                            data is
                            your consent by registering for the contest and accepting the
                            privacy policy and contest rules. </p>
                        <p class="mb-[24px]">The personal data collected will not be
                            disclosed
                            to third parties.</p><h5 class=" text-2.5xl mb-[24px]">Web
                            users:</h5>
                        <p class="mb-[24px]">The legal basis for the processing of data is
                            our
                            (i) legitimate interest in knowing our users' browsing modes
                            in order to adapt to their interests and improve our
                            relationship with them; as well as (ii) their consent when
                            browsing our website and accepting the terms of use of
                            cookies.</p><h5 class=" text-2.5xl mb-[24px]">Web users:</h5>
                        <p class="mb-[24px]">The legal basis for the processing of data is
                            our
                            (i) legitimate interest in knowing our users' browsing modes
                            in order to adapt to their interests and improve our
                            relationship with them; as well as (ii) their consent when
                            browsing our website and accepting the terms of use of
                            cookies.</p><h5 class=" text-2.5xl mb-[24px]">To which
                            recipients
                            will your data be communicated?</h5>
                        <p class="mb-[24px]">Your data will not be disclosed to third
                            parties
                            outside the service we provide, unless legally required.
                            Specifically, they will be communicated to the State Agency of
                            Tax Administration and to banks and financial institutions for
                            the collection of the service provided or product
                            purchased.</p>
                        <p class="mb-[24px]">Your data may also be communicated to our
                            service
                            providers when necessary for the execution of the contract. In
                            these cases, the data processor has committed itself by
                            contract to use the data only for the purpose that justifies
                            the processing and to maintain appropriate security
                            measures.</p><h5 class=" text-2.5xl mb-[24px]">International
                            data
                            transfers.</h5>
                        <p class="mb-[24px]">Due to AyrDrive has servers and nodes in
                            different
                            countries around the world, international data transfers are
                            made. many of these transfers are made to countries declared
                            by the European Commission to be of an adequate level, such
                            as: Canada, Argentina or Japan.</p>
                        <p class="mb-[24px]">However, there are many other countries where
                            the
                            data is sent, which are not declared of adequate level; the
                            transfers that are made to these countries, have their
                            legitimate basis in that such transfers are necessary for the
                            execution of a contract between the data subject and the data
                            controller, since otherwise, the service cannot be
                            provided.</p><h5 class=" text-2.5xl mb-[24px]">What security
                            measures do we apply?</h5>
                        <p class="mb-[24px]">Rest assured, we have adopted appropriate
                            technical and organizational measures to ensure
                            confidentiality, integrity and availability in the processing
                            of your personal data that we perform, in particular those
                            that prevent the loss, misuse, alteration, unauthorized access
                            and theft of personal data.</p><h5
                            class=" text-2.5xl mb-[24px]">
                            What are your rights when you provide us with your data?</h5>
                        <p class="mb-[24px]">You can exercise your rights of access,
                            rectification, deletion, portability, limitation or opposition
                            to the processing of your data, including the right to
                            withdraw your consent, as detailed below: </p>
                        <p class="mb-[24px]">Right of access: you can ask us if we are
                            processing your data and in what way.</p>
                        <p class="mb-[24px]">Right of rectification: You can ask us to
                            update
                            your personal data if they are incorrect, and delete them if
                            you wish.</p>
                        <p class="mb-[24px]">Right to limitation of processing: In this
                            case
                            they will only be kept by us for the exercise or defense of
                            claims.</p>
                        <p class="mb-[24px]">Right of opposition: After your request to
                            oppose
                            the processing, we will stop processing the data in the manner
                            you indicate, unless for compelling legitimate reasons or the
                            exercise or defense of possible claims must continue to be
                            treated.</p>
                        <p class="mb-[24px]">Right of opposition: After your request to
                            oppose
                            the processing, we will stop processing the data in the manner
                            you indicate, unless for compelling legitimate reasons or the
                            exercise or defense of possible claims must continue to be
                            treated.</p>
                        <p class="mb-[24px]">Right to data portability: In case you want
                            your
                            data to be processed by another company, we will facilitate
                            the portability of your data to the new data controller.</p>
                        <p class="mb-[24px]">Right to erasure: You can request that we
                            delete
                            your data when it is no longer necessary for processing, you
                            withdraw your consent, it is unlawful processing or there is a
                            legal obligation to do so. We will analyze the case and apply
                            the law.</p>
                        <p class="mb-[24px]">If you need more information about your
                            rights
                            under the Law and how to exercise them, we recommend that you
                            contact the Spanish Data Protection Agency, which is the
                            supervisory authority for data protection.</p>
                        <p class="mb-[24px]">You can contact the Data Protection Delegate
                            prior
                            to filing a complaint against the data controller before the
                            AEPD.</p>
                        <p class="mb-[24px]">In the event that we have not attended to the
                            exercise of your rights you can file a complaint with the
                            Spanish Data Protection Agency.</p>
                        <p class="mb-[24px]">We have forms for the exercise of rights that
                            can
                            be requested to the email address mentioned above; you can
                            also use those prepared by the Spanish Data Protection Agency
                            or third parties. These forms must be signed electronically or
                            be accompanied by a photocopy of the ID card. If you are
                            acting through a representative in the same way, a copy of
                            your ID card or an electronic signature must accompany the
                            form.</p>
                        <p class="mb-[24px]">The forms must be submitted in person or sent
                            by
                            mail or email to the addresses listed in the "Responsible"
                            section.</p>
                        <p class="mb-[24px]">The maximum time limit for a decision is one
                            month
                            from the receipt of your request.</p><h5
                            class=" text-2.5xl mb-[24px]">How long will we keep your
                            data?</h5>
                        <p class="mb-[24px]">Personal data will be kept for as long as you
                            maintain your relationship with us. </p>
                        <p class="mb-[24px]">At the end of the same, the personal data
                            processed for each of the purposes indicated will be kept for
                            the legally stipulated periods of time. If there is no such
                            legal period until the interested party requests its deletion
                            or revokes the consent given, or during the period that a
                            judge or court may require them according to the statute of
                            limitations for legal actions.</p>
                        <p class="mb-[24px]">For each treatment or type of data, we
                            provide you
                            with a specific period, which you can consult in the following
                            table:</p>
                        <div
                            class="relative -mx-6 flex flex-col overflow-hidden overflow-x-scroll px-6">
                            <table class="relative table-auto bg-white">
                                <tr>
                                    <th class="border border-cool-gray-30 bg-cool-gray-5 py-2 px-8">
                                        File
                                    </th>
                                    <th class="border border-cool-gray-30 bg-cool-gray-5 py-2 px-8">
                                        Document
                                    </th>
                                    <th class="border border-cool-gray-30 bg-cool-gray-5 py-2 px-8">
                                        Conservation
                                    </th>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4"
                                        rowspan="2">Customers
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Invoices
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">10
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Contracts
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">5
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4"
                                        rowspan="4">Human Resources
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Payrolls, TC1, TC2, etc
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">10
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Curriculums
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">Until
                                        the end of the selection process, and 1 year more
                                        with your consent.
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Contratos. Temporary workers data
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">4
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Worker's file.
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">Up to
                                        5 years after the sick leave.
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Marketing
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Databases or web visitors
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">While
                                        the treatment lasts
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4"
                                        rowspan="2">Suppliers
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Invoices
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">10
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Contracts
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">5
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4"
                                        rowspan="2">Access control and video surveillance
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">List
                                        of visitors
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">30
                                        days
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Videos
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">30
                                        days blocking. 3 years destruction
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Accounting
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Accounting books and documents.
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">6
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Taxation
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Management of the company's administration, rights
                                        and obligations related to the payment of taxes.
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">10
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">Health
                                        and Safety
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">Worker
                                        Medical Records
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">5
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Insurance
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Insurance policies
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">6
                                        years (general rule). 2 years (damages)
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Shopping
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Registration of all deliveries of goods or
                                        services, intra-community acquisitions, imports
                                        and exports for VAT purposes.
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">5
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4"
                                        rowspan="2">Organic Law on Data Protection
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Processing of personal data.
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">3
                                        years
                                    </td>
                                </tr>
                                <tr>
                                    <td class="border border-cool-gray-20 p-2 px-4">
                                        Personal data of employees stored in the networks,
                                        computers and communications equipment used by
                                        employees, access controls and internal
                                        management/administration systems.
                                    </td>
                                    <td class="border border-cool-gray-20 p-2 px-4">5
                                        years
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Information you provide to us">
                        Information you provide to us</h2>
                        <p class="mb-[24px]">We collect information you provide directly
                            to us.
                            For example, we collect information you provide when you
                            create an account, subscribe to our updates, respond to a
                            survey, fill out a form, request customer support or
                            communicate with us. The types of information we may collect
                            include your email address, username and password, survey
                            responses and any other information you choose to provide. If
                            you use our Storage Services, our third-party service provider
                            may collect your payment method information for use in
                            connection with your payments for storage.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Information collected by cookies and other tracking technologies via our services.">
                        Information collected by cookies and other tracking technologies
                        via our services.</h2>
                        <p class="mb-[24px]">We and our service providers use various
                            technologies to collect information when you interact with our
                            websites and mobile apps, including cookies and web beacons.
                            Cookies are small data files that are stored on your device
                            when you visit a website, which enable us to collect
                            information about your device identifiers, web browsers used
                            to access the Services, pages or features viewed, time spent
                            on pages, mobile app performance and links clicked. Web
                            beacons (or pixel tags) are electronic images that may be used
                            in our web Services or emails to help deliver cookies, count
                            website visits, understand usage and determine the
                            effectiveness of email marketing campaigns.</p>
                        <p class="mb-[24px]">We may also receive information about you
                            from
                            other sources, such as when logging into your Storage Services
                            account by using your account credentials for a separate
                            third-party service. We will receive information from that
                            service as indicated in the relevant third party’s
                            authorization screen.</p>
                        <p class="mb-[24px]">Using AyrDrive's Core software makes your
                            computer
                            part of a distributed network. This means your computer may
                            communicate with many other computers. As part of this
                            process, those other nodes can store some information about
                            your computer and your interactions. None of this information
                            is dangerous to share with the network. Your computer shares a
                            lot of it every day when you browse the web. We firmly believe
                            that you have the right to be aware of the information you're
                            sharing, and be able to make decisions about your own privacy.
                            Here's a list of some things that other people can learn about
                            your computer. IP Address: This is your computer's address on
                            the Internet. This needs to be shared so that other nodes'
                            messages can reach your computer. IP-associated information:
                            Using your IP address, it is often possible to look up an
                            approximate location (usually what city you are likely in) as
                            well as your ISP. Network information: When you communicate
                            with another node, it can record how long it takes your node
                            to respond, and test your internet connection's bandwidth.
                            Node ID: This is a pseudonym for your computer. It's a long
                            group of random numbers that can be used to identify a node on
                            the network. You need to send this out so that other nodes can
                            identify and contact your node. Disk space information: In
                            order to negotiate storage contracts, your computer has to let
                            other nodes know some information about how much it can store.
                            If you are not comfortable sharing this information, we
                            suggest you do not run AyrDrive's Core program.</p>
                        <p class="mb-[24px]">We may allow others to provide analytics
                            services
                            on our behalf in connection with our Services. These entities
                            may use cookies, web beacons and other technologies to collect
                            information about your use of the Services and other websites
                            and apps, including your IP address, web browser, pages
                            viewed, app performance, time spent on pages and links
                            clicked. This information may be used by Company and others
                            to, among other things, analyze and track data, determine the
                            popularity of certain content and better understand your
                            online activity.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Security">Security</h2>
                        <p class="mb-[24px]">AyrDrive takes reasonable measures to help
                            protect
                            information about you from loss, theft, misuse and
                            unauthorized access, disclosure, alteration, and
                            destruction.</p></div>
                    <div class="mb-[64px] flex flex-col"><h2
                        class="mb-3 text-[24px] font-medium"
                        id="Transfer of information to governing authorities">
                        Transfer of information to governing authorities</h2>
                        <p class="mb-[24px]">AyrDrive is based in Spain and the
                            information we
                            collect is governed by Spanish law. By accessing or using our
                            Services or otherwise providing information to us, you consent
                            to the processing and transfer of information in and to Spain
                            and other countries, where you may not have the same rights as
                            you do under your local law. Where this is the case, we will
                            take appropriate measures to protect information about you in
                            accordance with this Privacy Policy. Since AyrDrive uses
                            client-side end-to-end encryption, it cannot know the content
                            a user has under his Drive account.</p></div>
                </div>
            </div>
        </div>
    </div>
</div>


<div
    class="flex w-full flex-col items-center justify-center px-[24px] pt-16 sm:p-20 sm:py-12">
    <div
        class="flex w-full max-w-[1024px] flex-col items-center justify-center space-y-8 pb-9 text-center lg:flex-row lg:items-start lg:space-y-0 lg:space-x-32 lg:text-left">
        <div
            class="flex w-full max-w-[384px] flex-col items-center justify-center space-y-3 lg:items-start">
            <div class="flex flex-col space-y-1"><h2 class="text-[18px] font-medium ">
                Download
                AyrDrive</h2>
                <p class="text-[#3a3a3b] text-[14px]">Seamless file management and secure
                    cloud
                    storage on the go with the AyrDrive mobile app for iOS or Android.</p>
            </div>
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                <div class="flex"><a
                    class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    download href="/assets/AyrDrive.zip"><span>Download for
                    <!-- --> Windows</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                         fill="currentColor" viewBox="0 0 256 256">
                        <path
                            d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                    </svg>
                </a></div>
            </div>
        </div>
        <div
            class="flex mb-10 max-w-[384px] flex-col items-center justify-center space-y-3 text-center md:items-start md:text-left ">
            <div class="flex w-full flex-col space-y-1 md:max-w-sm"><h2
                class="text-[18px] font-medium">Newsletter</h2>
                <p class="text-[16px] sm:text-[14px] text-[#3a3a3b]">Want to hear from us
                    when
                    we
                    launch new products or release a new update? Type your mail address
                    and we’ll do the rest!</p></div>
            <form data-code="r3s4c1" method="post" target="_blank" rel="noopener"
                  action=""
                  class="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
                <input type="hidden" name="ml-submit" value="1">
                <input type="email" name="fields[email]"
                       placeholder="Your email address"
                       class="flex h-auto w-full flex-row rounded-lg px-[12px] py-3 text-[18px] outline-none sm:py-2 sm:text-[16px] md:w-64 border-cool-gray-20 bg-white focus:border-blue-50 focus:ring-opacity-20 mb-2 appearance-none border text-left transition-all duration-150 focus:ring focus:ring-primary"
                       required="">
                <input type="submit" name="signup" value="Subscribe"
                       class="ml-2 flex w-full cursor-pointer items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[12px] py-3 text-[18px] font-medium text-white transition-all duration-75 hover:bg-[#0066ff]-dark focus:outline-none active:bg-[#0066ff]-dark sm:mb-2 sm:py-2 sm:text-[16px]">
            </form>
            <span class="text-[14px] text-gray-40">By subscribing you agree to our
                <!-- --> <a href="/legal"
                            class="cursor-pointer underline">privacy policy.</a></span>
        </div>
    </div>
    <footer class="flex max-w-[800px] w-[100%] items-center justify-center">
        <div
            class="w-[100%] flex-col items-center justify-center md:space-y-[56px] lg:flex">
            <div
                class="flex w-[100%] flex-row justify-between md:justify-between lg:space-x-[80px]">
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Products</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[16px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/drive">AyrDrive
                                Drive</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/photos">AyrDrive
                                Photos</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/pricing">Pricing</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Company</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[16px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/about">About Us</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/privacy">Privacy</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="flex max-w-[200px] flex-row items-center hover:text-[#0066ff]"
                                href="/open-source">Open Source</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/legal">Legal</a></p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Join Us</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[16px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a href="/register"
                                                               target="_top"
                                                               class="hover:text-[#0066ff]">Create
                                Account</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/login?" target="_top">Log In</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/assets/images/homepage/AyrDrive-white-paper.pdf"
                                target="_blank"
                                rel="noreferrer" download="" class="hover:text-[#0066ff]">White
                                Paper</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex h-px w-full bg-cool-gray-10 mb-10"></div>
            <div class="flex w-full max-w-[900px] flex-row justify-between">
                <div class="flex flex-row items-center space-x-4"><a
                    class="flex flex-shrink-0" href="/"><img loading="lazy"
                                                             style="height:24px"
                                                             src="/assets/images/homepage/AyrDrive.svg"
                                                             alt="AyrDrive logo"></a>
                    <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive
                        Universal Technologies SL</p></div>
                <div class="flex flex-row space-x-1">
                </div>
            </div>
        </div>
        <div class="bg-gray-5 bg-opacity-50 flex flex-col overflow-hidden lg:hidden">
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                    id="headlessui-disclosure-button-5" type="button"
                    aria-expanded="false"><span class="flex flex-row">Products</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-[#3a3a3b]">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-[#3a3a3b]">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[#18181b] text-[18px] font-medium"
                    id="headlessui-disclosure-button-7" type="button"
                    aria-expanded="false"><span class="flex flex-row">Company</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-[#3a3a3b]">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-[#3a3a3b]">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                    id="headlessui-disclosure-button-9" type="button"
                    aria-expanded="false"><span class="flex flex-row">Join Us</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-[#3a3a3b]">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-[#3a3a3b]">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="flex w-screen">
                <div class="w-screen">
                    <button
                        class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                        id="headlessui-disclosure-button-15" type="button"
                        aria-expanded="false">
                        <div class="flex flex-row items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                 fill="currentColor" viewBox="0 0 256 256"
                                 class="text-gray-60">
                                <path
                                    d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z"></path>
                            </svg>
                            <span class="flex flex-row">English (EN)</span></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="flex text-[#3a3a3b]">
                            <path
                                d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="hidden text-[#3a3a3b]">
                            <path
                                d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="flex flex-col items-center space-y-4 py-10">
                <div class="flex flex-row space-x-1">
                </div>
                <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive Universal
                    Technologies SL</p><a class="flex flex-shrink-0" href="/"><img
                loading="lazy" width="110" height="12"
                src="/assets/images/homepage/AyrDrive.svg"
                alt="AyrDrive logo"></a></div>
        </div>
    </footer>
</div>
