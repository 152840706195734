<div class="fixed top-0 left-0 z-50">
    <a href="/#"
       class="group left-0 z-50 h-0 w-screen cursor-pointer items-center justify-center overflow-hidden bg-[#0066ff] text-white md:flex">
    </a>
    <nav
        class="border-b-[1px] border-gray-300 flex items-center h-[64px] py-[32px] pr-[24px] opacity-95 w-full bg-white transition-all duration-100 lg:h-16 undefined backdrop-blur-lg backdrop-saturate-150 backdrop-filter">
        <div class="mx-[24px] w-full lg:mx-[56px] xl:mx-[96px]">
            <div
                class="mx-auto flex max-w-screen-xl w-screen-xl items-center justify-between">
                <div
                    class="text-[18px] text-[#4D5358] flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-start space-x-[16px] lg:space-x-[24px]">
                    <a style="cursor: pointer" class="flex flex-shrink-0"
                       href="/">
                        <img
                            class="select-none"
                            src="/assets/images/homepage/AyrDrive.svg"
                            alt="AyrDrive logo"/>
                    </a>
                    <span class="w-[16px]"></span>
                    <a class="sm:hidden md:block whitespace-nowrap py-1.5 px-[12px] transition duration-150 ease-in-out text-[#4D5358] hover:text-[#0066ff]"
                       href="/pricing">Pricing</a>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 items-center transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl">
                        <span>Products</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none absolute top-full left-1/2 z-50 w-[196px] -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div
                                class="relative grid gap-0 whitespace-nowrap lg:grid-cols-1">
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/drive-info">AyrDrive Storage</a>
                                <a class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                   href="/photos">AyrDrive Photos</a>
                            </div>
                        </div>
                    </div>
                    <div
                        class="group relative flex space-x-1 py-1.5 px-[12px] pr-2 transition duration-150 ease-in-out hover:bg-cool-[#18181b] hover:bg-opacity-5 hover:text-[#0066ff] cursor-default rounded-3xl items-center">
                        <span>Our Values</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                             viewBox="0 0 24 24" fill="currentColor"
                             class="h-6 w-6 translate-y-px text-gray-40 transition duration-150 ease-in-out group-hover:text-cool-gray-30">
                            <path
                                d="M17,9.17a1,1,0,0,0-1.41,0L12,12.71,8.46,9.17a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42l4.24,4.24a1,1,0,0,0,1.42,0L17,10.59A1,1,0,0,0,17,9.17Z"></path>
                        </svg>
                        <div
                            class="pointer-events-none text-[#4D5358] absolute top-full left-1/2 z-50 w-52 -translate-x-1/2 translate-y-0 rounded-xl border border-black border-opacity-5 bg-white p-1.5 opacity-0 shadow-subtle transition duration-150 ease-in-out group-hover:pointer-events-auto group-hover:translate-y-1 group-hover:opacity-100">
                            <div
                                class="absolute -top-4 left-1/2 h-[16px] w-4/5 -translate-x-1/2"></div>
                            <div class="relative grid gap-0 lg:grid-cols-1"><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/privacy">Privacy</a><a
                                class="flex flex-row justify-start rounded-xl py-2 px-[12px] text-[18px] font-medium text-gray-500 hover:bg-cool-gray-5"
                                href="/open-source">Open Source</a></div>
                        </div>
                    </div>
                    <a class="whitespace-nowrap text-[#4D5358] py-1.5 px-[12px] transition duration-150 ease-in-out hover:text-[#0066ff] font-medium"
                       href="/about">About Us</a>
                </div>
                <div
                    class="space-x-[16px] inline-flex text-[18px] font-medium text-[#4D5358]">
                    <div
                        class="flex flex-1 flex-shrink-0 flex-grow flex-row items-center justify-end gap-[16px]">
                        <a id="loginButton" href="/login"
                           class="whitespace-nowrap rounded-xl border px-[12px] py-[4px] transition duration-150 ease-in-out focus:border focus:outline-none md:flex border-[#0066ff] text-[#0066ff] hover:bg-[#0066ff] hover:bg-opacity-10 active:border-[#0066ff]-dark active:text-[#0066ff]-dark text-[14px] font-medium">
                            Log in
                        </a>
                        <a href="/register"
                           class="flex justify-center rounded-xl border border-transparent py-[4px] px-[12px] text-[14px] font-medium focus:outline-none sm:inline-flex bg-[#0066ff] text-white hover:bg-[#0066ff]-dark active:bg-[#0066ff]-dark transition-all duration-75">
                            <p class="whitespace-nowrap">Get started</p></a>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</div>

<section id="priceTable" class="bg-[#F8F8Fb] py-[64px]" id="top-one">
    <div class="flex flex-col items-center py-[10px]">
        <div class="flex flex-col items-center space-y-10 pt-12">
            <div class="flex flex-col items-center px-5"><h1
                class="max-w-[800px] text-center text-[60px] font-semibold">
                Subscription plans
            </h1>
                <p class="mt-4 w-full max-w-3xl text-center text-[20px] text-[#3a3a3b]">
                    Each AyrDrive plan comes with a comprehensive range of features and
                    offers unrestricted access to all our privacy services
                </p></div>
            <div class="items center flex flex-col">
            </div>
        </div>
        <div class="scale-100 translate-y-0 opacity-100">
            <div
                class="grid grid-cols-3 p-[24px] pt-[64px] gap-4">
                <div
                    class="priceCard card  m-2 flex max-w-[288px] flex-shrink-0 flex-grow-0 flex-col overflow-hidden rounded-2xl xs:w-72       border border-1 border-transparent hover:border-[#0066ff]  transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div
                        class="info flex flex-col items-center justify-center rounded-t-2xl  bg-white p-[24px] pt-6">
                        <div
                            class="storage flex max-w-min flex-row whitespace-nowrap bg-[#f4f5f7] py-1 px-4 pb-0.5 text-[16px] font-semibold  text-[#8e8e96] rounded-full font-medium">
                            <p><span class="">Up to 10GB</span></p></div>
                        <div
                            class="planPrice flex flex-col items-center justify-center py-8 space-y-4">
                            <div class="priceBreakdown flex flex-row
              items-end space-x-px text-neutral-700
            ">
                                <p class=" flex flex-row items-start space-x-1 whitespace-nowrap font-medium text-[#18181b]">
                                    <span class="currency hidden">€</span><span
                                    class="price text-[36px] font-bold">Free</span></p>
                            </div>
                            <span
                                class="perUser hidden text-[14px] font-medium text-[#8e8e96]">Per user</span><span
                            class="priceBefore hidden text-[16px] font-medium text-neutral-100 line-through">€</span>
                            <div class="totalBilling flex flex-row text-[14px] font-medium text-[#8e8e96]
            "><p class="hidden"><span class="billingFrequency">Billed annually</span></p>
                                <p class="">Free forever</p></div>
                        </div>
                        <a href="/register" id="planButton10GB"
                           class="flex w-full flex-row">
                            <div
                                class="subscribePlan flex w-full origin-center transform cursor-pointer select-none items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[24px] py-[8px] text-[18px] font-medium text-white transition-all duration-75 hover:bg-primary-dark focus:bg-blue-70 focus:outline-none focus:ring-2 focus:ring-blue-20 focus:ring-offset-2 active:translate-y-0.5 active:bg-blue-70 sm:text-[16px]">
                                <p class="hidden ">Get 10GB</p>
                                <p class=" ">Sign up now</p>
                                <p class="hidden">Get started</p></div>
                        </a>
                    </div>
                    <div
                        class="featureList flex flex-col border-t border-neutral-20 bg-[#fafbfc] p-[24px] text-[#3a3a3b]">
                        <div class="flex flex-col space-y-2 text-[14px]">
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Encrypted file storage &amp; sharing</span>
                            </div>
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Access your files from any device</span>
                            </div>
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Get access to all our services</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="priceCard card  m-2 flex max-w-[288px] flex-shrink-0 flex-grow-0 flex-col overflow-hidden rounded-2xl xs:w-72 border border-1 border-transparent hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="info flex flex-col items-center justify-center rounded-t-2xl  bg-white p-[24px] pt-6
        ">
                        <div
                            class="storage flex max-w-min flex-row whitespace-nowrap bg-[#f4f5f7] py-1 px-4 pb-0.5 text-[16px] font-semibold  text-[#8e8e96] rounded-full font-medium">
                            <p>20GB</p></div>
                        <div
                            class="planPrice flex flex-col items-center justify-center py-8 space-y-4">
                            <div class="priceBreakdown flex flex-row
              items-end space-x-px text-neutral-700
            ">
                                <p class=" flex flex-row items-start space-x-1 whitespace-nowrap font-medium text-[#18181b]">
                                    <span class="currency ">€</span><span
                                    class="price text-[36px] font-bold">10.68</span></p>
                            </div>
                            <span
                                class="perUser hidden text-[14px] font-medium text-[#8e8e96]">Per user</span><span
                            class="priceBefore hidden text-[16px] font-medium text-neutral-100 line-through">€</span>
                            <div class="totalBilling flex flex-row text-[14px] font-medium text-[#8e8e96]
            "><p class=""><span class="billingFrequency">Billed annually</span></p>
                                <p class="hidden">Free forever</p></div>
                        </div>
                        <a href="/register" id="planButton20GB"
                           class="flex w-full flex-row">
                            <div
                                class="subscribePlan flex w-full origin-center transform cursor-pointer select-none items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[24px] py-[8px] text-[18px] font-medium text-white transition-all duration-75 hover:bg-primary-dark focus:bg-blue-70 focus:outline-none focus:ring-2 focus:ring-blue-20 focus:ring-offset-2 active:translate-y-0.5 active:bg-blue-70 sm:text-[16px]">
                                <p class=" ">Get 20GB</p>
                                <p class="hidden ">Sign up now</p>
                                <p class="hidden">Get started</p></div>
                        </a>
                    </div>
                    <div
                        class="featureList flex flex-col border-t border-neutral-20 bg-[#fafbfc] p-[24px] text-[#3a3a3b]">
                        <div class="flex flex-col space-y-2 text-[14px]">
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Encrypted file storage &amp; sharing</span>
                            </div>
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Access your files from any device</span>
                            </div>
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Get access to all our services</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="priceCard card  m-2 flex max-w-[288px] flex-shrink-0 flex-grow-0 flex-col overflow-hidden rounded-2xl xs:w-72 border border-1 border-transparent hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                    <div class="info flex flex-col items-center justify-center rounded-t-2xl  bg-white p-[24px] pt-6
        ">
                        <div
                            class="storage flex max-w-min flex-row whitespace-nowrap bg-[#f4f5f7] py-1 px-4 pb-0.5 text-[16px] font-semibold text-[#8e8e96] rounded-full font-medium">
                            <p>200GB</p></div>
                        <div
                            class="planPrice flex flex-col items-center justify-center py-8 space-y-4">
                            <div class="priceBreakdown flex flex-row
              items-end space-x-px text-neutral-700
            ">
                                <p class=" flex flex-row items-start space-x-1 whitespace-nowrap font-medium text-[#18181b]">
                                    <span class="currency ">€</span><span
                                    class="price text-[36px] font-bold">41.88</span></p>
                            </div>
                            <span
                                class="perUser hidden text-[14px] font-medium text-[#8e8e96]">Per user</span><span
                            class="priceBefore hidden text-[16px] font-medium text-neutral-100 line-through">€</span>
                            <div class="totalBilling flex flex-row text-[14px] font-medium text-[#8e8e96]
            "><p class=""><span class="billingFrequency">Billed annually</span></p>
                                <p class="hidden">Free forever</p></div>
                        </div>
                        <a href="/register" id="planButton200GB"
                           class="flex w-full flex-row">
                            <div
                                class="subscribePlan flex w-full origin-center transform cursor-pointer select-none items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[24px] py-[8px] text-[18px] font-medium text-white transition-all duration-75 hover:bg-primary-dark focus:bg-blue-70 focus:outline-none focus:ring-2 focus:ring-blue-20 focus:ring-offset-2 active:translate-y-0.5 active:bg-blue-70 sm:text-[16px]">
                                <p class=" ">Get 200GB</p>
                                <p class="hidden ">Sign up now</p>
                                <p class="hidden">Get started</p></div>
                        </a>
                    </div>
                    <div
                        class="featureList flex flex-col border-t border-neutral-20 bg-[#fafbfc] p-[24px] text-[#3a3a3b]">
                        <div class="flex flex-col space-y-2 text-[14px]">
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Encrypted file storage &amp; sharing</span>
                            </div>
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Access your files from any device</span>
                            </div>
                            <div class="flex flex-row items-start space-x-[8px]">
                                <svg class="mt-0.5 translate-y-px select-none" width="16"
                                     height="17" viewBox="0 0 16 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.3333 4.5L7.16663 12.5L4.66663 8.74242"
                                          stroke="#0066FF" stroke-width="1.5"
                                          stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <span>Get access to all our services</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#0066ff] py-[64px]">
    <div
        class="flex flex-col items-center justify-center space-y-8 px-[20px] text-center">
        <div class="flex flex-col items-center space-y-[16px] text-center text-white">
            <p
                class="text-[36px] font-semibold">
                Get started now for free!
            </p>
            <p class="w-full max-w-[570px] text-[20px] font-normal">
                Create your account to enjoy up to 10 GB of free storage and gain full
                access to our cloud services
            </p>
        </div>
        <a href="/register"
           class="flex rounded-lg bg-white px-[20px] py-[12px] text-[18px] font-medium
            text-[#0066ff] hover:bg-[#ffffffee]">
            Create free account
        </a>
    </div>
</section>

<div class="flex flex-col items-center justify-center py-[64px] px-[20px]">
    <div
        class="revealY max-w-screen-xl  flex max-w-[768px] flex-col items-center justify-center space-y-6 text-center text-black active">
        <p class="mb-6 max-w-screen-xl  text-[48px] font-semibold sm:leading-tight">
            Secure your cloud data like never before
        </p>
        <p class="text-[18px] max-w-[768px]  text-gray-600">
            What makes AyrDrive so special? We always strive to provide the best
            services for our clients, tailored to meet their needs. Our team is comprised
            of professionals who are dedicated to protecting you and your files online
        </p>
        <div
            class="flex cursor-pointer flex-row items-center justify-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline">
            <a href="/about">Meet the AyrDrive Team</a>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                 fill="currentColor" viewBox="0 0 256 256">
                <path
                    d="M184.49,136.49l-80,80a12,12,0,0,1-17-17L159,128,87.51,56.49a12,12,0,1,1,17-17l80,80A12,12,0,0,1,184.49,136.49Z"></path>
            </svg>
        </div>
    </div>
    <div
        class="revealY mt-[80px] grid grid-cols-1 flex-row flex-wrap justify-center gap-[40px] sm:grid-cols-2 active">
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 48 48"
                 height="32" width="32" class="mb-6 text-[48px] text-[#0066ff]">
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M13.639791666666666 19.220924166666666h14.6875l-3.9166666666666665 -4.40625"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M24.410625 25.585429166666664h-14.6875l3.9166666666666665 4.40625"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M44.0625 31.333333333333332h-13.708333333333332c-1.0815875 0 -1.9583333333333333 0.8767458333333332 -1.9583333333333333 1.9583333333333333v10.770833333333332c0 1.0815875 0.8767458333333332 1.9583333333333333 1.9583333333333333 1.9583333333333333h13.708333333333332c1.0815875 0 1.9583333333333333 -0.8767458333333332 1.9583333333333333 -1.9583333333333333V33.291666666666664c0 -1.0815875 -0.8767458333333332 -1.9583333333333333 -1.9583333333333333 -1.9583333333333333Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M37.20833333333333 22.520833333333332c-1.55805 0 -3.0524541666666667 0.6190291666666666 -4.1542125 1.7207875C31.952362499999996 25.343379166666665 31.333333333333332 26.83778333333333 31.333333333333332 28.395833333333332V31.333333333333332h11.75v-2.9375c0 -1.55805 -0.6190291666666666 -3.0524541666666667 -1.7207875 -4.1542125C40.2607875 23.1398625 38.76638333333333 22.520833333333332 37.20833333333333 22.520833333333332Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M37.20833333333333 39.46022083333333c-0.4055708333333333 0 -0.734375 -0.3288041666666666 -0.734375 -0.734375s0.3288041666666666 -0.734375 0.734375 -0.734375"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M37.20833333333333 39.46022083333333c0.4055708333333333 0 0.734375 -0.3288041666666666 0.734375 -0.734375s-0.3288041666666666 -0.734375 -0.734375 -0.734375"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M23.010416666666664 34.27083333333333H4.895833333333333c-1.03875875 0 -2.0349825 -0.4126208333333333 -2.769494583333333 -1.1471916666666666C1.3918149166666665 32.389070833333335 0.9791666666666666 31.392866666666666 0.9791666666666666 30.354166666666664v-25.458333333333332c0 -1.03875875 0.41264825 -2.0349825 1.1471720833333334 -2.769494583333333C2.860850833333333 1.3918149166666665 3.857074583333333 0.9791666666666666 4.895833333333333 0.9791666666666666h29.375c1.0387 0 2.0349041666666663 0.41264825 2.769475 1.1471720833333334 0.7345708333333333 0.7345120833333333 1.1471916666666666 1.7307358333333334 1.1471916666666666 2.769494583333333v13.708333333333332"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M0.9791666666666666 10.770833333333332h37.20833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M7.833333333333333 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M7.833333333333333 6.364583333333333c0.27038708333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21919624999999998 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M13.708333333333332 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M13.708333333333332 6.364583333333333c0.27038708333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21919624999999998 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M19.583333333333332 6.364583333333333c-0.27038708333333333 0 -0.4895833333333333 -0.21919624999999998 -0.4895833333333333 -0.4895833333333333s0.21919624999999998 -0.4895833333333333 0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff"
                      d="M19.583333333333332 6.364583333333333c0.27044583333333333 0 0.4895833333333333 -0.21919624999999998 0.4895833333333333 -0.4895833333333333s-0.21913749999999999 -0.4895833333333333 -0.4895833333333333 -0.4895833333333333"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col"><p
                class="mb-6 text-[24px] font-medium text-[#18181b]">Privacy First</p>
                <p class="text-[18px] text-gray-500">
                    With AyrDrive, you have full access and control over your data. We
                    do not store any files or passwords. Using encryption algorithms your
                    data is securely protected and accessible only to you
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 48 48"
                 class="mb-6 text-[48px] text-[#0066ff]"
                 height="32" width="32">
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M39.784325 41.21997916666667c-1.0580875 0 -1.9156416666666665 -0.85775 -1.9156416666666665 -1.9156416666666665 0 -1.0580875 0.8575541666666666 -1.9158374999999999 1.9156416666666665 -1.9158374999999999 1.0578916666666667 0 1.9156416666666665 0.85775 1.9156416666666665 1.9158374999999999 0 1.0578916666666667 -0.85775 1.9156416666666665 -1.9156416666666665 1.9156416666666665Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M7.214108333333334 41.21997916666667c-1.05802875 0 -1.9157004166666667 -0.85775 -1.9157004166666667 -1.9156416666666665 0 -1.0580875 0.8576716666666666 -1.9158374999999999 1.9157004166666667 -1.9158374999999999 1.0580091666666664 0 1.9157004166666667 0.85775 1.9157004166666667 1.9158374999999999 0 1.0578916666666667 -0.85769125 1.9156416666666665 -1.9157004166666667 1.9156416666666665Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M3.3844504166666662 10.568674583333333C2.32644125 10.568674583333333 1.46875 9.710983333333333 1.46875 8.652974166666667s0.85769125 -1.9157004166666667 1.9157004166666667 -1.9157004166666667 1.9157004166666667 0.85769125 1.9157004166666667 1.9157004166666667 -0.85769125 1.9157004166666667 -1.9157004166666667 1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linejoin="round"
                      d="M43.6154125 16.315775833333333c-1.0580875 0 -1.9156416666666665 -0.85769125 -1.9156416666666665 -1.9157004166666667s0.8575541666666666 -1.9157004166666667 1.9156416666666665 -1.9157004166666667c1.0578916666666667 0 1.9156416666666665 0.85769125 1.9156416666666665 1.9157004166666667s-0.85775 1.9157004166666667 -1.9156416666666665 1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m34.036029166666665 29.725737499999997 3.8332416666666664 0c1.0580875 0 1.9158374999999999 0.8575541666666666 1.9158374999999999 1.9156416666666665v1.9156416666666665"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M43.61462916666667 20.147333333333332v1.9156416666666665c0 1.0580875 -0.85775 1.9156416666666665 -1.9158374999999999 1.9156416666666665h-7.6627624999999995"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m12.964107916666666 27.8099 -3.83326125 0c-1.05802875 0 -1.9157004166666667 0.85775 -1.9157004166666667 1.9156416666666665l0 3.8314791666666665"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m3.3850183333333335 14.401113333333333 0 3.8314008333333334c0 1.0580091666666664 0.8576716666666666 1.9156025 1.9157004166666667 1.9156025H12.963970833333333"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M32.1203875 17.274301666666666H14.879142499999999c-1.0580091666666664 0 -1.9157004166666667 0.85769125 -1.9157004166666667 1.9157004166666667v13.40998125c0 1.0578916666666667 0.85769125 1.9156416666666665 1.9157004166666667 1.9156416666666665h17.241245c1.0580875 0 1.9158374999999999 -0.85775 1.9158374999999999 -1.9156416666666665V19.19000208333333c0 -1.0580091666666664 -0.85775 -1.9157004166666667 -1.9158374999999999 -1.9157004166666667Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="m23.5 28.7687 0 -2.9149791666666665" stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M23.500195833333333 22.10488333333333c0.3707125 0 0.7330041666666667 0.10986249999999999 1.0412458333333332 0.31587916666666666 0.3082416666666667 0.20601666666666665 0.5485291666666666 0.49859166666666666 0.6903124999999999 0.8411041666666667 0.14178333333333334 0.3425125 0.17899166666666666 0.7192958333333334 0.10672916666666667 1.0829583333333335 -0.07245833333333333 0.3636625 -0.2508625 0.6975583333333334 -0.5130833333333333 0.9597791666666666 -0.262025 0.262025 -0.5961166666666666 0.440625 -0.9595833333333332 0.5128875 -0.3636625 0.07226250000000001 -0.7404458333333332 0.03525 -1.0829583333333335 -0.10672916666666667 -0.3425125 -0.14178333333333334 -0.6352833333333333 -0.3820708333333333 -0.8411041666666667 -0.6903124999999999 -0.20601666666666665 -0.3082416666666667 -0.31587916666666666 -0.6705333333333333 -0.31587916666666666 -1.0412458333333332 0 -0.49702500000000005 0.1974 -0.9738791666666666 0.5489208333333333 -1.3254 0.3515208333333333 -0.3515208333333333 0.8281791666666666 -0.5489208333333333 1.3254 -0.5489208333333333Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M30.204941666666667 12.485040833333333c0 -1.7782645833333333 -0.7063708333333334 -3.483679166666666 -1.9638166666666663 -4.741105416666667 -1.2574458333333334 -1.25742625 -2.962958333333333 -1.96383625 -4.741124999999999 -1.96383625 -1.7783625 0 -3.483679166666666 0.70641 -4.741164166666667 1.96383625 -1.25742625 1.25742625 -1.96383625 2.962840833333333 -1.96383625 4.741105416666667v4.7892608333333335h13.409942083333332V12.485040833333333Z"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col"><p
                class="mb-6 text-[24px] font-medium text-[#18181b]">
                Encryption is a key
            </p>
                <p class="text-[18px] text-gray-500">
                    Your data is encrypted before it leaves your device, during transfer,
                    and when it is stored on a server. We're using AES-256 encryption,
                    which guarantees the highest level of security for your information
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg" height="32"
                 width="32" fill="none"
                 class="mb-6 text-[48px] text-[#0066ff]">
                <path
                    d="M46.02083333333333 23.5s-10.081499999999998 12.729166666666666 -22.520833333333332 12.729166666666666S0.9791666666666666 23.5 0.9791666666666666 23.5 11.060666666666666 10.770833333333332 23.5 10.770833333333332 46.02083333333333 23.5 46.02083333333333 23.5Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M15.666666666666666 23.5a7.833333333333333 7.833333333333333 0 1 0 15.666666666666666 0 7.833333333333333 7.833333333333333 0 1 0 -15.666666666666666 0Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M23.5 19.583333333333332a3.9166666666666665 3.9166666666666665 0 1 1 -3.9166666666666665 3.9166666666666665"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col">
                <p class="mb-6 text-[24px] font-medium text-[#18181b]">
                    Open Source
                </p>
                <p class="text-[18px] text-gray-500">
                    We always strive to improve our product, which is why it is
                    open-source. This allows everyone to personally verify the reliability
                    of our service. The only thing we keep confidential is your data
                </p>
            </div>
        </div>
        <div
            class="flex flex-col items-start justify-start rounded-3xl border-[1px] border-gray-300 p-[48px] sm:p-[40px] md:max-w-[488px] hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                 viewBox="-0.5 -0.5 48 48" class="mb-6 text-[48px] text-[#0066ff]"
                 height="32" width="32">
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="m31.823699999999995 32.04949583333333 -1.5666666666666667 11.663441666666666c-0.19583333333333333 1.3837583333333332 1.3708333333333331 2.372129166666667 2.5458333333333334 1.3837583333333332l3.9166666666666665 -3.9536791666666664 3.9166666666666665 3.9536791666666664c0.9791666666666666 0.9883708333333334 2.7416666666666663 0.19759583333333333 2.5458333333333334 -1.3837583333333332l-1.5666666666666667 -11.663441666666666"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M36.71875 33.546054166666664c4.867045833333333 0 8.8125 -3.9454541666666665 8.8125 -8.8125s-3.9454541666666665 -8.812539166666667 -8.8125 -8.812539166666667 -8.8125 3.9454933333333333 -8.8125 8.812539166666667 3.9454541666666665 8.8125 8.8125 8.8125Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M23.010416666666664 45.53125h-19.583333333333332c-1.1749999999999998 0 -1.9583333333333333 -0.7833333333333333 -1.9583333333333333 -1.9583333333333333V3.427083333333333c0 -1.1749999999999998 0.7833333333333333 -1.9583333333333333 1.9583333333333333 -1.9583333333333333h19.779166666666665c0.39166666666666666 0 0.9791666666666666 0.19586858333333332 1.3708333333333331 0.5875391666666666l9.595833333333333 8.616647083333334"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 14.197916666666666h12.924999999999999"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 23.989583333333332h12.924999999999999"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M10.085220833333333 33.78125h9.791666666666666"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-miterlimit="10"
                      d="M36.71875 27.51223333333333c1.5345499999999999 0 2.7786791666666666 -1.2441291666666665 2.7786791666666666 -2.7786791666666666 0 -1.5347458333333333 -1.2441291666666665 -2.7788749999999998 -2.7786791666666666 -2.7788749999999998 -1.5347458333333333 0 -2.7786791666666666 1.2441291666666665 -2.7786791666666666 2.7788749999999998 0 1.5345499999999999 1.2439333333333333 2.7786791666666666 2.7786791666666666 2.7786791666666666Z"
                      stroke-width="2"></path>
            </svg>
            <div class="flex w-full max-w-[400px] flex-col">
                <p class="mb-6 text-[24px] font-medium text-[#18181b]">
                    Compliant and Secure
                </p>
                <p class="text-[18px] text-gray-500">
                    We have successfully passed several independent audits and can assure
                    you that your files are in safe hands. Our full compliance with GDPR
                    guarantees a 100% secure environment
                </p>
            </div>
        </div>
    </div>
</div>

<section
    class="flex flex-col items-center justify-center py-[64px] px-[20px] bg-[#F8F8Fb]">
    <div
        class="revealY max-w-screen-xl  flex max-w-[768px] flex-col items-center justify-center space-y-6 text-center text-black active">
        <p class="mb-6 max-w-screen-xl  text-[48px] font-semibold sm:leading-tight">
            Unique purchase conditions
        </p>
        <p class="text-[18px] max-w-[768px]  text-gray-600">
            Make a purchase with confidence, knowing that your payment <br/>is secure and
            can be refunded within 30 days if needed
        </p>
        <div
            class="flex cursor-pointer flex-row items-center justify-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline">
        </div>
    </div>
    <div
        class="flex flex-col items-center justify-center space-y-[32px] text-center md:flex-row md:space-y-0 md:space-x-[128px] md:pt-[32px]">
        <div class="flex max-w-[183px] flex-col items-center space-y-[12px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                 fill="currentColor" viewBox="-0.5 -0.5 48 48" class="text-[#0066ff]">
                <path
                    d="M0.987 4.895833333333333a14.6875 3.9166666666666665 0 1 0 29.375 0 14.6875 3.9166666666666665 0 1 0 -29.375 0Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M30.354166666666664 4.895833333333333v5.875c0 2.1639583333333334 -6.574125 3.9166666666666665 -14.6875 3.9166666666666665S0.9791666666666666 12.934791666666667 0.9791666666666666 10.770833333333332v-5.875"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M30.354166666666664 10.770833333333332v5.875c0 2.1639583333333334 -6.574125 3.9166666666666665 -14.6875 3.9166666666666665S0.9791666666666666 18.809791666666666 0.9791666666666666 16.645833333333332v-5.875"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M16.653666666666666 30.354166666666664a14.6875 3.9166666666666665 0 1 0 29.375 0 14.6875 3.9166666666666665 0 1 0 -29.375 0Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M46.02083333333333 30.354166666666664v5.875c0 2.1639583333333334 -6.574125 3.9166666666666665 -14.6875 3.9166666666666665s-14.6875 -1.7527083333333333 -14.6875 -3.9166666666666665v-5.875"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M46.02083333333333 36.229166666666664v5.875c0 2.1639583333333334 -6.574125 3.9166666666666665 -14.6875 3.9166666666666665s-14.6875 -1.7527083333333333 -14.6875 -3.9166666666666665v-5.875"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M30.354166666666664 16.645833333333332v5.875c0 2.1639583333333334 -6.574125 3.9166666666666665 -14.6875 3.9166666666666665s-14.6875 -1.7527083333333333 -14.6875 -3.9166666666666665v-5.875"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M0.9791666666666666 22.520833333333332v5.875c0 2.1639583333333334 6.576083333333333 3.9166666666666665 14.6875 3.9166666666666665h0.9791666666666666"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M0.9791666666666666 28.395833333333332v5.875c0 2.1639583333333334 6.576083333333333 3.9166666666666665 14.6875 3.9166666666666665h0.9791666666666666"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path d="m30.362 22.520833333333332 0 3.9166666666666665" fill="none"
                      stroke="#000000" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="1"></path>
            </svg>
            <p class="text-[20px] font-medium text-[#3a3a3b]">
                30-day money-back guarantee
            </p>
        </div>
        <div class="flex max-w-[183px] flex-col items-center space-y-[12px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                 fill="currentColor" viewBox="-0.5 -0.5 48 48" class="text-[#0066ff]">

                <path d="m38.21491666666667 8.773333333333333 -37.20833333333333 0"
                      fill="none" stroke="#0066ff" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2"></path>
                <path
                    d="M18.63158333333333 26.398333333333333h-14.6875a2.9375 2.9375 0 0 1 -2.9375 -2.9375v-19.583333333333332a2.9375 2.9375 0 0 1 2.9375 -2.9375h31.333333333333332a2.9375 2.9375 0 0 1 2.9375 2.9375v14.6875"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>
                <path d="m34.298249999999996 14.648333333333333 -5.875 0" fill="none"
                      stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"></path>
                <path d="m10.79825 18.565 -5.875 0" fill="none" stroke="#0066ff"
                      stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"></path>
                <path d="m14.714916666666667 14.648333333333333 -9.791666666666666 0"
                      fill="none" stroke="#0066ff" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2"></path>
                <path d="m34.298249999999996 26.398333333333333 0 11.75" fill="none"
                      stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"></path>
                <path d="m40.173249999999996 32.27333333333333 -11.75 0" fill="none"
                      stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"></path>
                <path
                    d="m35.34791666666667 45.779958333333326 1.0829583333333335 -0.41124999999999995c5.150416666666667 -1.9583333333333333 9.617375 -6.901166666666667 9.617375 -12.411916666666666v-6.644625a2.0425416666666663 2.0425416666666663 0 0 0 -1.227875 -1.876083333333333 30.988666666666663 30.988666666666663 0 0 0 -10.56325 -1.9583333333333333 30.409 30.409 0 0 0 -10.479041666666665 1.9583333333333333 2.0445 2.0445 0 0 0 -1.2298333333333333 1.876083333333333v6.644625c0 5.51075 4.38275 10.449666666666667 9.533166666666666 12.411916666666666l1.0829583333333335 0.41124999999999995a3.0628333333333333 3.0628333333333333 0 0 0 2.1835416666666667 0Z"
                    fill="none" stroke="#0066ff" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2"></path>

            </svg>
            <p class="text-[20px] font-medium text-[#3a3a3b]">
                100% secure payment
            </p>
        </div>
        <div class="flex max-w-[183px] flex-col items-center space-y-[12px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                 fill="none" viewBox="-0.5 -0.5 48 48" class="text-[#0066ff]">

                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M6.583916666666666 37.133916666666664a7.128333333333333 7.128333333333333 0 1 0 14.256666666666666 0 7.128333333333333 7.128333333333333 0 1 0 -14.256666666666666 0"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M26.159416666666665 37.133916666666664a7.128333333333333 7.128333333333333 0 1 0 14.256666666666666 0 7.128333333333333 7.128333333333333 0 1 0 -14.256666666666666 0"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M20.8873875 37.627808333333334c0.8089875 -0.31881666666666664 1.6902374999999998 -0.4938916666666666 2.6126125 -0.4938916666666666 0.9221791666666665 0 1.803625 0.17507499999999998 2.6126125 0.4938916666666666"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M10.0936025 6.109216666666667c0.27213 -1.9331099999999999 1.9262558333333333 -3.37066375 3.8784204166666663 -3.37066375h19.056052083333334c1.9520666666666666 0 3.606270833333333 1.43755375 3.878283333333333 3.37066375l2.3699749999999997 16.8348125H7.7236079166666665l2.3699945833333333 -16.8348125Z"
                      stroke-width="2"></path>
                <path stroke="#0066ff" stroke-linecap="round" stroke-linejoin="round"
                      d="M2.285845 22.944029166666667H44.71423333333333"
                      stroke-width="2"></path>
            </svg>
            <p class="text-[20px] font-medium text-[#3a3a3b]">
                Anonymous account creation
            </p>
        </div>
    </div>
</section>

<div class="flex flex-col items-center justify-center space-y-24 py-[64px] px-5">
    <div class="max-w-screen-xl w-[100%] flex flex-row justify-between gap-[36px]">
        <div class="grid grid-cols-3 gap-[24px] w-full">
            <div
                class="flex justify-between snap-center flex-col overflow-hidden rounded-3xl bg-white p-[32px] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                <div class="flex flex-col gap-[15px]">
                    <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg"
                         class="w-[48px] h-[48px] text-gray-800 dark:text-white"
                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                         fill="#0066ff"
                         height="48" width="48">
                        <path
                            d="M0.9791666666666666 23.470625a22.520833333333332 22.520833333333332 0 1 0 45.041666666666664 0 22.520833333333332 22.520833333333332 0 1 0 -45.041666666666664 0Z"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                        <path
                            d="M1.3179583333333333 19.55395833333333h11.914499999999999a5.875 5.875 0 0 1 4.1555833333333325 1.7194166666666666L19.583333333333332 23.468666666666667a5.875 5.875 0 0 1 0.23695833333333333 8.058541666666667l-2.669208333333333 3.002125A5.875 5.875 0 0 0 15.666666666666666 38.432291666666664v6.1589583333333335"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                        <path
                            d="M39.677791666666664 7.803958333333333h-11.358333333333333a4.40625 4.40625 0 0 0 0 8.8125h0.846a5.875 5.875 0 0 1 4.895833333333333 2.614375l4.343583333333333 6.519291666666667a5.875 5.875 0 0 0 4.895833333333333 2.6163333333333334h2.1952916666666664"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <div>
                        <p class="mb-6 text-[24px] text-[#18181b] font-semibold sm:leading-tight">
                            Your Network Ally<br/><br/>
                        </p>
                        <p class="text-[18px] text-gray-500 font-normal">
                            Our cloud solution is designed to be your trusted partner,
                            providing
                            easy access and protection for your valuable data anytime,
                            anywhere.
                            Experience the convenience of secure file management at your
                            fingertips
                        </p>
                    </div>
                </div>
                <div
                    class="flex cursor-pointer flex-row items-center justify-center space-x-2 text-[#0066ff] lg:justify-start">
                    <div
                        class="flex flex-row items-center rounded-xl py-[12px] hover:underline decoration-2">
                        <a href="/privacy"
                           class="text-[20px] text-[#0066ff] visited:text-[#0066ff]">
                            The
                            fight for
                            privacy
                        </a>
                        <svg
                            class="w-[18px] h-[18px] text-gray-800 dark:text-white ml-[8px]"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="#0066ff" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div
                class="flex justify-between gap-[24px] snap-center flex-col overflow-hidden rounded-3xl bg-white p-[32px]  rounded-3xl border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                <div class="flex flex-col gap-[15px]">
                    <svg class="w-[48px] h-[48px] text-gray-800 dark:text-white"
                         xmlns="http://www.w3.org/2000/svg" fill="none"
                         viewBox="-0.5 -0.5 48 48" height="48" width="48">
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M29.046 10.366887916666666c3.9758083333333327 -0.7992545833333333 7.1769 -4.039199583333334 7.9774666666666665 -8.117859583333333 0.8003708333333334 4.07866 4.000679166666666 7.318605 7.9764875 8.117859583333333m0 0.004660833333333334c-3.9758083333333327 0.7992545833333333 -7.1769 4.039219166666666 -7.977270833333333 8.117879166666667 -0.8005666666666666 -4.07866 -4.000875 -7.3186245833333325 -7.976683333333333 -8.117879166666667"
                              stroke-width="2"></path>
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M22.685920833333334 10.373056666666665H5.82018625c-2.1096145833333333 0 -3.819787916666667 1.71015375 -3.819787916666667 3.819768333333333V33.291666666666664c0 2.1095166666666665 1.710173333333333 3.8197291666666664 3.819787916666667 3.8197291666666664H36.378391666666666c2.1095166666666665 0 3.8197291666666664 -1.7102125 3.8197291666666664 -3.8197291666666664v-9.518087499999998"
                              stroke-width="2"></path>
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M21.099866666666667 37.108850000000004v7.639654166666666"
                              stroke-width="2"></path>
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.368334166666667 44.75105h11.459265833333333"
                              stroke-width="2"></path>
                        <path stroke="#0066ff" stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.000398333333333 29.79565H40.198120833333334"
                              stroke-width="2"></path>
                    </svg>
                    <div>
                        <p class="mb-6 text-[24px]  text-[#18181b] font-semibold sm:leading-tight">
                            Various devices, Unified experience
                        </p>
                        <p class="text-[18px]  text-gray-500">
                            Discover our cloud storage service, available both as a web
                            version
                            and a desktop application. Enjoy seamless synchronization of
                            your
                            data
                            across all devices, combined with robust encryption measures
                            to
                            safeguard your information
                        </p>
                    </div>
                </div>
                <div
                    class="flex cursor-pointer text-[#0066ff]  flex-row items-center justify-center space-x-2 text-[#0066ff] lg:justify-start">
                    <div
                        class="flex flex-row items-center rounded-xl py-[12px] hover:underline decoration-2">
                        <a href="/drive-info"
                           class="text-[18px] text-[#0066ff] visited:text-[#0066ff]">Download
                            apps</a>
                        <svg
                            class="w-[18px] h-[18px] text-gray-800 dark:text-white ml-[8px]"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="#0066ff" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div
                class="justify-between flex snap-center flex-col overflow-hidden rounded-3xl bg-white p-[32px] border-[1px] border-gray-300 hover:border-[#0066ff] transition-all duration-300 hover:shadow-[5px_5px_0px_0px_rgba(0,102,255,1.0)]">
                <div class="flex flex-col gap-[15px]">
                    <svg viewBox="-0.5 -0.5 48 48" xmlns="http://www.w3.org/2000/svg"
                         class="w-[48px] h-[48px] text-gray-800 dark:text-white"
                         height="48" width="48">
                        <path
                            d="M40.14583333333333 27.234541666666665A8.446291666666665 8.446291666666665 0 0 0 45.041666666666664 19.30525a9.225708333333333 9.225708333333333 0 0 0 -9.701583333333332 -9.076875A13.134541666666665 13.134541666666665 0 0 0 23.5 2.9375a12.934791666666667 12.934791666666667 0 0 0 -13.071874999999999 11.963458333333334A6.973624999999999 6.973624999999999 0 0 0 1.9583333333333333 21.576916666666666 6.239249999999999 6.239249999999999 0 0 0 6.854166666666666 28.004166666666666"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                        <path
                            d="M11.75 32.314458333333334a11.75 11.75 0 1 0 23.5 0 11.75 11.75 0 1 0 -23.5 0Z"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                        <path
                            d="m28.736583333333332 28.899124999999998 -5.679166666666666 7.5865833333333335a1.46875 1.46875 0 0 1 -2.212916666666666 0.15666666666666668l-2.9375 -2.9375"
                            fill="none" stroke="#0066ff" stroke-linecap="round"
                            stroke-linejoin="round" stroke-width="2"></path>
                    </svg>
                    <div>
                        <p class="mb-6 text-[24px] font-semibold sm:leading-tight">
                            AyrDrive is free<br/><br/>
                        </p>
                        <p class=" text-[18px] text-gray-500">
                            We offer a generous free tier for our cloud service, providing
                            users
                            with essential features at no cost. This complimentary access
                            allows
                            individuals to experience the full functionality of our
                            service
                            before
                            considering any premium options
                        </p>
                    </div>
                </div>
                <div
                    class="flex cursor-pointer  text-[#0066ff] flex-row items-center justify-center space-x-2 text-[#0066ff] lg:justify-start">
                    <div
                        class="flex flex-row items-center rounded-xl py-[12px] hover:underline decoration-2">
                        <a href="/register"
                           class="text-[18px] text-[#0066ff] visited:text-[#0066ff]">Create
                            free account</a>
                        <svg
                            class="w-[18px] h-[18px] text-gray-800 dark:text-white ml-[8px]"
                            aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 10">
                            <path stroke="#0066ff" stroke-linecap="round"
                                  stroke-linejoin="round" stroke-width="2"
                                  d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="overflow-hidden bg-[#F8F8Fb]">
    <div
        class="flex flex-col items-center justify-center space-y-[16px] py-[64px] px-[20px]">
        <div class="flex w-full max-w-[768px] flex-col space-y-[16px] text-center"><p
            class="text-[48px] font-semibold">Built for the future</p>
            <p class="text-[20px]">We want to build a better World Wide Web, one that’s
                fair,
                safe, and equitable for all. The battle is raging for the future of
                internet privacy, and we’re at the frontline</p></div>
        <div
            class="flex flex-col space-y-[24px] lg:flex-row lg:space-y-0 lg:space-x-[64px]">
            <div
                class="flex w-full max-w-[120px] flex-col items-center space-y-[12px] pt-16 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                     fill="currentColor" viewBox="0 0 256 256" class="text-[#0066ff]">
                    <path
                        d="M128,24C74.17,24,32,48.6,32,80v96c0,31.4,42.17,56,96,56s96-24.6,96-56V80C224,48.6,181.83,24,128,24Zm80,104c0,9.62-7.88,19.43-21.61,26.92C170.93,163.35,150.19,168,128,168s-42.93-4.65-58.39-13.08C55.88,147.43,48,137.62,48,128V111.36c17.06,15,46.23,24.64,80,24.64s62.94-9.68,80-24.64ZM69.61,53.08C85.07,44.65,105.81,40,128,40s42.93,4.65,58.39,13.08C200.12,60.57,208,70.38,208,80s-7.88,19.43-21.61,26.92C170.93,115.35,150.19,120,128,120s-42.93-4.65-58.39-13.08C55.88,99.43,48,89.62,48,80S55.88,60.57,69.61,53.08ZM186.39,202.92C170.93,211.35,150.19,216,128,216s-42.93-4.65-58.39-13.08C55.88,195.43,48,185.62,48,176V159.36c17.06,15,46.23,24.64,80,24.64s62.94-9.68,80-24.64V176C208,185.62,200.12,195.43,186.39,202.92Z"></path>
                </svg>
                <p class="text-[20px] font-medium text-[#18181b]">
                    Distributed work
                </p>
            </div>
            <div
                class="flex w-full max-w-[120px] flex-col items-center space-y-[12px] pt-16 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                     fill="currentColor" viewBox="0 0 256 256" class="text-[#0066ff]">
                    <path
                        d="M160,16A80.07,80.07,0,0,0,83.91,120.78L26.34,178.34A8,8,0,0,0,24,184v40a8,8,0,0,0,8,8H72a8,8,0,0,0,8-8V208H96a8,8,0,0,0,8-8V184h16a8,8,0,0,0,5.66-2.34l9.56-9.57A80,80,0,1,0,160,16Zm0,144a63.7,63.7,0,0,1-23.65-4.51,8,8,0,0,0-8.84,1.68L116.69,168H96a8,8,0,0,0-8,8v16H72a8,8,0,0,0-8,8v16H40V187.31l58.83-58.82a8,8,0,0,0,1.68-8.84A64,64,0,1,1,160,160Zm32-84a12,12,0,1,1-12-12A12,12,0,0,1,192,76Z"></path>
                </svg>
                <p class="text-[20px] font-medium text-[#18181b]">AES-256 encryption</p>
            </div>
            <div
                class="flex w-full max-w-[120px] flex-col items-center space-y-[12px] pt-16 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                     fill="currentColor" viewBox="0 0 256 256" class="text-[#0066ff]">
                    <path
                        d="M96,208a8,8,0,0,1-8,8H40a24,24,0,0,1-20.77-36l34.29-59.25L39.47,124.5A8,8,0,1,1,35.33,109l32.77-8.77a8,8,0,0,1,9.8,5.66l8.79,32.77A8,8,0,0,1,81,148.5a8.37,8.37,0,0,1-2.08.27,8,8,0,0,1-7.72-5.93l-3.8-14.15L33.11,188A8,8,0,0,0,40,200H88A8,8,0,0,1,96,208Zm140.73-28-23.14-40a8,8,0,0,0-13.84,8l23.14,40A8,8,0,0,1,216,200H147.31l10.34-10.34a8,8,0,0,0-11.31-11.32l-24,24a8,8,0,0,0,0,11.32l24,24a8,8,0,0,0,11.31-11.32L147.31,216H216a24,24,0,0,0,20.77-36ZM128,32a7.85,7.85,0,0,1,6.92,4l34.29,59.25-14.08-3.78A8,8,0,0,0,151,106.92l32.78,8.79a8.23,8.23,0,0,0,2.07.27,8,8,0,0,0,7.72-5.93l8.79-32.79a8,8,0,1,0-15.45-4.14l-3.8,14.17L148.77,28a24,24,0,0,0-41.54,0L84.07,68a8,8,0,0,0,13.85,8l23.16-40A7.85,7.85,0,0,1,128,32Z"></path>
                </svg>
                <p class="text-[20px] font-medium text-[#18181b]">Built-in redundancy</p>
            </div>
            <div
                class="flex w-full max-w-[120px] flex-col items-center space-y-[12px] pt-16 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                     fill="currentColor" viewBox="0 0 256 256" class="text-[#0066ff]">
                    <path
                        d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"></path>
                </svg>
                <p class="text-[20px] font-medium text-[#18181b]">Opensource code</p>
            </div>
            <div
                class="flex w-full max-w-[120px] flex-col items-center space-y-[12px] pt-16 text-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                     fill="currentColor" viewBox="0 0 256 256" class="text-[#0066ff]">
                    <path
                        d="M225.86,102.82c-3.77-3.94-7.67-8-9.14-11.57-1.36-3.27-1.44-8.69-1.52-13.94-.15-9.76-.31-20.82-8-28.51s-18.75-7.85-28.51-8c-5.25-.08-10.67-.16-13.94-1.52-3.56-1.47-7.63-5.37-11.57-9.14C146.28,23.51,138.44,16,128,16s-18.27,7.51-25.18,14.14c-3.94,3.77-8,7.67-11.57,9.14C88,40.64,82.56,40.72,77.31,40.8c-9.76.15-20.82.31-28.51,8S41,67.55,40.8,77.31c-.08,5.25-.16,10.67-1.52,13.94-1.47,3.56-5.37,7.63-9.14,11.57C23.51,109.72,16,117.56,16,128s7.51,18.27,14.14,25.18c3.77,3.94,7.67,8,9.14,11.57,1.36,3.27,1.44,8.69,1.52,13.94.15,9.76.31,20.82,8,28.51s18.75,7.85,28.51,8c5.25.08,10.67.16,13.94,1.52,3.56,1.47,7.63,5.37,11.57,9.14C109.72,232.49,117.56,240,128,240s18.27-7.51,25.18-14.14c3.94-3.77,8-7.67,11.57-9.14,3.27-1.36,8.69-1.44,13.94-1.52,9.76-.15,20.82-.31,28.51-8s7.85-18.75,8-28.51c.08-5.25.16-10.67,1.52-13.94,1.47-3.56,5.37-7.63,9.14-11.57C232.49,146.28,240,138.44,240,128S232.49,109.73,225.86,102.82Zm-11.55,39.29c-4.79,5-9.75,10.17-12.38,16.52-2.52,6.1-2.63,13.07-2.73,19.82-.1,7-.21,14.33-3.32,17.43s-10.39,3.22-17.43,3.32c-6.75.1-13.72.21-19.82,2.73-6.35,2.63-11.52,7.59-16.52,12.38S132,224,128,224s-9.15-4.92-14.11-9.69-10.17-9.75-16.52-12.38c-6.1-2.52-13.07-2.63-19.82-2.73-7-.1-14.33-.21-17.43-3.32s-3.22-10.39-3.32-17.43c-.1-6.75-.21-13.72-2.73-19.82-2.63-6.35-7.59-11.52-12.38-16.52S32,132,32,128s4.92-9.15,9.69-14.11,9.75-10.17,12.38-16.52c2.52-6.1,2.63-13.07,2.73-19.82.1-7,.21-14.33,3.32-17.43S70.51,56.9,77.55,56.8c6.75-.1,13.72-.21,19.82-2.73,6.35-2.63,11.52-7.59,16.52-12.38S124,32,128,32s9.15,4.92,14.11,9.69,10.17,9.75,16.52,12.38c6.1,2.52,13.07,2.63,19.82,2.73,7,.1,14.33.21,17.43,3.32s3.22,10.39,3.32,17.43c.1,6.75.21,13.72,2.73,19.82,2.63,6.35,7.59,11.52,12.38,16.52S224,124,224,128,219.08,137.15,214.31,142.11ZM173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34Z"></path>
                </svg>
                <p class="text-[20px] font-medium text-[#18181b]">Independently
                    verified</p>
            </div>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-white py-[64px]">
    <div
        class="flex flex-col items-center justify-center space-y-20 px-[40px]">
        <p class="text-center text-[36px] font-semibold">Questions? We have answers</p>
        <div class="flex w-full max-w-[850px] flex-col space-y-2">
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===0 ? tabIndex=-1 : tabIndex=0"
                            type="button"
                            class="my-[20px] flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[18px] font-medium md:text-[24px]">Are my files accessible to AyrDrive?</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===0 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===0"
                          class=" pb-[24px] markdown will-change-height flex h-auto flex-col space-y-[12px] overflow-hidden duration-250 whitespace-wrap w-full pr-14 text-left text-[18px] text-gray-60 transition-all ease-in-out"><p>
AyrDrive, as well as any other third-party service, is unable to access your files, photos, or data. The zero-knowledge encryption employed by AyrDrive ensures that only you have the capability to access your private data. Moreover, AyrDrive's system fragments your files before encrypting them, ensuring that a server never possesses an entire file; instead, it only holds an encrypted shard that can be decrypted solely by you            </p></span>
                </div>
            </div>
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===1 ? tabIndex=-1 : tabIndex=1"
                            type="button"
                            class="my-[20px] flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[18px] font-medium md:text-[24px]">Does AyrDrive save my password data?</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===1 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===1"
                          class=" pb-[24px] markdown will-change-height flex h-auto flex-col space-y-[12px] overflow-hidden duration-250 whitespace-wrap w-full pr-14 text-left text-[18px] text-gray-60 transition-all ease-in-out"><p>
No, our service does not store your password. Your password is used to encrypt and decrypt your files in the storage. However, you also have the option to change it if the need arises           </p></span>
                </div>
            </div>
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===2 ? tabIndex=-1 : tabIndex=2"
                            type="button"
                            class="my-[20px] flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[18px] font-medium md:text-[24px]">How to create an AyrDrive account?</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===2 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===2"
                          class=" pb-[24px] markdown will-change-height flex h-auto flex-col space-y-[12px] overflow-hidden duration-250 whitespace-wrap w-full pr-14 text-left text-[18px] text-gray-60 transition-all ease-in-out"><p>
Visit our website and click on 'Get started' located in the upper right-hand corner. Register using your email address and create a password. Afterward, you'll be able to interact with our service                    </p></span>
                </div>
            </div>
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===3 ? tabIndex=-1 : tabIndex=3"
                            type="button"
                            class="my-[20px] flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[18px] font-medium md:text-[24px]">Can I use a credit card, PayPal, or cryptocurrency for payment?</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===3 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===3"
                          class=" pb-[24px] markdown will-change-height flex h-auto flex-col space-y-[12px] overflow-hidden duration-250 whitespace-wrap w-full pr-14 text-left text-[18px] text-gray-60 transition-all ease-in-out"><p>
We accept major credit and debit cards such as MasterCard, Visa, etc. You can also make payments through PayPal. Upon request, we also accept cryptocurrency                    </p></span>
                </div>
            </div>
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===4 ? tabIndex=-1 : tabIndex=4"
                            type="button"
                            class="my-[20px] flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[18px] font-medium md:text-[24px]">How does AyrDrive handle refunds?</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             fill="currentColor" viewBox="0 0 256 256"
                             [class]="tabIndex===4 ? 'rotate-45' : ''"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===4"
                          class=" pb-[24px] markdown will-change-height flex h-auto flex-col space-y-[12px] overflow-hidden duration-250 whitespace-wrap w-full pr-14 text-left text-[18px] text-gray-60 transition-all ease-in-out"><p>
We offer a refund within 30 days of purchase if you are dissatisfied with our service or for any other reasons                    </p></span>
                </div>
            </div>
            <div class="rounded-lg border border-gray-20 px-[20px]">
                <div class="flex flex-col items-stretch justify-start">
                    <button (click)="tabIndex===5 ? tabIndex=-1 : tabIndex=5"
                            type="button"
                            class="my-[20px] flex cursor-pointer flex-row items-center justify-between space-x-6 text-left hover:text-[#0066ff]">
                        <span class="w-full text-[18px] font-medium md:text-[24px]">How can I terminate my subscription?</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                             [class]="tabIndex===5 ? 'rotate-45' : ''"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="false duration-250 transition-transform ease-in-out">
                            <path
                                d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H136v32a8,8,0,0,1-16,0V136H88a8,8,0,0,1,0-16h32V88a8,8,0,0,1,16,0v32h32A8,8,0,0,1,176,128Z"></path>
                        </svg>
                    </button>
                    <span *ngIf="tabIndex===5"
                          class="  pb-[24px] markdown will-change-height flex h-auto flex-col space-y-[12px] overflow-hidden duration-250 whitespace-wrap w-full pr-14 text-left text-[18px] text-gray-60 transition-all ease-in-out"><p>
Cancelling your subscription is easy through the AyrDrive app. Simply go to settings, select subscriptions, and cancel the subscription renewal there. You will retain access to the free plan after cancellation                    </p></span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="overflow-hidden bg-[#0066ff] py-[64px]">
    <div
        class="flex flex-col items-center justify-center space-y-8 px-[20px] text-center">
        <div class="flex flex-col items-center space-y-[16px] text-center text-white">
            <p class="text-[36px] font-semibold">The best cloud storage service for your
                data
            </p>
            <p class="w-full max-w-[570px] text-[20px] font-normal">
                Ensure the safety of your important data with AyrDrive's reliable,
                opensource, and encrypted storage
            </p>
        </div>
        <a (click)="scrollToTop()"
           class="flex rounded-lg bg-white px-[20px] py-[12px] text-[18px] font-medium text-[#0066ff] hover:bg-[#ffffffee]">
            Select a plan now
        </a>
    </div>
</section>


<div class="flex w-full flex-col items-center justify-center px-[24px] py-[64px]">
    <div
        class="flex w-full max-w-screen-md flex-col items-center justify-between space-y-8 pb-9 text-center lg:flex-row lg:items-start lg:space-y-0 lg:space-x-32 lg:text-left">
        <div
            class="flex w-full max-w-[384px] flex-col items-center justify-center space-y-3 lg:items-start">
            <div class="flex flex-col space-y-1">
                <h2 class="text-[24px] font-medium ">
                    Download AyrDrive
                </h2>
                <p class="text-gray-600 text-[16px]">
                    Smooth data management and secure cloud storage are available with the
                    AyrDrive desktop app
                </p>
            </div>
            <div class="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-4">
                <div class="flex"><a
                    class="flex flex-row items-center space-x-1 text-[18px] font-semibold text-[#0066ff] hover:underline"
                    download href="/assets/AyrDrive.zip">
                    <span>Download for
                        <!-- --> Windows</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                         fill="currentColor" viewBox="0 0 256 256">
                        <path
                            d="M128,20A108,108,0,1,0,236,128,108.12,108.12,0,0,0,128,20Zm0,192a84,84,0,1,1,84-84A84.09,84.09,0,0,1,128,212Zm40.49-84.49a12,12,0,0,1,0,17l-32,32a12,12,0,0,1-17,0l-32-32a12,12,0,1,1,17-17L116,139V88a12,12,0,0,1,24,0v51l11.51-11.52A12,12,0,0,1,168.49,127.51Z"></path>
                    </svg>
                </a></div>
            </div>
        </div>
        <div
            class="flex mb-10 max-w-[384px] flex-col items-center justify-center space-y-3 text-center md:items-start md:text-left ">
            <div class="flex w-full flex-col space-y-1"><h2
                class="text-[24px] font-medium">Newsletter</h2>
                <p class="text-[16px] text-gray-600">
                    Want to stay informed about our latest products and updates? Enter
                    your email address, and we'll take care of the rest!
                </p>
            </div>
            <form data-code="r3s4c1" method="post" target="_blank" rel="noopener"
                  action=""
                  class="flex w-full flex-col items-center justify-center gap-4 md:flex-row">
                <input type="hidden" name="ml-submit" value="1">
                <input type="email" name="fields[email]"
                       placeholder="Your email address"
                       class="flex h-auto w-full flex-row rounded-lg px-[12px] py-3 text-[18px] outline-none sm:py-2 sm:text-[16px] md:w-64 border-cool-gray-20 bg-white focus:border-blue-50 focus:ring-opacity-20 mb-2 appearance-none border text-left transition-all duration-150 focus:ring focus:ring-primary"
                       required="">
                <input type="submit" name="signup" value="Subscribe"
                       class="ml-2 flex w-full cursor-pointer items-center justify-center rounded-lg border border-transparent bg-[#0066ff] px-[12px] py-3 text-[18px] font-medium text-white transition-all duration-75 hover:bg-[#0066ff]-dark focus:outline-none active:bg-[#0066ff]-dark sm:mb-2 sm:py-2 sm:text-[16px]">
            </form>
            <span class="text-[16px] text-gray-400">By subscribing you agree to our
                <a href="/legal" class="cursor-pointer underline visited:text-gray-400">
                    privacy policy
                </a>
            </span>
        </div>
    </div>
    <footer class="flex max-w-screen-md w-[100%] items-center justify-center">
        <div
            class="w-[100%] flex-col items-center justify-center md:space-y-[56px] lg:flex">
            <div
                class="flex w-[100%] flex-row justify-between md:justify-between lg:space-x-[80px]">
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Products</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/drive">AyrDrive
                                Storage</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/photos">AyrDrive
                                Photos</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/pricing">Pricing</a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Company</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/about">About Us</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/privacy">Privacy</a>
                            </p>
                            <p class="hover:text-[#0066ff]"><a
                                class="flex max-w-[200px] flex-row items-center hover:text-[#0066ff]"
                                href="/open-source">Open Source</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                class="hover:text-[#0066ff]" href="/legal">Legal</a></p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-1 flex-col items-center lg:flex-none">
                    <div class="flex flex-shrink-0 flex-col space-y-3"><h3
                        class="text-[18px] font-medium">Join Us</h3>
                        <div
                            class="flex flex-col space-y-1.5 text-[18px] text-[#697077]">
                            <p class="hover:text-[#0066ff]"><a href="/register"
                                                               target="_top"
                                                               class="hover:text-[#0066ff]">Create
                                Account</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/login?" target="_top">Log In</a></p>
                            <p class="hover:text-[#0066ff]"><a
                                href="/assets/images/homepage/AyrDrive-white-paper.pdf"
                                target="_blank"
                                rel="noreferrer" download="" class="hover:text-[#0066ff]">White
                                Paper</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex w-full max-w-[900px] flex-row justify-between  border-t-[1px] border-gray-300 pt-[20px]">
                <div class="flex flex-row items-center space-x-4"><a
                    style="cursor: pointer !important"
                    class="flex flex-shrink-0" href="/"><img loading="lazy"
                                                             style="height:24px"
                                                             src="/assets/images/homepage/AyrDrive.svg"
                                                             alt="AyrDrive logo"></a>
                    <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive
                        Universal Technologies SL</p></div>
                <div class="flex flex-row space-x-1">
                </div>
            </div>
        </div>
        <div class="bg-gray-5 bg-opacity-50 flex flex-col overflow-hidden lg:hidden">
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                    id="headlessui-disclosure-button-5" type="button"
                    aria-expanded="false"><span class="flex flex-row">Products</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <button
                    class="flex w-full items-center justify-between px-[24px] py-4 text-[#18181b] text-[18px] font-medium"
                    id="headlessui-disclosure-button-7" type="button"
                    aria-expanded="false"><span class="flex flex-row">Company</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </button>
            </div>
            <div class="w-screen">
                <a href="/register"
                   class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                   id="headlessui-disclosure-button-9" type="button"
                   aria-expanded="false"><span class="flex flex-row">Join Us</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="flex text-gray-600">
                        <path
                            d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                         fill="currentColor" viewBox="0 0 256 256"
                         class="hidden text-gray-600">
                        <path
                            d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                    </svg>
                </a>
            </div>
            <div class="flex w-screen">
                <div class="w-screen">
                    <button
                        class="flex w-full items-center justify-between px-[24px] py-4 text-[18px] font-medium"
                        id="headlessui-disclosure-button-15" type="button"
                        aria-expanded="false">
                        <div class="flex flex-row items-center space-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                 fill="currentColor" viewBox="0 0 256 256"
                                 class="text-gray-60">
                                <path
                                    d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24ZM101.63,168h52.74C149,186.34,140,202.87,128,215.89,116,202.87,107,186.34,101.63,168ZM98,152a145.72,145.72,0,0,1,0-48h60a145.72,145.72,0,0,1,0,48ZM40,128a87.61,87.61,0,0,1,3.33-24H81.79a161.79,161.79,0,0,0,0,48H43.33A87.61,87.61,0,0,1,40,128ZM154.37,88H101.63C107,69.66,116,53.13,128,40.11,140,53.13,149,69.66,154.37,88Zm19.84,16h38.46a88.15,88.15,0,0,1,0,48H174.21a161.79,161.79,0,0,0,0-48Zm32.16-16H170.94a142.39,142.39,0,0,0-20.26-45A88.37,88.37,0,0,1,206.37,88ZM105.32,43A142.39,142.39,0,0,0,85.06,88H49.63A88.37,88.37,0,0,1,105.32,43ZM49.63,168H85.06a142.39,142.39,0,0,0,20.26,45A88.37,88.37,0,0,1,49.63,168Zm101.05,45a142.39,142.39,0,0,0,20.26-45h35.43A88.37,88.37,0,0,1,150.68,213Z"></path>
                            </svg>
                            <span class="flex flex-row">English (EN)</span></div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="flex text-gray-600">
                            <path
                                d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                             fill="currentColor" viewBox="0 0 256 256"
                             class="hidden text-gray-600">
                            <path
                                d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div
                class="flex flex-col items-center space-y-4 py-10 border-t-[1px] border-gray-300">
                <div class="flex flex-row space-x-1">
                </div>
                <p class="text-[12px] text-[#697077]">Copyright © 2023, AyrDrive Universal
                    Technologies SL</p><a class="flex flex-shrink-0" routerLink="/"
                                          fragment="top-one"><img
                loading="lazy" width="110" height="12"
                src="/assets/images/homepage/AyrDrive.svg"
                alt="AyrDrive logo"></a></div>
        </div>
    </footer>
</div>
