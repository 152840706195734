<div class="auth-page-content bg-[#F3F3F8] -mt-[30px]">
    <a class="logo" [routerLink]="['/']"><img class="h-[48px]" src="/assets/images/homepage/AyrDrive.svg" alt="logo"></a>
    <div class="flex items-center justify-center">
    <div class="rounded-[16px] min-w-[450px] px-[36px] py-[48px] bg-white shadow-xl">
        <ng-content></ng-content>
    </div>
    </div>
    <div class="info-row">
        <ng-container *ngIf="infoRowTarget === 'signup' && !settings.get('registration.disable')">
            <span trans>Don't have an account?</span>&ngsp;
            <a class="text-[#0066ff]" href="/register" trans>Sign up.</a>
        </ng-container>

        <ng-container *ngIf="infoRowTarget !== 'signup'">
            <span trans>Already have an account?</span>&ngsp;
            <a href="/login" class="text-[#0066ff]" trans>Sign in.</a>
        </ng-container>
    </div>
</div>
<div class="flex items-center justify-center">
    <a routerLink="/" class="text-[#0066ff] mb-12">© AyrDrive</a>
</div>
